@charset "utf-8";
//@include sp {};
//@include tb {};
//@include pc {};

.c-share {
  width: 100%;
  height: auto;
  margin-top: 74px;
  margin-bottom: 43px;
  @include pc {
    margin-top: 93px;
    margin-bottom: 43px;
  }
  &__inner {
    display: block;
    max-width: 878px;
    height: auto;
    margin: 0 auto;
  }
  &__title {
    font-size: 13px;
    line-height: calc(32 / 26);
    text-align: center;
    letter-spacing: 0.06em;
    @include font-webfont_zeitung_bold;
    @include pc {
      font-size: 14px;
      line-height: calc(32 / 24);
    }
  }
  &__btns {
    display: flex;
    justify-content: space-between;
    max-width: 153px;
    margin: 0 auto;
    margin-top: 12px;
    @include pc {
      max-width: 184px;
      margin-top: 19px;
    }
  }
  &__btn {
    width: 41px;
    height: 41px;
    border: 2px solid #e8a8b4;
    border-radius: 50%;
    transition: all 0.3s ease 0s;
    will-change: contents;
    @include pc {
      width: 48px;
      height: 48px;
    }
    &:hover {
      @include pc {
        transition: all 0.3s ease 0s;
        transform: translateY(-0.3em);
        will-change: contents;
      }
    }
  }
  &__btnLink {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
  }
  &__btnImg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 16px;
    height: auto;
    margin: auto;
    @include tb {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      width: 20px;
      height: auto;
      margin: auto;
    }
    @at-root .c-share__btn.-line & {
      width: 24px;
      height: auto;
      @include tb {
        width: 28px;
        height: auto;
      }
    }
  }
}

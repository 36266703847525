@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.c-sectionInner {
  @include tb {
  }
  @include pc {
    min-height: 500px;
  }
}

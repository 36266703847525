@charset "utf-8";

/*
@include touch {} // max-width: 979px
@include sp {}    // max-width: 599px
@include tb {}    // min-width: 600px
@include pc {}    // min-width: 980px
@include wide {}  // min-width: 1280px
*/

/* -------------------------------------------------- ABOUT */
.l-main.p-about {
  padding-top: 42px;
}

/* スヌーピーミュージアムとは？ */
.p-aboutMuseum {
  margin: 88px 0;
}

.p-aboutMuseum .c-title {
  font-size: 16px;
}

.p-aboutMuseum .c-text {
  font-size: 13px;
}

/* THE HISTORY OF MUSEUM */
.p-history {
  max-width: 1040px;
  margin-right: auto;
  margin-left: auto;
}

.c-history {
  padding: 40px;
  margin: 32px 0;
  background: white;
  border-radius: $var-border-radius;
}

.history-date {
  margin-top: 0;
  font-size: 28px;
  @include font-webfont_zeitung_bold;
}

.history-title {
  margin-top: 1em;
  font-size: 16px;
}

.history-text {
  margin-bottom: 0;
  font-size: 13px;
}

.history-img img {
  width: 100%;
}

@include touch {
  .p-history {
    padding: 64px 0 80px;
  }
  .c-history {
    padding: 24px;
  }
  .history-date {
    font-size: 20px;
  }
  .history-title {
    font-size: 15px;
  }
  .history-img {
    margin-top: 32px;
  }
}

@media (min-width: 1025px) {
  .history-img {
    padding-left: 80px;
  }
}

@include pc {
  .p-history {
    padding: 80px 0 120px;
  }
  .c-history {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 880px;
    margin: 32px auto;
  }
  .history-txt {
    flex-basis: 55%;
  }
  .history-img {
    flex-basis: 45%;
    padding-left: 40px;
  }
}

@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include touch{}
*/

.c-h1 {
  @include fz(24);
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  &:first-child {
    margin-top: 0;
  }
}

.c-h2 {
  @include lhCrop(60 / 40);
  @include fz(20);
  position: relative;
  margin-top: 2rem;
  line-height: (60 / 40);
  @include pc {
    @include lhCrop(26/22);
    @include fz(22);
    line-height: (26/22);
  }
  &::after {
    display: block;
    width: 30px;
    height: 2px;
    margin-top: 11px;
    margin-left: 1px;
    content: "";
    background: #444;
    @include pc {
      width: 60px;
      height: 3px;
      margin-top: 20px;
    }
    @include touch {
      position: absolute;
      bottom: -7px;
      left: 0;
    }
  }
  &:first-child {
    margin-top: 0;
  }
}

.c-h3 {
  @include fz(14);
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  &:first-child {
    margin-top: 0;
  }
}

.c-h4 {
  @include fz(22);
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  &:first-child {
    margin-top: 0;
  }
}

.c-h5 {
  @include fz(18);
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  &:first-child {
    margin-top: 0;
  }
}

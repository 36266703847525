.p-access {
  &__header {
    position: relative;
    padding-top: 45px;
    @include touch {
      padding-top: 30px;
    }
  }
  &__body {
    max-width: 880px;
    margin-right: auto;
    margin-left: auto;
    color: $var-color;
  }
  &__map {
    max-width: 880px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    @include touch {
      margin-top: 6px;
    }
  }
  &__mapImg {
    width: 100%;
    max-width: none;
  }
  &__btn {
    position: absolute;
    right: 0;
    bottom: -166px;
    z-index: 3;
    @include touch {
      right: -5px;
      bottom: -112px;
    }
    @include wide {
      right: 35px;
      bottom: -141px;
    }
  }
}

@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include touch{}
*/

$sp: 600px; //固定
$pc: 980px; //コンテンツ幅
$wide: 1280px; //デザイン幅
$full: 1400px; //Max幅

//@include sp{}
@mixin sp {
  @media print, screen and (max-width: $sp - 1px) {
    @content;
  }
}

//@include tb{}
@mixin tb {
  @media print, screen and (min-width: $sp) {
    @content;
  }
}

//@include pc{}
@mixin pc {
  @media print, screen and (min-width: $pc) {
    @content;
  }
}

//@include under_pc{}
@mixin under_pc {
  @media print, screen and (max-width: $pc - 1px) {
    @content;
  }
}

//@include wide{}
@mixin wide {
  @media print, screen and (min-width: $wide) {
    @content;
  }
}

//@include normal{}
@mixin normal {
  @media print, screen and (min-width: $sp) and (max-width: $wide - 1px) {
    @content;
  }
}

//@include touch{}
@mixin touch {
  @media print, screen and (max-width: $pc - 1px) {
    @content;
  }
}

//@include onlyTB{}
@mixin onlyTB {
  @media print, screen and (min-width: $sp) and (max-width: $pc - 1px) {
    @content;
  }
}

//@include onlyPC {}
@mixin onlyPC {
  @media print, screen and (min-width: $point-pc) and (max-width: $point-wide) {
    @content;
  }
}

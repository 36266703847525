@charset "utf-8";
//@include sp {};
//@include tb {};
//@include pc {};

.p-newsDetail {
  @include pc {
    width: 100%;
    height: auto;
  }
  &__inner {
    margin: 0 auto 115px;
    @include pc {
      width: 100%;
      max-width: 1440px;
      height: auto;
      padding: 0 20px;
      padding: 0;
      margin-bottom: 120px;
    }
  }
  &__article {
    margin-bottom: 37px;
    border-bottom: 1px solid #f6f5f3;
    @include tb {
      width: 95%;
      margin: 0 auto;
      margin-bottom: 63px;
    }
    @include pc {
      max-width: 878px;
      height: auto;
      margin: 0 auto;
      margin-bottom: 64px;
    }
  }
  &__articleTitle {
    display: block;
    width: 100%;
    height: auto;
    padding: 36px 26px;
    margin-top: 36px;
    text-align: center;
    background-color: #f6f5f3;
    border-radius: 20px;
    @include tb {
      padding: 40px 26px;
      margin-top: 30px;
    }
  }
  &__articleHeading {
    margin-top: 2px;
    font-size: 18px;
    line-height: calc(54 / 36);
    color: #444;
    letter-spacing: 0.12em;
    @include sourceHanSansJP_bold;
    @include pc {
      margin-top: 17px;
      font-size: 22px;
      line-height: calc(32 / 22);
    }
  }
  &__articleTime {
    display: inline-block;
    margin-top: 9px;
    font-size: 12px;
    line-height: calc(54 / 24);
    color: #444;
    letter-spacing: 0.12em;
    @include sourceHanSansJP_regular;
    @include pc {
      margin-top: 16px;
      font-size: 13px;
      line-height: calc(26 / 13);
    }
  }
  &__contents {
    div {
      margin-top: 45px;
      @include pc {
        margin-top: 63px;
      }
      &:not(:first-child) {
        margin-top: 25px;
        @include pc {
          margin-top: 31px;
        }
      }
    }
    p {
      font-size: 14px;
      line-height: calc(60 / 32);
      letter-spacing: 0.06em;
      @include sourceHanSansJP_regular;
      word-wrap: break-word;
      @include pc {
        font-size: 15px;
        line-height: calc(32 / 15);
      }
    }
    a {
      position: relative;
      display: inline-block;
      padding-bottom: 2px;
      color: #e8a8b4;
      color: #e18b9c;
      text-decoration: none;
      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        content: "";
        border-bottom: 1px solid currentcolor;
        transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
        transform-origin: 0 0;
      }
      &:hover::after {
        transform: scaleX(0);
        transform-origin: 100% 0;
      }
    }
    figure {
      display: block;
      width: 100%;
      height: auto;
      margin-top: 48px;
      margin-bottom: 46px;
      @include pc {
        margin-top: 74px;
      }
    }
    img {
      display: block;
      width: 100%;
      height: auto;
      @include pc {
        max-width: 640px;
        margin: 0 auto;
      }
    }
  }
}

@charset "UTF-8";
/* ==========================================================================
Foundation
========================================================================== */
/* ============================================ */
/* ============================================ */
/* ============================================ */
/* ============================================ */
/* ============================================ */
br.SPbr {
  display: inline;
}

@media (min-width: 980px) {
  br.SPbr {
    display: none;
  }
}

br.PCbr {
  display: none;
}

@media (min-width: 980px) {
  br.PCbr {
    display: inline;
  }
}

/*
@include sp{}
@include tb{}
@include pc{}
@include touch{}
*/
/*
@include sp{}
@include tb{}
@include pc{}
@include touch{}
*/
html:not(.wf-active) {
  opacity: 0;
}

html.is-anim {
  transition: opacity .3s;
}

/*
@include sp{}
@include tb{}
@include pc{}
@include touch{}
*/
body {
  font-size: 16px;
  font-size: 1rem;
  color: #333;
  font-family: source-han-sans-japanese, sans-serif;
  font-style: normal;
  font-weight: 400;
}

* {
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

*::before,
*::after {
  box-sizing: inherit;
}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
span,
em,
small,
strong,
sub,
sup,
mark,
del,
ins,
strike,
abbr,
dfn,
blockquote,
q,
cite,
code,
pre,
ol,
ul,
li,
dl,
dt,
dd,
div,
section,
article,
main,
aside,
nav,
header,
hgroup,
footer,
img,
figure,
figcaption,
address,
time,
audio,
video,
canvas,
iframe,
details,
summary,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  padding: 0;
  margin: 0;
  border: 0;
}

a {
  -webkit-touch-callout: none;
}

a:hover {
  text-decoration: none;
}

article,
aside,
footer,
header,
nav,
section,
main {
  display: block;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

ol,
ul {
  list-style: none;
}

img,
video {
  max-width: 100%;
}

img {
  vertical-align: top;
  border-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote::after,
blockquote::before,
q::after,
q::before {
  content: "";
  content: none;
}

[hidden] {
  display: none !important;
}

[disabled] {
  cursor: not-allowed;
}

[data-whatintent="mouse"] :focus,
[data-whatintent="touch"] :focus {
  outline: none;
}

[data-whatintent="keyboard"] :focus {
  outline: 3px solid currentColor;
}

/* form */
input[type="text"] input[type="number"],
input[type="date"],
input[type="time"],
input[type="password"],
input[type="email"],
textarea {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

textarea {
  display: block;
}

fieldset {
  margin: 0;
  border: none;
}

legend {
  display: none;
}

/* table */
table {
  border-spacing: 0;
  border-collapse: collapse;
}

/*
@include touch {} // max-width: 979px
@include sp {}    // max-width: 599px
@include tb {}    // min-width: 600px
@include pc {}    // min-width: 980px
@include wide {}  // min-width: 1280px
*/
body {
  margin: 0;
  line-height: 1.8;
  letter-spacing: 0.12rem;
}

ul,
ol {
  padding: 0;
  margin: 0;
  list-style: none;
}

a {
  color: inherit;
  text-decoration: inherit;
}

dl,
dd,
figure {
  margin: 0;
}

img,
svg,
iframe {
  vertical-align: middle;
}

img {
  max-width: 100%;
}

img[height] {
  height: auto;
}

@media print, screen and (min-width: 600px) {
  .v-mb {
    display: none;
  }
}

@media print, screen and (max-width: 979px) {
  .v-dt {
    display: none;
  }
}

/* -------------------------------------------------- variables */
/* -------------------------------------------------- compornent */
.c-pageTitle {
  margin: 0 0 0.8em;
  font-size: 42px;
  line-height: 1.2;
  color: #3a3a3a;
  text-transform: uppercase;
  letter-spacing: 0;
  font-family: zeitung, sans-serif;
  font-style: normal;
  font-weight: 700;
}

.c-title {
  margin: 0 0 32px;
  font-size: 42px;
  line-height: 1.2;
  color: #3a3a3a;
  text-transform: uppercase;
  letter-spacing: 0;
  font-family: zeitung, sans-serif;
  font-style: normal;
  font-weight: 700;
}

/* ページビジュアル */
.c-keyVisual img {
  width: 100%;
}

.c-keyVisual.-hasText {
  position: relative;
}

.c-keyVisual .keyVisual-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  font-size: 18px;
}

.p-app .keyVisual-content {
  color: #3a3a3a;
  background: #f7d65b;
}

.p-gallery .keyVisual-content {
  color: white;
  background: #519c60;
}

.c-keyVisual .keyVisual-lead {
  margin: 0;
}

.c-keyVisual .keyVisual-copy {
  margin: 12px auto 0;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.2;
}

.c-keyVisual .keyVisual-text {
  margin: 32px auto 0;
}

@media print, screen and (max-width: 979px) {
  .c-keyVisual .keyVisual-image img {
    border-radius: 10px 10px 0 0;
  }
  .c-keyVisual .keyVisual-content {
    font-size: 16px;
    border-radius: 0 0 10px 10px;
  }
  .c-keyVisual .keyVisual-lead,
  .c-keyVisual .keyVisual-copy {
    text-align: center;
  }
}

@media print, screen and (min-width: 980px) {
  .c-keyVisual.-hasText {
    display: flex;
  }
  .c-keyVisual .keyVisual-image,
  .c-keyVisual .keyVisual-content {
    box-sizing: border-box;
    flex-basis: 50%;
  }
  .c-keyVisual .keyVisual-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px 0 0 20px;
  }
  .c-keyVisual .keyVisual-content {
    border-radius: 0 20px 20px 0;
  }
}

/* 下線のあるタイトル */
.c-title.-centerbar {
  text-align: center;
}

.c-title.-centerbar::after {
  display: block;
  width: 40px;
  margin: 12px auto 0;
  content: "";
  border-top: 2px solid currentcolor;
}

/* 両サイドに引き線のあるタイトル */
.c-title.-sidebar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  font-size: 20px;
  text-align: center;
  white-space: nowrap;
}

.c-title.-sidebar::before,
.c-title.-sidebar::after {
  flex-basis: 40%;
  content: "";
  border-top: 3px solid currentcolor;
}

.c-title.-sidebar::before {
  margin-right: 1em;
}

.c-title.-sidebar::after {
  margin-left: 1em;
}

@media print, screen and (max-width: 979px) {
  .c-title.-sidebar {
    font-size: 19px;
  }
}

.c-text {
  font-size: 15px;
  line-height: 1.8;
  color: #444;
  font-family: source-han-sans-japanese, sans-serif;
  font-style: normal;
  font-weight: 400;
}

.c-text p {
  margin: 0;
}

.c-note {
  font-size: 13px;
}

/* 下線リンク */
.c-link {
  position: relative;
  display: inline-block;
  padding-bottom: 2px;
  color: #e18b9c;
}

.c-link::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  content: "";
  border-bottom: 1px solid currentcolor;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform-origin: 0 0;
}

.c-link:hover::after {
  transform: scaleX(0);
  transform-origin: 100% 0;
}

[data-whatintent="keyboard"] .c-link:focus {
  position: relative;
  outline: none;
}

[data-whatintent="keyboard"] .c-link:focus::before {
  position: absolute;
  top: -0.5em;
  right: -1em;
  bottom: -0.5em;
  left: -1em;
  content: "";
  box-shadow: 0 0 0 3px #444;
}

/* ボタン */
a.c-button {
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  max-width: 240px;
  padding: 1em;
  font-size: 14px;
  line-height: 1;
  color: white;
  text-align: center;
  letter-spacing: 0.12em;
  background: #e18b9c;
  border-radius: 3em;
  transition: all 0.3s ease-in-out;
}

@media print, screen and (min-width: 980px) {
  a.c-button:hover {
    transition: all 0.3s ease 0s;
    transform: translateY(-0.3em);
  }
}

[data-whatintent="keyboard"] .c-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px #444;
}

.c-button.-map {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 170px;
  height: 170px;
  border-radius: 50%;
}

@media print, screen and (min-width: 980px) {
  .c-button.-map.-large {
    width: 180px;
    height: 180px;
  }
}

.c-button.-map::after {
  position: absolute;
  top: 70%;
  left: calc(50% - 16px);
  width: 42px;
  height: 30px;
  content: "";
  background: url(../img/common/ico_arrow.svg) center/cover no-repeat;
}

.c-button.-arrow {
  font-weight: 500;
  background-image: url(../img/common/ico_arrow.svg);
  background-repeat: no-repeat;
}

@media print, screen and (min-width: 980px) {
  .c-button.-arrow {
    font-size: 14px;
    font-size: 0.875rem;
    width: auto;
    max-width: none;
    padding: 1.367em 6em;
    background-position: right 30px center;
  }
}

@media print, screen and (max-width: 979px) {
  .c-button.-arrow {
    padding-top: 1.29em;
    padding-bottom: 1.29em;
    background-position: right 28px center;
    background-size: 25px 16px;
  }
}

@media print, screen and (min-width: 1280px) {
  .c-button.-arrow {
    font-size: 15px;
    font-size: 0.9375rem;
    padding: 1.367em 7.68em;
  }
}

@media print, screen and (max-width: 979px) {
  .c-button {
    padding: 1.4em 0.7em;
  }
  .c-button.-map {
    width: 130px;
    height: 130px;
  }
  .c-button.-map::after {
    left: calc(50% - 10px);
    width: 25.2px;
    height: 18px;
  }
}

@media print, screen and (min-width: 600px) {
  .c-button.-map {
    font-size: 16px;
  }
}

/* ルビ */
.c-menuRuby {
  display: table;
  font-size: 19px;
  line-height: 1.2;
  color: #fff;
  text-align: left;
  text-transform: uppercase;
  ruby-align: left;
  ruby-align: start;
  ruby-position: under;
  font-family: zeitung, sans-serif;
  font-style: normal;
  font-weight: 700;
}

.c-menuRuby rt {
  padding-top: 4px;
  font-size: 10px;
  text-align: inherit;
}

@-moz-document url-prefix() {
  .c-menuRuby {
    position: relative;
    padding-bottom: 0.7em;
  }
  .c-menuRuby rt {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

@media all and (-ms-high-contrast: none) {
  .c-menuRuby rt {
    display: table-footer-group;
    line-height: 2;
  }
}

@supports (-ms-ime-align: auto) {
  .c-menuRuby rt {
    display: table-footer-group;
  }
}

@media print, screen and (min-width: 980px) {
  .c-menuRuby {
    font-size: 32px;
  }
  .c-menuRuby rt {
    font-size: 13px;
  }
}

@media print, screen and (min-width: 1280px) {
  @-moz-document url-prefix() {
    .c-menuRuby {
      padding-bottom: 0.5em;
    }
  }
}

/* 背景色 */
.c-bg,
.c-bg > * {
  position: relative;
}

.c-bg::before {
  position: absolute;
  top: 0;
  right: calc(50% - 50vw);
  left: calc(50% - 50vw);
  height: 100%;
  content: "";
}

.c-bg.-beige::before {
  background: #f6f5f3;
}

.c-bg.-home::before {
  height: 900px;
}

@media print, screen and (min-width: 980px) {
  .c-bg.-home::before {
    height: 700px;
  }
}

@media print, screen and (min-width: 1280px) {
  .c-bg.-home::before {
    height: 600px;
  }
}

@media print, screen and (max-width: 979px) {
  .c-bg::before {
    right: -20px;
    left: -20px;
  }
}

/* -------------------------------------------------- layout */
@media print, screen and (max-width: 979px) {
  .l-column .c-button {
    display: block;
    max-width: 200px;
    margin: 40px auto 0;
  }
}

@media print, screen and (min-width: 980px) {
  .l-column {
    display: grid;
    grid-template: auto auto / 1fr 1fr 1fr;
    grid-gap: 0 32px;
  }
  .l-column .c-text {
    grid-area: 1 / 2 / 3 / 4;
    -ms-grid-column: 3;
    -ms-grid-column-span: 3;
  }
  .l-column .c-button {
    grid-area: 2 / 1 / 3 / 2;
    margin-top: auto;
  }
}

/* ==========================================================================
Layout Object
========================================================================== */
/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/
.c-btn a, a.c-btn, .c-btn
button,
button.c-btn, .c-btn
input,
input.c-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 50px;
  font-weight: 500;
  line-height: 0.92857;
  color: #fff;
  text-decoration: none;
  letter-spacing: 0.12em;
  cursor: pointer;
  background: #e18b9c;
  border-radius: 25px;
  transition: 0.3s ease-in-out;
  font-size: 14px;
  font-size: 0.875rem;
}

.c-btn a::before, a.c-btn::before, .c-btn
button::before,
button.c-btn::before, .c-btn
input::before,
input.c-btn::before {
  display: block;
  width: 0;
  height: 0;
  margin-top: calc((1 - 0.92857) * 0.5em);
  content: "";
}

@media print, screen and (min-width: 980px) {
  .c-btn a, a.c-btn, .c-btn
  button,
  button.c-btn, .c-btn
  input,
  input.c-btn {
    line-height: 1.85714;
  }
  .c-btn a::before, a.c-btn::before, .c-btn
  button::before,
  button.c-btn::before, .c-btn
  input::before,
  input.c-btn::before {
    display: block;
    width: 0;
    height: 0;
    margin-top: calc((1 - 1.85714) * 0.5em);
    content: "";
  }
}

.c-btn a + .c-btn a, a.c-btn + .c-btn a, .c-btn
button + .c-btn a,
button.c-btn + .c-btn a, .c-btn
input + .c-btn a,
input.c-btn + .c-btn a, .c-btn a + a.c-btn, a.c-btn + a.c-btn, .c-btn
button + a.c-btn,
button.c-btn + a.c-btn, .c-btn
input + a.c-btn,
input.c-btn + a.c-btn, .c-btn a + .c-btn
button, a.c-btn + .c-btn
button, .c-btn
button + .c-btn
button,
button.c-btn + .c-btn
button, .c-btn
input + .c-btn
button,
input.c-btn + .c-btn
button, .c-btn a +
button.c-btn, a.c-btn +
button.c-btn, .c-btn
button +
button.c-btn,
button.c-btn +
button.c-btn, .c-btn
input +
button.c-btn,
input.c-btn +
button.c-btn, .c-btn a + .c-btn
input, a.c-btn + .c-btn
input, .c-btn
button + .c-btn
input,
button.c-btn + .c-btn
input, .c-btn
input + .c-btn
input,
input.c-btn + .c-btn
input, .c-btn a +
input.c-btn, a.c-btn +
input.c-btn, .c-btn
button +
input.c-btn,
button.c-btn +
input.c-btn, .c-btn
input +
input.c-btn,
input.c-btn +
input.c-btn {
  margin-left: 10px;
}

.-left a::before, .-left
button::before, .-left
input::before {
  left: 8px;
}

.-right a::before, .-right
button::before, .-right
input::before {
  right: 8px;
}

.c-btn + .c-btn {
  margin-left: 10px;
}

.c-btn.-pseudo {
  position: relative;
}

.c-btn.-pseudo a,
.c-btn.-pseudo button,
.c-btn.-pseudo input {
  position: relative;
}

.c-btn.-pseudo a::before,
.c-btn.-pseudo button::before,
.c-btn.-pseudo input::before {
  position: absolute;
  top: 50%;
  display: block;
  width: 10px;
  height: 10px;
  content: "";
  background: #000;
  transform: translateY(-50%);
}

.c-btn.-pseudo a.-left,
.c-btn.-pseudo button.-left,
.c-btn.-pseudo input.-left {
  padding-left: 30px;
}

.c-btn.-pseudo a.-left::before,
.c-btn.-pseudo button.-left::before,
.c-btn.-pseudo input.-left::before {
  left: 10px;
}

.c-btn.-pseudo a.-right,
.c-btn.-pseudo button.-right,
.c-btn.-pseudo input.-right {
  padding-right: 30px;
}

.c-btn.-pseudo a.-right::before,
.c-btn.-pseudo button.-right::before,
.c-btn.-pseudo input.-right::before {
  right: 10px;
}

.c-btn.-pseudo_inline {
  display: flex;
  justify-content: center;
}

.c-btn.-pseudo_inline.-left a::before,
.c-btn.-pseudo_inline.-left input::before,
.c-btn.-pseudo_inline.-left button::before {
  display: block;
  width: 10px;
  height: 10px;
  margin-right: 6px;
  content: "";
  background: #000;
}

.c-btn.-pseudo_inline.-right a::after,
.c-btn.-pseudo_inline.-right input::after,
.c-btn.-pseudo_inline.-right button::after {
  display: block;
  width: 10px;
  height: 10px;
  margin-left: 6px;
  content: "";
  background: #000;
}

.c-btn.-arrow {
  position: relative;
}

.c-btn.-arrow::before {
  position: absolute;
  top: 50%;
  display: block;
  width: 10px;
  height: 10px;
  content: "";
  border: 2px #000 solid;
  border-top: 0;
  border-left: 0;
}

.c-btn.-arrow.-left::before {
  left: 15px;
  margin-top: -2px;
  transform: rotate(135deg) translateY(50%);
}

.c-btn.-arrow.-right::before {
  right: 15px;
  margin-top: -10px;
  transform: rotate(-45deg) translateY(50%);
}

.c-btn.-right, .c-btn.-left {
  position: relative;
}

.c-btn.-icon {
  position: relative;
}

.c-btn.-icon i {
  position: absolute;
  font-size: 20px;
  font-size: 1.25rem;
}

.c-btn.-icon.-left i {
  left: 10px;
  margin-right: 5px;
}

.c-btn.-icon.-right i {
  right: 10px;
  margin-left: 5px;
}

.c-btn.-icon_inline i {
  font-size: 20px;
  font-size: 1.25rem;
}

.c-btn.-icon_inline.-left i {
  margin-right: 5px;
}

.c-btn.-icon_inline.-right i {
  margin-left: 5px;
}

.c-btn.-white a,
.c-btn.-white button,
.c-btn.-white input {
  color: #000;
  background: #fff;
  border: 2px solid #000;
}

.c-btn.-white a:hover,
.c-btn.-white button:hover,
.c-btn.-white input:hover {
  color: #fff;
  background: #000;
}

.c-btn.-black a,
.c-btn.-black button,
.c-btn.-black input {
  color: #fff;
  background: #000;
  border: 2px solid #000;
}

.c-btn.-black a:hover,
.c-btn.-black button:hover,
.c-btn.-black input:hover {
  color: #000;
  background: #fff;
}

.c-btn.-w235 {
  width: 235px;
}

.c-btn.-w235 a,
.c-btn.-w235 button,
.c-btn.-w235 input {
  width: 100%;
}

.c-btn.-w400 {
  width: 100%;
}

@media print, screen and (min-width: 980px) {
  .c-btn.-w400 {
    width: 400px;
  }
}

.c-btn.-w400 a,
.c-btn.-w400 button,
.c-btn.-w400 input {
  width: 100%;
}

.c-btn.-h45 a,
.c-btn.-h45 button,
.c-btn.-h45 input {
  height: 45px;
  border-radius: 45px;
}

@media print, screen and (min-width: 980px) {
  .c-btn.-h45 a,
  .c-btn.-h45 button,
  .c-btn.-h45 input {
    letter-spacing: 0.18em;
  }
}

.c-btnWrap {
  display: flex;
}

.c-btnWrap.-left {
  justify-content: flex-start;
}

.c-btnWrap.-center {
  justify-content: center;
}

.c-btnWrap.-right {
  justify-content: flex-end;
}

.c-btnWrap.-english {
  margin-bottom: -18px;
}

@media print, screen and (min-width: 600px) {
  .c-btnWrap.-english {
    margin-bottom: 91px;
  }
}

@media print, screen and (min-width: 980px) {
  .c-btnWrap.-english .-bottom {
    margin-bottom: 190px;
  }
}

.c-btnWrap.-englishSP {
  display: block;
}

@media print, screen and (min-width: 980px) {
  .c-btnWrap.-englishSP {
    display: none !important;
  }
}

.c-btn.-amazon a,
.c-btn.-amazon button,
.c-btn.-amazon input {
  position: relative;
  background: #fff;
  border: 2px solid #f90;
}

.c-btn.-amazon a:hover,
.c-btn.-amazon button:hover,
.c-btn.-amazon input:hover {
  color: #000;
  background: #ffe3b9;
}

.c-btn.-amazon a img,
.c-btn.-amazon button img,
.c-btn.-amazon input img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 94px;
  height: auto;
  margin-top: 4px;
  transform: translate(-50%, -50%);
}

.c-btn.-ticket {
  width: 100%;
  max-width: 500px;
  padding-top: 68px;
  margin-top: 42px;
  background: none;
}

@media print, screen and (min-width: 980px) {
  .c-btn.-ticket {
    width: auto;
    max-width: none;
    padding-top: 98px;
    margin-top: 20px;
  }
}

.c-btn.-ticket:hover {
  box-shadow: none;
}

.c-btn.-ticket a,
.c-btn.-ticket button,
.c-btn.-ticket input {
  position: relative;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  line-height: 1.625;
  border-radius: 35px;
  font-size: 16px;
  font-size: 1rem;
}

.c-btn.-ticket a::before,
.c-btn.-ticket button::before,
.c-btn.-ticket input::before {
  display: block;
  width: 0;
  height: 0;
  margin-top: calc((1 - 1.625) * 0.5em);
  content: "";
}

@media print, screen and (min-width: 980px) {
  .c-btn.-ticket a,
  .c-btn.-ticket button,
  .c-btn.-ticket input {
    width: 554px;
    padding-top: 30px;
    padding-bottom: 30px;
    margin: 0 auto;
    line-height: 1.44444;
    border-radius: 40px;
    font-size: 18px;
    font-size: 1.125rem;
  }
  .c-btn.-ticket a::before,
  .c-btn.-ticket button::before,
  .c-btn.-ticket input::before {
    display: block;
    width: 0;
    height: 0;
    margin-top: calc((1 - 1.44444) * 0.5em);
    content: "";
  }
}

.c-btn.-ticket a .-img,
.c-btn.-ticket button .-img,
.c-btn.-ticket input .-img {
  position: absolute;
  top: -68px;
  left: 50%;
  width: 43px;
  height: auto;
  transform: translateX(-50%);
}

@media print, screen and (min-width: 980px) {
  .c-btn.-ticket a .-img,
  .c-btn.-ticket button .-img,
  .c-btn.-ticket input .-img {
    top: -98px;
    width: 62px;
  }
}

.c-btn.-ticket a .-img img,
.c-btn.-ticket button .-img img,
.c-btn.-ticket input .-img img {
  width: 43px;
  height: auto;
}

@media print, screen and (min-width: 980px) {
  .c-btn.-ticket a .-img img,
  .c-btn.-ticket button .-img img,
  .c-btn.-ticket input .-img img {
    width: 62px;
  }
}

.c-btnWrap.-english .c-btn.-ticket a .-img, .c-btnWrap.-english
.c-btn.-ticket button .-img, .c-btnWrap.-english
.c-btn.-ticket input .-img {
  position: absolute;
  top: -43px;
  left: 50%;
  width: 46px;
  height: auto;
  transform: translateX(-50%);
}

@media print, screen and (min-width: 980px) {
  .c-btnWrap.-english .c-btn.-ticket a .-img, .c-btnWrap.-english
  .c-btn.-ticket button .-img, .c-btnWrap.-english
  .c-btn.-ticket input .-img {
    top: -57px;
    width: 52px;
  }
}

.c-btnWrap.-english .c-btn.-ticket a .-img img, .c-btnWrap.-english
.c-btn.-ticket button .-img img, .c-btnWrap.-english
.c-btn.-ticket input .-img img {
  width: 33px;
  height: auto;
}

@media print, screen and (min-width: 980px) {
  .c-btnWrap.-english .c-btn.-ticket a .-img img, .c-btnWrap.-english
  .c-btn.-ticket button .-img img, .c-btnWrap.-english
  .c-btn.-ticket input .-img img {
    width: 45px;
  }
}

[data-whatintent="keyboard"] .c-btn.-ticket a, [data-whatintent="keyboard"]
.c-btn.-ticket button, [data-whatintent="keyboard"]
.c-btn.-ticket input {
  outline: none;
  box-shadow: 0 0 0 3px #444;
}

.c-btn.-ticket.-englishBtn {
  padding-top: 0;
  margin-bottom: 0;
}

@media print, screen and (min-width: 980px) {
  .c-btn.-ticket.-englishBtn {
    padding-top: 98px;
  }
}

.c-btn.-dayTicket {
  padding-top: 0;
  margin-top: 42px;
}

@media print, screen and (min-width: 980px) {
  .c-btn.-dayTicket {
    padding-top: 0;
    margin-top: 54px;
  }
}

.c-btn.-dayTicket a {
  background: #74b4d8;
}

.c-btn.disabled:hover {
  transform: none;
}

.c-btn.disabled a {
  pointer-events: none;
  background: #cecece;
}

.c-btnArea {
  width: 100%;
  max-width: 878px;
  height: auto;
}

@media print, screen and (min-width: 600px) {
  .c-btnArea {
    margin: 48px auto 0;
  }
}

.c-btnArea.-confirm {
  margin-top: 27px;
}

.c-btn {
  display: block;
  width: 275px;
  height: 50px;
  margin: 0 auto;
  margin-bottom: 100px;
  background-color: #e18b9c;
  border-radius: 50px;
  border-radius: 28px !important;
  transition: all 0.3s ease 0s;
}

@media print, screen and (min-width: 980px) {
  .c-btn {
    width: 240px;
    height: 56px;
  }
}

@media print, screen and (min-width: 980px) {
  .c-btn:hover {
    transition: all 0.3s ease 0s;
    transform: translateY(-0.3em);
  }
}

.c-btn__link {
  display: block;
  width: 100%;
  height: 100%;
  padding-top: 20px;
  font-size: 14px;
  line-height: calc(26 / 28);
  color: #fff;
  text-align: center;
  text-decoration: none;
  letter-spacing: 0.04em;
  border-radius: 28px !important;
  font-family: source-han-sans-japanese, sans-serif;
  font-style: normal;
  font-weight: 400;
}

@media print, screen and (min-width: 980px) {
  .c-btn__link {
    padding-top: 19px;
    line-height: calc(22 / 14);
  }
}

.p-inquires__inner .c-btn__link {
  padding-top: 0;
  letter-spacing: 0.1em;
}

.p-inquires__inner .c-btn {
  padding: 0;
}

@media print, screen and (min-width: 600px) {
  .p-inquires__inner .c-btn {
    width: 400px;
  }
}

.-back {
  margin-bottom: 20px;
  background-color: #808080 !important;
}

@media print, screen and (min-width: 600px) {
  .-back {
    margin-bottom: 24px;
  }
}

.c-faqNavBtn {
  display: block;
  border: 2.5px solid #519c60;
  border-radius: 40px;
}

.c-faqNavBtn:not(:last-child) {
  margin-right: 15px;
}

@media print, screen and (min-width: 600px) {
  .c-faqNavBtn:not(:last-child) {
    margin-right: 7px;
  }
}

@media print, screen and (min-width: 600px) {
  .c-faqNavBtn {
    border: 3px solid #519c60;
  }
}

@media print, screen and (min-width: 980px) {
  .c-faqNavBtn {
    margin-right: 0;
  }
}

.c-faqNavBtn__link {
  display: block;
  padding: 6.5px 15px;
  font-size: 13px;
  font-weight: 500;
  line-height: calc(22 / 13);
  color: #519c60;
  text-decoration: none;
  letter-spacing: 0.12em;
  border-radius: inherit;
  font-family: source-han-sans-japanese, sans-serif;
  font-style: normal;
  font-weight: 400;
}

@media print, screen and (min-width: 600px) {
  .c-faqNavBtn__link {
    padding: 6.5px 12px;
    font-size: 12px;
  }
}

@media print, screen and (min-width: 980px) {
  .c-faqNavBtn__link {
    padding: 5px 17px !important;
    font-size: 13px !important;
  }
}

[data-whatintent="keyboard"] .c-faqNavBtn__link:focus {
  outline: none;
  box-shadow: 0 0 0 3px #444;
}

/*
@include sp{}
@include tb{}
@include pc{}
@include touch{}
*/
.c-card {
  display: block;
}

.c-card__link {
  display: block;
  text-decoration: none;
}

.c-card__figure {
  overflow: hidden;
  border-radius: 20px;
}

.c-card__figure img {
  width: 100%;
  height: auto;
}

.c-card__meta {
  display: flex;
}

.c-card__cat a {
  text-decoration: none;
}

.c-card__tag {
  margin-left: 8px;
  text-transform: uppercase;
  letter-spacing: 0.06em;
  white-space: nowrap;
  font-size: 13px;
  font-size: 0.8125rem;
  font-family: zeitung, sans-serif;
  font-style: normal;
  font-weight: 700;
}

.c-card__tag.-new {
  color: #f74608;
}

.c-card__tag.-comingsoon {
  color: #54b1b4;
}

.c-card__tag.-soldout {
  color: #999;
}

.c-card__body {
  padding-top: 30px;
}

.c-card__date {
  margin-left: 20px;
}

.c-card__title {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.625;
  letter-spacing: 0.06em;
  font-family: source-han-sans-japanese, sans-serif;
  font-style: normal;
  font-weight: 500;
}

.c-card__title::before {
  display: block;
  width: 0;
  height: 0;
  margin-top: calc((1 - 1.625) * 0.5em);
  content: "";
}

.c-card__text {
  font-size: 13px;
  font-size: 0.8125rem;
  margin-top: 20px;
  line-height: 1.69231;
  color: #808080;
  letter-spacing: 0.12em;
}

.c-card__text::before {
  display: block;
  width: 0;
  height: 0;
  margin-top: calc((1 - 1.69231) * 0.5em);
  content: "";
}

.c-card__price {
  font-size: 13px;
  font-size: 0.8125rem;
  margin-top: 18px;
  font-family: source-han-sans-japanese, sans-serif;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.06em;
}

.c-card__pricelist {
  padding: 0 20px 20px;
}

.c-carousel {
  max-width: 1340px;
}

@media print, screen and (min-width: 980px) {
  .c-carousel {
    width: 50%;
  }
}

@media print, screen and (min-width: 980px) {
  .c-carousel:nth-child(n + 2) {
    margin-left: 80px;
  }
}

@media print, screen and (max-width: 979px) {
  .c-carousel:nth-child(n + 2) {
    margin-top: 44px;
  }
}

.c-carousel__container {
  position: relative;
}

.c-carousel .c-carousel__stage {
  overflow: hidden;
}

@media print, screen and (min-width: 980px) {
  .c-carousel .c-carousel__stage {
    border-radius: 20px;
  }
}

@media print, screen and (max-width: 979px) {
  .c-carousel .c-carousel__stage {
    border-radius: 10px;
  }
}

.c-carousel__content {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  transition-timing-function: ease;
  transition-duration: 0.2s;
  transition-property: transform;
  transform: translateX(0);
}

.c-carousel__item {
  flex-basis: 100%;
  flex-grow: 0;
  flex-shrink: 0;
}

.c-carousel__image {
  display: block;
  width: 100%;
  height: auto;
}

.c-carousel__button {
  position: absolute;
  top: 0;
  width: 44px;
  height: 100%;
  padding: 0;
  overflow: hidden;
  color: transparent;
  background-color: transparent;
  background-image: none;
  border-width: 0;
  transition-timing-function: linear;
  transition-duration: 0.2s;
  transition-property: color;
}

@media print, screen and (min-width: 980px) {
  .c-carousel__button {
    top: 50%;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}

@media print, screen and (min-width: 980px) {
  .c-carousel__stage:hover + .c-carousel__button:not(:disabled),
  .c-carousel__stage:hover + .c-carousel__button + .c-carousel__button:not(:disabled) {
    color: #fff;
    background-color: rgba(84, 177, 180, 0.9);
  }
}

.c-carousel__button:disabled {
  color: transparent;
  cursor: default;
}

@media print, screen and (min-width: 980px) {
  .c-carousel__button:disabled {
    background-color: transparent;
  }
}

.c-carousel__button:not(:disabled):hover {
  color: #fff;
  background-color: rgba(84, 177, 180, 0.9);
}

.c-carousel__button::before {
  display: block;
  width: 100%;
  height: 100%;
  content: "";
}

.c-carousel__button::after {
  position: absolute;
  top: 50%;
  width: 14px;
  height: 14px;
  content: "";
}

.c-carousel__button.-prev {
  left: 0;
}

@media print, screen and (min-width: 980px) {
  .c-carousel__button.-prev {
    transform: translate(-50%, -50%);
  }
}

@media print, screen and (max-width: 979px) {
  .c-carousel__button.-prev {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}

.c-carousel__button.-prev::after {
  left: 50%;
  box-shadow: inset 4px 4px 0 currentColor;
  transform: rotate(-45deg) translate(0, -50%);
}

.c-carousel__button.-next {
  right: 0;
}

@media print, screen and (min-width: 980px) {
  .c-carousel__button.-next {
    transform: translate(50%, -50%);
  }
}

@media print, screen and (max-width: 979px) {
  .c-carousel__button.-next {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.c-carousel__button.-next::after {
  right: 50%;
  box-shadow: inset -4px 4px 0 currentColor;
  transform: rotate(45deg) translate(-2px, -8px);
}

[data-whatintent="keyboard"] .c-carousel__button:focus {
  color: #fff;
  background-color: rgba(84, 177, 180, 0.9);
  outline: none;
  box-shadow: 0 0 0 3px #444;
}

.c-carousel__footer {
  padding: 0 36px;
}

@media print, screen and (min-width: 980px) {
  .c-carousel__footer {
    margin-top: 18px;
  }
}

@media print, screen and (max-width: 979px) {
  .c-carousel__footer {
    margin-top: 25px;
  }
}

.c-carousel__indicator {
  display: flex;
  justify-content: center;
}

.c-carousel__indicatorItem {
  width: 8px;
  height: 8px;
  margin: 0 4px;
}

.c-carousel__indicatorButton {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0;
  overflow: hidden;
  background-color: rgba(84, 177, 180, 0.6);
  background-image: none;
  border-radius: 50%;
  transition-timing-function: linear;
  transition-duration: 0.2s;
  transition-property: background-color;
}

.c-carousel__indicatorButton:disabled {
  cursor: default;
  background-color: #54b1b4;
}

.c-carousel__indicatorButton::before {
  display: block;
  width: 100%;
  height: 0;
  padding-top: 100%;
  content: "";
}

[data-whatintent="keyboard"] .c-carousel__indicatorButton:focus {
  outline: none;
  box-shadow: 0 0 0 3px #444;
}

/*
@include touch {} // max-width: 979px
@include sp {}    // max-width: 599px
@include tb {}    // min-width: 600px
@include pc {}    // min-width: 980px
@include wide {}  // min-width: 1280px
*/
.c-character {
  pointer-events: none;
}

.c-character .c-character__img {
  width: inherit;
  height: inherit;
}

.c-character:not(.active) {
  opacity: 0;
}

.c-character.active {
  transition: opacity 1s;
}

@keyframes sprites {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 0;
  }
}

/* -------------------------------------------------- NEWS (charlie brown / snoopy) */
.c-character.-charlie {
  position: absolute;
  top: 98px;
  right: 34px;
  z-index: 1;
}

.c-character.-charlie img {
  opacity: 0;
}

.c-character.-charlie .c-character__sprites {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
}

.c-character.-charlie .c-character__sprites::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: url(../img/news/charlie_brown_sprites.png) 0 0 no-repeat;
  background-size: calc(105% * 9) 100%;
}

@media print, screen and (max-width: 599px) {
  .c-character.-charlie {
    width: 71px;
    height: 71px;
  }
}

@media print, screen and (min-width: 600px) {
  .c-character.-charlie {
    top: 77px;
    right: 64px;
    width: 105px;
    height: 105px;
  }
}

@media print, screen and (min-width: 980px) {
  .c-character.-charlie {
    top: 53px;
    right: 132px;
  }
}

@media print, screen and (min-width: 1166px) {
  .c-character.-charlie {
    right: calc(50% - 450px);
  }
}

.c-character.-charlie:not(.active) {
  transform: translateX(32px);
}

.c-character.-charlie.active {
  transition: opacity 1s, transform 1s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.c-character.-charlie.active .c-character__sprites {
  animation: charlieWalk 0.5s both cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

.c-character.-charlie.active .c-character__sprites::after {
  animation: sprites 0.6s 0.1s steps(8) both;
}

@keyframes charlieWalk {
  0% {
    transform: translateY(-16%);
  }
  40% {
    transform: translateY(0);
  }
  70% {
    transform: translateY(-6%);
  }
  100% {
    transform: translateY(0);
  }
}

.c-character.-snoopy {
  position: absolute;
  top: 98px;
  right: 64px;
}

.c-character.-snoopy::before, .c-character.-snoopy::after {
  position: absolute;
  bottom: 0;
  width: 8px;
  height: 8px;
  content: "";
}

.c-character.-snoopy::before {
  left: 11px;
  background: url(../img/news/obj_1.svg) center/cover no-repeat;
}

.c-character.-snoopy::after {
  left: 40px;
  background: url(../img/news/obj_2.svg) center/cover no-repeat;
}

@media print, screen and (max-width: 599px) {
  .c-character.-snoopy {
    width: 52px;
    height: 70px;
  }
}

@media print, screen and (min-width: 600px) {
  .c-character.-snoopy {
    top: 86px;
    right: 108px;
    width: 75px;
    height: 100px;
  }
  .c-character.-snoopy::before, .c-character.-snoopy::after {
    width: 10px;
    height: 10px;
  }
  .c-character.-snoopy::before {
    left: 16px;
  }
  .c-character.-snoopy::after {
    left: 59px;
  }
}

@media print, screen and (min-width: 980px) {
  .c-character.-snoopy {
    top: 62px;
    right: 210px;
  }
}

@media print, screen and (min-width: 1280px) {
  .c-character.-snoopy {
    right: calc(50% - 415px);
  }
}

.c-character.-snoopy:not(.active) {
  transform: translateX(64px);
}

.c-character.-snoopy:not(.active)::before, .c-character.-snoopy:not(.active)::after {
  transform: rotate(360deg);
}

.c-character.-snoopy.active {
  transition: opacity 1s, transform 1s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.c-character.-snoopy.active::before, .c-character.-snoopy.active::after {
  transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/* -------------------------------------------------- TICKET (schroeder / sally brown) */
@media print, screen and (max-width: 599px) {
  .c-character.-schroeder {
    position: absolute;
    top: 40px;
    right: 30px;
    width: 37px;
    height: 70px;
  }
}

@media print, screen and (min-width: 600px) {
  .c-character.-schroeder {
    position: relative;
    top: 2px;
    width: 52px;
    height: 100px;
    margin: -100px 59px 0 auto;
    margin-right: 6.6%;
  }
}

.c-character.-schroeder .c-character__obj {
  position: absolute;
}

.c-character.-schroeder .c-character__obj::after {
  display: block;
  width: 18px;
  height: 18px;
  content: "";
}

.c-character.-schroeder .c-character__obj:first-of-type {
  top: 28px;
  left: -17px;
}

.c-character.-schroeder .c-character__obj:first-of-type::after {
  background: url(../img/ticket/obj_1.svg) center/cover no-repeat;
}

.c-character.-schroeder .c-character__obj:last-of-type {
  top: 4px;
  right: -20px;
}

.c-character.-schroeder .c-character__obj:last-of-type::after {
  background: url(../img/ticket/obj_2.svg) center/cover no-repeat;
}

.c-character.-schroeder:not(.active) {
  transform: translateX(-12px);
}

.c-character.-schroeder:not(.active) .c-character__obj:first-of-type {
  transform: translate(50%, 150%);
}

.c-character.-schroeder:not(.active) .c-character__obj:last-of-type {
  transform: translate(-20%, 150%);
}

.c-character.-schroeder.active {
  transition: opacity 1s, transform 1s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.c-character.-schroeder.active .c-character__obj {
  opacity: 0;
  transition: opacity 1s, transform 1s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.c-character.-schroeder.active .c-character__obj:first-of-type {
  transform: rotate(-20deg);
}

.c-character.-schroeder.active .c-character__obj:first-of-type::after {
  animation: noteDance 1s both cubic-bezier(0.215, 0.61, 0.355, 1);
}

.c-character.-schroeder.active .c-character__obj:last-of-type {
  transform: rotate(10deg);
}

.c-character.-schroeder.active .c-character__obj:last-of-type::after {
  animation: noteDance 1s both cubic-bezier(0.215, 0.61, 0.355, 1);
}

@keyframes noteDance {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(-4%);
  }
  40% {
    transform: translateX(10%);
  }
  60% {
    transform: translateX(-8%);
  }
  80% {
    transform: translateX(6%);
  }
  100% {
    transform: translateX(0);
  }
}

@media print, screen and (max-width: 599px) {
  .c-character.-sally {
    width: 41px;
    height: 70px;
  }
}

@media print, screen and (min-width: 600px) {
  .c-character.-sally {
    width: 58px;
    height: 101px;
  }
}

.c-character.-sally:not(.active) .c-character__img {
  transform: translateY(-12px);
}

.c-character.-sally.active .c-character__img {
  transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/* -------------------------------------------------- WORKSHOP (lucy) */
.c-character.-lucy {
  position: relative;
}

.c-character.-lucy img {
  opacity: 0;
}

.c-character.-lucy::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: url(../img/workshop/lucy_sprites.png) 0 0 no-repeat;
  background-size: 900% 100%;
}

@media print, screen and (max-width: 599px) {
  .c-character.-lucy {
    width: 43px;
    height: 71px;
  }
}

@media print, screen and (min-width: 600px) {
  .c-character.-lucy {
    width: 75px;
    height: 123px;
  }
}

.c-character.-lucy.active::after {
  animation: sprites 0.4s 0.1s steps(8) both;
}

/* -------------------------------------------------- ACCESS (peppermint patty) */
.c-character.-peppermint_patty {
  /* MEMO: 62x70 <- touch | pc -> 94x106 */
  background: url(../img/access/peppermint_patty_sprites.png) 0 0 no-repeat;
  background-size: calc(100% * 9) 100%;
}

.c-character.-peppermint_patty img {
  opacity: 0;
}

.c-character.-peppermint_patty.active {
  animation: sprites 0.6s steps(8) 3 both;
}

/* -------------------------------------------------- FAQ (linus) */
.c-character.-linus {
  position: relative;
}

.c-character.-linus img {
  opacity: 0;
}

.c-character.-linus::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: url(../img/faq/linus_sprites.png) 0 0 no-repeat;
  background-size: 900% 100%;
}

@media print, screen and (max-width: 599px) {
  .c-character.-linus {
    top: -90px;
    width: 63px;
    height: 70px;
    margin: -70px 10px 0 auto;
  }
}

@media print, screen and (min-width: 600px) {
  .c-character.-linus {
    top: -70px;
    width: 90px;
    height: 101px;
    margin: -101px 60px 0 auto;
  }
}

@media print, screen and (min-width: 980px) {
  .c-character.-linus {
    margin-right: calc(50% - 442px);
  }
}

.c-character.-linus.active::after {
  animation: sprites 0.4s 0.1s steps(8) both;
}

/*
@include sp{}
@include tb{}
@include pc{}
@include touch{}
*/
.c-h1 {
  font-size: 24px;
  font-size: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

.c-h1:first-child {
  margin-top: 0;
}

.c-h2 {
  font-size: 20px;
  font-size: 1.25rem;
  position: relative;
  margin-top: 2rem;
  line-height: 1.5;
}

.c-h2::before {
  display: block;
  width: 0;
  height: 0;
  margin-top: calc((1 - 1.5) * 0.5em);
  content: "";
}

@media print, screen and (min-width: 980px) {
  .c-h2 {
    font-size: 22px;
    font-size: 1.375rem;
    line-height: 1.18182;
  }
  .c-h2::before {
    display: block;
    width: 0;
    height: 0;
    margin-top: calc((1 - 1.18182) * 0.5em);
    content: "";
  }
}

.c-h2::after {
  display: block;
  width: 30px;
  height: 2px;
  margin-top: 11px;
  margin-left: 1px;
  content: "";
  background: #444;
}

@media print, screen and (min-width: 980px) {
  .c-h2::after {
    width: 60px;
    height: 3px;
    margin-top: 20px;
  }
}

@media print, screen and (max-width: 979px) {
  .c-h2::after {
    position: absolute;
    bottom: -7px;
    left: 0;
  }
}

.c-h2:first-child {
  margin-top: 0;
}

.c-h3 {
  font-size: 14px;
  font-size: 0.875rem;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

.c-h3:first-child {
  margin-top: 0;
}

.c-h4 {
  font-size: 22px;
  font-size: 1.375rem;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

.c-h4:first-child {
  margin-top: 0;
}

.c-h5 {
  font-size: 18px;
  font-size: 1.125rem;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

.c-h5:first-child {
  margin-top: 0;
}

/*
@include sp{}
@include tb{}
@include pc{}
@include touch{}
*/
.c-headingLine {
  position: relative;
  height: 32px;
  padding-right: 20px;
  padding-left: 20px;
  margin-bottom: 40px;
}

@media print, screen and (min-width: 600px) {
  .c-headingLine {
    margin-right: auto;
    margin-left: auto;
  }
}

@media print, screen and (min-width: 980px) {
  .c-headingLine {
    margin-bottom: 60px;
  }
}

.c-headingLine__title {
  font-size: 20px;
  font-size: 1.25rem;
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  padding: 10px 20px;
  text-transform: uppercase;
  letter-spacing: 0.06em;
  background: #f6f5f3;
  transform: translate(-50%, -50%);
  font-family: zeitung, sans-serif;
  font-style: normal;
  font-weight: 700;
}

@media print, screen and (min-width: 980px) {
  .c-headingLine__title {
    font-size: 22px;
    font-size: 1.375rem;
    min-width: 180px;
    padding: 10px 56px;
  }
}

.c-headingLine::before {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: calc(100% - 40px);
  height: 3px;
  content: "";
  background: #000;
  transform: translate(-50%, -50%);
}

.c-headingLine.-w740 {
  max-width: 1040px;
}

.c-headingLine.-w1040 {
  max-width: 700px;
}

@media print, screen and (min-width: 980px) {
  .c-headingLine.-w1040 {
    max-width: 1040px;
  }
}

/*
@include sp{}
@include tb{}
@include pc{}
@include touch{}
*/
.c-pageHeading {
  margin-bottom: 40px;
}

.c-pageHeading__title {
  display: flex;
  align-items: baseline;
}

.c-pageHeading__jp {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 1.8;
}

.c-pageHeading__jp::before {
  display: block;
  width: 0;
  height: 0;
  margin-top: calc((1 - line-height/fontsize) * 0.5em);
  content: "";
}

.c-pageHeading__en {
  font-size: 12px;
  font-size: 0.75rem;
  margin-left: 15px;
  text-transform: uppercase;
}

.c-pageHeading__lead {
  font-size: 16px;
  font-size: 1rem;
  margin-top: 1em;
}

.c-pageNation {
  margin-bottom: 145px;
  text-align: center;
}

@media print, screen and (min-width: 600px) {
  .c-pageNation {
    margin-bottom: 213px;
  }
}

.c-pageNation.-about {
  margin-top: 80px;
  margin-bottom: 0;
}

@media print, screen and (min-width: 980px) {
  .c-pageNation__inner {
    max-width: 878px;
    height: auto;
    margin: 0 auto;
  }
}

.c-pageNation__numbers {
  position: relative;
  display: inline-block;
  width: auto;
  max-width: 203px;
  height: auto;
  margin: 0 auto;
}

.c-pageNation__numbers::before {
  position: absolute;
  right: 0;
  left: 0;
  display: block;
  max-width: 71px;
  height: 3px;
  margin: 0 auto;
  content: "";
  background: #000;
}

@media print, screen and (min-width: 980px) {
  .c-pageNation__numbers {
    max-width: 248px;
  }
  .c-pageNation__numbers::before {
    max-width: 80px;
  }
}

.c-pageNation__numberpoints {
  display: flex;
  justify-content: center;
  column-gap: 24px;
}

.c-pageNation__numberPoint {
  position: relative;
  display: block;
  margin-top: 19px;
  text-align: center;
}

@media print, screen and (min-width: 980px) {
  .c-pageNation__numberPoint {
    display: block;
    width: 20px;
    height: 20px;
    margin-top: 20px;
  }
}

@media print, screen and (min-width: 980px) {
  .c-pageNation__numberPoint:hover {
    width: 10px;
  }
}

.c-pageNation__number {
  font-family: zeitung, sans-serif;
  font-style: normal;
  font-weight: 600;
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  font-size: 13px;
  line-height: calc(28.8 / 13);
  color: #444;
  text-decoration: none;
  letter-spacing: 0.12em;
  transition: all 0.2s ease 0s;
  backface-visibility: hidden;
}

@media print, screen and (min-width: 980px) {
  .c-pageNation__number {
    font-size: 15px;
  }
}

.c-pageNation__numberPoint {
  font-family: zeitung, sans-serif;
  font-style: normal;
  font-weight: 600;
  position: relative;
  display: block;
  width: 10px;
  height: 100%;
  font-size: 13px;
  line-height: calc(28.8 / 13);
  color: #444;
  text-decoration: none;
  letter-spacing: 0.12em;
  transition: all 0.2s ease 0s;
  backface-visibility: hidden;
}

@media print, screen and (min-width: 980px) {
  .c-pageNation__numberPoint {
    font-size: 15px;
    width: 10px;
  }
}

.c-pageNation__numberPoint .active::before {
  position: absolute;
  bottom: -15px;
  left: -1px;
  display: inline-block;
  width: 10px;
  height: 10px;
  content: "";
  background: #519c60;
  border-radius: 100%;
}

@media print, screen and (min-width: 980px) {
  .c-pageNation__numberPoint .active::before {
    top: 35px;
    bottom: -22px;
    left: -2px;
    width: 11px;
    height: 11px;
  }
}

.c-pageNation__numberPoint.-pcView {
  display: none;
}

@media print, screen and (min-width: 600px) {
  .c-pageNation__numberPoint.-pcView {
    display: block;
  }
}

.c-pageNation__arrow.-prev {
  position: absolute;
  top: 1px;
  left: -13vw;
}

@media print, screen and (min-width: 600px) {
  .c-pageNation__arrow.-prev {
    left: -44px;
  }
}

.c-pageNation__arrow.-next {
  position: absolute;
  top: 1px;
  right: -13vw;
}

@media print, screen and (min-width: 600px) {
  .c-pageNation__arrow.-next {
    right: -44px;
  }
}

.c-pageNation__arrow, .c-pageNation__underDot {
  display: block;
  width: 20px;
  height: 20px;
  margin-top: 22px;
  text-align: center;
  font-size: 13px;
}

@media print, screen and (min-width: 980px) {
  .c-pageNation__arrow, .c-pageNation__underDot {
    font-size: 15px;
  }
}

.c-pageNation__underDot {
  margin-top: 20px;
  font-size: 18px;
}

@media print, screen and (min-width: 980px) {
  .c-pageNation__underDot {
    margin-top: 26px;
  }
}

.c-pageNation__arrow a {
  display: block;
  width: 100%;
  height: 100%;
}

/*
@include sp{}
@include tb{}
@include pc{}
@include touch{}
*/
.c-pullmenu {
  position: relative;
  display: inline-block;
}

.c-pullmenu::before {
  position: absolute;
  top: 50%;
  right: 25px;
  z-index: 1;
  display: block;
  width: 9px;
  height: 9px;
  margin-top: -2px;
  pointer-events: none;
  content: "";
  border-right: 2px solid #519c60;
  border-bottom: 2px solid #519c60;
  transform: translateY(-50%) rotate(45deg);
}

.c-pullmenu select {
  height: 42px;
  padding: 0 60px;
  font-size: inherit;
  color: inherit;
  color: #519c60;
  text-indent: 0.01px;
  text-overflow: "";
  letter-spacing: 0.12em;
  vertical-align: middle;
  background: none transparent;
  border: 3px solid #519c60;
  border-radius: 20px;
  outline: none;
  appearance: button;
  appearance: none;
  font-size: 14px;
  font-size: 0.875rem;
}

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/
@media print, screen and (min-width: 980px) {
  .c-sectionInner {
    min-height: 500px;
  }
}

.c-share {
  width: 100%;
  height: auto;
  margin-top: 74px;
  margin-bottom: 43px;
}

@media print, screen and (min-width: 980px) {
  .c-share {
    margin-top: 93px;
    margin-bottom: 43px;
  }
}

.c-share__inner {
  display: block;
  max-width: 878px;
  height: auto;
  margin: 0 auto;
}

.c-share__title {
  font-size: 13px;
  line-height: calc(32 / 26);
  text-align: center;
  letter-spacing: 0.06em;
  font-family: zeitung, sans-serif;
  font-style: normal;
  font-weight: 700;
}

@media print, screen and (min-width: 980px) {
  .c-share__title {
    font-size: 14px;
    line-height: calc(32 / 24);
  }
}

.c-share__btns {
  display: flex;
  justify-content: space-between;
  max-width: 153px;
  margin: 0 auto;
  margin-top: 12px;
}

@media print, screen and (min-width: 980px) {
  .c-share__btns {
    max-width: 184px;
    margin-top: 19px;
  }
}

.c-share__btn {
  width: 41px;
  height: 41px;
  border: 2px solid #e8a8b4;
  border-radius: 50%;
  transition: all 0.3s ease 0s;
  will-change: contents;
}

@media print, screen and (min-width: 980px) {
  .c-share__btn {
    width: 48px;
    height: 48px;
  }
}

@media print, screen and (min-width: 980px) {
  .c-share__btn:hover {
    transition: all 0.3s ease 0s;
    transform: translateY(-0.3em);
    will-change: contents;
  }
}

.c-share__btnLink {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}

.c-share__btnImg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 16px;
  height: auto;
  margin: auto;
}

@media print, screen and (min-width: 600px) {
  .c-share__btnImg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 20px;
    height: auto;
    margin: auto;
  }
}

.c-share__btn.-line .c-share__btnImg {
  width: 24px;
  height: auto;
}

@media print, screen and (min-width: 600px) {
  .c-share__btn.-line .c-share__btnImg {
    width: 28px;
    height: auto;
  }
}

/*
@include touch {} // max-width: 979px
@include sp {}    // max-width: 599px
@include tb {}    // min-width: 600px
@include pc {}    // min-width: 980px
@include wide {}  // min-width: 1280px
*/
/* slider */
.c-slider {
  padding-bottom: 40px;
}

.c-slider.flickity-enabled {
  position: relative;
  display: block;
}

.c-slider.flickity-enabled:focus {
  outline: 0;
}

.slider-item {
  position: relative;
  box-sizing: content-box;
}

.slider-item[data-num]::after {
  position: absolute;
  left: calc(50% - 1em);
  width: 2em;
  height: 2em;
  font-size: 40px;
  line-height: 2em;
  color: white;
  content: attr(data-num);
  background: #519c60;
  border-radius: 2em;
  font-family: zeitung, sans-serif;
  font-style: normal;
  font-weight: 700;
}

.c-slider.-gallery .slider-item[data-num]::after {
  display: none;
}

.c-slider.-about .slider-item[data-num]::after {
  display: block !important;
}

.slider-img img {
  width: 100%;
  border-radius: 20px;
}

.slider-img {
  padding-bottom: 24px;
}

.p-aboutMuseum .slider-item[data-num] .slider-img {
  padding-bottom: 80px;
}

.flickity-page-dots {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.flickity-page-dots .dot {
  flex-basis: 8px;
  width: 8px;
  height: 8px;
  background: #a7dddf;
  border-radius: 8px;
}

.flickity-page-dots .dot:not(:first-child) {
  margin-left: 8px;
}

.flickity-page-dots .dot.is-selected {
  background: #4daabb;
}

@media print, screen and (max-width: 979px) {
  .c-slider {
    margin: 0 auto;
  }
  .slider-item {
    width: 280px;
  }
  .slider-item[data-num]::after {
    top: 240px;
    text-align: center;
  }
  .c-slider .slider-item:not(:first-child) {
    margin-left: 30px;
  }
}

@media (min-width: 1025px) {
  .c-slider .slider-item:not(:first-child) {
    margin-left: 40px;
  }
}

@media print, screen and (min-width: 980px) {
  .c-slider {
    text-align: center;
  }
  .slider-item {
    width: 480px;
  }
  .slider-item[data-num]::after {
    top: 420px;
  }
  .c-slider .slider-item:not(:first-child) {
    margin-left: 80px;
  }
}

/* not flickity */
.c-slider:not(.flickity-enabled) {
  display: flex;
  align-items: flex-start;
  overflow: auto;
}

@media (max-width: 1024px) {
  .c-slider:not(.flickity-enabled) {
    padding-left: 40px;
    margin: 88px -40px;
  }
  .c-slider:not(.flickity-enabled) .slider-item:last-child {
    padding-right: 40px;
  }
  .c-slider:not(.flickity-enabled) .slider-item:last-child[data-num]::after {
    left: calc(50% - 1em - 20px);
  }
}

@media print, screen and (max-width: 979px) {
  .c-slider:not(.flickity-enabled) {
    padding-left: 20px;
    margin: 0 -20px;
  }
  .c-slider:not(.flickity-enabled) .slider-item {
    min-width: 280px;
  }
  .c-slider:not(.flickity-enabled) .slider-item:last-child {
    padding-right: 20px;
  }
  .c-slider:not(.flickity-enabled) .slider-item:last-child[data-num]::after {
    left: calc(50% - 1em - 10px);
  }
}

@media print, screen and (min-width: 980px) {
  .c-slider:not(.flickity-enabled) {
    padding-left: 80px;
    margin: 88px -80px;
  }
  .c-slider:not(.flickity-enabled) .slider-item {
    min-width: 480px;
  }
  .c-slider:not(.flickity-enabled) .slider-item:last-child {
    padding-right: 80px;
  }
  .c-slider:not(.flickity-enabled) .slider-item:last-child[data-num]::after {
    left: calc(50% - 1em - 40px);
  }
}

.slider-text {
  margin-top: 94px;
}

.c-tab__list {
  display: flex;
  justify-content: center;
}

.c-tab__button {
  color: #fff;
  letter-spacing: inherit;
  background-color: #519c60;
}

@media print, screen and (min-width: 980px) {
  .c-tab__button {
    font-size: 14px;
    font-size: 0.875rem;
    padding: 6px 40px;
    border-radius: 19px;
  }
}

@media print, screen and (max-width: 979px) {
  .c-tab__button {
    font-size: 13px;
    font-size: 0.8125rem;
    padding: 9px 36px;
    border-radius: 22px;
  }
}

@media print, screen and (min-width: 980px) {
  .c-tab__button:nth-child(n + 2) {
    margin-left: 16px;
  }
}

@media print, screen and (max-width: 979px) {
  .c-tab__button:nth-child(n + 2) {
    margin-left: 23px;
  }
}

.c-tab__button[aria-selected="false"] {
  opacity: 0.5;
}

[data-whatintent="keyboard"] .c-tab__button:focus {
  outline: none;
  box-shadow: 0 0 0 3px #444;
}

.c-tab__contents {
  display: grid;
  grid-template-areas: "panel";
  grid-template-rows: auto;
  grid-template-columns: 1fr;
}

.c-tab__panel {
  /* autoprefixer grid: no-autoplace */
  grid-area: panel;
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.c-tab__panel[aria-hidden="true"] {
  display: none;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
}

.c-tab__panel:not([aria-hidden="true"]) {
  transition-delay: 0.2s;
  transition-timing-function: cubic-bezier(0.645, 0, 0.785, 0.39);
}

.c-viewmore {
  display: inline-block;
  color: #e18b9c;
  font-size: 13px;
  font-family: bitter, serif;
  font-style: normal;
  font-weight: 400;
  background: linear-gradient(currentcolor, currentcolor) left bottom/100% 1px no-repeat;
}

@media (hover: hover) {
  .c-viewmore {
    transition: background-size 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .c-viewmore:hover {
    background-position: right bottom;
    background-size: 0 1px;
  }
}

@media print, screen and (max-width: 979px) {
  .c-viewmore {
    font-size: 12px;
  }
}

/*
@include sp{}
@include tb{}
@include pc{}
@include touch{}
*/
.l-container {
  display: flex;
  flex-direction: column;
}

.l-container.-displayBlock {
  display: block;
}

/*
@include sp{}
@include tb{}
@include pc{}
@include touch{}
*/
.l-contents {
  padding-top: 55px;
  overflow-x: hidden;
}

@media print, screen and (min-width: 980px) {
  .l-contents {
    padding-top: 0;
    padding-left: 80px;
  }
}

/*
@include touch {} // max-width: 979px
@include sp {}    // max-width: 599px
@include tb {}    // min-width: 600px
@include pc {}    // min-width: 980px
@include wide {}  // min-width: 1280px
*/
/* -------------------------------------------------- スタッフ募集中 */
a.p-footerPr {
  position: relative;
  display: block;
  padding: 0.8em 1em 1em;
  font-size: 13px;
  line-height: 1.4;
  color: white;
  text-align: center;
  letter-spacing: 0.1rem;
  background: #d73d5b;
}

@media print, screen and (min-width: 980px) {
  a.p-footerPr {
    font-size: 15px;
  }
}

.p-footerPr .-en {
  display: inline-block;
  margin-bottom: 4px;
  font-size: 12px;
  font-family: zeitung, sans-serif;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}

@media print, screen and (min-width: 980px) {
  .p-footerPr .-en {
    font-size: 13px;
  }
}

.p-footerPr::after {
  position: absolute;
  top: calc(50% - 15px);
  right: 40px;
  width: 42px;
  height: 30px;
  content: "";
  background: url(../img/common/ico_arrow.svg) center/cover no-repeat;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

@media print, screen and (max-width: 979px) {
  .p-footerPr::after {
    position: absolute;
    top: calc(50% - 15px);
    right: 40px;
    width: 28px;
    height: 20px;
    content: "";
    background: url(../img/common/ico_arrow.svg) center/cover no-repeat;
    transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
}

.p-footerPr:hover::after {
  transform: translateX(8px);
}

@media print, screen and (max-width: 599px) {
  .p-footerPr::after {
    top: 22px;
    right: 20%;
  }
}

@media print, screen and (max-width: 979px) {
  .p-footerPr::after {
    top: 22px;
    right: 35%;
  }
}

@media print, screen and (min-width: 980px) {
  .p-footerPr::after {
    right: 37.8%;
  }
}

/* -------------------------------------------------- back to top */
#top,
#mainContents {
  display: block;
}

.c-pagetop {
  position: absolute;
  z-index: 1000;
  overflow: hidden;
  line-height: 1.6;
  color: transparent;
  text-transform: uppercase;
  letter-spacing: 0;
}

.c-pagetop img {
  position: absolute;
  transform-origin: 100% 0;
}

.c-pagetop .c {
  position: absolute;
  top: 78%;
  right: 18%;
}

.c-pagetop .c:nth-child(1) {
  transform: rotate(-65deg);
}

.c-pagetop .c:nth-child(2) {
  transform: rotate(-60deg);
}

.c-pagetop .c:nth-child(3) {
  transform: rotate(-55deg);
}

.c-pagetop .c:nth-child(4) {
  transform: rotate(-50deg);
}

.c-pagetop .c:nth-child(5) {
  transform: rotate(-42deg);
}

.c-pagetop .c:nth-child(6) {
  transform: rotate(-37deg);
}

.c-pagetop .c:nth-child(7) {
  transform: rotate(-29deg);
}

.c-pagetop .c:nth-child(8) {
  transform: rotate(-24deg);
}

.c-pagetop .c:nth-child(9) {
  transform: rotate(-19deg);
}

.c-pagetop .c::after {
  position: absolute;
  display: block;
  width: 1em;
  height: 1em;
  color: #3a3a3a;
  font-family: zeitung, sans-serif;
  font-style: normal;
  font-weight: 700;
}

.c-pagetop .c:nth-child(1)::after {
  content: "B";
}

.c-pagetop .c:nth-child(2)::after {
  content: "a";
}

.c-pagetop .c:nth-child(3)::after {
  content: "c";
}

.c-pagetop .c:nth-child(4)::after {
  content: "k";
}

.c-pagetop .c:nth-child(5)::after {
  content: "t";
}

.c-pagetop .c:nth-child(6)::after {
  content: "o";
}

.c-pagetop .c:nth-child(7)::after {
  content: "T";
}

.c-pagetop .c:nth-child(8)::after {
  content: "o";
}

.c-pagetop .c:nth-child(9)::after {
  content: "p";
}

@media print, screen and (max-width: 979px) {
  .c-pagetop {
    top: -98px;
    right: 0;
    width: calc(165px * 0.8);
    height: calc(165px * 0.8);
    font-size: 9px;
  }
  .p-footerPr + .l-footer .c-pagetop {
    top: -170px;
  }
  .c-pagetop img {
    right: calc(-16px * 0.8);
    width: calc(109px * 0.8);
    height: calc(140px * 0.8);
  }
}

@media print, screen and (min-width: 980px) {
  .c-pagetop {
    top: -122px;
    right: calc(50% - 50vw + 40px);
    width: 140px;
    height: 160px;
    font-size: 12px;
  }
  .p-footerPr + .l-footer .c-pagetop {
    top: -210px;
  }
  .c-pagetop img {
    right: -16px;
  }
}

/* animation */
.c-pagetop img {
  transition: transform 0.7s;
}

.c-pagetop .c::after {
  transition: opacity 0.56s, transform 0.56s;
}

.c-pagetop.active img {
  transition-timing-function: cubic-bezier(0.39, 1.56, 0.55, 0.99);
}

.c-pagetop.active .c::after {
  transition-delay: 0.1s;
  transition-timing-function: cubic-bezier(0.39, 1.5, 0.55, 0.99);
  transform: translateY(-120px);
}

.c-pagetop:not(.active) img {
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-duration: 0.3s;
  transform: rotate(-45deg);
}

.c-pagetop:not(.active) .c::after {
  opacity: 0;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-duration: 0.2s;
  transform: none;
}

[data-whatintent="keyboard"] .c-pagetop:focus {
  outline: 3px solid #444;
}

@media print, screen and (max-width: 979px) {
  .c-pagetop.active .c::after {
    transform: translateY(-96px);
  }
}

/* -------------------------------------------------- footer */
.l-footer {
  position: relative;
  box-sizing: content-box;
  padding: 0 40px;
  margin: 0 auto;
  background-color: #f6f5f3;
}

@media print, screen and (max-width: 979px) {
  .l-footer {
    padding: 40px 32px;
  }
}

@media print, screen and (min-width: 980px) {
  .l-footer {
    padding: 64px 80px;
  }
}

@media (min-width: 1400px) {
  .l-footer::before {
    position: absolute;
    top: 0;
    right: calc(50% - 50vw + 40px);
    left: calc(50% - 50vw + 40px);
    z-index: -1;
    height: 100%;
    content: "";
    background: #f6f5f3;
  }
}

.c-footerLinks {
  font-size: 12px;
}

.footerLinks-item a {
  position: relative;
  display: inline-block;
  padding-bottom: 2px;
}

.footerLinks-item a::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  content: "";
  border-bottom: 1px solid currentcolor;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform-origin: 0 0;
}

.footerLinks-item a:hover::after {
  transform: scaleX(0);
  transform-origin: 100% 0;
}

[data-whatintent="keyboard"] .footerLinks-item a:focus {
  position: relative;
  outline: none;
}

[data-whatintent="keyboard"] .footerLinks-item a:focus::before {
  position: absolute;
  top: -1em;
  right: -1em;
  bottom: -1em;
  left: -1em;
  content: "";
  border: 3px solid;
}

@media print, screen and (max-width: 979px) {
  .c-footerLinks {
    font-size: 11px;
  }
  .footerLinks-item a {
    padding: 0.8em 0;
  }
  .footerLinks-item a::after {
    bottom: calc(0.8em - 2px);
  }
}

@media print, screen and (min-width: 980px) {
  .c-footerLinks {
    display: flex;
    flex-wrap: wrap;
  }
  .footerLinks-item {
    margin: 0 32px 12px 0;
  }
}

.copyright {
  margin: 8px 0 0;
  font-size: 12px;
  color: #888;
}

@media print, screen and (max-width: 979px) {
  .copyright {
    margin-top: 2em;
    font-size: 10px;
  }
}

@media print, screen and (min-width: 980px) {
  .-marginAdjustment {
    margin-left: 80px;
  }
}

/*
@include touch {} // max-width: 979px
@include sp {}    // max-width: 599px
@include tb {}    // min-width: 600px
@include pc {}    // min-width: 980px
@include wide {}  // min-width: 1280px
*/
/* -------------------------------------------------- header */
.l-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  color: #383737;
  text-align: center;
  background: #f7d65b;
}

.l-header svg {
  fill: currentcolor;
}

.l-header .snoopy {
  position: relative;
  margin: 0;
}

.l-header .snoopy a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 123px;
}

.l-header .c-menuButton,
.l-header .c-ticketButton,
.l-header .c-englishLink {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  text-transform: uppercase;
  font-family: zeitung, sans-serif;
  font-style: normal;
  font-weight: 700;
}

.l-header .c-menuButton,
.l-header .c-ticketButton {
  flex-direction: column;
  line-height: 1.6;
  letter-spacing: 0.08rem;
}

.l-header .c-menuButton {
  position: relative;
  padding: 0;
  background: none;
  border: 0;
  -webkit-appearance: none;
  appearance: none;
}

.l-header .c-menuButton svg {
  fill: none;
  stroke: currentcolor;
  stroke-width: 3;
}

.l-header .c-menuButton .p1 {
  transform: translateY(-14px);
}

.l-header .c-menuButton .p3 {
  transform: translateY(14px);
}

.l-header .c-menuButton::after {
  position: absolute;
  bottom: 1em;
  left: 0;
  width: 100%;
  content: "Close";
}

.l-header .c-englishLink span {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
}

.snsLinks-item svg {
  fill: currentcolor;
}

[data-whatintent="keyboard"] .l-header *:focus {
  outline: none;
  box-shadow: inset 0 0 0 3px currentColor;
}

@media print, screen and (max-width: 979px) {
  .l-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 55px;
  }
  .l-header .snoopy a {
    width: 55px;
    height: 55px;
  }
  .l-header .snoopy img {
    width: 32px;
    height: 32px;
  }
  .l-header .c-menuButton,
  .l-header .c-ticketButton {
    width: 55px;
    height: 55px;
    font-size: 9px;
  }
  .l-header .c-menuButton {
    order: 2;
    margin-left: 8px;
  }
  .l-header .c-menuButton svg {
    width: 26px;
    height: 26px;
  }
  .l-header .c-ticketButton {
    order: 1;
    margin-left: auto;
  }
  .l-header .c-ticketButton svg {
    width: 24px;
    height: 24px;
    margin: 2px 0;
  }
  .l-header .c-snsLinks {
    display: none;
  }
  .l-header .c-englishLink {
    width: 55px;
    height: 55px;
    font-size: 12px;
  }
  .l-header .c-englishLink {
    position: relative;
  }
  .l-header .c-englishLink::before,
  .l-header .c-englishLink::after {
    position: absolute;
    top: 1px;
    width: 1px;
    height: calc(100% - 2px);
    content: "";
    background-image: repeating-linear-gradient(to bottom, currentcolor 0 1px, transparent 1px 5px);
  }
  .l-header .c-englishLink::before {
    left: 0;
  }
  .l-header .c-englishLink::after {
    right: 0;
  }
}

@media print, screen and (max-width: 979px) and (-ms-high-contrast: none) {
  .l-header .c-englishLink::before,
  .l-header .c-englishLink::after {
    background-image: linear-gradient(0deg, #3a3a3a 1px, transparent 1px);
    background-repeat: repeat-y;
    background-size: 1px 5px;
  }
}

@media print, screen and (min-width: 980px) {
  .l-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 80px;
    height: 100%;
    overflow-x: hidden;
  }
  .l-header .snoopy {
    flex-basis: 123px;
    width: 80px;
    height: 123px;
    margin-bottom: auto;
  }
  .l-header .snoopy::after {
    position: absolute;
    bottom: 0;
    left: 3px;
    width: calc(100% - 6px);
    height: 1px;
    content: "";
    background-image: repeating-linear-gradient(to right, currentcolor 0 2px, transparent 2px 8px);
  }
  .l-header .c-menuButton,
  .l-header .c-ticketButton {
    box-sizing: content-box;
    flex-basis: 80px;
    width: 80px;
    min-height: 80px;
    margin: 4vh 0;
  }
  .l-header .c-menuButton {
    margin-top: 40px;
  }
  .l-header .c-menuButton svg {
    width: 32px;
    height: 32px;
    margin-bottom: 6px;
  }
  .l-header .c-ticketButton {
    margin-bottom: 48px;
  }
  .l-header .c-ticketButton svg {
    width: 30px;
    height: 30px;
    margin-bottom: 8px;
  }
  .l-header .c-snsLinks {
    position: relative;
    padding: 27px 19px;
    margin-top: auto;
  }
  .l-header .c-snsLinks::before,
  .l-header .c-snsLinks::after {
    position: absolute;
    left: 3px;
    width: calc(100% - 6px);
    height: 1px;
    content: "";
    background-image: repeating-linear-gradient(to right, currentcolor 0 2px, transparent 2px 8px);
  }
  .l-header .c-snsLinks::before {
    top: 0;
  }
  .l-header .c-snsLinks::after {
    bottom: 0;
  }
  .l-header .snsLinks-item a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
  }
  .l-header .snsLinks-item svg {
    width: 15px;
    height: 15px;
  }
  .l-header .c-englishLink {
    flex-basis: 70px;
    width: 80px;
    min-height: 70px;
  }
}

@media print, screen and (min-width: 980px) and (-ms-high-contrast: none) {
  .l-header {
    min-height: 680px;
  }
  .l-header .snoopy::after {
    background-image: linear-gradient(90deg, #3a3a3a 2px, transparent 2px);
  }
  .l-header .c-snsLinks::before,
  .l-header .c-snsLinks::after {
    background-image: linear-gradient(90deg, #3a3a3a 2px, transparent 2px);
  }
  .l-header .snoopy::after,
  .l-header .c-snsLinks::before,
  .l-header .c-snsLinks::after {
    background-repeat: repeat-x;
    background-size: 8px 1px;
  }
}

/* animation */
.l-header .c-menuButton path {
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform-origin: 50% 50%;
}

.l-header .c-menuButton .label,
.l-header .c-menuButton::after {
  transition: opacity 0.2s, transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.l-header .c-menuButton[aria-expanded="true"] .label,
.l-header .c-menuButton[aria-expanded="false"]::after {
  opacity: 0;
  transform: translateY(0.8em);
}

.l-header .c-menuButton[aria-expanded="true"] .p1 {
  transform: rotate(-45deg);
}

.l-header .c-menuButton[aria-expanded="true"] .p2 {
  transform: scaleX(0);
}

.l-header .c-menuButton[aria-expanded="true"] .p3 {
  transform: rotate(45deg);
}

/*
@include touch {} // max-width: 979px
@include sp {}    // max-width: 599px
@include tb {}    // min-width: 600px
@include pc {}    // min-width: 980px
@include wide {}  // min-width: 1280px
*/
/* -------------------------------------------------- main */
.l-main {
  box-sizing: content-box;
  max-width: 1200px;
  padding: 0 20px;
  margin: 0 auto;
}

.l-inner {
  max-width: 880px;
  margin: 88px auto;
}

@media print, screen and (min-width: 980px) {
  .l-main {
    padding: 0 80px;
  }
}

/*
@include touch {} // max-width: 979px
@include sp {}    // max-width: 599px
@include tb {}    // min-width: 600px
@include pc {}    // min-width: 980px
@include wide {}  // min-width: 1280px
*/
/* -------------------------------------------------- menu */
.l-menu {
  position: fixed;
  z-index: 999;
  box-sizing: border-box;
  padding: 55px 30px 0;
  color: white;
}

[data-whatintent="keyboard"] .l-menu *:focus {
  position: relative;
  outline: none;
}

[data-whatintent="keyboard"] .l-menu *:focus::after {
  position: absolute;
  top: -1em;
  right: -1em;
  bottom: -1em;
  left: -1em;
  content: "";
  border: 3px solid #fff;
}

.l-menu,
.menu-overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media print, screen and (min-width: 980px) {
  .l-menu {
    padding: 0 40px 0 120px;
  }
}

@media print, screen and (min-width: 1280px) {
  .l-menu {
    padding: 0 80px 0 160px;
  }
}

.menu-overlay {
  position: fixed;
  background: #444;
}

/* menu list */
.l-menu .c-siteLinks {
  position: relative;
  display: grid;
  grid-template-columns: 3fr 7fr;
  grid-gap: 32px 24px;
  width: 100%;
  max-width: 1200px;
  -ms-grid-rows: auto 16px [5] auto;
  -ms-grid-columns: 3fr 24px 7fr;
}

.l-menu .siteLinks-item.-news {
  grid-area: 2 / 1;
}

.l-menu .siteLinks-item.-gallery {
  grid-area: 3 / 1;
}

.l-menu .siteLinks-item.-ticket {
  grid-area: 4 / 1;
}

.l-menu .siteLinks-item.-store {
  grid-area: 5 / 1;
}

.l-menu .siteLinks-item.-cafe {
  grid-area: 6 / 1;
}

.l-menu .siteLinks-item.-news,
.l-menu .siteLinks-item.-app {
  -ms-grid-row: 3;
}

.l-menu .siteLinks-item.-gallery,
.l-menu .siteLinks-item.-about {
  -ms-grid-row: 5;
}

.l-menu .siteLinks-item.-ticket,
.l-menu .siteLinks-item.-access {
  -ms-grid-row: 7;
}

.l-menu .siteLinks-item.-store,
.l-menu .siteLinks-item.-faq {
  -ms-grid-row: 9;
}

.l-menu .siteLinks-item.-cafe {
  -ms-grid-row: 11;
}

.l-menu .siteLinks-item.-workshop,
.l-menu .siteLinks-item.-app,
.l-menu .siteLinks-item.-about,
.l-menu .siteLinks-item.-access,
.l-menu .siteLinks-item.-faq {
  -ms-grid-column: 3;
}

.l-menu .c-siteLinks a {
  display: inline-block;
}

.l-menu .c-localLinks,
.l-menu .c-snsLinks {
  display: flex;
  font-size: 13px;
}

.l-menu .c-localLinks {
  flex-wrap: wrap;
}

.l-menu .localLinks-item:not(:first-child) {
  position: relative;
  padding-left: 20px;
  margin-left: 20px;
}

.l-menu .localLinks-item:not(:first-child)::before {
  position: absolute;
  left: -0.25em;
  color: #808080;
  content: "/";
}

.l-menu .c-snsLinks {
  justify-content: space-between;
  width: 100px;
  margin-left: auto;
}

.l-menu .snsLinks-item svg {
  width: 18px;
  height: 18px;
}

.l-menu .c-englishLink {
  font-size: 13px;
  text-transform: uppercase;
}

.l-menu .c-englishLink a::before {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 2.5em;
  margin-right: 1.5em;
  vertical-align: middle;
  content: "";
  border-bottom: 1px solid currentcolor;
}

@media print, screen and (max-width: 979px) {
  .l-menu.active {
    overflow: auto;
  }
  .l-menu .c-siteLinks {
    -ms-grid-rows: auto 16px [7] auto;
    -ms-grid-columns: auto 24px auto;
    grid-template: auto / auto auto;
    grid-gap: 24px;
    padding: 32px 0;
  }
  .l-menu .c-localLinks,
  .l-menu .c-snsLinks,
  .l-menu .c-englishLink {
    font-size: 10px;
  }
  .l-menu .c-localLinks {
    padding: 20px 0;
    border-top: 1px solid #3a3a3a;
    border-bottom: 1px solid #3a3a3a;
  }
  .l-menu .localLinks-item:not(:first-child) {
    padding-left: 1em;
    margin-left: 1em;
  }
  .l-menu .siteLinks-item.-local {
    grid-area: 7 / 1 / 8 / 3;
    -ms-grid-row: 13;
    -ms-grid-column-span: 3;
  }
  .l-menu .siteLinks-item.-sns {
    grid-area: 8 / 2 / 9 / 3;
    -ms-grid-row: 15;
    margin-top: -10px;
  }
  .l-menu .c-englishLink {
    grid-area: 8 / 1 / 9 / 2;
    -ms-grid-row: 15;
    align-self: center;
    margin-top: -10px;
  }
  .l-menu .c-snsLinks {
    margin-right: -10px;
  }
  .l-menu .snsLinks-item {
    padding: 10px;
  }
  .l-menu .snsLinks-item svg {
    width: 14px;
    height: 14px;
  }
}

@media print, screen and (max-width: 599px) {
  .l-menu .c-siteLinks {
    grid-gap: 16px;
    padding: 24px 0;
  }
}

@media print, screen and (min-width: 980px) {
  .l-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .l-menu .siteLinks-item {
    display: inline-block;
  }
  .l-menu .siteLinks-item.-local {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: auto 0 6px;
  }
  .l-menu .siteLinks-item.-local {
    -ms-grid-row: 11;
    -ms-grid-column: 3;
    -ms-grid-row-align: end;
  }
  .l-menu .siteLinks-item.-sns {
    position: absolute;
    right: 0;
    bottom: 6px;
  }
  .l-menu .c-englishLink {
    position: absolute;
    top: 0;
    right: 0;
  }
}

@media print, screen and (min-width: 1280px) {
  .l-menu .c-siteLinks {
    -ms-grid-columns: 1fr 24px 2fr;
    grid-template-columns: 1fr 2fr;
  }
}

/* menu animation */
.l-menu.anima .c-siteLinks {
  transition: opacity 0.3s, transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.l-menu.anima .menu-overlay {
  transition: opacity 0.3s;
}

.l-menu:not(.active) {
  pointer-events: none;
}

.l-menu:not(.active) .c-siteLinks {
  opacity: 0;
}

.l-menu:not(.active) .menu-overlay {
  opacity: 0;
}

@media print, screen and (min-width: 600px) {
  .l-menu:not(.active) .c-siteLinks {
    transform: translateX(-10vw);
  }
}

@media print, screen and (max-width: 979px) {
  .l-menu:not(.active) .c-siteLinks {
    transform: translateY(-10vh);
  }
}

.l-subHeader {
  position: relative;
  width: 100%;
  height: 77px;
  padding: 0 20px;
  margin: 0 auto;
}

@media print, screen and (min-width: 600px) {
  .l-subHeader {
    width: 100%;
    max-width: 1440px;
    height: 127px;
    margin: 0 auto;
  }
}

.l-subHeader__inner {
  position: relative;
  display: block;
  width: 100%;
  max-width: 878px;
  height: 77px;
  margin: 0 auto;
}

@media print, screen and (min-width: 600px) {
  .l-subHeader__inner {
    width: 100%;
    max-width: 878px;
    height: 127px;
    margin: 0 auto;
  }
}

.l-subHeader__heading {
  position: absolute;
  top: 26px;
  z-index: 0;
  display: block;
  font-size: 32px;
  line-height: calc(51.7 / 32);
  text-transform: uppercase;
}

@media print, screen and (min-width: 980px) {
  .l-subHeader__heading {
    top: 43px;
    left: 79px;
    font-size: 42px;
  }
}

@media print, screen and (min-width: 600px) and (max-width: 979px) {
  .l-subHeader__heading {
    top: 43px;
    left: 10%;
    font-size: 42px;
    letter-spacing: 0.02em;
  }
}

@media print, screen and (min-width: 980px) {
  .l-subHeader.-faq .l-subHeader__heading {
    left: 0;
  }
}

@media print, screen and (min-width: 600px) and (max-width: 979px) {
  .l-subHeader.-faq .l-subHeader__heading {
    left: 0;
  }
}

.l-subHeader.-inquires .l-subHeader__heading {
  top: 21px;
}

@media print, screen and (min-width: 600px) {
  .l-subHeader.-inquires .l-subHeader__heading {
    top: 5px;
    left: 0;
  }
}

.l-subHeader__subHeading {
  position: absolute;
  top: 70px;
  z-index: 0;
  display: block;
  font-size: 16px;
  line-height: calc(51.7 / 32);
  color: #808080;
  text-transform: uppercase;
  font-family: source-han-sans-japanese, sans-serif;
  font-style: normal;
  font-weight: 700;
}

@media print, screen and (min-width: 600px) {
  .l-subHeader__subHeading {
    position: absolute;
    top: 67px;
    z-index: 0;
    display: block;
    font-size: 16px;
    line-height: calc(32 / 16);
    text-transform: uppercase;
  }
}

.l-subHeader__character {
  position: absolute;
  top: 43px;
  right: 14px;
  z-index: 10;
  display: block;
  width: 67px;
  height: auto;
}

@media print, screen and (min-width: 600px) {
  .l-subHeader__character {
    top: 56px;
    right: 44px;
    width: 100px;
    height: auto;
  }
}

@media print, screen and (min-width: 980px) {
  .l-subHeader__character {
    top: 56px;
    right: 30px !important;
    width: 100px;
    height: auto;
  }
}

.l-subHeader.-snoopy .l-subHeader__character {
  position: absolute;
  top: 42px;
  right: 24px;
  display: block;
  width: 51px;
  height: auto;
}

@media print, screen and (min-width: 600px) {
  .l-subHeader.-snoopy .l-subHeader__character {
    top: 62px;
    right: 63px !important;
    width: 74px;
    height: auto;
  }
}

@media print, screen and (min-width: 980px) {
  .l-subHeader.-snoopy .l-subHeader__character {
    right: 23px;
    width: 74px;
    height: auto;
  }
}

.l-subHeader.-faq .l-subHeader__character {
  position: absolute;
  top: 42px;
  right: 24px;
  display: block;
  width: 51px;
  height: auto;
}

@media print, screen and (min-width: 600px) {
  .l-subHeader.-faq .l-subHeader__character {
    top: 68px;
    right: 0 !important;
    width: 89px;
    height: auto;
  }
}

@media print, screen and (min-width: 980px) {
  .l-subHeader.-faq .l-subHeader__character {
    right: 23px;
    width: 74px;
    height: auto;
  }
}

.l-subHeader__characterImg {
  display: block;
  width: 100%;
  height: auto;
}

/*
@include touch {} // max-width: 979px
@include sp {}    // max-width: 599px
@include tb {}    // min-width: 600px
@include pc {}    // min-width: 980px
@include wide {}  // min-width: 1280px
*/
/* -------------------------------------------------- ABOUT */
.l-main.p-about {
  padding-top: 42px;
}

/* スヌーピーミュージアムとは？ */
.p-aboutMuseum {
  margin: 88px 0;
}

.p-aboutMuseum .c-title {
  font-size: 16px;
}

.p-aboutMuseum .c-text {
  font-size: 13px;
}

/* THE HISTORY OF MUSEUM */
.p-history {
  max-width: 1040px;
  margin-right: auto;
  margin-left: auto;
}

.c-history {
  padding: 40px;
  margin: 32px 0;
  background: white;
  border-radius: 20px;
}

.history-date {
  margin-top: 0;
  font-size: 28px;
  font-family: zeitung, sans-serif;
  font-style: normal;
  font-weight: 700;
}

.history-title {
  margin-top: 1em;
  font-size: 16px;
}

.history-text {
  margin-bottom: 0;
  font-size: 13px;
}

.history-img img {
  width: 100%;
}

@media print, screen and (max-width: 979px) {
  .p-history {
    padding: 64px 0 80px;
  }
  .c-history {
    padding: 24px;
  }
  .history-date {
    font-size: 20px;
  }
  .history-title {
    font-size: 15px;
  }
  .history-img {
    margin-top: 32px;
  }
}

@media (min-width: 1025px) {
  .history-img {
    padding-left: 80px;
  }
}

@media print, screen and (min-width: 980px) {
  .p-history {
    padding: 80px 0 120px;
  }
  .c-history {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 880px;
    margin: 32px auto;
  }
  .history-txt {
    flex-basis: 55%;
  }
  .history-img {
    flex-basis: 45%;
    padding-left: 40px;
  }
}

.p-accessBus {
  /* autoprefixer grid: no-autoplace */
}

@media print, screen and (min-width: 980px) {
  .p-accessBus {
    display: block;
    grid-template-areas: "title image" "description image" "button image";
    grid-template-rows: 96px 104px 80px;
    grid-template-columns: 1fr 50%;
    column-gap: 80px;
    padding-top: 60px;
    padding-bottom: 145px;
  }
}

@media print, screen and (max-width: 979px) {
  .p-accessBus {
    padding-top: 50px;
    padding-bottom: 70px;
  }
}

.p-accessBus__title {
  padding-top: 20px;
}

@media print, screen and (min-width: 980px) {
  .p-accessBus__title {
    grid-area: title;
  }
}

@media print, screen and (min-width: 980px) {
  .p-accessBus__image {
    grid-area: image;
  }
}

@media print, screen and (max-width: 979px) {
  .p-accessBus__image {
    margin-top: 35px;
    text-align: center;
  }
}

.p-accessBus__description {
  letter-spacing: 0.06em;
}

@media print, screen and (min-width: 980px) {
  .p-accessBus__description {
    font-size: 15px;
    font-size: 0.9375rem;
    grid-area: description;
    margin-top: 26px;
    line-height: 2.13333;
  }
}

@media print, screen and (max-width: 979px) {
  .p-accessBus__description {
    font-size: 14px;
    font-size: 0.875rem;
    margin-top: 30px;
    line-height: 1.85714;
  }
}

@media print, screen and (min-width: 980px) {
  .p-accessBus__btn {
    grid-area: button;
  }
}

@media print, screen and (max-width: 979px) {
  .p-accessBus__btn {
    max-width: 240px;
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto;
  }
}

.p-access__header {
  position: relative;
  padding-top: 45px;
}

@media print, screen and (max-width: 979px) {
  .p-access__header {
    padding-top: 30px;
  }
}

.p-access__body {
  max-width: 880px;
  margin-right: auto;
  margin-left: auto;
  color: #444;
}

.p-access__map {
  max-width: 880px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

@media print, screen and (max-width: 979px) {
  .p-access__map {
    margin-top: 6px;
  }
}

.p-access__mapImg {
  width: 100%;
  max-width: none;
}

.p-access__btn {
  position: absolute;
  right: 0;
  bottom: -166px;
  z-index: 3;
}

@media print, screen and (max-width: 979px) {
  .p-access__btn {
    right: -5px;
    bottom: -112px;
  }
}

@media print, screen and (min-width: 1280px) {
  .p-access__btn {
    right: 35px;
    bottom: -141px;
  }
}

.p-accessAbout {
  position: relative;
  padding-top: 139px;
  padding-bottom: 70px;
  margin-top: -80px;
}

@media print, screen and (max-width: 979px) {
  .p-accessAbout {
    padding-top: 147px;
    margin-top: -30px;
  }
}

@media print, screen and (min-width: 980px) {
  .p-accessAbout__list {
    font-size: 15px;
    font-size: 0.9375rem;
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    line-height: 1.73333;
  }
}

@media print, screen and (max-width: 979px) {
  .p-accessAbout__list {
    font-size: 14px;
    font-size: 0.875rem;
    margin-top: 25px;
    line-height: 1.64286;
  }
}

.p-accessAbout__term {
  font-weight: 500;
}

@media print, screen and (min-width: 980px) {
  .p-accessAbout__term {
    width: 120px;
    min-height: 2.2em;
  }
}

@media print, screen and (max-width: 979px) {
  .p-accessAbout__term:not(:first-of-type) {
    margin-top: 20px;
  }
}

@media print, screen and (min-width: 980px) {
  .p-accessAbout__detail {
    width: calc(100% - 120px);
  }
}

@media print, screen and (max-width: 979px) {
  .p-accessAbout__detail {
    margin-top: 8px;
  }
}

.p-accessAbout__notice {
  margin-top: 20px;
}

@media print, screen and (min-width: 980px) {
  .p-accessAbout__notice {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 1.73333;
  }
}

@media print, screen and (max-width: 979px) {
  .p-accessAbout__notice {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.64286;
  }
}

.p-accessAbout__notice > small {
  font-size: inherit;
}

.p-accessAbout__contact {
  font-size: 15px;
  font-size: 0.9375rem;
  margin-top: 20px;
}

.p-accessAbout__illust {
  position: absolute;
}

@media print, screen and (min-width: 980px) {
  .p-accessAbout__illust {
    right: 0;
    bottom: 0;
    width: 94px;
    height: 106px;
  }
}

@media print, screen and (max-width: 979px) {
  .p-accessAbout__illust {
    top: 42px;
    left: 0;
    width: 62px;
  }
}

.p-accessAbout__illust > img {
  width: 100%;
  height: 100%;
}

@media print, screen and (min-width: 980px) {
  .p-accessCar {
    padding-top: 112px;
    padding-bottom: 130px;
  }
}

@media print, screen and (max-width: 979px) {
  .p-accessCar {
    padding-top: 80px;
    padding-bottom: 112px;
  }
}

.p-accessCar__body {
  display: flex;
}

@media print, screen and (min-width: 980px) {
  .p-accessCar__body {
    margin-top: 45px;
  }
}

@media print, screen and (max-width: 979px) {
  .p-accessCar__body {
    flex-direction: column-reverse;
    margin-top: 40px;
  }
}

@media print, screen and (min-width: 980px) {
  .p-accessCar__textGroup {
    flex-grow: 1;
    width: calc(100% - 68px - 440px);
    margin-right: 68px;
  }
}

@media print, screen and (max-width: 979px) {
  .p-accessCar__textGroup {
    margin-top: 40px;
  }
}

.p-accessCar__subTitle {
  font-weight: 500;
  letter-spacing: 0.06em;
}

@media print, screen and (min-width: 980px) {
  .p-accessCar__subTitle::before {
    display: block;
    width: 0;
    height: 0;
    margin-top: calc((1 - 1.8125) * 0.5em);
    content: "";
  }
}

@media print, screen and (max-width: 979px) {
  .p-accessCar__subTitle {
    font-size: 14px;
    font-size: 0.875rem;
  }
}

.p-accessCar__list {
  margin-top: 14px;
  letter-spacing: 0.06em;
}

@media print, screen and (min-width: 980px) {
  .p-accessCar__list {
    font-size: 15px;
    font-size: 0.9375rem;
    display: flex;
    flex-wrap: wrap;
    line-height: 2.13333;
  }
}

@media print, screen and (max-width: 979px) {
  .p-accessCar__list {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.85714;
  }
}

@media print, screen and (min-width: 980px) {
  .p-accessCar__term {
    width: 118px;
  }
}

@media print, screen and (max-width: 979px) {
  .p-accessCar__term {
    font-weight: 500;
  }
}

@media print, screen and (max-width: 979px) {
  .p-accessCar__term:not(:first-of-type) {
    margin-top: 20px;
  }
}

@media print, screen and (min-width: 980px) {
  .p-accessCar__description {
    width: calc(100% - 118px);
  }
}

@media print, screen and (max-width: 979px) {
  .p-accessCar__description {
    margin-top: 6px;
  }
}

.p-accessCar__summary {
  letter-spacing: 0.06em;
}

@media print, screen and (min-width: 980px) {
  .p-accessCar__summary {
    font-size: 15px;
    font-size: 0.9375rem;
    margin-top: 30px;
    line-height: 2.13333;
  }
}

@media print, screen and (max-width: 979px) {
  .p-accessCar__summary {
    font-size: 14px;
    font-size: 0.875rem;
    margin-top: 14px;
    line-height: 1.85714;
  }
}

.p-accessCar__map {
  flex-shrink: 0;
}

@media print, screen and (min-width: 980px) {
  .p-accessCar__map {
    width: 440px;
  }
}

@media print, screen and (max-width: 979px) {
  .p-accessCar__map {
    text-align: center;
  }
}

@media print, screen and (min-width: 980px) {
  .p-accessRailway {
    padding-top: 100px;
    padding-bottom: 130px;
  }
}

@media print, screen and (max-width: 979px) {
  .p-accessRailway {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.p-accessRailway__body {
  /* autoprefixer grid: no-autoplace */
}

@media print, screen and (min-width: 980px) {
  .p-accessRailway__body {
    display: grid;
    grid-template-areas: "map map" "summary detail";
    grid-template-rows: repeat(2, auto);
    grid-template-columns: 43% 1fr;
    gap: 60px;
    margin-top: 30px;
  }
}

@media print, screen and (max-width: 979px) {
  .p-accessRailway__body {
    display: grid;
    grid-template-areas: "map" "summary" "detail";
    grid-template-rows: repeat(3, auto);
    grid-template-columns: 100%;
    row-gap: 30px;
    margin-top: 40px;
  }
}

.p-accessRailway__summary {
  grid-area: summary;
}

.p-accessRailway__text {
  letter-spacing: 0.06em;
}

@media print, screen and (min-width: 980px) {
  .p-accessRailway__text {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 2.13333;
  }
}

@media print, screen and (max-width: 979px) {
  .p-accessRailway__text {
    font-size: 14px;
    font-size: 0.875rem;
    margin-top: 5px;
    line-height: 1.85714;
  }
}

.p-accessRailway__detail {
  font-size: 15px;
  font-size: 0.9375rem;
  grid-area: detail;
}

@media print, screen and (max-width: 979px) {
  .p-accessRailway__detail {
    font-size: 14px;
    font-size: 0.875rem;
  }
}

.p-accessRailway__list > dt {
  font-weight: 500;
}

.p-accessRailway__map {
  grid-area: map;
}

@media print, screen and (max-width: 979px) {
  .p-accessRailway__map {
    text-align: center;
  }
}

/*
@include touch {} // max-width: 979px
@include sp {}    // max-width: 599px
@include tb {}    // min-width: 600px
@include pc {}    // min-width: 980px
@include wide {}  // min-width: 1280px
*/
/* -------------------------------------------------- APP */
.l-main.p-app {
  padding-top: 42px;
}

.p-app .keyVisual-copy {
  font-size: 24px;
  text-transform: uppercase;
}

.p-app .keyVisual-text {
  max-width: 380px;
  font-size: 13px;
}

.c-keyVisual .c-appLinks {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.c-keyVisual .appLinks-item:nth-child(n + 2) {
  margin-left: 24px;
}

.c-keyVisual .c-appLinks img {
  width: auto;
  height: 50px;
}

.c-keyVisual .keyVisual-smartphone {
  position: absolute;
}

.p-app .c-title {
  font-size: 22px;
}

.c-title.-centerbar {
  text-align: center;
}

.c-title.-centerbar::after {
  display: block;
  width: 40px;
  margin: 12px auto 0;
  content: "";
  border-top: 2px solid currentcolor;
}

@media print, screen and (max-width: 979px) {
  .p-app .c-title {
    font-size: 20px;
  }
  .p-app .c-keyVisual {
    padding-top: calc(88vw / 3.75);
  }
  .p-app .keyVisual-content {
    padding-top: calc(56vw / 3.75);
  }
  .c-keyVisual .keyVisual-smartphone {
    top: 0;
    left: calc(50% - 92vw / 3.75);
    width: calc(210vw / 3.75);
    height: calc(389vw / 3.75);
  }
}

@media print, screen and (min-width: 980px) {
  .p-app .c-keyVisual {
    padding-bottom: 160px;
  }
  .c-keyVisual .keyVisual-smartphone {
    top: 50px;
    left: calc(25% - 140px);
    width: 320px;
    height: 593px;
  }
}

/* 遊び方 */
.p-appHowToPlay .c-title.-centerbar {
  margin-bottom: 80px;
}

.c-howToPlay {
  position: relative;
}

.c-howToPlay + .c-howToPlay {
  margin-top: 112px;
}

.c-howToPlay::after {
  position: absolute;
  top: -70px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 140px;
  font-size: 70px;
  color: white;
  content: attr(data-num);
  background: #519c60;
  border-radius: 140px;
  font-family: zeitung, sans-serif;
  font-style: normal;
  font-weight: 700;
}

.c-howToPlay:not(.-reverse)::after {
  right: -70px;
}

.c-howToPlay.-reverse::after {
  left: -70px;
}

.c-howToPlay > * {
  box-sizing: border-box;
  flex-basis: 50%;
}

.c-howToPlay .c-title {
  margin-bottom: 40px;
}

.howToPlay-img img {
  width: 100%;
}

.howToPlay-text {
  font-size: 15px;
}

@media print, screen and (max-width: 979px) {
  .p-appHowToPlay .c-title.-centerbar {
    margin-bottom: 64px;
  }
  .c-howToPlay + .c-howToPlay {
    margin-top: 64px;
  }
  .c-howToPlay::after {
    top: -35px;
    width: 70px;
    height: 70px;
    font-size: 35px;
    border-radius: 70px;
  }
  .c-howToPlay:not(.-reverse)::after {
    right: 0;
  }
  .c-howToPlay.-reverse::after {
    left: 0;
  }
  .howToPlay-img {
    padding: 0 35px;
    margin-bottom: 40px;
  }
  .howToPlay-text {
    font-size: 14px;
  }
  .c-howToPlay .c-title {
    margin-bottom: 32px;
  }
}

@media print, screen and (min-width: 980px) {
  .c-howToPlay {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .c-howToPlay:not(.-reverse) .howToPlay-text {
    order: -1;
    padding-right: 80px;
  }
  .c-howToPlay.-reverse .howToPlay-text {
    padding-left: 80px;
  }
}

/* 公園をめぐろう！ */
.p-appGoPark {
  margin-top: 80px;
  margin-bottom: 100px;
}

.p-appGoPark .c-title {
  margin: 40px 0 20px;
  font-size: 15px;
}

.goPark-img img {
  width: 100%;
  border-radius: 20px;
}

.goPark-text {
  font-size: 13px;
}

@media print, screen and (min-width: 980px) {
  .p-appGoPark {
    margin-top: 160px;
    margin-bottom: 200px;
  }
  .p-appGoPark .c-title {
    font-size: 16px;
  }
}

/*
@include sp{}
@include tb{}
@include pc{}
@include touch{}
*/
.p-cafe {
  padding-bottom: 100px;
}

.p-cafe__photo {
  max-width: 880px;
  margin-top: -66px;
  margin-right: auto;
  margin-bottom: 55px;
  margin-left: auto;
}

@media print, screen and (min-width: 980px) {
  .p-cafe__photo {
    margin-bottom: 100px;
  }
}

.p-cafe__photo figure {
  max-width: 880px;
  overflow: hidden;
  border-radius: 20px;
}

.p-cafe__btn {
  margin-top: 40px;
  text-align: center;
}

@media print, screen and (min-width: 980px) {
  .p-cafe__btn {
    margin-top: 50px;
    margin-bottom: 160px;
  }
}

.p-cafe__btnText {
  margin-top: 20px;
  font-size: 13px;
  font-size: 0.8125rem;
}

@media print, screen and (min-width: 980px) {
  .p-cafe__btnText {
    font-size: 14px;
    font-size: 0.875rem;
    margin-top: 30px;
  }
}

.p-cafe__btn a.c-button {
  position: relative;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  height: 56px;
  margin-right: auto;
  margin-left: auto;
  transition: all 0.3s ease 0s;
}

.p-cafe__btn a.c-button::before {
  position: absolute;
  top: 50%;
  right: 20px;
  width: 30px;
  height: 20px;
  content: "";
  background-image: url(../img/common/ico_arrow.svg);
  background-size: 100% auto;
  transform: translateY(-50%);
}

@media print, screen and (min-width: 980px) {
  .p-cafe__btn a.c-button:hover {
    transition: all 0.3s ease 0s;
    transform: translateY(-0.3em);
  }
}

.p-cafe__btn.-englishBtn {
  margin-top: 71px;
}

@media print, screen and (min-width: 600px) {
  .p-cafe__btn.-englishBtn {
    margin-top: 0;
  }
}

.p-cafe__btn.-englishBtn a.c-button {
  max-width: 280px;
  height: 48px;
  margin-bottom: 104px;
}

@media print, screen and (min-width: 600px) {
  .p-cafe__btn.-englishBtn a.c-button {
    max-width: 400px;
    height: 56px;
    margin-bottom: 0;
  }
}

.p-pageHeader.-camera {
  padding-bottom: 64px;
}

@media print, screen and (max-width: 599px) {
  .p-pageHeader.-camera {
    padding-bottom: 32px;
  }
}

.p-pageHeader.-camera .p-pageHeader__img img {
  width: 100%;
}

.p-pageHeader.-camera .p-pageHeader__lead {
  padding-top: 48px;
}

@media print, screen and (max-width: 599px) {
  .p-pageHeader.-camera .p-pageHeader__lead {
    padding-top: 24px;
  }
}

.p-pageHeader.-camera .p-pageHeader__lead p {
  margin-top: 1em;
}

.p-camera {
  max-width: 700px;
  margin: 0 auto;
}

.l-main.p-camera {
  padding-bottom: 136px;
}

/* こんな写真が撮れます */
.p-camera__img img {
  border-radius: 20px;
}

.p-camera__img .caption {
  margin-top: .5em;
  font-size: 14px;
  text-align: right;
}

@media print, screen and (max-width: 599px) {
  .p-camera__img .caption {
    font-size: 12px;
  }
}

.p-camera__text {
  margin-top: .5em;
  font-size: 16px;
}

@media print, screen and (min-width: 980px) {
  .p-camera__text {
    line-height: 1.2em;
  }
}

@media print, screen and (max-width: 599px) {
  .p-camera__text {
    margin-top: 1em;
    line-height: 1.6;
    text-align: center;
  }
}

.p-camera__text .price {
  position: relative;
  top: -.15em;
  font-size: 180%;
  vertical-align: middle;
}

.p-camera__text .note {
  font-size: 80%;
}

@media print, screen and (max-width: 599px) {
  .p-camera__text .note {
    display: block;
    line-height: 1;
  }
}

/* SNOOPY ROOM CAMERA 撮影方法 */
.p-camera__flow {
  margin-top: 48px;
  padding: 24px;
  border-radius: 20px;
  text-align: center;
  background: #f6f5f3;
}

@media print, screen and (max-width: 599px) {
  .p-camera__flow {
    padding: 24px calc(24vw / 3.75);
  }
}

.p-camera__flowTitle {
  font-size: 23px;
}

@media print, screen and (min-width: 980px) {
  .p-camera__flowTitle .main,
  .p-camera__flowTitle .free {
    position: relative;
    top: -.15em;
    vertical-align: middle;
  }
  .p-camera__flowTitle .main {
    font-size: 120%;
  }
}

@media print, screen and (max-width: 599px) {
  .p-camera__flowTitle {
    font-size: 18px;
    line-height: 1.2;
  }
}

.p-camera__flowTitle .free {
  font-size: 70%;
}

.p-camera__flowTitle::before {
  content: "";
  display: inline-block;
  position: relative;
  top: -.3em;
  width: 3em;
  height: 2.5em;
  margin-right: .5em;
  vertical-align: middle;
  background: url(../img/camera/camera_ico.png) center/contain no-repeat;
}

@media print, screen and (max-width: 599px) {
  .p-camera__flowTitle::before {
    display: block;
    width: 3.23em;
    height: 2.65em;
    margin: 0 auto;
  }
}

.p-camera__flowList {
  display: flex;
  gap: 2em .5em;
  justify-content: center;
  margin-top: 24px;
  text-align: left;
}

@media print, screen and (max-width: 599px) {
  .p-camera__flowList {
    column-gap: 0;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.p-camera__flowItem {
  z-index: 0;
  position: relative;
  padding-left: 1.4em;
  counter-increment: step;
  font-size: 18px;
  line-height: 1.4;
  font-weight: 700;
}

.p-camera__flowItem::before {
  content: counter(step);
  z-index: -1;
  position: absolute;
  top: calc(50% - .55em);
  left: 0;
  color: #f7d65b;
  font-size: 444%;
  line-height: 1;
  font-family: "zeitung", sans-serif;
}

@media print, screen and (max-width: 599px) {
  .p-camera__flowItem {
    font-size: 16px;
  }
  .p-camera__flowItem:nth-child(2n+1) {
    flex-basis: 40%;
  }
  .p-camera__flowItem:nth-child(2n) {
    flex-basis: 60%;
  }
}

.p-camera__flowNote {
  margin-top: 24px;
  font-size: 14px;
}

@media print, screen and (max-width: 599px) {
  .p-camera__flowNote {
    font-size: 10px;
    letter-spacing: .1em;
  }
}

.p-camera__flowNote b {
  font-size: 120%;
  vertical-align: middle;
}

.p-camera__flowNote .num {
  position: relative;
  top: -.15em;
  font-size: 160%;
  vertical-align: middle;
}

/*
@include sp{}
@include tb{}
@include pc{}
@include touch{}
*/
.p-cardAll {
  padding-top: 80px;
  overflow: hidden;
}

.p-cardAll__title {
  font-family: zeitung, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  font-size: 1.375rem;
  text-transform: uppercase;
  letter-spacing: 0.12em;
}

.p-cardAll__meta {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.p-cardAll__select {
  display: flex;
  justify-content: flex-end;
}

.p-cardAll__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
}

@media print, screen and (max-width: 599px) {
  .p-cardAll__list {
    max-width: 275px;
  }
}

@media print, screen and (min-width: 600px) and (max-width: 979px) {
  .p-cardAll__list {
    justify-content: center;
  }
}

@media print, screen and (min-width: 980px) {
  .p-cardAll__list {
    justify-content: flex-start;
  }
}

.p-cardAll__item {
  margin-bottom: 60px;
}

@media print, screen and (min-width: 600px) {
  .p-cardAll__item {
    width: 46%;
    margin-bottom: 80px;
    margin-left: 7%;
  }
  .p-cardAll__item:nth-child(2n + 1) {
    margin-left: 0;
  }
}

@media print, screen and (min-width: 980px) {
  .p-cardAll__item {
    width: 23% !important;
    margin-bottom: 80px;
    margin-left: 2% !important;
  }
  .p-cardAll__item:nth-child(2n + 1) {
    margin-left: 2%;
  }
}

@media print, screen and (min-width: 980px) {
  .p-cardAll__item {
    max-width: 25%;
    margin-bottom: 80px;
    margin-left: 26px;
  }
  .p-cardAll__item:nth-child(4n + 1) {
    margin-left: 0;
  }
}

.p-cardAll .c-sectionInner {
  margin-bottom: 80px;
}

/*
@include sp{}
@include tb{}
@include pc{}
@include touch{}
*/
.p-cardPick {
  position: relative;
  padding-top: 90px;
  padding-bottom: 90px;
  margin-right: -20px;
  margin-left: -20px;
  background: #f6f5f3;
}

@media print, screen and (min-width: 980px) {
  .p-cardPick {
    padding-top: 75px;
    margin-right: calc((((100vw - 85px) - 100%) / 2) * -1);
    margin-left: calc((((100vw - 85px) - 100%) / 2) * -1);
  }
}

.p-cardPick.-column3 {
  margin: 0 -20px;
}

@media print, screen and (min-width: 980px) {
  .p-cardPick.-column3 {
    margin: 0 auto;
  }
}

.p-cardPick__list {
  display: flex;
  flex-wrap: wrap;
  margin-right: auto;
  margin-left: auto;
}

@media print, screen and (max-width: 599px) {
  .p-cardPick__list {
    max-width: 315px;
  }
}

@media print, screen and (min-width: 980px) {
  .p-cardPick__list {
    flex-direction: row;
    max-width: 1360px;
    padding: 0 80px;
  }
}

.p-cardPick__list .flickity-viewport {
  width: 100%;
}

.p-cardPick__list .flickity-button {
  position: absolute;
  color: #333;
  background: rgba(255, 255, 255, 0.75);
  border: none;
}

.p-cardPick__list .flickity-button:hover {
  cursor: pointer;
  background: white;
}

.p-cardPick__list .flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19f;
}

.p-cardPick__list .flickity-button:active {
  opacity: 0.6;
}

.p-cardPick__list .flickity-button:disabled {
  pointer-events: none;
  cursor: auto;
  opacity: 0.3;
}

.p-cardPick__list .flickity-button-icon {
  fill: currentColor;
}

.p-cardPick__list .flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  transform: translateY(-50%);
}

.p-cardPick__list .flickity-prev-next-button.previous {
  left: 10px;
}

.p-cardPick__list .flickity-prev-next-button.next {
  right: 10px;
}

.p-cardPick__list .flickity-rtl .flickity-prev-next-button.previous {
  right: 10px;
  left: auto;
}

.p-cardPick__list .flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.p-cardPick__list .flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  top: 20%;
  left: 20%;
  width: 60%;
  height: 60%;
}

.p-cardPick__list .flickity-page-dots {
  bottom: 64px;
}

.p-cardPick.-column3 .p-cardPick__list {
  max-width: inherit;
}

@media print, screen and (max-width: 599px) {
  .p-cardPick.-column3 .p-cardPick__list {
    max-width: 275px;
  }
}

@media print, screen and (min-width: 600px) {
  .p-cardPick.-column3 .p-cardPick__list {
    max-width: 86%;
  }
}

@media print, screen and (min-width: 980px) {
  .p-cardPick.-column3 .p-cardPick__list {
    padding: 0 80px;
  }
}

.p-cardPick__list.-gallery {
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;
  margin-right: auto;
  margin-left: auto;
}

@media print, screen and (max-width: 599px) {
  .p-cardPick__list.-gallery {
    max-width: 315px;
  }
}

@media print, screen and (min-width: 980px) {
  .p-cardPick__list.-gallery {
    flex-direction: row;
    max-width: 1360px;
    padding: 0;
  }
}

@media print, screen and (min-width: 980px) {
  .p-cardPick__list.-gallery .flickity-page-dots {
    bottom: -26px;
  }
}

@media print, screen and (min-width: 600px) {
  .p-cardPick__list.-store {
    padding: 0 80px !important;
    margin-bottom: 18px;
  }
}

.p-cardPick__item {
  width: 100%;
  margin-left: 10%;
}

@media print, screen and (min-width: 600px) and (max-width: 979px) {
  .p-cardPick__item {
    max-width: 30%;
    margin-left: 5%;
  }
}

@media print, screen and (min-width: 980px) {
  .p-cardPick__item {
    width: 28%;
    max-width: 365px;
    margin-bottom: 0;
    margin-left: 8%;
  }
}

@media print, screen and (min-width: 980px) {
  .p-cardPick__list.-store .p-cardPick__item {
    width: 31%;
    max-width: 365px;
    margin-bottom: 0;
    margin-left: 13.8%;
  }
}

@media print, screen and (min-width: 980px) {
  .p-cardPick__list.-store .p-cardPick__item:not(:first-of-type) {
    margin-left: 4.4%;
  }
}

.p-cardPick__list.-store .p-cardPick__item:nth-child(1) {
  position: absolute;
  left: 1%;
}

.p-cardPick__list.-store .p-cardPick__item:nth-child(2) {
  position: absolute;
  left: 45%;
}

.p-cardPick__list.-store .p-cardPick__item:nth-child(3) {
  position: absolute;
  left: 80%;
}

.p-cardPick__list.-cafe .p-cardPick__item:nth-child(3n + 1) {
  margin-bottom: 61px;
  margin-left: 0;
}

.p-cardPick__list.-cafe .p-cardPick__item:nth-child(1), .p-cardPick__list.-cafe .p-cardPick__item:nth-child(2), .p-cardPick__list.-cafe .p-cardPick__item:nth-child(3) {
  margin-top: 0;
  margin-bottom: 61px;
}

.p-cardPick.-column3 .p-cardPick__item {
  max-width: 1000px;
}

@media print, screen and (max-width: 979px) {
  .p-cardPick.-column3 .p-cardPick__item {
    margin-bottom: 60px;
    margin-left: 0;
  }
}

@media print, screen and (min-width: 600px) and (max-width: 979px) {
  .p-cardPick.-column3 .p-cardPick__item {
    width: 46%;
  }
  .p-cardPick.-column3 .p-cardPick__item:nth-child(2n + 1) {
    margin-left: 0;
  }
  .p-cardPick.-column3 .p-cardPick__item:nth-child(2n + 2) {
    margin-left: 6%;
  }
}

.flickity-prev-next-button {
  display: none;
}

@media print, screen and (min-width: 1280px) {
  .flickity-prev-next-button {
    top: 46% !important;
    display: block;
    width: 80px !important;
    height: 80px !important;
    color: #fff !important;
    background-color: rgba(84, 177, 180, 0.9) !important;
    border-radius: 50%;
    transform: translateY(-50%);
  }
  .flickity-prev-next-button.previous {
    left: 43px !important;
  }
  .flickity-prev-next-button.next {
    right: 43px !important;
  }
}

.p-cardPick__list.-gallery .flickity-prev-next-button {
  display: none;
}

@media print, screen and (min-width: 980px) {
  .p-cardPick__list.-gallery .flickity-prev-next-button {
    top: 50% !important;
    display: block;
    width: 80px;
    height: 80px;
    color: #fff !important;
    background-color: rgba(84, 177, 180, 0.9) !important;
    border-radius: 50%;
    transform: translateY(-50%);
  }
  .p-cardPick__list.-gallery .flickity-prev-next-button.previous {
    left: -22px !important;
  }
  .p-cardPick__list.-gallery .flickity-prev-next-button.next {
    right: -22px !important;
  }
}

.p-cardPick__list.-gallery .flickity-prev-next-button .flickity-page-dots {
  bottom: 0 !important;
}

.c-slider.-gallery .flickity-prev-next-button {
  display: none;
}

@media print, screen and (min-width: 1280px) {
  .c-slider.-gallery .flickity-prev-next-button {
    position: absolute;
    top: 46% !important;
    display: block;
    width: 80px;
    height: 80px;
    color: #fff !important;
    background-color: rgba(84, 177, 180, 0.9) !important;
    border-radius: 50%;
    fill: #fff;
    transform: translateY(-50%);
  }
  .c-slider.-gallery .flickity-prev-next-button.previous {
    left: -38px !important;
  }
  .c-slider.-gallery .flickity-prev-next-button.next {
    right: -38px !important;
  }
}

.c-slider.-gallery .flickity-prev-next-button .flickity-page-dots {
  bottom: 0 !important;
}

.c-slider.-gallery .flickity-prev-next-button .flickity-button {
  color: #fff !important;
}

.c-slider.-gallery .flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  top: 30%;
  left: 29%;
  width: 42%;
  height: 42%;
}

.c-slider.-gallery .flickity-prev-next-button .flickity-viewport {
  height: 252.75px !important;
}

@media print, screen and (min-width: 980px) {
  .c-slider.-gallery .flickity-prev-next-button .flickity-viewport {
    height: 450px;
  }
}

.c-slider.-gallery .flickity-prev-next-button .flickity-enabled {
  height: 288.75px !important;
}

@media print, screen and (min-width: 980px) {
  .c-slider.-gallery .flickity-prev-next-button .flickity-enabled {
    height: 450px;
  }
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  top: 30% !important;
  left: 29% !important;
  width: 42% !important;
  height: 42% !important;
}

@media print, screen and (min-width: 1280px) {
  .c-slider.-about .flickity-prev-next-button {
    position: absolute;
    top: 37% !important;
    display: block;
  }
}

.c-slider {
  height: 288.75px !important;
  margin-top: 50px;
}

@media print, screen and (min-width: 980px) {
  .c-slider {
    height: 490px !important;
  }
}

.c-slider.-about {
  height: 480px !important;
}

@media print, screen and (min-width: 600px) {
  .c-slider.-about {
    height: 480px !important;
  }
}

@media print, screen and (min-width: 980px) {
  .c-slider.-about {
    height: 633px !important;
  }
}

@media print, screen and (min-width: 600px) {
  .c-slider .slider-item:not(:first-child) {
    margin-left: 40px !important;
  }
}

@media print, screen and (min-width: 980px) {
  .c-slider .slider-item:not(:first-child) {
    margin-left: 78px !important;
  }
}

@media print, screen and (min-width: 600px) {
  .c-slider .slider-item:nth-child(3) {
    position: absolute;
  }
}

@media print, screen and (min-width: 980px) {
  .c-slider .slider-item:nth-child(3) {
    position: absolute;
  }
}

.flickity-button:disabled {
  display: none !important;
}

.p-confirm {
  margin-top: 44px;
  margin-bottom: 78px;
}

@media print, screen and (min-width: 600px) {
  .p-confirm {
    margin-top: 62px;
    margin-bottom: 46px;
  }
}

@media print, screen and (min-width: 600px) {
  .p-confirm__inner {
    display: flex;
    justify-content: space-between;
  }
}

@media print, screen and (min-width: 600px) {
  .p-confirm__inner:not(:last-child) {
    margin-bottom: 5px;
  }
}

.p-confirm__inner > .p-inquires__formOneLine {
  margin-bottom: 20px;
}

@media print, screen and (min-width: 600px) {
  .p-confirm__inner > .p-inquires__formOneLine {
    margin-bottom: 9px;
  }
}

.p-confirm__inner > .p-inquires__formOneLine .p-inquires__formOneLineInner {
  margin-bottom: 9px;
}

.p-confirm__answerArea {
  width: 100%;
}

@media print, screen and (min-width: 600px) {
  .p-confirm__answerArea {
    width: 47.8%;
  }
}

.p-confirm__answer {
  font-family: source-han-sans-japanese, sans-serif;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 11px;
  font-size: 14px;
  line-height: calc(22 / 14);
  letter-spacing: 0.12em;
}

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/
@media print, screen and (min-width: 980px) {
  .p-english {
    max-width: 880px;
    height: auto;
    margin: 0 auto;
  }
}

.p-english .c-h2 {
  margin-top: 70px;
}

.p-english .c-btnWrap + .c-h2 {
  margin-top: 100px;
}

@media print, screen and (min-width: 980px) {
  .p-english .c-btnWrap + .c-h2 {
    margin-top: 70px;
  }
}

@media print, screen and (min-width: 980px) {
  .p-english .c-btn.-ticket {
    padding-top: 50px;
  }
}

.p-english .c-btn.-dayTicket {
  margin-top: 32px;
}

@media print, screen and (min-width: 980px) {
  .p-english .c-btn.-dayTicket {
    padding-top: 0;
    margin-top: 52px;
  }
}

@media print, screen and (min-width: 980px) {
  .p-english__text {
    margin-bottom: 46px;
  }
}

.p-english__text p,
.p-english__text li {
  font-family: source-han-sans-japanese, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: calc(26 / 13);
  letter-spacing: 0.12em;
}

@media print, screen and (min-width: 980px) {
  .p-english__text p,
  .p-english__text li {
    font-size: 15px;
    font-size: 0.9375rem;
    margin-bottom: 20px;
    line-height: calc(26 / 15);
  }
}

@media print, screen and (min-width: 980px) {
  .p-english__text p,
  .p-english__text ul {
    margin-bottom: 70px;
  }
}

.p-english__text.-access {
  margin-top: 70px;
  margin-bottom: 80px;
}

@media print, screen and (min-width: 980px) {
  .p-english__text.-access {
    margin-bottom: 80px;
  }
}

.p-english__text.-access p {
  margin-bottom: 20px;
}

.p-english__text.-access p:last-of-type {
  margin-bottom: 58px;
}

.p-english__tableComment {
  margin-top: 16px;
}

@media print, screen and (min-width: 980px) {
  .p-english__tableComment {
    margin-top: 16px;
  }
}

.p-english__tableComment p {
  font-family: source-han-sans-japanese, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  font-size: 0.8125rem;
  margin-bottom: 20px;
  line-height: 2;
  letter-spacing: 0.12em;
}

@media print, screen and (min-width: 980px) {
  .p-english__tableComment p {
    font-size: 15px;
    font-size: 0.9375rem;
    margin-bottom: 20px;
    line-height: 1.73333;
  }
}

.c-btnWrap + .p-english__tableComment {
  margin-top: 80px;
}

.p-english__tableComment + .p-ticket__section {
  padding-top: 0 !important;
  margin-top: 0 !important;
}

.p-english .-noFlex {
  display: block;
}

.p-english .-pc {
  display: none;
}

@media print, screen and (min-width: 980px) {
  .p-english .-pc {
    display: block;
  }
}

.p-english .-tableUnder {
  display: block;
  padding: 0 20px;
  margin-top: 16px !important;
}

@media print, screen and (min-width: 980px) {
  .p-english .-tableUnder {
    width: 880px !important;
    padding: 0;
    margin: 0 auto;
  }
}

.p-english .p-ticket__lead {
  font-size: 13px !important;
}

@media print, screen and (min-width: 600px) {
  .p-english .p-ticket__lead {
    font-size: 15px !important;
  }
}

.p-english__textSmall {
  margin-bottom: 20px;
  line-height: calc(26 / 26);
  color: #808080;
  letter-spacing: 0.12em;
  font-size: 13px;
  font-size: 0.8125rem;
}

@media print, screen and (min-width: 600px) {
  .p-english__textSmall {
    margin-bottom: 26px;
    line-height: calc(26 / 13);
  }
}

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/
.p-faqNav {
  padding-right: 20px;
  padding-left: 20px;
  margin-bottom: 52px;
  overflow: auto;
  white-space: nowrap;
}

@media print, screen and (min-width: 600px) {
  .p-faqNav {
    width: 100%;
    max-width: 878px;
    height: auto;
    margin: 40px auto 34px;
  }
}

@media print, screen and (min-width: 980px) {
  .p-faqNav {
    max-width: 1440px;
  }
}

.p-faqNav__inner {
  width: 836px;
  padding-right: 20px;
}

@media print, screen and (min-width: 600px) {
  .p-faqNav__inner {
    display: block;
    width: 100%;
    max-width: 878px;
    height: auto;
    padding-right: 0;
    margin: 0 auto;
    overflow: auto;
    white-space: nowrap;
  }
}

@media print, screen and (min-width: 980px) {
  .p-faqNav__inner {
    max-width: 878px;
    padding: 0;
  }
}

.p-faqNav__btns {
  display: flex;
  justify-content: space-between;
}

@media print, screen and (min-width: 600px) {
  .p-faq {
    width: 100%;
    max-width: 1440px;
    height: auto;
    padding: 63px 0 93px;
    margin: 0 auto;
  }
}

.p-faq.-bgColor {
  width: 100vw !important;
  max-width: 100vw !important;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: calc(((100vw - 100%) / 2) * -1);
  margin-left: calc(((100vw - 100%) / 2) * -1);
  background-color: #f6f5f3;
}

@media print, screen and (min-width: 980px) {
  .p-faq.-bgColor {
    padding-right: 120px;
    padding-left: 120px;
  }
}

.p-faq__inner {
  padding: 72px 0;
}

@media print, screen and (min-width: 600px) {
  .p-faq__inner {
    display: block;
    width: 100%;
    max-width: 878px;
    height: auto;
    padding: 5px 20px 0;
    margin: 0 auto;
  }
}

@media print, screen and (min-width: 980px) {
  .p-faq__inner {
    padding: 5px 0 0;
  }
}

.p-faq__heading {
  font-family: source-han-sans-japanese, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: calc(26 / 36);
  color: #444;
  letter-spacing: 0.12em;
}

.p-faq__heading::after {
  display: block;
  width: 30px;
  height: 1px;
  margin-top: 14px;
  content: "";
  background-color: #444;
}

@media print, screen and (min-width: 600px) {
  .p-faq__heading::after {
    width: 40px;
    height: 2px;
    margin-top: 16px;
  }
}

.p-faq__listWrap {
  width: auto;
  height: auto;
}

.p-faq__list {
  margin-top: 26px;
}

@media print, screen and (min-width: 600px) {
  .p-faq__list {
    margin-top: 37px;
  }
}

.p-faq__listInner {
  display: flex;
}

.p-faq__listTitle {
  display: block;
  width: 100%;
  height: auto;
  margin-top: 4px;
  margin-bottom: 15px;
  font-size: 15px;
  line-height: calc(44 / 30);
  color: #444;
  letter-spacing: 0.12em;
  font-family: source-han-sans-japanese, sans-serif;
  font-style: normal;
  font-weight: 500;
}

@media print, screen and (min-width: 600px) {
  .p-faq__listTitle {
    margin-bottom: 11px;
    font-size: 16px;
    line-height: calc(26 / 16);
  }
}

.p-faq__listDescription {
  display: block;
  width: 100%;
  height: auto;
  margin-top: 1px;
  font-size: 14px;
  line-height: calc(46 / 28);
  color: #444;
  letter-spacing: 0.12em;
  font-family: source-han-sans-japanese, sans-serif;
  font-style: normal;
  font-weight: 400;
}

@media print, screen and (min-width: 600px) {
  .p-faq__listDescription {
    margin-top: 5px;
    line-height: calc(26 / 16);
  }
}

.p-faq__listHead {
  display: block;
  width: 30px;
  height: 30px;
  padding-top: 4px;
  padding-left: 1px;
  margin-right: 21px;
  font-size: 13px;
  line-height: calc(26 / 15);
  color: #fff;
  text-align: center;
  letter-spacing: 0.12em;
  border-radius: 50%;
  font-family: zeitung, sans-serif;
  font-style: normal;
  font-weight: 600;
}

@media print, screen and (min-width: 600px) {
  .p-faq__listHead {
    width: 34px;
    height: 32px;
    padding-top: 3px;
    margin-right: 18px;
    font-size: 15px;
  }
}

.p-faq .-green {
  background-color: #54b1b4;
}

.p-faq .-orange {
  background-color: #f74608;
}

.p-fixedButton {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 100;
  width: 180px;
  transition: opacity 0.5s ease-in-out;
}

.p-fixedButton.opacity {
  opacity: 0;
}

@media print, screen and (max-width: 979px) {
  .p-fixedButton {
    width: 150px;
  }
}

@media print, screen and (max-width: 599px) {
  .p-fixedButton {
    bottom: 20px;
    right: 20px;
    width: 130px;
  }
}

.p-fixedButton::before {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 5px;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background-color: #3a3a3a;
  transition: transform 0.3s ease-in-out;
}

@media print, screen and (max-width: 979px) {
  .p-fixedButton::before {
    width: 150px;
    height: 150px;
  }
}

@media print, screen and (max-width: 599px) {
  .p-fixedButton::before {
    top: 3px;
    left: 3px;
    width: 130px;
    height: 130px;
  }
}

@media print, screen and (min-width: 980px) {
  .p-fixedButton:hover .p-fixedButton-link img {
    transform: translate(-4px, -4px);
  }
  .p-fixedButton:hover::before {
    transform: translate(4px, 4px);
  }
}

.p-fixedButton .p-fixedButton-link {
  position: relative;
}

.p-fixedButton .p-fixedButton-link img {
  transition: transform 0.3s ease-in-out;
}

.p-fixedButton .p-fixedButton-text {
  position: absolute;
  top: -18px;
  right: -16px;
  color: #e60020;
  font-family: zeitung, sans-serif;
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: bold;
  transform: rotate(35deg);
}

@media print, screen and (max-width: 599px) {
  .p-fixedButton .p-fixedButton-text {
    font-size: 16px;
    font-size: 1rem;
    top: -9px;
    right: -8px;
  }
}

@media print, screen and (min-width: 980px) {
  .p-gallery {
    margin-right: -80px;
    margin-left: -80px;
  }
}

@media print, screen and (max-width: 979px) {
  .p-gallery {
    margin-right: -20px;
    margin-left: -20px;
  }
}

@media print, screen and (min-width: 980px) {
  .p-gallery__main {
    padding-right: 80px;
    padding-left: 80px;
    margin-top: -37px;
    margin-bottom: 175px;
  }
}

@media print, screen and (max-width: 979px) {
  .p-gallery__main {
    padding-right: 20px;
    padding-left: 20px;
    margin-top: -77px;
  }
}

.p-galleryComic {
  /* autoprefixer grid: no-autoplace */
  display: grid;
  grid-template-areas: "figure" "title" "body";
  grid-template-rows: repeat(3, auto);
  grid-template-columns: 1fr;
  max-width: 880px;
  margin-right: auto;
  margin-left: auto;
}

@media print, screen and (min-width: 980px) {
  .p-galleryComic.-left {
    display: grid;
    grid-template-areas: "figure title" "figure body";
    grid-template-rows: repeat(2, auto);
    grid-template-columns: 50% 1fr;
    column-gap: 80px;
  }
}

@media print, screen and (min-width: 980px) {
  .p-galleryComic.-right {
    display: grid;
    grid-template-areas: "title figure" "body figure";
    grid-template-rows: repeat(2, auto);
    grid-template-columns: 1fr 50%;
    column-gap: 80px;
  }
}

@media print, screen and (min-width: 980px) {
  .p-galleryComic + .p-galleryComic {
    margin-top: 108px;
  }
}

@media print, screen and (max-width: 979px) {
  .p-galleryComic + .p-galleryComic {
    margin-top: 80px;
  }
}

.p-galleryComic.-left + .p-galleryComic.-right,
.p-galleryComic.-right + .p-galleryComic.-left {
  margin-top: 54px;
}

.p-galleryComic__title {
  grid-area: title;
  line-height: 1.45455;
}

@media print, screen and (min-width: 980px) {
  .p-galleryComic__title {
    font-size: 22px;
    font-size: 1.375rem;
    margin-top: 42px;
    letter-spacing: 0.06em;
  }
}

@media print, screen and (max-width: 979px) {
  .p-galleryComic__title {
    font-size: 20px;
    font-size: 1.25rem;
    margin-top: 37px;
  }
}

@media print, screen and (min-width: 980px) {
  .-left .p-galleryComic__title,
  .-right .p-galleryComic__title {
    display: flex;
    align-items: flex-end;
  }
}

@media print, screen and (max-width: 979px) {
  .-left .p-galleryComic__title,
  .-right .p-galleryComic__title {
    margin-top: 28px;
  }
}

.p-galleryComic__body {
  grid-area: body;
  margin-top: 28px;
}

.p-galleryComic__text {
  letter-spacing: 0.06em;
}

@media print, screen and (min-width: 980px) {
  .p-galleryComic__text {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 2.13333;
  }
}

@media print, screen and (max-width: 979px) {
  .p-galleryComic__text {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.9;
  }
}

.p-galleryComic__text.-center {
  margin-top: 0;
}

@media print, screen and (min-width: 980px) {
  .p-galleryComic__text.-center {
    margin-top: 93px;
  }
}

@media print, screen and (min-width: 1280px) {
  .p-galleryComic__text.-center {
    margin-top: 146px;
    margin-bottom: 172px;
  }
}

.p-galleryComic__figure {
  grid-area: figure;
}

@media print, screen and (max-width: 979px) {
  .p-galleryComic__figure {
    text-align: center;
  }
}

@media print, screen and (min-width: 980px) {
  .p-galleryComic.-specialBottom {
    margin-bottom: -354px !important;
  }
}

@media print, screen and (max-width: 979px) {
  .p-galleryComic.-specialBottom {
    margin-bottom: -170px;
  }
}

@media print, screen and (min-width: 600px) {
  .p-galleryComic.-specialBottom {
    margin-bottom: -10px !important;
  }
}

@media print, screen and (min-width: 600px) {
  .-specialBottom {
    margin-bottom: 0 !important;
  }
}

@media print, screen and (min-width: 980px) {
  .p-galleryContent {
    padding-top: 25px;
  }
}

@media print, screen and (max-width: 979px) {
  .p-galleryContent {
    padding-top: 48px;
  }
}

.p-galleryContent__title {
  position: relative;
  line-height: 1.18182;
  text-align: center;
}

@media print, screen and (min-width: 980px) {
  .p-galleryContent__title {
    font-size: 22px;
    font-size: 1.375rem;
    padding-bottom: 20px;
  }
}

@media print, screen and (max-width: 979px) {
  .p-galleryContent__title {
    font-size: 20px;
    font-size: 1.25rem;
    padding-bottom: 12px;
  }
}

.p-galleryContent__title::after {
  position: absolute;
  bottom: 0;
  left: 50%;
  display: block;
  width: 60px;
  content: "";
  border-bottom: 3px solid;
  transform: translateX(-50%);
}

@media print, screen and (max-width: 979px) {
  .p-galleryContent__title::after {
    transform: translateX(-50%) scale(0.5);
  }
}

.p-galleryContent__banner {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  margin-top: 38px;
  overflow: hidden;
  color: #fff;
  text-align: center;
}

@media print, screen and (min-width: 980px) {
  .p-galleryContent__banner {
    border-radius: 20px;
  }
}

@media print, screen and (max-width: 979px) {
  .p-galleryContent__banner {
    flex-direction: column-reverse;
    border-radius: 10px;
  }
}

.p-galleryContent__banner.-green {
  background-color: #519c60;
}

.p-galleryContent__banner.-blue {
  background-color: #54b1b4;
  margin-bottom: 75px;
}

.p-galleryContent__bannerText {
  flex: 1;
}

@media print, screen and (max-width: 979px) {
  .p-galleryContent__bannerText {
    padding-top: 48px;
    padding-bottom: 43px;
  }
}

.p-galleryContent__head {
  letter-spacing: 0.06em;
}

@media print, screen and (min-width: 600px) {
  .p-galleryContent__head {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

@media print, screen and (max-width: 979px) {
  .p-galleryContent__head {
    font-size: 20px;
    font-size: 1.25rem;
    margin-top: -8px;
  }
}

@media print, screen and (min-width: 1280px) {
  .p-galleryContent__head {
    font-size: 21px;
    font-size: 1.3125rem;
    margin-top: -50px;
  }
}

.p-galleryContent__head::after {
  content: "";
  display: block;
  border-bottom: 2px solid #fff;
  width: 30px;
  height: auto;
  margin: -16px auto 44px;
  margin: 4px auto 26px;
}

@media print, screen and (min-width: 600px) {
  .p-galleryContent__head::after {
    margin: 15px auto 30px;
    width: 60px;
  }
}

@media print, screen and (min-width: 980px) {
  .p-galleryContent__head::after {
    margin: 6px auto 11px;
  }
}

@media print, screen and (min-width: 1280px) {
  .p-galleryContent__head::after {
    margin: 15px auto 44px;
  }
}

@media print, screen and (min-width: 1280px) {
  .p-galleryContent__head.-permanent {
    margin-top: -10px;
  }
}

.p-galleryContent__name {
  font-weight: bold;
  letter-spacing: 0.06em;
}

@media print, screen and (min-width: 980px) {
  .p-galleryContent__name {
    font-size: 28px;
    font-size: 1.75rem;
    margin-top: 6px;
    padding: 0 20px;
  }
}

@media print, screen and (max-width: 979px) {
  .p-galleryContent__name {
    font-size: 24px;
    font-size: 1.5rem;
    margin-top: 13px;
    line-height: 1.45833;
  }
}

.p-galleryContent__text {
  text-align: left;
  margin: 0 auto;
  padding: 0 33px;
}

@media print, screen and (min-width: 600px) {
  .p-galleryContent__text {
    padding: 0 20px;
  }
}

@media print, screen and (min-width: 980px) {
  .p-galleryContent__text {
    max-width: 417px;
  }
}

@media print, screen and (max-width: 979px) {
  .p-galleryContent__text.-permanentText {
    padding: 0 33px;
  }
}

@media print, screen and (min-width: 1280px) {
  .p-galleryContent__text.-permanentText {
    padding: 0 20px;
  }
}

.p-galleryContent__description {
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: calc(26 / 13);
  letter-spacing: 0.06em;
}

@media print, screen and (min-width: 600px) {
  .p-galleryContent__description {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: calc(23 / 13);
  }
}

@media print, screen and (min-width: 1280px) {
  .p-galleryContent__description {
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: calc(26 / 13);
  }
}

@media print, screen and (min-width: 600px) {
  .p-galleryContent__description:not(:first-of-type) {
    margin-top: 7px;
  }
}

@media print, screen and (min-width: 980px) {
  .p-galleryContent__description:not(:first-of-type) {
    margin-top: 10px;
  }
}

@media print, screen and (min-width: 1280px) {
  .p-galleryContent__description:not(:first-of-type) {
    margin-top: 20px;
  }
}

@media print, screen and (max-width: 979px) {
  .p-galleryContent__description.-permanentDescription {
    line-height: calc(46 / 26);
  }
}

@media print, screen and (max-width: 979px) {
  .p-galleryContent__description.-permanentDescription:not(:first-of-type) {
    margin-top: 18px;
  }
}

.p-galleryContent__break {
  display: inline-block;
}

.p-galleryContent__period {
  letter-spacing: 0.06em;
}

@media print, screen and (min-width: 600px) {
  .p-galleryContent__period {
    font-size: 16px;
    font-size: 1rem;
    margin-top: 0;
  }
}

@media print, screen and (min-width: 980px) {
  .p-galleryContent__period {
    font-size: 16px;
    font-size: 1rem;
  }
}

@media print, screen and (max-width: 979px) {
  .p-galleryContent__period {
    font-size: 13px;
    font-size: 0.8125rem;
    margin-top: 23px;
    letter-spacing: 0.12em;
  }
}

@media print, screen and (min-width: 1280px) {
  .p-galleryContent__period {
    font-size: 18px;
    font-size: 1.125rem;
    margin-top: 33px;
  }
}

.p-galleryContent__image {
  flex: 1;
}

@media print, screen and (max-width: 979px) {
  .p-galleryContent__image {
    width: 100%;
  }
}

.p-galleryContent__image > img {
  width: 100%;
}

.p-galleryFeature {
  /* autoprefixer grid: no-autoplace */
  display: grid;
  grid-template-areas: "figure" "title" "body";
  grid-template-rows: repeat(3, auto);
  grid-template-columns: 1fr;
  padding-bottom: 77px;
  margin-top: 100px;
}

@media print, screen and (min-width: 1280px) {
  .p-galleryFeature {
    grid-template-areas: "figure title" "figure body";
    grid-template-rows: repeat(2, auto);
    grid-template-columns: 50% 1fr;
    column-gap: 80px;
    padding-right: 80px;
    padding-bottom: 115px;
    padding-left: 80px;
    margin-top: 110px;
  }
}

.p-galleryFeature__title {
  font-size: 20px;
  font-size: 1.25rem;
  grid-area: title;
  margin-top: 60px;
  line-height: 1.45455;
  letter-spacing: 0.06em;
}

@media print, screen and (min-width: 1280px) {
  .p-galleryFeature__title {
    font-size: 22px;
    font-size: 1.375rem;
  }
}

.p-galleryFeature__body {
  grid-area: body;
  margin-top: 27px;
}

@media print, screen and (min-width: 1280px) {
  .p-galleryFeature__body {
    margin-top: 0;
  }
}

.p-galleryFeature__body > p {
  letter-spacing: 0.06em;
}

@media print, screen and (min-width: 980px) {
  .p-galleryFeature__body > p {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 2.13333;
  }
}

@media print, screen and (max-width: 979px) {
  .p-galleryFeature__body > p {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.9;
  }
}

@media print, screen and (min-width: 980px) {
  .p-galleryFeature__body > p:nth-child(n + 2) {
    margin-top: 2.13333em;
  }
}

@media print, screen and (max-width: 979px) {
  .p-galleryFeature__body > p:nth-child(n + 2) {
    margin-top: 1.85714em;
  }
}

@media print, screen and (min-width: 1280px) {
  .p-galleryFeature__body .-posterSide {
    margin-top: 244px;
    margin-bottom: 268px;
  }
}

.p-galleryFeature__figure {
  grid-area: figure;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

@media print, screen and (min-width: 980px) {
  .p-galleryFeature__figure {
    max-width: none;
  }
}

.p-galleryLook {
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
}

@media print, screen and (min-width: 980px) {
  .p-galleryLook {
    padding-right: 80px;
    padding-bottom: 170px;
    padding-left: 80px;
    margin-top: 90px;
  }
}

@media print, screen and (max-width: 979px) {
  .p-galleryLook {
    padding-right: 20px;
    padding-bottom: 100px;
    padding-left: 20px;
    margin-top: 77px;
  }
}

.p-galleryLook__title {
  font-family: zeitung, sans-serif;
  font-style: normal;
  font-weight: 700;
  position: relative;
  text-align: center;
}

@media print, screen and (min-width: 980px) {
  .p-galleryLook__title {
    font-size: 22px;
    font-size: 1.375rem;
    letter-spacing: 0.06em;
  }
}

@media print, screen and (max-width: 979px) {
  .p-galleryLook__title {
    font-size: 20px;
    font-size: 1.25rem;
    letter-spacing: 0.02em;
  }
}

.p-galleryLook__title::before {
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  height: 3px;
  content: "";
  transform: translateY(-50%);
}

@media print, screen and (min-width: 980px) {
  .p-galleryLook__title::before {
    width: calc((100% - 240px) / 2);
    background-color: currentColor;
  }
}

@media print, screen and (max-width: 979px) {
  .p-galleryLook__title::before {
    width: calc((100% - 185px) / 2);
    background-image: linear-gradient(to bottom, transparent 0 25%, currentColor 25% 75%, transparent 75% 100%);
  }
}

.p-galleryLook__title::after {
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
  height: 3px;
  content: "";
  transform: translateY(-50%);
}

@media print, screen and (min-width: 980px) {
  .p-galleryLook__title::after {
    width: calc((100% - 240px) / 2);
    background-color: currentColor;
  }
}

@media print, screen and (max-width: 979px) {
  .p-galleryLook__title::after {
    width: calc((100% - 185px) / 2);
    background-image: linear-gradient(to bottom, transparent 0 25%, currentColor 25% 75%, transparent 75% 100%);
  }
}

.p-galleryLook__text {
  margin-top: 40px;
  text-align: center;
}

@media print, screen and (min-width: 980px) {
  .p-galleryLook__text {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 2.13333;
  }
}

@media print, screen and (max-width: 979px) {
  .p-galleryLook__text {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.85714;
  }
}

.p-galleryLook__link {
  position: relative;
  text-transform: uppercase;
}

.p-galleryLook__link::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  content: "";
  border-bottom: 1px solid;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform-origin: 0 0;
}

.p-galleryLook__link:hover::after {
  transform: scaleX(0);
  transform-origin: 100% 0;
}

.p-galleryPhoto {
  max-width: 1040px;
  padding-bottom: 80px;
  margin-right: auto;
  margin-left: auto;
}

@media print, screen and (min-width: 980px) {
  .p-galleryPhoto {
    padding-top: 72px;
    margin-bottom: 130px;
  }
}

@media print, screen and (max-width: 979px) {
  .p-galleryPhoto {
    padding-top: 66px;
    padding-right: 28px;
    padding-left: 28px;
    margin-bottom: 70px;
  }
}

.p-galleryPhoto__title {
  margin-bottom: 27px;
  text-align: center;
  letter-spacing: 0.06em;
}

@media print, screen and (min-width: 980px) {
  .p-galleryPhoto__title {
    font-size: 22px;
    font-size: 1.375rem;
  }
}

@media print, screen and (max-width: 979px) {
  .p-galleryPhoto__title {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

.p-galleryPhoto__carousel {
  display: flex;
}

@media print, screen and (min-width: 980px) {
  .p-galleryPhoto__carousel {
    margin-top: 53px;
  }
}

@media print, screen and (max-width: 979px) {
  .p-galleryPhoto__carousel {
    flex-direction: column;
    margin-top: 28px;
  }
}

.p-galleryPhoto__text {
  margin-top: 44px;
  letter-spacing: 0.06em;
}

@media print, screen and (min-width: 980px) {
  .p-galleryPhoto__text {
    font-size: 15px;
    font-size: 0.9375rem;
    margin-top: 52px;
    line-height: 2.13333;
  }
}

@media print, screen and (max-width: 979px) {
  .p-galleryPhoto__text {
    font-size: 14px;
    font-size: 0.875rem;
    margin-top: 44px;
    margin-right: -28px;
    margin-left: -28px;
    line-height: 1.9;
  }
}

/*
@include touch {} // max-width: 979px
@include sp {}    // max-width: 599px
@include tb {}    // min-width: 600px
@include pc {}    // min-width: 980px
@include wide {}  // min-width: 1280px
*/
/* -------------------------------------------------- HOME */
body.isLoading,
body.isOpening {
  width: 100%;
}

#mainVisual ~ .l-contents {
  padding-top: 0;
}

/* -------------------------------------------------- visual */
.l-visual {
  position: relative;
  max-height: 100vh;
  overflow: hidden;
  background: #f6f5f3;
}

.mainVisual-symbol,
.mainVisual-loader {
  z-index: 1;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-bottom: 80px;
}

@media print, screen and (max-width: 979px) {
  .mainVisual-symbol,
  .mainVisual-loader {
    padding-top: 55px;
  }
}

@media print, screen and (min-width: 980px) {
  .l-visual {
    min-height: 680px;
  }
  .mainVisual-symbol,
  .mainVisual-loader {
    padding-left: 80px;
  }
}

@media screen and (min-device-width: 980px) {
  .l-visual {
    height: 100vh !important;
  }
}

/* :::::: symbol :::::: */
.mainVisual-symbol {
  position: relative;
}

.mainVisual-symbol .logo {
  width: 158.4px;
  height: 273.6px;
}

.mainVisual-symbol:not(.active) {
  pointer-events: none;
  opacity: 0;
  transform: scale(1.08);
}

body.isOpening .mainVisual-symbol {
  transition: opacity 0.6s, transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}

body.isOpening .mainVisual-symbol.active {
  transition-delay: 0.3s;
}

@media screen and (min-width: 600px) {
  .mainVisual-symbol .logo {
    width: 193.6px;
    height: 334.4px;
  }
}

@media screen and (min-width: 1280px) {
  .mainVisual-symbol .logo {
    width: 220px;
    height: 380px;
  }
}

@media screen and (min-width: 1440px) and (min-height: 1024px) {
  .mainVisual-symbol .logo {
    width: 246.4px;
    height: 425.6px;
  }
}

/* :::::: loader :::::: */
.mainVisual-loader {
  position: absolute;
  top: 0;
  left: 0;
}

.mainVisual-loader svg {
  width: 48px;
  height: 48px;
  fill: none;
  stroke: #f7d65b;
  stroke-dasharray: 268;
  stroke-dashoffset: 268;
  stroke-width: 8;
  animation: loading 1.2s infinite linear;
}

.mainVisual-loader circle {
  animation: loader 1.6s infinite cubic-bezier(0.3, 0, 0.3, 1);
}

@keyframes loading {
  from {
    transform: none;
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    stroke-dashoffset: 268;
  }
  to {
    stroke-dashoffset: -268;
  }
}

body:not(.isLoading) .mainVisual-loader {
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.6s;
}

/* :::::: scroll :::::: */
.mainVisual-scroll {
  position: absolute;
  bottom: 64px;
  left: calc(50% - 4em + 40px);
  z-index: 1;
  width: 8em;
  font-size: 15px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.06em;
}

.mainVisual-scroll svg {
  display: block;
  width: 30px;
  height: 16px;
  margin: 0 auto 1em;
  fill: none;
  stroke: #3a3a3a;
  stroke-width: 2;
}

.mainVisual-scroll:not(.active) {
  pointer-events: none;
  opacity: 0;
  transform: translateY(-2em);
}

.mainVisual-scroll:not(.active) svg {
  transform: translateY(-2em);
}

body.isOpening .mainVisual-scroll {
  transition: opacity 1s, transform 1s cubic-bezier(0.19, 1, 0.22, 1);
}

body.isOpening .mainVisual-scroll svg {
  transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1);
}

@media print, screen and (max-width: 979px) {
  .mainVisual-scroll {
    bottom: 48px;
    left: calc(50% - 4em);
    font-size: 12px;
  }
  .mainVisual-scroll svg {
    stroke-width: 1;
  }
}

/* -------------------------------------------------- opening */
.mainVisual-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

@media print, screen and (max-width: 979px) {
  .mainVisual-canvas {
    top: 55px;
    height: calc(100% - 55px);
  }
}

@media print, screen and (min-width: 980px) {
  .mainVisual-canvas {
    left: 80px;
    width: calc(100% - 80px);
  }
}

/* :::::: zoom/pan :::::: */
.mv-container,
.mv-orbit {
  position: absolute;
}

.mv-orbit {
  top: 0;
  left: 0;
}

.mv-container {
  top: calc(50% - 207.69231px);
  left: calc(50% - 230.76923px);
}

.mv-container,
.mv-orbit {
  width: 461.53846px;
}

.mv-orbit {
  height: 115.38462px;
}

.mv-illustration,
.mv-woodstock {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.mv-illustration {
  width: 461.53846px;
}

.mv-woodstock {
  z-index: 2;
  height: 120px;
}

@media screen and (min-width: 600px) {
  .mv-container {
    top: calc(50% - 266.66667px);
    left: calc(50% - 333.33333px);
  }
  .mv-container,
  .mv-orbit {
    width: 666.66667px;
  }
  .mv-orbit {
    height: 166.66667px;
  }
  .mv-illustration {
    width: 666.66667px;
  }
  .mv-woodstock {
    height: 176.47059px;
  }
}

@media screen and (min-width: 1280px) {
  .mv-container {
    top: calc(50% - 385.71429px);
    left: calc(50% - 428.57143px);
  }
  .mv-container,
  .mv-orbit {
    width: 857.14286px;
  }
  .mv-orbit {
    height: 214.28571px;
  }
  .mv-illustration {
    width: 857.14286px;
  }
  .mv-woodstock {
    height: 230.76923px;
  }
}

@media screen and (min-width: 1440px) and (min-height: 1024px) {
  .mv-container {
    top: calc(50% - 540px);
    left: calc(50% - 600px);
  }
  .mv-container,
  .mv-orbit {
    width: 1200px;
  }
  .mv-orbit {
    height: 300px;
  }
  .mv-illustration {
    left: 60px;
    width: 1080px;
  }
  .mv-woodstock {
    height: 300px;
  }
}

.mv-woodstock,
.mv-orbit.-line {
  top: 0;
}

.mv-woodstock .ws {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  margin: -25px 0 0 -25px;
  background: url(../img/home/ws_sprites.png) 0 0 no-repeat;
  background-size: 450px 2100px;
  will-change: transform;
}

.mv-woodstock .ws:not([style]) {
  opacity: 0;
}

@media screen and (min-width: 600px) {
  .mv-woodstock,
  .mv-orbit.-line {
    top: 20px;
  }
  .mv-woodstock .ws {
    width: 71.42857px;
    height: 71.42857px;
    margin: -35.71429px 0 0 -35.71429px;
    background-size: 642.85714px 3000px;
  }
}

@media screen and (min-width: 1280px) {
  .mv-woodstock,
  .mv-orbit.-line {
    top: 80px;
  }
  .mv-woodstock .ws {
    width: 83.33333px;
    height: 83.33333px;
    margin: -41.66667px 0 0 -41.66667px;
    background-size: 750px 3500px;
  }
}

@media screen and (min-width: 1440px) and (min-height: 1024px) {
  .mv-woodstock,
  .mv-orbit.-line {
    top: 140px;
  }
  .mv-woodstock .ws {
    width: 100px;
    height: 100px;
    margin: -50px 0 0 -50px;
    background-size: 900px 4200px;
  }
}

.mv-kite {
  position: absolute;
  top: calc(5.6% / 0.24);
  left: calc(5.64% / 0.24);
  width: calc(1.58% / 0.24);
  height: calc(7.03% / 0.24);
  transform: rotate(1deg);
  transform-origin: 46% 0;
}

.mv-kite.swing {
  animation: swing 3.6s cubic-bezier(0.645, 0.045, 0.355, 1);
}

@keyframes swing {
  0% {
    transform: rotateZ(1deg);
  }
  34% {
    transform: rotateY(12deg) rotateZ(-2deg);
  }
  70% {
    transform: rotateY(-4deg) rotateZ(1deg);
  }
  90% {
    transform: rotateY(1deg) rotateZ(-0.2deg);
  }
  100% {
    transform: none;
  }
}

.mv-woodstock[data-n="1"] .ws[data-f="1"] {
  background-position: 0 0%;
}

.mv-woodstock[data-n="1"] .ws[data-f="2"] {
  background-position: 0 2.5%;
}

.mv-woodstock[data-n="1"] .ws[data-f="3"] {
  background-position: 0 5%;
}

.mv-woodstock[data-n="1"] .ws[data-f="4"] {
  background-position: 0 7.5%;
}

.mv-woodstock[data-n="1"] .ws[data-f="5"] {
  background-position: 0 10%;
}

.mv-woodstock[data-n="1"] .ws[data-f="6"] {
  background-position: 0 12.5%;
}

.mv-woodstock[data-n="1"] .ws[data-f="7"] {
  background-position: 0 15%;
}

.mv-woodstock[data-n="1"] .ws[data-f="8"] {
  background-position: 0 17.5%;
}

.mv-woodstock[data-n="1"] .ws[data-f="9"] {
  background-position: 0 20%;
}

.mv-woodstock[data-n="1"] .ws[data-f="10"] {
  background-position: 0 22.5%;
}

.mv-woodstock[data-n="1"] .ws[data-f="11"] {
  background-position: 0 25%;
}

.mv-woodstock[data-n="1"] .ws[data-f="12"] {
  background-position: 0 27.5%;
}

.mv-woodstock[data-n="1"] .ws[data-f="13"] {
  background-position: 0 30%;
}

.mv-woodstock[data-n="1"] .ws[data-f="14"] {
  background-position: 0 32.5%;
}

.mv-woodstock[data-n="1"] .ws[data-f="15"] {
  background-position: 0 35%;
}

.mv-woodstock[data-n="1"] .ws[data-f="16"] {
  background-position: 0 37.5%;
}

.mv-woodstock[data-n="1"] .ws[data-f="17"] {
  background-position: 0 40%;
}

.mv-woodstock[data-n="1"] .ws[data-f="18"] {
  background-position: 0 42.5%;
}

.mv-woodstock[data-n="1"] .ws[data-f="19"] {
  background-position: 0 45%;
}

.mv-woodstock[data-n="1"] .ws[data-f="20"] {
  background-position: 0 47.5%;
}

.mv-woodstock[data-n="1"] .ws[data-f="21"] {
  background-position: 0 50%;
}

.mv-woodstock[data-n="1"] .ws[data-f="22"] {
  background-position: 0 52.5%;
}

.mv-woodstock[data-n="1"] .ws[data-f="23"] {
  background-position: 0 55%;
}

.mv-woodstock[data-n="1"] .ws[data-f="24"] {
  background-position: 0 57.5%;
}

.mv-woodstock[data-n="1"] .ws[data-f="25"] {
  background-position: 0 60%;
}

.mv-woodstock[data-n="1"] .ws[data-f="26"] {
  background-position: 0 62.5%;
}

.mv-woodstock[data-n="1"] .ws[data-f="27"] {
  background-position: 0 65%;
}

.mv-woodstock[data-n="1"] .ws[data-f="28"] {
  background-position: 0 67.5%;
}

.mv-woodstock[data-n="1"] .ws[data-f="29"] {
  background-position: 0 70%;
}

.mv-woodstock[data-n="1"] .ws[data-f="30"] {
  background-position: 0 72.5%;
}

.mv-woodstock[data-n="1"] .ws[data-f="31"] {
  background-position: 0 75%;
}

.mv-woodstock[data-n="1"] .ws[data-f="32"] {
  background-position: 0 77.5%;
}

.mv-woodstock[data-n="1"] .ws[data-f="33"] {
  background-position: 0 80%;
}

.mv-woodstock[data-n="1"] .ws[data-f="34"] {
  background-position: 0 82.5%;
}

.mv-woodstock[data-n="1"] .ws[data-f="35"] {
  background-position: 0 85%;
}

.mv-woodstock[data-n="1"] .ws[data-f="36"] {
  background-position: 0 87.5%;
}

.mv-woodstock[data-n="1"] .ws[data-f="37"] {
  background-position: 0 90%;
}

.mv-woodstock[data-n="2"] .ws[data-f="1"] {
  background-position: 12.5% 0%;
}

.mv-woodstock[data-n="2"] .ws[data-f="2"] {
  background-position: 12.5% 2.5%;
}

.mv-woodstock[data-n="2"] .ws[data-f="3"] {
  background-position: 12.5% 5%;
}

.mv-woodstock[data-n="2"] .ws[data-f="4"] {
  background-position: 12.5% 7.5%;
}

.mv-woodstock[data-n="2"] .ws[data-f="5"] {
  background-position: 12.5% 10%;
}

.mv-woodstock[data-n="2"] .ws[data-f="6"] {
  background-position: 12.5% 12.5%;
}

.mv-woodstock[data-n="2"] .ws[data-f="7"] {
  background-position: 12.5% 15%;
}

.mv-woodstock[data-n="2"] .ws[data-f="8"] {
  background-position: 12.5% 17.5%;
}

.mv-woodstock[data-n="2"] .ws[data-f="9"] {
  background-position: 12.5% 20%;
}

.mv-woodstock[data-n="2"] .ws[data-f="10"] {
  background-position: 12.5% 22.5%;
}

.mv-woodstock[data-n="2"] .ws[data-f="11"] {
  background-position: 12.5% 25%;
}

.mv-woodstock[data-n="2"] .ws[data-f="12"] {
  background-position: 12.5% 27.5%;
}

.mv-woodstock[data-n="2"] .ws[data-f="13"] {
  background-position: 12.5% 30%;
}

.mv-woodstock[data-n="2"] .ws[data-f="14"] {
  background-position: 12.5% 32.5%;
}

.mv-woodstock[data-n="2"] .ws[data-f="15"] {
  background-position: 12.5% 35%;
}

.mv-woodstock[data-n="2"] .ws[data-f="16"] {
  background-position: 12.5% 37.5%;
}

.mv-woodstock[data-n="2"] .ws[data-f="17"] {
  background-position: 12.5% 40%;
}

.mv-woodstock[data-n="2"] .ws[data-f="18"] {
  background-position: 12.5% 42.5%;
}

.mv-woodstock[data-n="2"] .ws[data-f="19"] {
  background-position: 12.5% 45%;
}

.mv-woodstock[data-n="2"] .ws[data-f="20"] {
  background-position: 12.5% 47.5%;
}

.mv-woodstock[data-n="2"] .ws[data-f="21"] {
  background-position: 12.5% 50%;
}

.mv-woodstock[data-n="2"] .ws[data-f="22"] {
  background-position: 12.5% 52.5%;
}

.mv-woodstock[data-n="2"] .ws[data-f="23"] {
  background-position: 12.5% 55%;
}

.mv-woodstock[data-n="2"] .ws[data-f="24"] {
  background-position: 12.5% 57.5%;
}

.mv-woodstock[data-n="2"] .ws[data-f="25"] {
  background-position: 12.5% 60%;
}

.mv-woodstock[data-n="2"] .ws[data-f="26"] {
  background-position: 12.5% 62.5%;
}

.mv-woodstock[data-n="2"] .ws[data-f="27"] {
  background-position: 12.5% 65%;
}

.mv-woodstock[data-n="2"] .ws[data-f="28"] {
  background-position: 12.5% 67.5%;
}

.mv-woodstock[data-n="2"] .ws[data-f="29"] {
  background-position: 12.5% 70%;
}

.mv-woodstock[data-n="3"] .ws[data-f="1"] {
  background-position: 25% 0%;
}

.mv-woodstock[data-n="3"] .ws[data-f="2"] {
  background-position: 25% 2.5%;
}

.mv-woodstock[data-n="3"] .ws[data-f="3"] {
  background-position: 25% 5%;
}

.mv-woodstock[data-n="3"] .ws[data-f="4"] {
  background-position: 25% 7.5%;
}

.mv-woodstock[data-n="3"] .ws[data-f="5"] {
  background-position: 25% 10%;
}

.mv-woodstock[data-n="3"] .ws[data-f="6"] {
  background-position: 25% 12.5%;
}

.mv-woodstock[data-n="3"] .ws[data-f="7"] {
  background-position: 25% 15%;
}

.mv-woodstock[data-n="3"] .ws[data-f="8"] {
  background-position: 25% 17.5%;
}

.mv-woodstock[data-n="3"] .ws[data-f="9"] {
  background-position: 25% 20%;
}

.mv-woodstock[data-n="3"] .ws[data-f="10"] {
  background-position: 25% 22.5%;
}

.mv-woodstock[data-n="3"] .ws[data-f="11"] {
  background-position: 25% 25%;
}

.mv-woodstock[data-n="3"] .ws[data-f="12"] {
  background-position: 25% 27.5%;
}

.mv-woodstock[data-n="3"] .ws[data-f="13"] {
  background-position: 25% 30%;
}

.mv-woodstock[data-n="3"] .ws[data-f="14"] {
  background-position: 25% 32.5%;
}

.mv-woodstock[data-n="3"] .ws[data-f="15"] {
  background-position: 25% 35%;
}

.mv-woodstock[data-n="3"] .ws[data-f="16"] {
  background-position: 25% 37.5%;
}

.mv-woodstock[data-n="3"] .ws[data-f="17"] {
  background-position: 25% 40%;
}

.mv-woodstock[data-n="3"] .ws[data-f="18"] {
  background-position: 25% 42.5%;
}

.mv-woodstock[data-n="3"] .ws[data-f="19"] {
  background-position: 25% 45%;
}

.mv-woodstock[data-n="3"] .ws[data-f="20"] {
  background-position: 25% 47.5%;
}

.mv-woodstock[data-n="3"] .ws[data-f="21"] {
  background-position: 25% 50%;
}

.mv-woodstock[data-n="3"] .ws[data-f="22"] {
  background-position: 25% 52.5%;
}

.mv-woodstock[data-n="3"] .ws[data-f="23"] {
  background-position: 25% 55%;
}

.mv-woodstock[data-n="3"] .ws[data-f="24"] {
  background-position: 25% 57.5%;
}

.mv-woodstock[data-n="3"] .ws[data-f="25"] {
  background-position: 25% 60%;
}

.mv-woodstock[data-n="3"] .ws[data-f="26"] {
  background-position: 25% 62.5%;
}

.mv-woodstock[data-n="3"] .ws[data-f="27"] {
  background-position: 25% 65%;
}

.mv-woodstock[data-n="3"] .ws[data-f="28"] {
  background-position: 25% 67.5%;
}

.mv-woodstock[data-n="3"] .ws[data-f="29"] {
  background-position: 25% 70%;
}

.mv-woodstock[data-n="3"] .ws[data-f="30"] {
  background-position: 25% 72.5%;
}

.mv-woodstock[data-n="3"] .ws[data-f="31"] {
  background-position: 25% 75%;
}

.mv-woodstock[data-n="4"] .ws[data-f="1"] {
  background-position: 37.5% 0%;
}

.mv-woodstock[data-n="4"] .ws[data-f="2"] {
  background-position: 37.5% 2.5%;
}

.mv-woodstock[data-n="4"] .ws[data-f="3"] {
  background-position: 37.5% 5%;
}

.mv-woodstock[data-n="4"] .ws[data-f="4"] {
  background-position: 37.5% 7.5%;
}

.mv-woodstock[data-n="4"] .ws[data-f="5"] {
  background-position: 37.5% 10%;
}

.mv-woodstock[data-n="4"] .ws[data-f="6"] {
  background-position: 37.5% 12.5%;
}

.mv-woodstock[data-n="4"] .ws[data-f="7"] {
  background-position: 37.5% 15%;
}

.mv-woodstock[data-n="4"] .ws[data-f="8"] {
  background-position: 37.5% 17.5%;
}

.mv-woodstock[data-n="4"] .ws[data-f="9"] {
  background-position: 37.5% 20%;
}

.mv-woodstock[data-n="4"] .ws[data-f="10"] {
  background-position: 37.5% 22.5%;
}

.mv-woodstock[data-n="4"] .ws[data-f="11"] {
  background-position: 37.5% 25%;
}

.mv-woodstock[data-n="4"] .ws[data-f="12"] {
  background-position: 37.5% 27.5%;
}

.mv-woodstock[data-n="4"] .ws[data-f="13"] {
  background-position: 37.5% 30%;
}

.mv-woodstock[data-n="4"] .ws[data-f="14"] {
  background-position: 37.5% 32.5%;
}

.mv-woodstock[data-n="4"] .ws[data-f="15"] {
  background-position: 37.5% 35%;
}

.mv-woodstock[data-n="4"] .ws[data-f="16"] {
  background-position: 37.5% 37.5%;
}

.mv-woodstock[data-n="4"] .ws[data-f="17"] {
  background-position: 37.5% 40%;
}

.mv-woodstock[data-n="4"] .ws[data-f="18"] {
  background-position: 37.5% 42.5%;
}

.mv-woodstock[data-n="4"] .ws[data-f="19"] {
  background-position: 37.5% 45%;
}

.mv-woodstock[data-n="4"] .ws[data-f="20"] {
  background-position: 37.5% 47.5%;
}

.mv-woodstock[data-n="4"] .ws[data-f="21"] {
  background-position: 37.5% 50%;
}

.mv-woodstock[data-n="4"] .ws[data-f="22"] {
  background-position: 37.5% 52.5%;
}

.mv-woodstock[data-n="4"] .ws[data-f="23"] {
  background-position: 37.5% 55%;
}

.mv-woodstock[data-n="4"] .ws[data-f="24"] {
  background-position: 37.5% 57.5%;
}

.mv-woodstock[data-n="4"] .ws[data-f="25"] {
  background-position: 37.5% 60%;
}

.mv-woodstock[data-n="4"] .ws[data-f="26"] {
  background-position: 37.5% 62.5%;
}

.mv-woodstock[data-n="4"] .ws[data-f="27"] {
  background-position: 37.5% 65%;
}

.mv-woodstock[data-n="4"] .ws[data-f="28"] {
  background-position: 37.5% 67.5%;
}

.mv-woodstock[data-n="4"] .ws[data-f="29"] {
  background-position: 37.5% 70%;
}

.mv-woodstock[data-n="4"] .ws[data-f="30"] {
  background-position: 37.5% 72.5%;
}

.mv-woodstock[data-n="4"] .ws[data-f="31"] {
  background-position: 37.5% 75%;
}

.mv-woodstock[data-n="4"] .ws[data-f="32"] {
  background-position: 37.5% 77.5%;
}

.mv-woodstock[data-n="4"] .ws[data-f="33"] {
  background-position: 37.5% 80%;
}

.mv-woodstock[data-n="4"] .ws[data-f="34"] {
  background-position: 37.5% 82.5%;
}

.mv-woodstock[data-n="4"] .ws[data-f="35"] {
  background-position: 37.5% 85%;
}

.mv-woodstock[data-n="4"] .ws[data-f="36"] {
  background-position: 37.5% 87.5%;
}

.mv-woodstock[data-n="4"] .ws[data-f="37"] {
  background-position: 37.5% 90%;
}

.mv-woodstock[data-n="5"] .ws[data-f="1"] {
  background-position: 50% 0%;
}

.mv-woodstock[data-n="5"] .ws[data-f="2"] {
  background-position: 50% 2.5%;
}

.mv-woodstock[data-n="5"] .ws[data-f="3"] {
  background-position: 50% 5%;
}

.mv-woodstock[data-n="5"] .ws[data-f="4"] {
  background-position: 50% 7.5%;
}

.mv-woodstock[data-n="5"] .ws[data-f="5"] {
  background-position: 50% 10%;
}

.mv-woodstock[data-n="5"] .ws[data-f="6"] {
  background-position: 50% 12.5%;
}

.mv-woodstock[data-n="5"] .ws[data-f="7"] {
  background-position: 50% 15%;
}

.mv-woodstock[data-n="5"] .ws[data-f="8"] {
  background-position: 50% 17.5%;
}

.mv-woodstock[data-n="5"] .ws[data-f="9"] {
  background-position: 50% 20%;
}

.mv-woodstock[data-n="5"] .ws[data-f="10"] {
  background-position: 50% 22.5%;
}

.mv-woodstock[data-n="5"] .ws[data-f="11"] {
  background-position: 50% 25%;
}

.mv-woodstock[data-n="5"] .ws[data-f="12"] {
  background-position: 50% 27.5%;
}

.mv-woodstock[data-n="5"] .ws[data-f="13"] {
  background-position: 50% 30%;
}

.mv-woodstock[data-n="5"] .ws[data-f="14"] {
  background-position: 50% 32.5%;
}

.mv-woodstock[data-n="5"] .ws[data-f="15"] {
  background-position: 50% 35%;
}

.mv-woodstock[data-n="5"] .ws[data-f="16"] {
  background-position: 50% 37.5%;
}

.mv-woodstock[data-n="5"] .ws[data-f="17"] {
  background-position: 50% 40%;
}

.mv-woodstock[data-n="5"] .ws[data-f="18"] {
  background-position: 50% 42.5%;
}

.mv-woodstock[data-n="5"] .ws[data-f="19"] {
  background-position: 50% 45%;
}

.mv-woodstock[data-n="5"] .ws[data-f="20"] {
  background-position: 50% 47.5%;
}

.mv-woodstock[data-n="5"] .ws[data-f="21"] {
  background-position: 50% 50%;
}

.mv-woodstock[data-n="5"] .ws[data-f="22"] {
  background-position: 50% 52.5%;
}

.mv-woodstock[data-n="5"] .ws[data-f="23"] {
  background-position: 50% 55%;
}

.mv-woodstock[data-n="5"] .ws[data-f="24"] {
  background-position: 50% 57.5%;
}

.mv-woodstock[data-n="5"] .ws[data-f="25"] {
  background-position: 50% 60%;
}

.mv-woodstock[data-n="5"] .ws[data-f="26"] {
  background-position: 50% 62.5%;
}

.mv-woodstock[data-n="5"] .ws[data-f="27"] {
  background-position: 50% 65%;
}

.mv-woodstock[data-n="5"] .ws[data-f="28"] {
  background-position: 50% 67.5%;
}

.mv-woodstock[data-n="5"] .ws[data-f="29"] {
  background-position: 50% 70%;
}

.mv-woodstock[data-n="5"] .ws[data-f="30"] {
  background-position: 50% 72.5%;
}

.mv-woodstock[data-n="5"] .ws[data-f="31"] {
  background-position: 50% 75%;
}

.mv-woodstock[data-n="5"] .ws[data-f="32"] {
  background-position: 50% 77.5%;
}

.mv-woodstock[data-n="5"] .ws[data-f="33"] {
  background-position: 50% 80%;
}

.mv-woodstock[data-n="6"] .ws[data-f="1"] {
  background-position: 62.5% 0%;
}

.mv-woodstock[data-n="6"] .ws[data-f="2"] {
  background-position: 62.5% 2.5%;
}

.mv-woodstock[data-n="6"] .ws[data-f="3"] {
  background-position: 62.5% 5%;
}

.mv-woodstock[data-n="6"] .ws[data-f="4"] {
  background-position: 62.5% 7.5%;
}

.mv-woodstock[data-n="6"] .ws[data-f="5"] {
  background-position: 62.5% 10%;
}

.mv-woodstock[data-n="6"] .ws[data-f="6"] {
  background-position: 62.5% 12.5%;
}

.mv-woodstock[data-n="6"] .ws[data-f="7"] {
  background-position: 62.5% 15%;
}

.mv-woodstock[data-n="6"] .ws[data-f="8"] {
  background-position: 62.5% 17.5%;
}

.mv-woodstock[data-n="6"] .ws[data-f="9"] {
  background-position: 62.5% 20%;
}

.mv-woodstock[data-n="6"] .ws[data-f="10"] {
  background-position: 62.5% 22.5%;
}

.mv-woodstock[data-n="6"] .ws[data-f="11"] {
  background-position: 62.5% 25%;
}

.mv-woodstock[data-n="7"] .ws[data-f="1"] {
  background-position: 75% 0%;
}

.mv-woodstock[data-n="7"] .ws[data-f="2"] {
  background-position: 75% 2.5%;
}

.mv-woodstock[data-n="7"] .ws[data-f="3"] {
  background-position: 75% 5%;
}

.mv-woodstock[data-n="7"] .ws[data-f="4"] {
  background-position: 75% 7.5%;
}

.mv-woodstock[data-n="7"] .ws[data-f="5"] {
  background-position: 75% 10%;
}

.mv-woodstock[data-n="7"] .ws[data-f="6"] {
  background-position: 75% 12.5%;
}

.mv-woodstock[data-n="7"] .ws[data-f="7"] {
  background-position: 75% 15%;
}

.mv-woodstock[data-n="7"] .ws[data-f="8"] {
  background-position: 75% 17.5%;
}

.mv-woodstock[data-n="7"] .ws[data-f="9"] {
  background-position: 75% 20%;
}

.mv-woodstock[data-n="7"] .ws[data-f="10"] {
  background-position: 75% 22.5%;
}

.mv-woodstock[data-n="7"] .ws[data-f="11"] {
  background-position: 75% 25%;
}

.mv-woodstock[data-n="7"] .ws[data-f="12"] {
  background-position: 75% 27.5%;
}

.mv-woodstock[data-n="7"] .ws[data-f="13"] {
  background-position: 75% 30%;
}

.mv-woodstock[data-n="8"] .ws[data-f="1"] {
  background-position: 87.5% 0%;
}

.mv-woodstock[data-n="8"] .ws[data-f="2"] {
  background-position: 87.5% 2.5%;
}

.mv-woodstock[data-n="8"] .ws[data-f="3"] {
  background-position: 87.5% 5%;
}

.mv-woodstock[data-n="8"] .ws[data-f="4"] {
  background-position: 87.5% 7.5%;
}

.mv-woodstock[data-n="8"] .ws[data-f="5"] {
  background-position: 87.5% 10%;
}

.mv-woodstock[data-n="8"] .ws[data-f="6"] {
  background-position: 87.5% 12.5%;
}

.mv-woodstock[data-n="8"] .ws[data-f="7"] {
  background-position: 87.5% 15%;
}

.mv-woodstock[data-n="8"] .ws[data-f="8"] {
  background-position: 87.5% 17.5%;
}

.mv-woodstock[data-n="8"] .ws[data-f="9"] {
  background-position: 87.5% 20%;
}

.mv-woodstock[data-n="8"] .ws[data-f="10"] {
  background-position: 87.5% 22.5%;
}

.mv-woodstock[data-n="8"] .ws[data-f="11"] {
  background-position: 87.5% 25%;
}

.mv-woodstock[data-n="8"] .ws[data-f="12"] {
  background-position: 87.5% 27.5%;
}

.mv-woodstock[data-n="8"] .ws[data-f="13"] {
  background-position: 87.5% 30%;
}

.mv-woodstock[data-n="8"] .ws[data-f="14"] {
  background-position: 87.5% 32.5%;
}

.mv-woodstock[data-n="8"] .ws[data-f="15"] {
  background-position: 87.5% 35%;
}

.mv-woodstock[data-n="8"] .ws[data-f="16"] {
  background-position: 87.5% 37.5%;
}

.mv-woodstock[data-n="8"] .ws[data-f="17"] {
  background-position: 87.5% 40%;
}

.mv-woodstock[data-n="8"] .ws[data-f="18"] {
  background-position: 87.5% 42.5%;
}

.mv-woodstock[data-n="8"] .ws[data-f="19"] {
  background-position: 87.5% 45%;
}

.mv-woodstock[data-n="8"] .ws[data-f="20"] {
  background-position: 87.5% 47.5%;
}

.mv-woodstock[data-n="8"] .ws[data-f="21"] {
  background-position: 87.5% 50%;
}

.mv-woodstock[data-n="8"] .ws[data-f="22"] {
  background-position: 87.5% 52.5%;
}

.mv-woodstock[data-n="8"] .ws[data-f="23"] {
  background-position: 87.5% 55%;
}

.mv-woodstock[data-n="8"] .ws[data-f="24"] {
  background-position: 87.5% 57.5%;
}

.mv-woodstock[data-n="8"] .ws[data-f="25"] {
  background-position: 87.5% 60%;
}

.mv-woodstock[data-n="8"] .ws[data-f="26"] {
  background-position: 87.5% 62.5%;
}

.mv-woodstock[data-n="8"] .ws[data-f="27"] {
  background-position: 87.5% 65%;
}

.mv-woodstock[data-n="8"] .ws[data-f="28"] {
  background-position: 87.5% 67.5%;
}

.mv-woodstock[data-n="8"] .ws[data-f="29"] {
  background-position: 87.5% 70%;
}

.mv-woodstock[data-n="8"] .ws[data-f="30"] {
  background-position: 87.5% 72.5%;
}

.mv-woodstock[data-n="8"] .ws[data-f="31"] {
  background-position: 87.5% 75%;
}

.mv-woodstock[data-n="8"] .ws[data-f="32"] {
  background-position: 87.5% 77.5%;
}

.mv-woodstock[data-n="8"] .ws[data-f="33"] {
  background-position: 87.5% 80%;
}

.mv-woodstock[data-n="8"] .ws[data-f="34"] {
  background-position: 87.5% 82.5%;
}

.mv-woodstock[data-n="8"] .ws[data-f="35"] {
  background-position: 87.5% 85%;
}

.mv-woodstock[data-n="8"] .ws[data-f="36"] {
  background-position: 87.5% 87.5%;
}

.mv-woodstock[data-n="8"] .ws[data-f="37"] {
  background-position: 87.5% 90%;
}

/* :::::: animation :::::: */
.mv-illustration,
.mv-woodstock {
  transform: scale(0.9);
}

.mv-illustration:not(.active),
.mv-woodstock:not(.active),
.mv-illustration.active.hide,
.mv-woodstock.active.hide,
.mv-orbit.-line.hide {
  opacity: 0;
}

.mv-container.scene1 .mv-illustration,
.mv-container.scene1 .mv-woodstock,
.mv-container.scene1 .mv-orbit.-line {
  transform: none;
}

.mv-container.scene2 .mv-illustration {
  transform: scale(2.4);
  transform-origin: 50% 24%;
}

.mv-container.scene2 .mv-woodstock,
.mv-container.scene2 .mv-orbit.-line {
  transform: scale(2.6);
  transform-origin: 51% 28%;
}

@media screen and (min-width: 600px) {
  .mv-container.scene2 .mv-illustration {
    transform: scale(2.2);
  }
  .mv-container.scene2 .mv-woodstock,
  .mv-container.scene2 .mv-orbit.-line {
    transform: scale(2.4);
  }
}

@media screen and (min-width: 1280px) {
  .mv-container.scene2 .mv-illustration {
    transform: scale(2);
    transform-origin: 50% 12%;
  }
  .mv-container.scene2 .mv-woodstock,
  .mv-container.scene2 .mv-orbit.-line {
    transform: scale(2.2);
    transform-origin: 50% 12%;
  }
}

@media screen and (min-width: 1440px) and (min-height: 1024px) {
  .mv-container.scene2 .mv-illustration {
    transform: scale(1.9);
  }
  .mv-container.scene2 .mv-woodstock,
  .mv-container.scene2 .mv-orbit.-line {
    transform: scale(2);
  }
}

body.isOpening .mv-illustration {
  transition: opacity 1s;
}

body.isOpening .scene1 .mv-illustration {
  transition: opacity 2s, transform 2s cubic-bezier(0.215, 0.61, 0.355, 1);
}

body.isOpening .scene1 .mv-woodstock,
body.isOpening .scene1 .mv-orbit.-line {
  transition: opacity 0.4s, transform 2s cubic-bezier(0.215, 0.61, 0.355, 1);
}

body.isOpening .scene2 .mv-illustration {
  transition: transform 3.6s cubic-bezier(0.645, 0.045, 0.355, 1);
}

body.isOpening .scene2 .mv-woodstock,
body.isOpening .scene2 .mv-orbit.-line {
  transition: transform 3.6s cubic-bezier(0.645, 0.045, 0.355, 1);
}

body.isOpening .mv-illustration.hide {
  transition: opacity 1s;
}

body.isOpening .mv-woodstock.hide,
body.isOpening .mv-orbit.-line.hide {
  transition: opacity 1s;
}

/* -------------------------------------------------- SKIP */
.mv-skip {
  position: absolute;
  z-index: 999;
  text-transform: uppercase;
  border-radius: 48px;
  font-family: zeitung, sans-serif;
  font-style: normal;
  font-weight: 600;
}

.mv-skip .ico {
  position: relative;
  top: -2px;
  width: 1.5em;
  height: 1em;
  margin-left: 0.5em;
  vertical-align: middle;
  fill: none;
  stroke: #3a3a3a;
  stroke-width: 2;
}

.mv-skip.hide,
.mainVisual-canvas.is-skip {
  pointer-events: none;
  opacity: 0;
  transition: opacity 1s;
}

.mv-skip.hide {
  transition-duration: 0.6s;
}

@media print, screen and (max-width: 979px) {
  .mv-skip {
    top: 32px;
    left: 24px;
    font-size: 12px;
  }
}

@media print, screen and (min-width: 980px) {
  .mv-skip {
    top: 40px;
    left: 56px;
    font-size: 16px;
  }
}

/* -------------------------------------------------- PICK UP */
.c-pickup {
  position: absolute;
  right: calc(50% - 80px);
  bottom: 40px;
  z-index: 10000;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  border: 3px solid #3a3a3a;
}

.c-pickup:not(.open) {
  width: 160px;
  height: 70px;
  border-radius: 35px;
}

.c-pickup.open {
  bottom: 20px;
  width: calc(100% - 40px);
  border-radius: 20px;
}

@media print, screen and (max-width: 979px) {
  .c-pickup.open {
    right: 20px;
  }
}

@media print, screen and (min-width: 980px) {
  .c-pickup {
    right: 60px;
    bottom: 60px;
  }
  .c-pickup:not(.open) {
    width: 200px;
    height: 90px;
    border-radius: 45px;
  }
  .c-pickup.open {
    bottom: 50px;
    width: 400px;
  }
}

.c-pickup:not(.active) {
  pointer-events: none;
  opacity: 0;
  transform: scale(1.08);
}

body.isOpening .c-pickup {
  transition: opacity 1s, transform 1s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.c-pickup.anima {
  transition-delay: 0s, 0.3s, 0.3s, 0s, 0s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-duration: 0.6s, 0.6s, 0.6s, 0.4s, 0.3s;
  transition-property: bottom, right, width, height, border-radius;
}

.c-pickup.anima.open {
  transition-delay: 0.3s, 0s, 0s, 0.3s, 0.3s;
  transition-duration: 0.6s, 0.4s, 0.4s, 0.6s, 0.4s;
}

/* :::::: pickup-title :::::: */
.pickup-title {
  position: absolute;
  top: 14px;
  left: 0;
  width: 100%;
  margin: 0;
  font-size: 16px;
  font-size: 20px;
  line-height: 1.2;
  color: #3a3a3a;
  text-align: center;
  text-transform: uppercase;
  font-family: zeitung, sans-serif;
  font-style: normal;
  font-weight: 700;
}

.pickup-subTitle {
  display: block;
  margin-top: 0.5em;
  font-size: 11px;
}

.c-pickup.open .pickup-title {
  top: 24px;
  font-size: 24px;
}

@media print, screen and (min-width: 980px) {
  .pickup-title {
    top: 20px;
    font-size: 20px;
  }
  .pickup-subTitle {
    font-size: 13px;
  }
  .c-pickup.open .pickup-title {
    top: 40px;
    font-size: 28px;
  }
}

.c-pickup.anima .pickup-title {
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-duration: 0.6s;
  transition-property: top, font-size;
}

.c-pickup.anima.open .pickup-title {
  transition-delay: 0.3s;
  transition-duration: 0.6s;
}

/* :::::: pickup-content :::::: */
.pickup-content {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  overflow: hidden;
  text-align: center;
}

.c-pickup:not(.open) .pickup-content {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.c-pickup.open .pickup-content {
  padding: 32px 20px;
  padding-top: 96px;
}

@media print, screen and (min-width: 980px) {
  .pickup-content {
    padding: 0 36px;
  }
  .c-pickup.open .pickup-content {
    padding: 36px;
    padding-top: 128px;
  }
}

.c-pickup.anima .pickup-content {
  transition-delay: 0.6s, 0.3s, 0s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-duration: 0s, 0.3s, 0.3s;
  transition-property: width, padding, opacity;
}

.c-pickup.anima.open .pickup-content {
  transition-delay: 0.3s;
  transition-duration: 0s, 0.6s, 0.6s;
}

/* :::::: pickup-list :::::: */
.c-pickupList {
  overflow: auto;
  font-size: 13px;
  text-align: left;
}

.c-pickup:not(.open) .c-pickupList {
  width: calc(100vw - 86px);
}

.c-pickup.open .c-pickupList {
  height: calc(100% - 73px);
}

@media print, screen and (max-width: 979px) {
  .c-pickupList {
    width: 100%;
  }
}

@media print, screen and (min-width: 980px) {
  .c-pickupList {
    font-size: 14px;
  }
  .c-pickup:not(.open) .c-pickupList {
    width: 322px;
  }
  .c-pickup.open .c-pickupList {
    height: calc(100% - 62px);
  }
}

.pickupList-item {
  position: relative;
  padding-left: 1.8em;
}

.pickupList-item:not(:last-child) {
  padding-bottom: 12px;
}

.pickupList-item::before {
  position: absolute;
  top: 0.5em;
  left: 0;
  display: block;
  width: 10px;
  height: 10px;
  content: "";
  background: #3a3a3a;
  border-radius: 0.75em;
}

.pickupList-item a {
  padding-bottom: 0.2em;
  border-bottom: 1px solid currentcolor;
}

.pickupList-limit {
  display: block;
  margin-top: 0.2em;
  font-size: 0.8em;
  color: #808080;
}

.pickup-content .c-button {
  margin-top: 20px;
}

@media print, screen and (max-width: 979px) {
  .pickup-content .c-button {
    max-width: 190px;
  }
}

/* :::::: pickup-trigger :::::: */
.c-pickupButton {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  color: transparent;
  cursor: pointer;
  background: none;
  border: 0;
  -webkit-appearance: none;
  appearance: none;
}

.c-pickupButton::selection {
  color: transparent;
}

.c-pickupButton::before,
.c-pickupButton::after {
  position: absolute;
  top: calc(50% - 1px);
  left: calc(50% - 12px);
  width: 24px;
  height: 2px;
  content: "";
  background: #3a3a3a;
  opacity: 0;
}

.c-pickup.open .c-pickupButton {
  top: 16px;
  right: 16px;
  width: 40px;
  height: 40px;
}

.c-pickup.open .c-pickupButton::before,
.c-pickup.open .c-pickupButton::after {
  opacity: 1;
}

.c-pickup.open .c-pickupButton::before {
  transform: rotate(45deg);
}

.c-pickup.open .c-pickupButton::after {
  transform: rotate(-45deg);
}

@media print, screen and (min-width: 980px) {
  .c-pickup.open .c-pickupButton {
    top: 24px;
    right: 24px;
  }
}

.c-pickup.anima .c-pickupButton::before,
.c-pickup.anima .c-pickupButton::after {
  transition: opacity 0.3s;
}

.c-pickup.anima.open .c-pickupButton::before,
.c-pickup.anima.open .c-pickupButton::after {
  transition-delay: 0.3s;
}

/* -------------------------------------------------- 重要なお知らせ */
.c-notice {
  position: relative;
  padding: 20px 0;
  font-size: 13px;
  color: white;
  background: #519c60;
}

.c-notice > * {
  position: relative;
}

.notice-title {
  margin: 0;
  font-size: inherit;
  font-weight: normal;
  color: #d4d4d4;
}

.notice-content p {
  margin: 0;
}

@media print, screen and (max-width: 979px) {
  .c-notice {
    padding: 24px;
    margin: 0 -20px;
    text-align: center;
  }
  .notice-title {
    margin-bottom: 1em;
  }
}

@media print, screen and (min-width: 980px) {
  .c-notice {
    display: flex;
    align-items: center;
  }
  .c-notice::before {
    position: absolute;
    top: 0;
    right: calc(50% - 50vw + 40px);
    left: calc(50% - 50vw + 40px);
    display: block;
    height: 100%;
    content: "";
    background: #519c60;
  }
  .notice-title {
    flex-basis: 13em;
  }
}

.c-notice_02 {
  position: relative;
  padding: 20px 0;
  font-size: 13px;
  color: white;
  background: #444;
}

.c-notice_02 > * {
  position: relative;
}

.notice_02-title {
  margin: 0;
  font-size: inherit;
  font-weight: normal;
  color: #d4d4d4;
}

.notice_02-content p {
  margin: 0;
}

@media print, screen and (max-width: 979px) {
  .c-notice_02 {
    padding: 24px;
    margin: 0 -20px;
    text-align: center;
  }
  .notice_02-title {
    margin-bottom: 1em;
  }
}

@media print, screen and (min-width: 980px) {
  .c-notice_02 {
    display: flex;
    align-items: center;
  }
  .c-notice_02::before {
    position: absolute;
    top: 0;
    right: calc(50% - 50vw + 40px);
    left: calc(50% - 50vw + 40px);
    display: block;
    height: 100%;
    content: "";
    background: #444;
  }
  .notice_02-title {
    flex-basis: 13em;
  }
}

/* -------------------------------------------------- news, campaign */
.p-homeNews {
  margin-top: 104px;
}

.p-homeCampaign {
  margin: 40px 0 104px;
}

@media print, screen and (max-width: 979px) {
  .p-homeNews,
  .p-homeCampaign {
    text-align: center;
  }
  .p-homeNews .c-title,
  .p-homeNews .c-newsList,
  .p-homeCampaign .c-title,
  .p-homeCampaign .c-newsList {
    text-align: left;
  }
  .p-homeNews .c-viewmore,
  .p-homeCampaign .c-viewmore {
    margin-top: 32px;
  }
  .newsList-content {
    padding-bottom: 16px;
    border-bottom: 1px solid #f6f5f3;
  }
  .p-homeCampaign .newsList-content:not(:first-child) {
    padding-top: 16px;
  }
  .newsList-date {
    font-size: 12px;
  }
  .newsList-content + .newsList-date {
    margin-top: 16px;
  }
}

@media print, screen and (min-width: 980px) {
  .c-newsList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .newsList-date,
  .newsList-content {
    padding: 18px 0;
    border-bottom: 1px solid #f6f5f3;
  }
  .newsList-date {
    padding: 20px 0;
  }
  .newsList-date:first-of-type,
  .newsList-content:first-of-type {
    border-top: 1px solid #f6f5f3;
  }
  .newsList-date {
    flex-basis: 8rem;
    font-size: 13px;
  }
  .newsList-content {
    flex-basis: calc(100% - 8rem);
  }
  .p-homeNews,
  .p-homeCampaign {
    position: relative;
  }
  .p-homeNews .c-viewmore,
  .p-homeCampaign .c-viewmore {
    position: absolute;
    top: .5em;
    right: 0;
  }
}

/* -------------------------------------------------- contents */
.p-homeContents {
  margin: 104px 0;
}

.c-contentsList {
  display: grid;
}

.contentsList-item {
  position: relative;
  overflow: hidden;
  border-radius: 20px;
}

.contentsList-item::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  content: "";
  background: linear-gradient(to top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
}

.contentsList-item.-gallery::before {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.05));
}

.contentsList-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

[data-whatintent="keyboard"] .contentsList-item a {
  display: block;
  border-radius: inherit;
}

[data-whatintent="keyboard"] .contentsList-item a:focus {
  position: relative;
  outline: none;
}

[data-whatintent="keyboard"] .contentsList-item a:focus::before {
  position: absolute;
  top: 3px;
  right: 3px;
  bottom: 3px;
  left: 3px;
  display: block;
  content: "";
  border-radius: inherit;
  box-shadow: 0 0 0 3px #444;
}

@media print, screen and (max-width: 979px) {
  .c-contentsList {
    -ms-grid-rows: 1fr 20px 1fr 20px 1fr;
    -ms-grid-columns: 1fr 20px 1fr;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
  .c-contentsList picture {
    pointer-events: none;
  }
  .contentsList-item > a {
    display: inline-block;
    width: 100%;
    height: 100%;
  }
  .contentsList-item.-store,
  .contentsList-item.-cafe {
    -ms-grid-row: 3;
  }
  .contentsList-item.-workshop,
  .contentsList-item.-app {
    -ms-grid-row: 5;
  }
  .contentsList-item.-cafe,
  .contentsList-item.-app {
    -ms-grid-column: 3;
  }
}

@media print, screen and (min-width: 980px) {
  .c-contentsList {
    -ms-grid-rows: 1fr 32px 1fr;
    -ms-grid-columns: 1fr 32px 1fr 32px 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 32px;
  }
  .contentsList-item.-cafe,
  .contentsList-item.-workshop,
  .contentsList-item.-app {
    -ms-grid-row: 3;
  }
  .contentsList-item.-workshop,
  .contentsList-item.-online {
    -ms-grid-column: 3;
  }
  .contentsList-item.-store,
  .contentsList-item.-app {
    -ms-grid-column: 5;
  }
}

.contentsList-item .c-menuRuby {
  position: absolute;
  bottom: 16px;
  left: 13px;
  width: calc(100% - 26px);
  font-size: 24px;
}

.contentsList-item.-camera .c-menuRuby {
  letter-spacing: -.01em;
}

.contentsList-item.-camera .c-menuRuby rt {
  letter-spacing: .12em;
}

.contentsList-item .c-menuRuby rt {
  font-size: 11px;
}

.contentsList-item .c-menuRuby .strong {
  display: inline-block;
  margin-bottom: 4px;
  color: #e60020;
  font-size: 22px;
  font-weight: bold;
}

@media print, screen and (max-width: 599px) {
  .contentsList-item .c-menuRuby {
    font-size: 18px;
  }
  .contentsList-item .c-menuRuby rt {
    font-size: 9px;
  }
  .contentsList-item .c-menuRuby .strong {
    margin-bottom: 4px;
    font-size: 16px;
  }
}

@media print, screen and (min-width: 980px) {
  .contentsList-item .c-menuRuby {
    bottom: 24px;
    left: 24px;
    width: calc(100% - 48px);
    font-size: 28px;
  }
  .contentsList-item .c-menuRuby .v-mb {
    display: none;
  }
  .contentsList-item .c-menuRuby .strong {
    font-size: 24px;
  }
}

@media print, screen and (min-width: 1280px) {
  .contentsList-item .c-menuRuby {
    bottom: 32px;
    left: 32px;
    width: calc(100% - 64px);
    font-size: 32px;
  }
  .contentsList-item .c-menuRuby .strong {
    font-size: 28px;
  }
}

@media (min-width: 1400px) {
  .contentsList-item .c-menuRuby {
    font-size: 38px;
  }
  .contentsList-item .c-menuRuby .strong {
    font-size: 30px;
  }
}

.contentsList-info {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  padding: 40px;
  color: white;
}

@media print, screen and (max-width: 979px) {
  .contentsList-info {
    opacity: 0;
  }
}

.contentsList-info::before {
  position: absolute;
  top: -20px;
  left: -20px;
  width: calc(100% + 40px);
  height: calc(100% + 420px);
  content: "";
  background: #519c60;
}

.contentsList-info > * {
  position: relative;
}

.contentsList-info .info-title {
  margin-bottom: 1em;
  font-size: 18px;
  font-weight: 600;
}

.contentsList-info .info-text {
  font-size: 13px;
  letter-spacing: 0.05em;
}

.contentsList-info .info-image {
  position: absolute;
  top: 40px;
  right: 40px;
}

.contentsList-info {
  transition: opacity 0.3s, transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.contentsList-item:not(:hover) .contentsList-info {
  pointer-events: none;
  opacity: 0;
  transform: scale(0.95);
}

@media print, screen and (min-width: 980px) {
  .contentsList-info {
    padding: 32px;
  }
  .contentsList-info .info-image {
    top: 32px;
    right: 32px;
  }
}

@media print, screen and (min-width: 1280px) {
  .contentsList-info {
    padding: 40px;
  }
  .contentsList-info .info-image {
    top: 40px;
    right: 40px;
  }
}

/* -------------------------------------------------- ticket */
.p-homeTicket {
  position: relative;
  padding: 104px 0;
}

.p-homeTicket-inner {
  position: relative;
  overflow: hidden;
  background: white;
  border-radius: 20px;
}

.p-homeTicket-inner > * {
  position: relative;
  z-index: 1;
}

.p-homeTicket-inner::before,
.p-homeTicket-inner::after {
  position: absolute;
  content: "";
}

.p-homeTicket-inner::after {
  width: 140px;
  height: 140px;
  background: #f6f5f3;
  border-radius: 140px;
}

.ticketInfo-title {
  margin-top: 24px;
  color: #808080;
}

@media print, screen and (max-width: 979px) {
  .p-homeTicket-inner {
    padding: 68px 6% 64px;
  }
  .p-homeTicket-inner::before {
    top: 80px;
    left: 10%;
    width: 80%;
    height: 30px;
    background-image: repeating-linear-gradient(to right, #f6f5f3 0 24%, transparent 24% 38%, #f6f5f3 38% 62%, transparent 62% 76%, #f6f5f3 76% 100%);
  }
  .p-homeTicket-inner::after {
    top: -48px;
    left: calc(50% - 48px);
    width: 96px;
    height: 96px;
  }
  .p-homeTicket-inner .c-title {
    text-align: center;
  }
  .ticketInfo-title {
    margin-top: 16px;
  }
}

@media print, screen and (min-width: 980px) {
  .p-homeTicket-inner {
    padding: 56px 72px;
    padding-right: 120px;
  }
  .p-homeTicket-inner::before {
    top: 12%;
    right: 160px;
    width: 30px;
    height: 76%;
    background-image: repeating-linear-gradient(to bottom, #f6f5f3 0 24%, transparent 24% 38%, #f6f5f3 38% 62%, transparent 62% 76%, #f6f5f3 76% 100%);
  }
  .p-homeTicket-inner::after {
    top: calc(50% - 70px);
    right: -70px;
  }
}

/* -------------------------------------------------- access */
.p-homeAccess {
  margin-bottom: 104px;
}

.l-accessMap {
  position: relative;
  margin-bottom: 60px;
}

.l-accessMap .c-button.-map {
  position: absolute;
  right: 0;
  bottom: -120px;
}

@media print, screen and (min-width: 980px) {
  .l-accessMap .c-button.-map {
    right: -73px;
  }
}

@media print, screen and (min-width: 1280px) {
  .l-accessMap .c-button.-map {
    right: 0;
  }
}

.accessInfo-title {
  font-weight: 600;
}

@media print, screen and (max-width: 979px) {
  .accessMap-map {
    margin: 0 -20px;
  }
  .accessMap-map img {
    min-height: 300px;
    object-fit: cover;
  }
  .accessInfo-content {
    margin-bottom: 20px;
  }
  .p-homeAccess .c-button {
    margin-top: 52px;
  }
}

@media print, screen and (min-width: 600px) {
  .accessMap-map {
    width: 100vw;
  }
  .accessMap-map img {
    min-height: 300px;
    object-fit: cover;
  }
}

@media print, screen and (min-width: 980px) {
  .accessMap-map {
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
  .accessMap-map img {
    width: 100%;
  }
  .c-accessInfo {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .accessInfo-title,
  .accessInfo-content {
    margin-bottom: 20px;
  }
  .accessInfo-title {
    flex-basis: 7em;
  }
  .accessInfo-content {
    flex-basis: calc(100% - 7em);
  }
}

@media print, screen and (min-width: 1280px) {
  .accessMap-map {
    width: 980px;
    height: 100%;
    margin: 0 auto;
  }
  .accessMap-map img {
    margin-left: -40px;
  }
}

.p-inquires {
  margin-top: -19px;
}

@media print, screen and (min-width: 600px) {
  .p-inquires {
    width: 100%;
    max-width: 1440px;
    height: auto;
    margin: 34px auto 144px;
  }
}

.p-inquires__inner {
  padding-top: 50px;
}

@media print, screen and (min-width: 600px) {
  .p-inquires__inner {
    display: block;
    width: 100%;
    max-width: 878px;
    height: auto;
    margin: 0 auto;
  }
}

@media print, screen and (min-width: 980px) {
  .p-inquires__inner {
    padding: 0;
  }
}

.p-inquires.-complete .p-inquires__inner {
  margin-bottom: 81px;
}

@media print, screen and (min-width: 600px) {
  .p-inquires.-complete .p-inquires__inner {
    margin-bottom: 62px;
  }
}

.p-inquires__text {
  margin-bottom: 15px;
}

@media print, screen and (min-width: 600px) {
  .p-inquires__text {
    margin-bottom: 8px;
  }
}

.p-inquires.-complete .p-inquires__text {
  margin-bottom: -4px;
}

@media print, screen and (min-width: 600px) {
  .p-inquires.-complete .p-inquires__text {
    margin-bottom: 18px;
  }
}

.p-inquires__description {
  font-family: source-han-sans-japanese, sans-serif;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 1.9;
  letter-spacing: 0.06em;
}

@media print, screen and (min-width: 600px) {
  .p-inquires__description {
    margin-bottom: 0;
    font-size: 15px;
    line-height: 2.2;
    letter-spacing: 0.12em;
  }
}

.p-inquires.-confirm .p-inquires__description {
  line-height: 2;
}

@media print, screen and (min-width: 600px) {
  .p-inquires.-confirm .p-inquires__description {
    margin-bottom: 10px;
  }
}

.p-inquires__description .-must {
  color: #d73d5b;
}

.p-inquires__description.-English {
  letter-spacing: 0.07em;
}

.p-inquires__descriptionSP {
  display: block;
  margin-bottom: -12px;
}

@media print, screen and (min-width: 600px) {
  .p-inquires__descriptionSP {
    display: block;
    margin-bottom: -33px;
  }
}

.p-inquires__form {
  padding: 30px 0 0;
  margin-bottom: 36px;
}

@media print, screen and (min-width: 600px) {
  .p-inquires__form {
    padding: 56px 0 0;
    margin-bottom: 49px;
  }
}

@media print, screen and (min-width: 600px) {
  .p-inquires__formArea {
    display: flex;
    justify-content: space-between;
  }
}

@media print, screen and (min-width: 600px) {
  .p-inquires__formArea:not(:last-child) {
    margin-bottom: 5px;
  }
}

.p-inquires__formOneLine {
  width: 100%;
  margin-bottom: 28px;
}

@media print, screen and (min-width: 600px) {
  .p-inquires__formOneLine {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media print, screen and (min-width: 600px) {
  .p-inquires__formArea.-mb0 .p-inquires__formOneLine {
    margin-bottom: 0;
  }
}

.p-inquires__formOneLineInner {
  display: flex;
  margin-bottom: 11px;
}

@media print, screen and (min-width: 600px) {
  .p-inquires__formOneLineInner {
    display: flex;
    width: 50%;
  }
}

.p-inquires__formArea.-br .p-inquires__formOneLineInner {
  display: block;
}

.p-inquires__textName {
  font-family: source-han-sans-japanese, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: calc(22 / 14);
  letter-spacing: 0.06em;
}

@media print, screen and (min-width: 600px) {
  .p-inquires__textName {
    line-height: calc(22 / 14);
    letter-spacing: 0.12em;
  }
}

.p-inquires__textName span {
  margin-left: 9px;
  font-size: 14px;
  color: #d73d5b;
}

@media print, screen and (min-width: 600px) {
  .p-inquires__textName span {
    margin-left: 5px;
  }
}

.p-inquires__fillInArea {
  position: relative;
  width: 100%;
  height: 47px;
  padding-left: 18px;
  font-size: 14px;
  line-height: calc(22 / 14);
  color: #727272;
  letter-spacing: 0.06em;
  background-color: #fff;
  border: 3px solid #f7d65b;
  border-radius: 0;
  appearance: none;
  font-family: source-han-sans-japanese, sans-serif;
  font-style: normal;
  font-weight: 400;
}

@media print, screen and (min-width: 600px) {
  .p-inquires__fillInArea {
    width: 47.9%;
    height: 50px;
    letter-spacing: 0.12em;
  }
}

.p-inquires__serect {
  position: relative;
  display: inline-block;
  width: 100%;
}

.p-inquires__serect::after {
  position: absolute;
  top: 42%;
  right: 17.5px;
  width: 12px;
  height: 12px;
  margin-top: -4px;
  content: "";
  border: 0;
  border-right: solid 3px #519c60;
  border-bottom: solid 3px #519c60;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media print, screen and (min-width: 600px) {
  .p-inquires__serect::after {
    right: 25.5px;
    width: 10px;
    height: 10px;
    border-right: solid 2px #519c60;
    border-bottom: solid 2px #519c60;
  }
}

@media print, screen and (min-width: 600px) {
  .p-inquires__serect {
    width: 47.9%;
    height: 50px;
  }
}

.p-inquires__serect > .p-inquires__fillInArea {
  padding-left: 12px;
}

@media print, screen and (min-width: 600px) {
  .p-inquires__serect > .p-inquires__fillInArea {
    width: 100%;
    height: 50px;
    padding-left: 17px;
  }
}

.p-inquires__fillInTextArea {
  width: 100%;
  height: 273px;
  padding: 18px;
  border: 3px solid #f7d65b;
}

@media print, screen and (min-width: 600px) {
  .p-inquires__fillInTextArea {
    width: 47.9%;
    height: 361px;
  }
}

.p-inquires__errorMessage {
  font-family: source-han-sans-japanese, sans-serif;
  font-style: normal;
  font-weight: 700;
  margin-left: 5px;
  font-size: 14px;
  color: #d73d5b;
}

.p-inquires__formArea.-br .p-inquires__errorMessage {
  margin-left: 0;
}

.p-inquires textarea {
  resize: none;
}

@media print, screen and (min-width: 980px) {
  .p-inquires.-confirm {
    margin-bottom: 125px;
  }
}

.p-inquires.-complete {
  margin-top: -17px;
  margin-bottom: 123px;
}

@media print, screen and (min-width: 600px) {
  .p-inquires.-complete {
    margin-top: 30px;
  }
}

@media print, screen and (min-width: 980px) {
  .p-inquires.-complete {
    margin-top: 83px;
    margin-bottom: 131px;
  }
}

@media print, screen and (min-width: 980px) {
  .p-newsDetail {
    width: 100%;
    height: auto;
  }
}

.p-newsDetail__inner {
  margin: 0 auto 115px;
}

@media print, screen and (min-width: 980px) {
  .p-newsDetail__inner {
    width: 100%;
    max-width: 1440px;
    height: auto;
    padding: 0 20px;
    padding: 0;
    margin-bottom: 120px;
  }
}

.p-newsDetail__article {
  margin-bottom: 37px;
  border-bottom: 1px solid #f6f5f3;
}

@media print, screen and (min-width: 600px) {
  .p-newsDetail__article {
    width: 95%;
    margin: 0 auto;
    margin-bottom: 63px;
  }
}

@media print, screen and (min-width: 980px) {
  .p-newsDetail__article {
    max-width: 878px;
    height: auto;
    margin: 0 auto;
    margin-bottom: 64px;
  }
}

.p-newsDetail__articleTitle {
  display: block;
  width: 100%;
  height: auto;
  padding: 36px 26px;
  margin-top: 36px;
  text-align: center;
  background-color: #f6f5f3;
  border-radius: 20px;
}

@media print, screen and (min-width: 600px) {
  .p-newsDetail__articleTitle {
    padding: 40px 26px;
    margin-top: 30px;
  }
}

.p-newsDetail__articleHeading {
  margin-top: 2px;
  font-size: 18px;
  line-height: calc(54 / 36);
  color: #444;
  letter-spacing: 0.12em;
  font-family: source-han-sans-japanese, sans-serif;
  font-style: normal;
  font-weight: 700;
}

@media print, screen and (min-width: 980px) {
  .p-newsDetail__articleHeading {
    margin-top: 17px;
    font-size: 22px;
    line-height: calc(32 / 22);
  }
}

.p-newsDetail__articleTime {
  display: inline-block;
  margin-top: 9px;
  font-size: 12px;
  line-height: calc(54 / 24);
  color: #444;
  letter-spacing: 0.12em;
  font-family: source-han-sans-japanese, sans-serif;
  font-style: normal;
  font-weight: 400;
}

@media print, screen and (min-width: 980px) {
  .p-newsDetail__articleTime {
    margin-top: 16px;
    font-size: 13px;
    line-height: calc(26 / 13);
  }
}

.p-newsDetail__contents div {
  margin-top: 45px;
}

@media print, screen and (min-width: 980px) {
  .p-newsDetail__contents div {
    margin-top: 63px;
  }
}

.p-newsDetail__contents div:not(:first-child) {
  margin-top: 25px;
}

@media print, screen and (min-width: 980px) {
  .p-newsDetail__contents div:not(:first-child) {
    margin-top: 31px;
  }
}

.p-newsDetail__contents p {
  font-size: 14px;
  line-height: calc(60 / 32);
  letter-spacing: 0.06em;
  font-family: source-han-sans-japanese, sans-serif;
  font-style: normal;
  font-weight: 400;
  word-wrap: break-word;
}

@media print, screen and (min-width: 980px) {
  .p-newsDetail__contents p {
    font-size: 15px;
    line-height: calc(32 / 15);
  }
}

.p-newsDetail__contents a {
  position: relative;
  display: inline-block;
  padding-bottom: 2px;
  color: #e8a8b4;
  color: #e18b9c;
  text-decoration: none;
}

.p-newsDetail__contents a::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  content: "";
  border-bottom: 1px solid currentcolor;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform-origin: 0 0;
}

.p-newsDetail__contents a:hover::after {
  transform: scaleX(0);
  transform-origin: 100% 0;
}

.p-newsDetail__contents figure {
  display: block;
  width: 100%;
  height: auto;
  margin-top: 48px;
  margin-bottom: 46px;
}

@media print, screen and (min-width: 980px) {
  .p-newsDetail__contents figure {
    margin-top: 74px;
  }
}

.p-newsDetail__contents img {
  display: block;
  width: 100%;
  height: auto;
}

@media print, screen and (min-width: 980px) {
  .p-newsDetail__contents img {
    max-width: 640px;
    margin: 0 auto;
  }
}

@media print, screen and (min-width: 980px) {
  .p-newsList {
    width: 100%;
    height: auto;
  }
}

.p-newsList__inner {
  padding: 0;
  margin: 0 auto;
}

@media print, screen and (min-width: 980px) {
  .p-newsList__inner {
    width: 100%;
    max-width: 1440px;
    height: auto;
  }
}

.p-newsList__articleList {
  margin: 0 auto;
  margin-bottom: 52px;
}

@media print, screen and (min-width: 600px) {
  .p-newsList__articleList {
    width: 95%;
  }
}

@media print, screen and (min-width: 980px) {
  .p-newsList__articleList {
    max-width: 878px;
    height: auto;
    margin-bottom: 65px;
  }
}

.p-newsList__article {
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 37px;
  background-color: #f6f5f3;
  border-radius: 20px;
  transition: all 0.3s ease 0s;
}

@media print, screen and (min-width: 600px) {
  .p-newsList__article {
    margin-top: 27px;
  }
}

@media print, screen and (min-width: 1280px) {
  .p-newsList__article {
    min-height: 172px;
  }
  .p-newsList__article:hover {
    background-color: #f7d65b;
    transition: all 0.3s ease 0s;
  }
}

@media print, screen and (max-width: 979px) {
  .p-newsList__article {
    border-radius: 10px;
  }
}

@media print, screen and (min-width: 1280px) {
  .p-newsList__article:hover {
    transition: all 0.3s ease 0s;
    transform: translateX(1.5em);
  }
}

.p-newsList__article:hover .p-newsList__articleHeading {
  color: #808080;
  transition: transform 0.3s;
}

@media print, screen and (min-width: 1280px) {
  .p-newsList__article:hover .p-newsList__arrowLine::before {
    width: 130px;
    background-color: #808080;
    transition: all 0.3s ease 0s;
  }
}

.p-newsList__article:hover .p-newsList__arrowLine::after {
  border-top: 2px solid #808080;
  transition: all 0.3s ease 0s;
}

.p-newsList__articleLink {
  display: block;
  width: 100%;
  height: 100%;
  padding: 39px 20px;
  text-decoration: none;
  border-radius: inherit;
}

@media print, screen and (min-width: 980px) {
  .p-newsList__articleLink {
    padding: 57px;
    cursor: pointer;
  }
}

[data-whatintent="keyboard"] .p-newsList__articleLink:focus {
  outline: none;
  box-shadow: 0 0 0 3px currentColor;
}

.p-newsList__articleHeading {
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  line-height: calc(26 / 17);
  color: #444;
  letter-spacing: 0.12em;
  font-family: source-han-sans-japanese, sans-serif;
  font-style: normal;
  font-weight: 700;
}

@media print, screen and (min-width: 980px) {
  .p-newsList__articleHeading {
    font-size: 18px;
    line-height: calc(26 / 18);
  }
}

.p-newsList__articleTime {
  display: block;
  margin-top: 10px;
  font-size: 12px;
  line-height: calc(26 / 13);
  color: #808080;
  letter-spacing: 0.12em;
  font-family: source-han-sans-japanese, sans-serif;
  font-style: normal;
  font-weight: 400;
}

@media print, screen and (min-width: 980px) {
  .p-newsList__articleTime {
    font-size: 13px;
  }
}

.p-newsList__arrow {
  position: relative;
  display: block;
  width: auto;
  height: auto;
}

.p-newsList__arrowLine {
  position: relative;
  display: block;
}

.p-newsList__arrowLine::before {
  position: absolute;
  top: -6px;
  right: 5px;
  display: block;
  width: 38px;
  height: 1px;
  content: "";
  background: #444;
  transition: all 0.3s ease 0s;
}

.p-newsList__arrowLine::after {
  position: absolute;
  top: -9px;
  right: 5px;
  display: block;
  width: 8px;
  height: 7px;
  content: "";
  border-top: 1px solid #444;
  transform: rotate(45deg);
}

@media print, screen and (min-width: 980px) {
  .p-newsList__arrowLine::before {
    top: 21px;
    right: -18px;
    width: 80px;
    height: 2px;
  }
  .p-newsList__arrowLine::after {
    top: 15px;
    right: -17px;
    width: 14px;
    height: 7px;
    border-top: 2px solid #444;
  }
}

/*
@include sp{}
@include tb{}
@include pc{}
@include touch{}
*/
.p-note {
  max-width: 1040px;
  margin-right: auto;
  margin-left: auto;
}

@media print, screen and (min-width: 980px) {
  .p-note {
    padding: 0 80px 168px;
  }
}

@media print, screen and (max-width: 979px) {
  .p-note {
    padding: 0 20px 125px;
  }
}

@media print, screen and (min-width: 980px) {
  .p-note.-workshop {
    padding-top: 100px;
  }
}

@media print, screen and (max-width: 979px) {
  .p-note.-workshop {
    padding-top: 80px;
  }
}

.p-note__list {
  font-size: 15px;
  font-size: 0.9375rem;
  padding-left: 1.2em;
  margin-top: 34px;
  line-height: 1.73333;
  list-style-image: url(../img/common/icon_list.svg);
}

.p-note__item {
  margin-top: 5px;
}

/*
@include sp{}
@include tb{}
@include pc{}
@include touch{}
*/
.p-pageHeader {
  padding: 30px 20px 80px;
}

@media print, screen and (min-width: 980px) {
  .p-pageHeader {
    padding: 48px 80px 80px;
  }
}

.p-pageHeader__inner {
  position: relative;
  max-width: 878px;
  height: auto;
  margin: 0 auto;
}

.p-pageHeader.-english .p-pageHeader__inner {
  max-width: 1200px;
}

@media print, screen and (min-width: 600px) {
  .p-pageHeader.-faq .p-pageHeader__inner {
    padding-left: 20px;
  }
}

@media print, screen and (min-width: 980px) {
  .p-pageHeader.-faq .p-pageHeader__inner {
    padding-left: 0;
  }
}

@media print, screen and (min-width: 600px) {
  .p-pageHeader.-privacy .p-pageHeader__inner {
    margin-left: 0;
  }
}

@media print, screen and (min-width: 980px) {
  .p-pageHeader.-privacy .p-pageHeader__inner {
    margin: 0 auto;
  }
}

.p-pageHeader.-terms .p-pageHeader__inner {
  max-width: 1200px;
}

@media print, screen and (min-width: 600px) {
  .p-pageHeader.-terms .p-pageHeader__inner {
    margin-left: 0;
  }
}

@media print, screen and (min-width: 980px) {
  .p-pageHeader.-terms .p-pageHeader__inner {
    max-width: 878px;
    margin: 0 auto;
  }
}

@media print, screen and (min-width: 1280px) {
  .p-pageHeader.-terms .p-pageHeader__inner {
    padding: 0;
  }
}

.p-pageHeader__mainTitle {
  padding-bottom: 30px;
  font-size: 32px;
  line-height: 1.3125;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  font-family: zeitung, sans-serif;
  font-style: normal;
  font-weight: 700;
}

@media print, screen and (min-width: 980px) {
  .p-pageHeader__mainTitle {
    font-size: 42px;
  }
}

@media print, screen and (min-width: 980px) {
  .p-pageHeader.-faq .p-pageHeader__mainTitle {
    padding-bottom: 27px !important;
  }
}

.p-pageHeader.-privacy .p-pageHeader__mainTitle {
  font-family: source-han-sans-japanese, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: calc(60 / 48);
  letter-spacing: 0.12em;
}

@media print, screen and (min-width: 980px) {
  .p-pageHeader.-privacy .p-pageHeader__mainTitle {
    font-size: 32px;
    line-height: calc(26 / 32);
  }
}

.p-pageHeader.-terms .p-pageHeader__mainTitle {
  font-family: source-han-sans-japanese, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: calc(26 / 48);
  letter-spacing: 0.12em;
}

@media print, screen and (min-width: 980px) {
  .p-pageHeader.-terms .p-pageHeader__mainTitle {
    font-size: 32px;
    line-height: calc(26 / 32);
  }
}

.p-pageHeader.-english .p-pageHeader__mainTitle {
  text-transform: none;
}

.p-pageHeader.-english .p-pageHeader__mainTitle .-sp {
  display: block;
}

@media print, screen and (min-width: 980px) {
  .p-pageHeader.-english .p-pageHeader__mainTitle .-sp {
    display: none;
  }
}

.p-pageHeader__mainTitle .normalcase {
  text-transform: none;
}

.p-pageHeader__subTitle {
  display: block;
  margin-top: -27px;
  font-size: 16px;
  line-height: calc(51.7 / 32);
  color: #808080;
  text-transform: uppercase;
  font-family: source-han-sans-japanese, sans-serif;
  font-style: normal;
  font-weight: 700;
}

@media print, screen and (min-width: 600px) {
  .p-pageHeader__subTitle {
    margin-top: -26px;
    line-height: calc(32 / 16);
  }
}

.p-pageHeader__kv {
  position: relative;
}

.p-pageHeader__logo {
  display: block;
  width: 210px;
  margin: 0 auto;
  margin-top: 34px;
}

@media print, screen and (min-width: 980px) {
  .p-pageHeader__logo {
    width: 320px;
    margin-top: 32px;
  }
}

.p-pageHeader__logo img {
  width: 100%;
  height: auto;
}

.p-pageHeader__lead {
  padding-top: 90px;
  margin: 0 auto;
}

@media print, screen and (min-width: 980px) {
  .p-pageHeader__lead {
    max-width: 840px;
    padding-top: 80px;
    padding-top: 110px;
  }
}

.p-pageHeader__leadTitle {
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1.15385;
  text-align: center;
  letter-spacing: 0.12em;
  font-family: source-han-sans-japanese, sans-serif;
  font-style: normal;
  font-weight: 500;
}

@media print, screen and (min-width: 980px) {
  .p-pageHeader__leadTitle {
    line-height: 1.625;
    text-align: left;
    font-size: 16px;
    font-size: 1rem;
  }
}

.p-pageHeader__leadText {
  margin-top: 12px;
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 1.76923;
  letter-spacing: 0.12em;
}

@media print, screen and (min-width: 980px) {
  .p-pageHeader__leadText {
    line-height: 2;
  }
}

.p-pageHeader.-cafe {
  padding-bottom: 140px;
  background: #f6f5f3;
}

@media print, screen and (max-width: 599px) {
  .p-pageHeader.-cafe {
    padding-bottom: 120px;
  }
}

@media print, screen and (min-width: 980px) {
  .p-pageHeader.-cafe {
    padding: 48px 80px 80px;
  }
}

.p-pageHeader.-cafe .p-pageHeader__lead {
  padding-top: 32px;
}

@media print, screen and (min-width: 980px) {
  .p-pageHeader.-cafe .p-pageHeader__lead {
    max-width: 840px;
    padding-top: 22px;
  }
}

.p-pageHeader.-store .p-pageHeader__lead {
  padding-top: 32px;
}

@media print, screen and (min-width: 980px) {
  .p-pageHeader.-store .p-pageHeader__lead {
    max-width: 840px;
    padding-top: 22px;
  }
}

.p-pageHeader.-inquires {
  padding-top: 31px;
  padding-right: 19px;
  padding-left: 19px;
  margin-bottom: -95px;
}

@media print, screen and (min-width: 980px) {
  .p-pageHeader.-inquires {
    padding-right: 80px;
    padding-left: 80px;
  }
}

@media print, screen and (min-width: 1280px) {
  .p-pageHeader.-inquires {
    padding-top: 58px;
    margin-bottom: -80px;
  }
}

.p-pageHeader.-news {
  margin-bottom: -79px;
}

@media print, screen and (min-width: 600px) {
  .p-pageHeader.-news {
    padding-right: 44px;
    padding-left: 44px;
    margin-bottom: -78px;
  }
}

.p-pageHeader.-news .p-pageHeader__mainTitle {
  margin-bottom: -27px;
}

@media print, screen and (min-width: 600px) {
  .p-pageHeader.-news .p-pageHeader__mainTitle {
    margin-top: -2px;
    margin-bottom: 0;
  }
}

@media print, screen and (min-width: 980px) {
  .p-pageHeader.-news .p-pageHeader__mainTitle {
    margin-left: 0;
  }
}

@media print, screen and (min-width: 1280px) {
  .p-pageHeader.-news .p-pageHeader__mainTitle {
    margin-left: 37px;
  }
}

.p-pageHeader.-newsDetail {
  margin-bottom: -81px;
}

@media print, screen and (min-width: 600px) {
  .p-pageHeader.-newsDetail {
    padding-right: 44px;
    padding-left: 44px;
  }
}

.p-pageHeader.-newsDetail .p-pageHeader__mainTitle {
  margin-bottom: -27px;
}

@media print, screen and (min-width: 600px) {
  .p-pageHeader.-newsDetail .p-pageHeader__mainTitle {
    margin-top: -2px;
    margin-bottom: 0;
  }
}

@media print, screen and (min-width: 1280px) {
  .p-pageHeader.-newsDetail .p-pageHeader__mainTitle {
    margin-left: 37px;
  }
}

.p-pageHeader.-ticket {
  padding-right: 0;
  padding-bottom: 33px;
  padding-left: 0;
}

@media print, screen and (min-width: 600px) {
  .p-pageHeader.-ticket {
    padding-right: 0;
    padding-bottom: 29px;
    padding-left: 0;
  }
}

.p-pageHeader.-faq {
  padding-right: 20px;
  padding-right: 20px;
  padding-bottom: 9px;
}

@media print, screen and (min-width: 600px) {
  .p-pageHeader.-faq {
    padding-top: 44px;
    padding-right: 20px;
    padding-right: 20px;
    padding-bottom: 0;
  }
}

@media print, screen and (min-width: 980px) {
  .p-pageHeader.-faq {
    padding-top: 44px;
    padding-right: 80px;
    padding-left: 80px;
  }
}

@media print, screen and (min-width: 1280px) {
  .p-pageHeader.-faq {
    padding-right: 80px;
    padding-left: 80px;
  }
}

.p-pageHeader.-privacy {
  padding-top: 39px;
  padding-bottom: 2px;
}

@media print, screen and (min-width: 600px) {
  .p-pageHeader.-privacy {
    padding-top: 77px;
    padding-bottom: 10px;
  }
}

@media print, screen and (min-width: 980px) {
  .p-pageHeader.-privacy {
    padding-right: 80px;
    padding-left: 80px;
  }
}

@media print, screen and (min-width: 1280px) {
  .p-pageHeader.-privacy {
    padding-right: 0;
    padding-left: 0;
  }
}

.p-pageHeader.-terms {
  max-width: 1200px;
  padding-top: 46px;
  padding-bottom: 8px;
  margin: 0 auto;
}

@media print, screen and (min-width: 600px) {
  .p-pageHeader.-terms {
    padding-top: 76px;
    padding-bottom: 10px;
  }
}

@media print, screen and (min-width: 980px) {
  .p-pageHeader.-terms {
    padding-right: 80px;
    padding-left: 80px;
  }
}

@media print, screen and (min-width: 1280px) {
  .p-pageHeader.-terms {
    padding-right: 0;
    padding-left: 0;
  }
}

@media print, screen and (min-width: 600px) {
  .p-pageHeader.-news, .p-pageHeader.-newsDetail {
    max-width: 1440px;
    padding-bottom: 0;
    margin: 0 auto;
  }
}

@media print, screen and (min-width: 980px) {
  .p-pageHeader.-news, .p-pageHeader.-newsDetail {
    padding-left: 100px;
  }
}

@media print, screen and (min-width: 1280px) {
  .p-pageHeader.-news, .p-pageHeader.-newsDetail {
    padding-left: 44px;
  }
}

.p-pageHeader.-english {
  padding-bottom: 0;
}

@media print, screen and (min-width: 600px) {
  .p-pageHeader.-english .p-pageHeader__img img {
    width: 100%;
    height: auto;
  }
}

.p-pageHeader.-workshop .p-pageHeader__lead {
  padding-top: 32px;
}

@media print, screen and (min-width: 980px) {
  .p-pageHeader.-workshop .p-pageHeader__lead {
    max-width: 840px;
    padding-top: 22px;
  }
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

.p-pageHeader__kv.flickity-enabled {
  position: relative;
}

.p-pageHeader__kv.flickity-enabled:focus {
  outline: none;
}

.p-pageHeader__kv.flickity-enabled .flickity-slider {
  transform: translateX(0) !important;
}

.p-pageHeader__kv.flickity-enabled .flickity-page-dots {
  bottom: 16px;
}

.p-pageHeader__kv.flickity-enabled .p-pageHeader__img {
  left: 0 !important;
  transition: opacity .4s;
}

.p-pageHeader__kv.flickity-enabled .p-pageHeader__img.is-selected {
  z-index: 1;
}

.p-pageHeader__kv.flickity-enabled .p-pageHeader__img:not(.is-selected) {
  opacity: 0;
  transition-delay: .2s;
}

.p-pageHeader__kv.flickity-enabled .p-pageHeader__img,
.p-pageHeader__kv.flickity-enabled .p-pageHeader__img img {
  width: 100%;
}

.p-privacy {
  margin: 0 auto;
  margin-bottom: 115px;
}

@media print, screen and (min-width: 980px) {
  .p-privacy {
    width: 100%;
    max-width: 1440px;
    height: auto;
    padding: 0;
    margin-bottom: 206px;
  }
}

.p-shopMap {
  margin: 80px 0 120px;
}

@media print, screen and (min-width: 980px) {
  .p-shopMap .c-sectionInner {
    margin-top: 48px;
  }
}

.p-shopMap__title {
  font-size: 22px;
  font-family: zeitung, sans-serif;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}

@media print, screen and (max-width: 979px) {
  .p-shopMap__title {
    font-size: 18px;
  }
}

.p-shopMap__app {
  position: relative;
}

@media print, screen and (max-width: 979px) {
  .p-shopMap__app {
    margin-top: 80px;
  }
}

@media print, screen and (min-width: 980px) {
  .p-shopMap__app {
    max-width: 1200px;
    height: 860px;
  }
}

@media (min-width: 960px) and (max-width: 1440px) {
  .p-shopMap__app {
    height: calc(860vw / 14.4);
  }
}

/* マップ */
.p-shopMap__map {
  position: relative;
  width: 100%;
}

.p-shopMap__map .img {
  width: 100%;
  height: auto;
  fill: none;
  stroke: #7d7d7d;
  stroke-width: 12;
}

.p-shopMap__map .nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.p-shopMap__map .nav_item {
  cursor: default;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  overflow: hidden;
  height: 1.2em;
  font-size: 22px;
  line-height: 1.2;
  font-weight: 700;
  letter-spacing: 0;
  text-transform: uppercase;
}

.p-shopMap__map .nav_item .ja {
  order: -1;
  color: #519c60;
}

.p-shopMap__map .nav_item .ja,
.p-shopMap__map .nav_item .en {
  transition: transform 0.1s cubic-bezier(0.3, 1, 0.7, 1);
}

.p-shopMap__map .nav_item .en {
  font-family: zeitung, sans-serif;
  font-style: normal;
  font-weight: 700;
}

.p-shopMap__map .nav_item:not(.is-selected) .ja,
.p-shopMap__map .nav_item:not(.is-selected) .en {
  transform: translateY(-100%);
  transition-duration: .3s;
}

.p-shopMap__map .nav_item.-i1 {
  top: 20%;
  left: 4%;
}

.p-shopMap__map .nav_item.-i2 {
  top: 6%;
  left: 25%;
}

.p-shopMap__map .nav_item.-i3 {
  top: 47%;
  left: 53.4%;
}

.p-shopMap__map .nav_item.-i4 {
  top: 6%;
  right: 4%;
}

.p-shopMap__map .nav_item.-i5 {
  top: 30%;
  right: 4%;
}

.p-shopMap__map .nav_item.-i6 {
  bottom: 16%;
  right: 10%;
}

.p-shopMap__map .nav_item.-i7 {
  bottom: 6%;
  left: 51%;
}

.p-shopMap__map .nav_item.-i8 {
  bottom: 6%;
  left: 30%;
}

.p-shopMap__map .nav_item.-i9 {
  bottom: 22%;
  left: 22%;
}

.p-shopMap__map .nav_item.-i10 {
  top: 54%;
  left: 22%;
}

@media print, screen and (max-width: 979px) {
  .p-shopMap__map .nav_item {
    font-size: calc(11vw / 3.75);
  }
  .p-shopMap__map .nav_item.-i3 {
    left: 53%;
  }
}

@media print, screen and (min-width: 980px) {
  .p-shopMap__map {
    position: absolute;
    top: calc(50% - 190px);
    left: calc(48% - 365px);
    width: 730px;
    height: 380px;
  }
}

@media (min-width: 960px) and (max-width: 1440px) {
  .p-shopMap__map {
    top: calc(50% - 190vw / 14.4);
    left: calc(48% - 365vw / 14.4);
    width: calc(730vw / 14.4);
    height: calc(380vw / 14.4);
  }
  .p-shopMap__map .nav_item {
    font-size: calc(22vw / 14.4);
  }
}

/* サムネイル */
.p-shopMap__thumb .flickity-viewport {
  position: relative;
  height: 100%;
}

.p-shopMap__thumb .flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

.p-shopMap__thumb .thumb_item {
  width: 200px;
  border-radius: 200px;
}

.p-shopMap__thumb .thumb_item::after {
  content: "";
  position: absolute;
  inset: -4px;
  border: 8px solid #519c60;
  border-radius: inherit;
  transition: opacity 0.1s, transform 0.1s cubic-bezier(0.3, 1, 0.7, 1);
}

.p-shopMap__thumb .thumb_item:not(.is-selected)::after {
  opacity: 0;
  transform: scale(1.05);
  transition-duration: .3s;
}

.p-shopMap__thumb .thumb_item img {
  width: 100%;
  border-radius: inherit;
}

.p-shopMap__thumb .tree {
  position: absolute;
}

.p-shopMap__thumb .charlie {
  position: absolute;
}

@media print, screen and (max-width: 979px) {
  .p-shopMap__thumb {
    margin-top: calc(32vw / 3.75);
  }
  .p-shopMap__thumb .thumb_item {
    width: 50vw;
    border-radius: 50vw;
    transform: scale(1.1);
    transition: transform 0.3s cubic-bezier(0.3, 1, 0.7, 1);
  }
  .p-shopMap__thumb .thumb_item::after {
    inset: calc(-4vw / 3.75);
    border-width: calc(8vw / 3.75);
  }
  .p-shopMap__thumb .thumb_item:not(.is-selected) {
    transform: scale(0.8);
  }
  .p-shopMap__thumb .tree {
    width: 104px;
    bottom: calc(100% + 11px);
    right: 56px;
  }
  .p-shopMap__thumb .charlie {
    width: 56px;
    bottom: calc(100% + 12px);
    right: 0;
  }
}

@media print, screen and (min-width: 980px) {
  .p-shopMap__thumb .thumb_item {
    position: absolute;
  }
  .p-shopMap__thumb .thumb_item.-i1 {
    top: 0;
    left: 0;
  }
  .p-shopMap__thumb .thumb_item.-i2 {
    top: 0;
    left: 20%;
  }
  .p-shopMap__thumb .thumb_item.-i3 {
    top: 0;
    left: 40%;
  }
  .p-shopMap__thumb .thumb_item.-i4 {
    top: 0;
    left: 60%;
  }
  .p-shopMap__thumb .thumb_item.-i5 {
    top: 50%;
    right: 2%;
  }
  .p-shopMap__thumb .thumb_item.-i6 {
    bottom: 0;
    left: 60%;
  }
  .p-shopMap__thumb .thumb_item.-i7 {
    bottom: 0;
    left: 40%;
  }
  .p-shopMap__thumb .thumb_item.-i8 {
    bottom: 0;
    left: 20%;
  }
  .p-shopMap__thumb .thumb_item.-i9 {
    bottom: 0;
    left: 0;
  }
  .p-shopMap__thumb .thumb_item.-i10 {
    top: 50%;
    left: 0;
  }
  .p-shopMap__thumb .tree {
    top: 10%;
    right: 3%;
  }
  .p-shopMap__thumb .charlie {
    top: 34%;
    right: -3%;
  }
}

@media (min-width: 960px) and (max-width: 1440px) {
  .p-shopMap__thumb .thumb_item {
    width: calc(200vw / 14.4);
  }
  .p-shopMap__thumb .tree {
    width: calc(209vw / 14.4);
  }
  .p-shopMap__thumb .charlie {
    width: calc(114vw / 14.4);
  }
}

/*
@include sp{}
@include tb{}
@include pc{}
@include touch{}
*/
.p-store__tag {
  font-family: zeitung, sans-serif;
  font-style: normal;
  font-weight: 700;
}

.p-store__tag.-new {
  color: #f74608;
}

.p-pageHeader.-store .p-pageHeader__link {
  max-width: 1200px;
  margin: 32px auto 0;
}

.p-pageHeader.-store .p-pageHeader__link .c-button {
  position: relative;
  max-width: 320px;
}

@media print, screen and (min-width: 980px) {
  .p-pageHeader.-store .p-pageHeader__link {
    text-align: right;
  }
}

@media print, screen and (max-width: 979px) {
  .p-pageHeader.-store .p-pageHeader__link {
    margin-top: 64px;
    text-align: center;
  }
  .p-pageHeader.-store .p-pageHeader__link .c-button {
    max-width: 264px;
    padding: 1.25em 1em;
  }
}

.p-pageHeader.-store .p-pageHeader__link .c-button {
  padding: 0;
  background: none;
}

.p-pageHeader.-store .p-pageHeader__link .c-button .label {
  display: block;
  z-index: 1;
  position: relative;
  padding: 1em;
  border-radius: 3em;
  background: #e18b9c;
}

.onlinestore-woodstock {
  pointer-events: none;
  position: absolute;
  bottom: calc(100% + 24px);
  right: 24px;
  width: 60px;
  height: 60px;
}

@media print, screen and (max-width: 979px) {
  .onlinestore-woodstock {
    bottom: calc(100% + 6px);
  }
}

.onlinestore-woodstock .os-woodstock {
  width: 100%;
  height: 100%;
}

.onlinestore-woodstock .os-woodstock .ws {
  z-index: -1;
  position: absolute;
  bottom: -36px;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../img/store/ws_sprites.png) 0 0/300px 600px no-repeat;
}

.onlinestore-woodstock .os-orbit {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 157px;
  height: 80px;
}

.onlinestore-woodstock .os-orbit.-line {
  bottom: -16px;
  left: 24px;
}

.c-button.is-anim .os-woodstock .ws {
  top: -36px;
}

.c-button.is-anim .onlinestore-woodstock {
  opacity: 0;
  transition: opacity .4s 1.6s;
}

.os-woodstock .ws[data-f="1"] {
  background-position: 0 0%;
}

.os-woodstock .ws[data-f="2"] {
  background-position: 0 11.1111%;
}

.os-woodstock .ws[data-f="3"] {
  background-position: 0 22.2222%;
}

.os-woodstock .ws[data-f="4"] {
  background-position: 0 33.3333%;
}

.os-woodstock .ws[data-f="5"] {
  background-position: 0 44.4444%;
}

.os-woodstock .ws[data-f="6"] {
  background-position: 0 55.5555%;
}

.os-woodstock .ws[data-f="7"] {
  background-position: 0 66.6666%;
}

.os-woodstock .ws[data-f="8"] {
  background-position: 0 77.7777%;
}

.os-woodstock .ws[data-f="9"] {
  background-position: 0 88.8888%;
}

.os-woodstock .ws[data-f="10"] {
  background-position: 0 99.9999%;
}

.os-woodstock .ws[data-f="11"] {
  background-position: 25% 0%;
}

.os-woodstock .ws[data-f="12"] {
  background-position: 25% 11.1111%;
}

.os-woodstock .ws[data-f="13"] {
  background-position: 25% 22.2222%;
}

.os-woodstock .ws[data-f="14"] {
  background-position: 25% 33.3333%;
}

.os-woodstock .ws[data-f="15"] {
  background-position: 25% 44.4444%;
}

.os-woodstock .ws[data-f="16"] {
  background-position: 25% 55.5555%;
}

.os-woodstock .ws[data-f="17"] {
  background-position: 25% 66.6666%;
}

.os-woodstock .ws[data-f="18"] {
  background-position: 25% 77.7777%;
}

.os-woodstock .ws[data-f="19"] {
  background-position: 25% 88.8888%;
}

.os-woodstock .ws[data-f="20"] {
  background-position: 25% 99.9999%;
}

.os-woodstock .ws[data-f="21"] {
  background-position: 50% 0%;
}

.os-woodstock .ws[data-f="22"] {
  background-position: 50% 11.1111%;
}

.os-woodstock .ws[data-f="23"] {
  background-position: 50% 22.2222%;
}

.os-woodstock .ws[data-f="24"] {
  background-position: 50% 33.3333%;
}

.os-woodstock .ws[data-f="25"] {
  background-position: 50% 44.4444%;
}

.os-woodstock .ws[data-f="26"] {
  background-position: 50% 55.5555%;
}

.os-woodstock .ws[data-f="27"] {
  background-position: 50% 66.6666%;
}

.os-woodstock .ws[data-f="28"] {
  background-position: 50% 77.7777%;
}

.os-woodstock .ws[data-f="29"] {
  background-position: 50% 88.8888%;
}

.os-woodstock .ws[data-f="30"] {
  background-position: 50% 99.9999%;
}

.os-woodstock .ws[data-f="31"] {
  background-position: 75% 0%;
}

.os-woodstock .ws[data-f="32"] {
  background-position: 75% 11.1111%;
}

.os-woodstock .ws[data-f="33"] {
  background-position: 75% 22.2222%;
}

.os-woodstock .ws[data-f="34"] {
  background-position: 75% 33.3333%;
}

.os-woodstock .ws[data-f="35"] {
  background-position: 75% 44.4444%;
}

.os-woodstock .ws[data-f="36"] {
  background-position: 75% 55.5555%;
}

.os-woodstock .ws[data-f="37"] {
  background-position: 75% 66.6666%;
}

.os-woodstock .ws[data-f="38"] {
  background-position: 75% 77.7777%;
}

.os-woodstock .ws[data-f="39"] {
  background-position: 75% 88.8888%;
}

.os-woodstock .ws[data-f="40"] {
  background-position: 75% 99.9999%;
}

.os-woodstock .ws[data-f="41"] {
  background-position: 100% 0%;
}

.os-woodstock .ws[data-f="42"] {
  background-position: 100% 11.1111%;
}

.os-woodstock .ws[data-f="43"] {
  background-position: 100% 22.2222%;
}

.os-woodstock .ws[data-f="44"] {
  background-position: 100% 33.3333%;
}

.os-woodstock .ws[data-f="45"] {
  background-position: 100% 44.4444%;
}

.os-woodstock .ws[data-f="46"] {
  background-position: 100% 55.5555%;
}

.os-woodstock .ws[data-f="47"] {
  background-position: 100% 66.6666%;
}

.os-woodstock .ws[data-f="48"] {
  background-position: 100% 77.7777%;
}

.os-woodstock .ws[data-f="49"] {
  background-position: 100% 88.8888%;
}

.os-woodstock .ws[data-f="50"] {
  background-position: 100% 99.9999%;
}

.p-terms {
  margin: 0 auto;
  margin-bottom: 129px;
}

@media print, screen and (min-width: 980px) {
  .p-terms {
    width: 100%;
    max-width: 1440px;
    height: auto;
    padding: 0;
    margin-bottom: 110px;
  }
}

/*
@include sp{}
@include tb{}
@include pc{}
@include touch{}
*/
.p-ticket {
  position: relative;
}

@media print, screen and (min-width: 980px) {
  .p-ticket {
    position: static;
    max-width: 1200px;
    height: auto;
    margin: 0 auto;
  }
}

.p-ticket .c-pageTitle {
  margin: 0 0 2.1em;
}

.p-ticket__note {
  max-width: 880px;
  height: auto;
  margin: 0 auto;
  margin-top: 62px;
  line-height: 1.76923;
  letter-spacing: 0.03em;
  font-size: 13px;
  font-size: 0.8125rem;
}

@media print, screen and (min-width: 980px) {
  .p-ticket__note {
    margin-top: 32px;
    line-height: 2;
    letter-spacing: 0.12em;
  }
}

@media print, screen and (max-width: 979px) {
  .p-ticket__section.-info .p-ticket__note {
    padding: 0 20px;
  }
}

.p-ticket__noteItem {
  padding-left: 1.5em;
  text-indent: -1.5em;
}

.p-ticket__section.-fee .c-h2 {
  margin-bottom: 28px;
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-fee .c-h2 {
    margin-bottom: 44px;
  }
}

.p-ticket__section.-fee .c-h2:lang(en) {
  margin-bottom: 28px;
}

@media print, screen and (min-width: 600px) {
  .p-ticket__section.-fee .c-h2:lang(en) {
    margin-bottom: 38px;
    font-size: 22px;
    font-size: 1.375rem;
    line-height: calc(26 / 22);
    letter-spacing: 0.12em;
  }
}

.p-ticket__section.-fee .p-ticket__lead {
  font-size: 14px;
  font-size: 0.875rem;
  margin-bottom: 56px;
  line-height: 1.85714;
  letter-spacing: 0.06em;
}

.p-ticket__section.-fee .p-ticket__lead::before {
  display: block;
  width: 0;
  height: 0;
  margin-top: calc((1 - 1.85714) * 0.5em);
  content: "";
}

@media print, screen and (min-width: 600px) {
  .p-ticket__section.-fee .p-ticket__lead {
    font-size: 15px;
    font-size: 0.9375rem;
    padding-right: 120px;
    margin-bottom: 46px;
    line-height: 1.73333;
    letter-spacing: 0.12em;
  }
  .p-ticket__section.-fee .p-ticket__lead::before {
    display: block;
    width: 0;
    height: 0;
    margin-top: calc((1 - 1.73333) * 0.5em);
    content: "";
  }
}

.p-ticket__section.-fee .p-ticketFee__table {
  width: 100%;
  border: 3px solid #519c60;
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-fee .p-ticketFee__table.-sp {
    display: none;
  }
}

.p-ticket__section.-fee .p-ticketFee__table.-sp caption {
  padding: 15px 8px 10px;
  line-height: 1.3;
  color: #fff;
  letter-spacing: 0.12em;
  background: #519c60;
  font-size: 20px;
  font-size: 1.25rem;
}

.p-ticket__section.-fee .p-ticketFee__table.-sp caption::before {
  display: block;
  width: 0;
  height: 0;
  margin-top: calc((1 - 1.3) * 0.5em);
  content: "";
}

.p-ticket__section.-fee .p-ticketFee__table.-sp caption h3 {
  font-weight: 700;
}

.p-ticket__section.-fee .p-ticketFee__table.-sp caption h3:lang(en) {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: calc(26 / 40);
  letter-spacing: 0.12em;
}

@media print, screen and (min-width: 600px) {
  .p-ticket__section.-fee .p-ticketFee__table.-sp caption h3:lang(en) {
    font-size: 22px;
    font-size: 1.375rem;
    line-height: calc(26 / 22);
  }
}

.p-ticket__section.-fee .p-ticketFee__table.-sp tbody {
  display: block;
  padding: 20px 20px 0;
}

.p-ticket__section.-fee .p-ticketFee__table.-sp tbody:not(:first-of-type) {
  border-top: 3px solid #519c60;
}

.p-ticket__section.-fee .p-ticketFee__table.-sp tbody tr:not(:nth-of-type(2)) td {
  border-top: 3px solid #519c60;
}

.p-ticket__section.-fee .p-ticketFee__table.-sp tr,
.p-ticket__section.-fee .p-ticketFee__table.-sp th,
.p-ticket__section.-fee .p-ticketFee__table.-sp td {
  display: block;
  width: 100%;
}

.p-ticket__section.-fee .p-ticketFee__table.-sp th {
  padding: 8px;
  font-weight: 500;
  color: #fff;
  letter-spacing: 0.12em;
  background: #519c60;
  font-size: 14px;
  font-size: 0.875rem;
}

.p-ticket__section.-fee .p-ticketFee__table.-sp td dl {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: space-around;
  padding: 18px 0;
}

.p-ticket__section.-fee .p-ticketFee__table.-sp td dt {
  flex-basis: 50%;
  width: 50%;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.06em;
  font-size: 14px;
  font-size: 0.875rem;
}

.p-ticket__section.-fee .p-ticketFee__table.-sp td dt.-englishDT {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: calc(32 / 24);
  letter-spacing: 0.06em;
}

@media print, screen and (min-width: 600px) {
  .p-ticket__section.-fee .p-ticketFee__table.-sp td dt.-englishDT {
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: calc(19 / 13);
    letter-spacing: 0.12em;
  }
}

.p-ticket__section.-fee .p-ticketFee__table.-sp td dd {
  flex-basis: 50%;
  width: 50%;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.12em;
  font-size: 20px;
  font-size: 1.25rem;
}

.p-ticket__section.-fee .p-ticketFee__table.-pc {
  display: none;
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-fee .p-ticketFee__table.-pc {
    position: relative;
    display: table;
    width: 100%;
  }
}

.p-ticket__section.-fee .p-ticketFee__table.-pc caption {
  position: absolute;
  top: 22px;
  left: 34px;
  line-height: 1.18182;
  color: #fff;
  letter-spacing: 0.12em;
  font-size: 22px;
  font-size: 1.375rem;
}

.p-ticket__section.-fee .p-ticketFee__table.-pc caption::before {
  display: block;
  width: 0;
  height: 0;
  margin-top: calc((1 - 1.18182) * 0.5em);
  content: "";
}

.p-ticket__section.-fee .p-ticketFee__table.-pc caption h3 {
  font-weight: 500;
}

.p-ticket__section.-fee .p-ticketFee__table.-pc thead th {
  background: #519c60;
}

.p-ticket__section.-fee .p-ticketFee__table.-pc tbody:not(:first-of-type) {
  border-top: 3px solid #519c60;
}

.p-ticket__section.-fee .p-ticketFee__table.-pc tbody th {
  padding: 30px 8px 26px 27px;
}

.p-ticket__section.-fee .p-ticketFee__table.-pc th,
.p-ticket__section.-fee .p-ticketFee__table.-pc td {
  text-align: center;
}

.p-ticket__section.-fee .p-ticketFee__table.-pc th {
  font-size: 16px;
  font-size: 1rem;
  width: 25%;
  padding: 20px 5px 18px;
  font-weight: 500;
  color: #fff;
}

.p-ticket__section.-fee .p-ticketFee__table.-pc th span {
  display: inline-block;
  width: 100%;
  padding: 23px 0 20px;
  line-height: 1.625;
  background: #519c60;
  font-size: 16px;
  font-size: 1rem;
}

.p-ticket__section.-fee .p-ticketFee__table.-pc th span::before {
  display: block;
  width: 0;
  height: 0;
  margin-top: calc((1 - 1.625) * 0.5em);
  content: "";
}

.p-ticket__section.-fee .p-ticketFee__table.-pc th span:lang(en) {
  letter-spacing: 0.12em;
}

.p-ticket__section.-fee .p-ticketFee__table.-pc th:lang(en) {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: calc(32 / 12);
  letter-spacing: 0.06em;
}

@media print, screen and (min-width: 600px) {
  .p-ticket__section.-fee .p-ticketFee__table.-pc th:lang(en) {
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: calc(19 / 13);
    letter-spacing: 0.12em;
  }
}

.p-ticket__section.-fee .p-ticketFee__table.-pc td {
  font-size: 22px;
  font-size: 1.375rem;
  position: relative;
  width: 25%;
  padding: 40px 0;
  font-weight: 700;
  letter-spacing: 0.12em;
}

.p-ticket__section.-fee .p-ticketFee__table.-pc td:not(:first-of-type)::after {
  position: absolute;
  top: 50%;
  left: -1px;
  display: block;
  width: 3px;
  height: 60px;
  content: "";
  background: #519c60;
  transform: translateY(-50%);
}

.p-ticket__section.-fee .p-ticketSchedule {
  margin-top: 40px;
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-fee .p-ticketSchedule {
    margin-top: 25px;
  }
}

.p-ticket__section.-fee .p-ticketSchedule + .p-ticket__note {
  margin-top: 34px;
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-fee .p-ticketSchedule + .p-ticket__note {
    margin-top: 27px;
  }
}

.p-ticket__section.-fee .p-ticketSchedule + .c-h2 {
  margin-top: 70px;
}

.p-ticket__section.-fee .p-ticketSchedule__table {
  width: 100%;
  border: 3px solid #519c60;
}

.p-ticket__section.-fee .p-ticketSchedule__table caption {
  width: 100%;
  padding: 15px 24px 12px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: left;
  letter-spacing: 0.12em;
  vertical-align: middle;
  background: #519c60;
  font-size: 20px;
  font-size: 1.25rem;
}

.p-ticket__section.-fee .p-ticketSchedule__table caption::after {
  display: block;
  clear: both;
  content: "";
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-fee .p-ticketSchedule__table caption {
    font-size: 22px;
    font-size: 1.375rem;
    padding: 24px 30px 18px;
    font-weight: 500;
  }
}

.p-ticket__section.-fee .p-ticketSchedule__table caption span {
  display: inline-block;
  float: right;
  margin-top: 0.2em;
  font-weight: 500;
  font-size: 14px;
  font-size: 0.875rem;
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-fee .p-ticketSchedule__table caption span {
    font-size: 16px;
    font-size: 1rem;
  }
}

.p-ticket__section.-fee .p-ticketSchedule__table tr,
.p-ticket__section.-fee .p-ticketSchedule__table th,
.p-ticket__section.-fee .p-ticketSchedule__table td {
  display: block;
  width: 100%;
}

.p-ticket__section.-fee .p-ticketSchedule__table th {
  padding: 8px;
  font-weight: 500;
  color: #fff;
  letter-spacing: 0.12em;
  background: #519c60;
  font-size: 14px;
  font-size: 0.875rem;
}

.p-ticket__section.-fee .p-ticketSchedule__table td dl {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: space-around;
  padding: 18px 0;
}

.p-ticket__section.-fee .p-ticketSchedule__table td dt {
  flex-basis: 34%;
  width: 34%;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.06em;
  font-size: 14px;
  font-size: 0.875rem;
}

.p-ticket__section.-fee .p-ticketSchedule__table td dt.-englishDT {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: calc(32 / 24);
  letter-spacing: 0.06em;
}

@media print, screen and (min-width: 600px) {
  .p-ticket__section.-fee .p-ticketSchedule__table td dt.-englishDT {
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: calc(19 / 13);
    letter-spacing: 0.12em;
  }
}

.p-ticket__section.-fee .p-ticketSchedule__table td dd {
  position: relative;
  display: flex;
  flex-basis: 66%;
  justify-content: space-around;
  width: 66%;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.12em;
  font-size: 20px;
  font-size: 1.25rem;
}

.p-ticket__section.-fee .p-ticketSchedule__table td time {
  font-size: 20px;
  font-size: 1.25rem;
  font-family: source-han-sans-japanese, sans-serif;
  font-style: normal;
  font-weight: 700;
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-fee .p-ticketSchedule__table td time {
    font-size: 22px;
    font-size: 1.375rem;
  }
}

.p-ticket__section.-fee .p-ticketSchedule__table td time:first-of-type::after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  width: 10px;
  height: 2px;
  vertical-align: middle;
  content: "";
  background: #3a3a3a;
  transform: translate(-50%, -50%);
}

.p-ticket__section.-fee .p-ticketSchedule__table_02 {
  width: 100%;
  table-layout: fixed;
  border: 3px solid #519c60;
}

.p-ticket__section.-fee .p-ticketSchedule__table_02 caption {
  width: 100%;
  padding: 17px 24px 14px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: left;
  letter-spacing: 0.12em;
  vertical-align: middle;
  background: #519c60;
  font-size: 20px;
  font-size: 1.25rem;
}

.p-ticket__section.-fee .p-ticketSchedule__table_02 caption::after {
  display: block;
  clear: both;
  content: "";
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-fee .p-ticketSchedule__table_02 caption {
    font-size: 22px;
    font-size: 1.375rem;
    padding: 24px 30px 18px;
    font-weight: 500;
  }
}

.p-ticket__section.-fee .p-ticketSchedule__table_02 caption span {
  display: inline-block;
  margin-top: 21px;
  font-weight: 500;
  line-height: 1.3;
  font-size: 12px;
  font-size: 0.75rem;
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-fee .p-ticketSchedule__table_02 caption span {
    float: right;
    margin-top: 0.2em;
    font-size: 16px;
    font-size: 1rem;
  }
}

.p-ticket__section.-fee .p-ticketSchedule__table_02 tbody {
  display: block;
  padding: 20px 0;
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-fee .p-ticketSchedule__table_02 tbody {
    padding: 34px 0 23px;
  }
}

.p-ticket__section.-fee .p-ticketSchedule__table_02 tbody tr {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0 10px;
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-fee .p-ticketSchedule__table_02 tbody tr {
    padding: 0 30px;
  }
}

.p-ticket__section.-fee .p-ticketSchedule__table_02 tbody tr td {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc((100% - 15px) / 2);
  min-height: 50px;
  padding: 4px;
  margin: 9px 0;
  font-weight: bold;
  text-align: center;
  border: 2.5px solid #f7d65b;
  border-radius: 30px;
  font-size: 20px;
  font-size: 1.25rem;
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-fee .p-ticketSchedule__table_02 tbody tr td {
    width: calc((100% - 84px) / 4);
    min-height: 60px;
    padding: 7px;
    margin: 14px 0;
    border-width: 4px;
    font-size: 22px;
    font-size: 1.375rem;
  }
}

.p-ticket__section.-fee .p-ticketSchedule__table_02 tbody tr td + td {
  margin-left: 15px;
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-fee .p-ticketSchedule__table_02 tbody tr td + td {
    margin-left: 28px;
  }
}

@media print, screen and (max-width: 979px) {
  .p-ticket__section.-fee .p-ticketSchedule__table_02 tbody tr td:nth-child(odd) {
    margin-left: 0;
  }
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-fee .p-ticketSchedule__table_02 tbody tr td:nth-child(4n + 1) {
    margin-left: 0;
  }
}

.p-ticket__section.-fee .p-ticketSchedule__table_02 tbody tr td._danger {
  color: #d73d5b;
  border-color: #d73d5b;
}

.p-ticket__section.-fee .p-ticketSchedule__table_02 tbody tr td._danger_text {
  min-height: 0;
  padding: 0;
  margin-top: 0;
  margin-left: auto !important;
  color: #d73d5b;
  border: none;
  font-size: 10px;
  font-size: 0.625rem;
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-fee .p-ticketSchedule__table_02 tbody tr td._danger_text {
    font-size: 14px;
    font-size: 0.875rem;
  }
}

.p-ticket__section.-info {
  padding-top: 70px;
  padding-bottom: 160px;
  margin-top: 55px;
  margin-right: calc(((100vw - 100%) / 2) * -1);
  margin-left: calc(((100vw - 100%) / 2) * -1);
  background: #f6f5f3;
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-info {
    padding-top: 90px;
    padding-bottom: 160px;
    margin-top: 70px;
  }
}

.p-ticket__section.-info .p-ticket__inner {
  padding: 0 20px;
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-info .p-ticket__inner {
    padding: 0;
  }
}

.p-ticket__section.-info .p-ticketInfo {
  max-width: 880px;
  margin: auto;
}

.p-ticket__section.-info .p-ticketInfo__table {
  width: 100%;
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-info .p-ticketInfo__table {
    width: 880px;
    margin: 0 auto;
  }
  .p-ticket__section.-info .p-ticketInfo__table th.-englishTh {
    margin-bottom: 20px;
    background-color: #f6f5f3;
  }
}

@media print, screen and (min-width: 980px) and (min-width: 600px) {
  .p-ticket__section.-info .p-ticketInfo__table th.-englishTh {
    font-size: 16px;
    font-size: 1rem;
    line-height: calc(26 / 36);
    letter-spacing: 0.12em;
  }
}

.p-ticket__section.-info .p-ticketInfo__table .p-ticketInfo__icon {
  display: inline-block;
  vertical-align: middle;
}

.p-ticket__section.-info .p-ticketInfo__table .p-ticketInfo__text {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  border: 0;
}

.p-ticket__section.-info .p-ticketInfo__table .-enabled .p-ticketInfo__icon {
  width: 20px;
  height: 20px;
  border: 2px solid #d73d5b;
  border-radius: 50%;
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-info .p-ticketInfo__table .-enabled .p-ticketInfo__icon {
    width: 30px;
    height: 30px;
    border-width: 3px;
  }
}

.p-ticket__section.-info .p-ticketInfo__table .-enabled .-smallText {
  font-size: 12px;
  font-size: 0.75rem;
}

@media print, screen and (min-width: 600px) {
  .p-ticket__section.-info .p-ticketInfo__table .-enabled .-smallText {
    margin-left: 0 !important;
  }
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-info .p-ticketInfo__table .-enabled .-smallText {
    font-size: 13px;
    font-size: 0.8125rem;
    margin-left: 25px !important;
  }
}

.p-ticket__section.-info .p-ticketInfo__table .-disabled .p-ticketInfo__icon {
  position: relative;
  width: 16px;
  height: 16px;
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-info .p-ticketInfo__table .-disabled .p-ticketInfo__icon {
    width: 23px;
    height: 23px;
  }
}

.p-ticket__section.-info .p-ticketInfo__table .-disabled .p-ticketInfo__icon::before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 2px;
  content: "";
  background: #3a3a3a;
  transform: translate(-50%, -50%) rotate(45deg);
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-info .p-ticketInfo__table .-disabled .p-ticketInfo__icon::before {
    width: 30px;
    height: 3px;
  }
}

.p-ticket__section.-info .p-ticketInfo__table .-disabled .p-ticketInfo__icon::after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 2px;
  content: "";
  background: #3a3a3a;
  transform: translate(-50%, -50%) rotate(-45deg);
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-info .p-ticketInfo__table .-disabled .p-ticketInfo__icon::after {
    width: 30px;
    height: 3px;
  }
}

.p-ticket__section.-info .p-ticketInfo__table .-disabled .-smallText {
  font-size: 12px;
  font-size: 0.75rem;
}

@media print, screen and (min-width: 600px) {
  .p-ticket__section.-info .p-ticketInfo__table .-disabled .-smallText {
    margin-left: 0 !important;
  }
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-info .p-ticketInfo__table .-disabled .-smallText {
    font-size: 13px;
    font-size: 0.8125rem;
    margin-left: 25px !important;
  }
}

.p-ticket__section.-info .p-ticketInfo__table .-ended .p-ticketInfo__icon {
  width: 18px;
  height: 2px;
  background: #54b1b4;
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-info .p-ticketInfo__table .-ended .p-ticketInfo__icon {
    width: 26px;
    height: 3px;
  }
}

.p-ticket__section.-info .p-ticketInfo__table .-ended .-smallText {
  font-size: 12px;
  font-size: 0.75rem;
}

@media print, screen and (min-width: 600px) {
  .p-ticket__section.-info .p-ticketInfo__table .-ended .-smallText {
    margin-left: 0 !important;
  }
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-info .p-ticketInfo__table .-ended .-smallText {
    font-size: 13px;
    font-size: 0.8125rem;
    margin-left: 25px !important;
  }
}

.p-ticket__section.-info .p-ticketInfo__table caption {
  font-size: 20px;
  font-size: 1.25rem;
  padding: 0 20px;
  margin-bottom: 40px;
  text-align: left;
  letter-spacing: 0.12em;
}

@media print, screen and (min-width: 600px) {
  .p-ticket__section.-info .p-ticketInfo__table caption {
    box-sizing: border-box;
    float: left;
    width: 40%;
    padding: 0 0 0 20px;
    font-size: 22px;
    font-size: 1.375rem;
  }
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-info .p-ticketInfo__table caption {
    padding: 0;
  }
}

.p-ticket__section.-info .p-ticketInfo__table thead {
  display: block;
  max-width: 450px;
  margin-bottom: 40px;
}

@media print, screen and (min-width: 600px) {
  .p-ticket__section.-info .p-ticketInfo__table thead {
    box-sizing: border-box;
    float: right;
    width: 60%;
    padding-right: 20px;
  }
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-info .p-ticketInfo__table thead {
    padding-right: 0;
  }
}

.p-ticket__section.-info .p-ticketInfo__table thead tr {
  display: block;
}

.p-ticket__section.-info .p-ticketInfo__table thead th {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  text-align: center;
}

@media print, screen and (min-width: 600px) {
  .p-ticket__section.-info .p-ticketInfo__table thead th {
    display: block;
    padding: 0;
    text-align: right;
  }
}

.p-ticket__section.-info .p-ticketInfo__table thead th span {
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 400;
}

@media print, screen and (min-width: 600px) {
  .p-ticket__section.-info .p-ticketInfo__table thead th span {
    display: inline-block;
    margin-right: 5px;
    font-weight: 500;
    font-size: 13px;
    font-size: 0.8125rem;
  }
  .p-ticket__section.-info .p-ticketInfo__table thead th span:not(:first-of-type) {
    margin-left: 25px;
  }
}

.p-ticket__section.-info .p-ticketInfo__table thead th span .p-ticketInfo__icon {
  margin-right: 18px;
}

@media print, screen and (min-width: 600px) {
  .p-ticket__section.-info .p-ticketInfo__table thead th span .p-ticketInfo__icon {
    margin-right: 20px;
    transform: scale(0.83);
  }
}

@media print, screen and (max-width: 340px) {
  .p-ticket__section.-info .p-ticketInfo__table thead th span .p-ticketInfo__icon.-englishMargin {
    margin-right: 5px;
  }
}

@media print, screen and (max-width: 599px) {
  .p-ticket__section.-info .p-ticketInfo__table thead th span .p-ticketInfo__icon.-englishMargin {
    margin-right: 10px;
  }
}

@media print, screen and (min-width: 600px) {
  .p-ticket__section.-info .p-ticketInfo__table thead th span .p-ticketInfo__icon.-englishMargin {
    margin-right: 8px;
  }
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-info .p-ticketInfo__table thead th span .p-ticketInfo__icon.-englishMargin {
    margin-right: -12px;
  }
}

.p-ticket__section.-info .p-ticketInfo__table thead th span .-enabled .p-ticketInfo__icon {
  border-width: 4px;
}

.p-ticket__section.-info .p-ticketInfo__table thead th span.-englishMarginLeft {
  margin-left: 2px !important;
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-info .p-ticketInfo__table thead th span.-englishMarginLeft {
    margin-left: 20px !important;
  }
}

.p-ticket__section.-info .p-ticketInfo__table tbody {
  position: relative;
  padding-bottom: 20px;
  margin: 27px 20px 0;
  background: #fff;
  border-radius: 8px;
}

@media print, screen and (max-width: 599px) {
  .p-ticket__section.-info .p-ticketInfo__table tbody {
    display: block;
  }
}

@media (max-width: 979px) {
  .p-ticket__section.-info .p-ticketInfo__table tbody {
    display: block;
    clear: both;
  }
}

.p-ticket__section.-info .p-ticketInfo__table tbody.-englishLineColor {
  z-index: 4;
  background-color: #f6f5f3;
  border-bottom: 3px solid #fff;
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-info .p-ticketInfo__table tbody.-englishLineColor {
    z-index: 0;
    border-bottom: 5px solid #fff;
  }
}

.p-ticket__section.-info .p-ticketInfo__table tbody.-englishLineColor::after {
  z-index: 3;
}

.p-ticket__section.-info .p-ticketInfo__table tbody:first-of-type {
  margin-top: 0;
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-info .p-ticketInfo__table tbody:first-of-type {
    position: relative;
    z-index: 1;
    display: block;
    width: 66.6%;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }
  .p-ticket__section.-info .p-ticketInfo__table tbody:first-of-type tr,
  .p-ticket__section.-info .p-ticketInfo__table tbody:first-of-type th,
  .p-ticket__section.-info .p-ticketInfo__table tbody:first-of-type td {
    width: 24%;
  }
  .p-ticket__section.-info .p-ticketInfo__table tbody:first-of-type th {
    padding-left: 50%;
  }
  .p-ticket__section.-info .p-ticketInfo__table tbody:first-of-type th.-englishTh {
    border-top-left-radius: 8px;
  }
}

@media print, screen and (min-width: 980px) and (min-width: 600px) {
  .p-ticket__section.-info .p-ticketInfo__table tbody:first-of-type th.-englishTh {
    font-size: 16px;
    font-size: 1rem;
    line-height: calc(26 / 36);
    letter-spacing: 0.12em;
    border-top-left-radius: 16px;
  }
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-info .p-ticketInfo__table tbody:first-of-type td .p-ticketInfo__time,
  .p-ticket__section.-info .p-ticketInfo__table tbody:first-of-type td .p-ticketInfo__iconWrap {
    width: 48%;
    text-align: center;
  }
  .p-ticket__section.-info .p-ticketInfo__table tbody:first-of-type::before, .p-ticket__section.-info .p-ticketInfo__table tbody:first-of-type::after {
    position: absolute;
    top: 0;
    z-index: 3;
    width: 3px;
    height: 100%;
    content: "";
    background: #f6f5f3;
  }
}

@media print, screen and (min-width: 980px) and (min-width: 980px) {
  .p-ticket__section.-info .p-ticketInfo__table tbody:first-of-type::before, .p-ticket__section.-info .p-ticketInfo__table tbody:first-of-type::after {
    width: 5px;
  }
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-info .p-ticketInfo__table tbody:first-of-type::before {
    left: 47%;
  }
  .p-ticket__section.-info .p-ticketInfo__table tbody:first-of-type::after {
    left: 100%;
  }
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-info .p-ticketInfo__table tbody:last-of-type {
    width: 33.3%;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }
  .p-ticket__section.-info .p-ticketInfo__table tbody:last-of-type tr,
  .p-ticket__section.-info .p-ticketInfo__table tbody:last-of-type th,
  .p-ticket__section.-info .p-ticketInfo__table tbody:last-of-type td {
    width: 33.3%;
  }
  .p-ticket__section.-info .p-ticketInfo__table tbody:last-of-type td {
    text-align: center;
  }
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-info .p-ticketInfo__table tbody {
    float: left;
    padding-bottom: 0;
    margin: 0;
    border-radius: 0;
  }
  .p-ticket__section.-info .p-ticketInfo__table tbody:not(:first-of-type) td time {
    display: none;
  }
}

.p-ticket__section.-info .p-ticketInfo__table tbody::after {
  position: absolute;
  top: 0;
  left: 66%;
  z-index: 1;
  width: 3px;
  height: 100%;
  content: "";
  background: #f6f5f3;
}

@media print, screen and (min-width: 600px) {
  .p-ticket__section.-info .p-ticketInfo__table tbody::after {
    left: calc(50% - 2px);
  }
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-info .p-ticketInfo__table tbody::after {
    content: none;
  }
}

tbody.-englishLineColor .p-ticket__section.-info .p-ticketInfo__table tbody::after {
  background-color: #fff;
}

@media print, screen and (max-width: 599px) {
  .p-ticket__section.-info .p-ticketInfo__table tbody tr,
  .p-ticket__section.-info .p-ticketInfo__table tbody th {
    display: block;
  }
}

@media (max-width: 979px) {
  .p-ticket__section.-info .p-ticketInfo__table tbody tr,
  .p-ticket__section.-info .p-ticketInfo__table tbody th {
    display: block;
  }
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-info .p-ticketInfo__table tbody tr:nth-of-type(2) td {
    height: 86px;
    padding-top: 20px;
  }
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-info .p-ticketInfo__table tbody tr:last-of-type td {
    height: 86px;
    padding-bottom: 20px;
  }
}

.p-ticket__section.-info .p-ticketInfo__table tbody tr:last-of-type td.-radiusLeftBottom {
  border-bottom-left-radius: 8px;
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-info .p-ticketInfo__table tbody tr:last-of-type td.-radiusLeftBottom {
    border-bottom-left-radius: 16px;
  }
}

.p-ticket__section.-info .p-ticketInfo__table tbody tr:last-of-type td.-radiusRightBottom {
  border-bottom-left-radius: 8px;
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-info .p-ticketInfo__table tbody tr:last-of-type td.-radiusRightBottom {
    border-bottom-right-radius: 16px;
  }
}

.p-ticket__section.-info .p-ticketInfo__table tbody th {
  position: relative;
  z-index: 2;
  padding: 24px 8px 20px;
  margin-bottom: 20px !important;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  background: #fff;
  border-bottom: 3px solid #f6f5f3;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-size: 18px;
  font-size: 1.125rem;
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-info .p-ticketInfo__table tbody th {
    padding: 30px 8px 25px;
    margin-bottom: 0;
    background: none;
    border-bottom: 5px solid #f6f5f3;
    border-radius: 0;
  }
}

.p-ticket__section.-info .p-ticketInfo__table tbody th.-englishLineColor {
  z-index: 4;
  margin-bottom: 20px !important;
  background-color: #f6f5f3;
  border-bottom: 3px solid #fff;
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-info .p-ticketInfo__table tbody th.-englishLineColor {
    z-index: 0;
    border-bottom: 5px solid #fff;
  }
}

.p-ticket__section.-info .p-ticketInfo__table tbody th.-englishLineColor::after {
  z-index: 3;
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-info .p-ticketInfo__table tbody th.-englishLineColor::after {
    z-index: 0;
  }
}

.p-ticket__section.-info .p-ticketInfo__table tbody th.-radiusRightTop {
  border-top-right-radius: 8px;
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-info .p-ticketInfo__table tbody th.-radiusRightTop {
    border-bottom: 5px solid #fff;
    border-top-right-radius: 16px;
  }
}

.p-ticket__section.-info .p-ticketInfo__table tbody td {
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 8px 0;
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-info .p-ticketInfo__table tbody td {
    display: table-cell;
    height: 60px;
    padding: 0;
  }
}

.p-ticket__section.-info .p-ticketInfo__table tbody td .p-ticketInfo__time {
  box-sizing: border-box;
  flex-basis: 65.7%;
  padding-left: 20px;
}

@media print, screen and (min-width: 600px) {
  .p-ticket__section.-info .p-ticketInfo__table tbody td .p-ticketInfo__time {
    flex-basis: 50%;
  }
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-info .p-ticketInfo__table tbody td .p-ticketInfo__time {
    display: inline-block;
    padding: 0;
  }
}

.p-ticket__section.-info .p-ticketInfo__table tbody td .p-ticketInfo__time time {
  font-size: 16px;
  font-size: 1rem;
  font-family: source-han-sans-japanese, sans-serif;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.12em;
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-info .p-ticketInfo__table tbody td .p-ticketInfo__time time {
    font-size: 22px;
    font-size: 1.375rem;
    vertical-align: middle;
  }
}

.p-ticket__section.-info .p-ticketInfo__table tbody td .p-ticketInfo__time time:first-of-type::after {
  display: inline-block;
  width: 10px;
  height: 2px;
  margin: 0 20px;
  vertical-align: super;
  content: "";
  background: #3a3a3a;
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-info .p-ticketInfo__table tbody td .p-ticketInfo__time time:first-of-type::after {
    margin: 0 12px;
  }
}

.p-ticket__section.-info .p-ticketInfo__table tbody td .p-ticketInfo__iconWrap {
  flex-basis: 34%;
  text-align: center;
}

@media print, screen and (min-width: 600px) {
  .p-ticket__section.-info .p-ticketInfo__table tbody td .p-ticketInfo__iconWrap {
    flex-basis: 50%;
  }
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-info .p-ticketInfo__table tbody td .p-ticketInfo__iconWrap {
    display: inline-block;
    width: auto;
  }
}

.p-ticket__section.-info .p-ticketInfo__table tbody td .p-ticketInfo__icon {
  vertical-align: sub;
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-info .p-ticketInfo__table tbody td .p-ticketInfo__icon {
    vertical-align: middle;
  }
}

.p-ticket__section.-info .p-ticketInfo__table tbody.-englishLineColor::before, .p-ticket__section.-info .p-ticketInfo__table tbody.-englishLineColor::after {
  content: "";
  background: #fff !important;
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-info .p-ticketInfo__table tbody.-englishLineColor::before, .p-ticket__section.-info .p-ticketInfo__table tbody.-englishLineColor::after {
    z-index: 2;
  }
}

.p-ticket__section.-info .p-ticketInfo__table tbody.-englishLineColor::after {
  content: "";
  background: #fff !important;
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-info .p-ticketInfo__table tbody.-englishLineColor::after {
    z-index: -2;
  }
}

.p-ticket__section.-info .p-ticketInfo__table tbody .-englishBackColor {
  background-color: #f6f5f3 !important;
}

.p-ticket__section.-info .p-ticketInfo__table tbody.-spLine::after {
  position: absolute;
  top: 0;
  left: 66%;
  z-index: 1;
  width: 3px;
  height: 100%;
  content: "";
  background: #fff !important;
}

@media print, screen and (min-width: 600px) {
  .p-ticket__section.-info .p-ticketInfo__table tbody.-spLine::after {
    left: calc(50% - 2px);
  }
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-info .p-ticketInfo__table tbody.-spLine::after {
    content: none;
  }
}

.p-ticket__section.-info .p-ticketInfo__title {
  font-size: 20px;
  font-size: 1.25rem;
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-info .p-ticketInfo__title {
    font-size: 22px;
    font-size: 1.375rem;
  }
}

.p-ticket__section.-info .p-ticketInfo__title + .p-ticket__note {
  padding: 0;
  margin-top: 30px;
}

.p-ticket__section.-info .p-ticketInfo__table_02 {
  width: 100%;
  margin-top: 35px;
  background: #fff;
  border-radius: 10px;
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-info .p-ticketInfo__table_02 {
    margin-top: 47px;
  }
}

.p-ticket__section.-info .p-ticketInfo__table_02 tr {
  line-height: 1.53846;
  font-size: 13px;
  font-size: 0.8125rem;
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-info .p-ticketInfo__table_02 tr {
    line-height: 1.625;
    font-size: 16px;
    font-size: 1rem;
  }
}

.p-ticket__section.-info .p-ticketInfo__table_02 tr + tr {
  border-top: 3px solid #f6f5f3;
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-info .p-ticketInfo__table_02 tr + tr {
    border-width: 4px;
  }
}

.p-ticket__section.-info .p-ticketInfo__table_02 tr th,
.p-ticket__section.-info .p-ticketInfo__table_02 tr td {
  padding-top: 19px;
  padding-right: 0.4em;
  padding-bottom: 18px;
  padding-left: 1em;
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-info .p-ticketInfo__table_02 tr th,
  .p-ticket__section.-info .p-ticketInfo__table_02 tr td {
    padding-top: 24px;
    padding-bottom: 21px;
  }
}

.p-ticket__section.-info .p-ticketInfo__table_02 tr th {
  width: calc(134.5 / 336 * 100%);
  font-weight: normal;
  border-right: 3px solid #f6f5f3;
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-info .p-ticketInfo__table_02 tr th {
    width: calc(274 / 879 * 100%);
    border-width: 4px;
  }
}

.p-ticket__section.-info .p-ticketInfo__table_02 tr td {
  width: calc(198.5 / 336 * 100%);
  text-align: center;
}

@media print, screen and (min-width: 980px) {
  .p-ticket__section.-info .p-ticketInfo__table_02 tr td {
    width: calc(602 / 879 * 100%);
  }
}

.p-ticket__section.-info .p-ticketInfo__table_02 tr td._danger {
  color: #d73d5b;
}

.p-ticket__section.-info .p-ticketInfo__table_02._bg_baige {
  background: #f6f5f3;
}

.p-ticket__section.-info .p-ticketInfo__table_02._bg_baige tr + tr {
  border-color: #fff;
}

.p-ticket__section.-info .p-ticketInfo__table_02._bg_baige th {
  border-color: #fff;
}

.p-ticket__section.-englishBottom {
  padding-bottom: 0 !important;
  background-color: #fff !important;
}

@media print, screen and (min-width: 980px) {
  .p-ticket__inner {
    max-width: 880px;
    height: auto;
    margin: 0 auto;
  }
}

.p-ticket__form {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 500px;
  margin: 42px auto 100px;
}

@media print, screen and (min-width: 980px) {
  .p-ticket__form {
    width: auto;
    max-width: none;
    margin: 54px auto 100px;
  }
}

.p-ticket__form.disabled input[type="submit"] {
  pointer-events: none;
  background: #cecece;
}

.p-ticket__form input[type="submit"] {
  width: 100%;
  padding: 20px 50px;
  font-weight: 500;
  line-height: 1.625;
  color: #fff;
  letter-spacing: 0.12em;
  background: #74b4d8;
  border-radius: 35px;
  transition: all 0.3s ease 0s;
  font-size: 16px;
  font-size: 1rem;
}

@media print, screen and (min-width: 980px) {
  .p-ticket__form input[type="submit"] {
    width: 554px;
    max-width: none;
    padding: 30px 0;
    line-height: 1.44444;
    border-radius: 40px;
    font-size: 18px;
    font-size: 1.125rem;
  }
}

@media print, screen and (min-width: 980px) {
  .p-ticket__form input[type="submit"]:hover {
    transform: translateY(-0.3em);
  }
}

@media print, screen and (min-width: 980px) {
  .p-user {
    max-width: 878px;
    height: auto;
    padding: 0;
    margin: 0 auto;
  }
}

.p-user h2 {
  font-family: source-han-sans-japanese, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: calc(32 / 16);
  letter-spacing: 0.12em;
}

@media print, screen and (min-width: 600px) {
  .p-user h2 {
    font-size: 22px;
    line-height: calc(26 / 22);
    letter-spacing: 0.12em;
  }
}

.p-user h2::after {
  display: block;
  width: 30px;
  height: 1px;
  margin-top: 4px;
  content: "";
  background-color: #444;
}

@media print, screen and (min-width: 600px) {
  .p-user h2::after {
    width: 60px;
    height: 2.5px;
    margin-top: 18px;
  }
}

.p-user p + h2 {
  margin-top: 57px;
}

@media print, screen and (min-width: 600px) {
  .p-user p + h2 {
    margin-top: 50px;
  }
}

.p-user h3 {
  font-family: source-han-sans-japanese, sans-serif;
  font-style: normal;
  font-weight: 700;
  margin-top: 24px;
  margin-bottom: 12px;
  font-size: 14px;
  line-height: calc(52 / 28);
  letter-spacing: 0.06em;
}

@media print, screen and (min-width: 600px) {
  .p-user h3 {
    margin-top: 29px;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: calc(32 / 16);
    letter-spacing: 0.12em;
  }
}

.p-user p {
  font-family: source-han-sans-japanese, sans-serif;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 19px;
  font-size: 14px;
  line-height: calc(52 / 28);
  letter-spacing: 0.06em;
}

@media print, screen and (min-width: 600px) {
  .p-user p {
    margin-top: 9px;
    margin-bottom: 19px;
    font-size: 15px;
    line-height: calc(32 / 15);
    letter-spacing: 0.12em;
  }
}

.p-user__linkarea {
  width: 100%;
  height: auto;
  margin-top: 43px;
  margin-bottom: 50px;
}

@media print, screen and (min-width: 600px) {
  .p-user__linkarea {
    margin-top: 47px;
    margin-bottom: 54px;
  }
}

.p-user__linkarea strong {
  font-family: source-han-sans-japanese, sans-serif;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 8px;
  font-size: 14px;
  line-height: calc(52 / 28);
  letter-spacing: 0.06em;
}

@media print, screen and (min-width: 600px) {
  .p-user__linkarea strong {
    margin-bottom: 0;
    font-size: 16px;
    line-height: calc(32 / 15);
    letter-spacing: 0.12em;
  }
}

.p-user__linkarea a {
  display: inline;
  padding-bottom: 5px;
  margin-top: 13px;
  font-size: 14px;
  line-height: calc(52 / 28);
  letter-spacing: 0.06em;
  word-break: break-all;
  border-bottom: 1px solid #444;
  font-family: source-han-sans-japanese, sans-serif;
  font-style: normal;
  font-weight: 400;
}

@media print, screen and (min-width: 600px) {
  .p-user__linkarea a {
    display: inline-block;
    padding-bottom: 7px;
    margin-top: 16px;
    font-size: 15px;
    line-height: 1;
    letter-spacing: 0.12em;
  }
}

@media print, screen and (min-width: 600px) {
  .p-user__linkarea a:hover {
    padding-bottom: 8px;
    border-bottom: none;
  }
}

.p-user__linkarea a:not(:last-of-type) {
  margin-bottom: 51px;
}

@media print, screen and (min-width: 600px) {
  .p-user__linkarea a:not(:last-of-type) {
    margin-bottom: 53px;
  }
}

.p-user__linkarea > p {
  margin-bottom: 9px;
}

@media print, screen and (min-width: 600px) {
  .p-user__linkarea > p {
    margin-bottom: -2px !important;
  }
}

.p-user__linkarea > p:not(:first-of-type) {
  margin-top: 51px;
}

@media print, screen and (min-width: 600px) {
  .p-user__linkarea > p:not(:first-of-type) {
    margin-top: 0;
  }
}

@media print, screen and (min-width: 980px) {
  .p-workshop {
    max-width: none;
    margin-right: -80px;
    margin-left: -80px;
  }
}

@media print, screen and (max-width: 979px) {
  .p-workshop {
    margin-right: -20px;
    margin-left: -20px;
  }
}

.p-workshop__main {
  margin-right: auto;
  margin-left: auto;
}

@media print, screen and (min-width: 980px) {
  .p-workshop__main {
    max-width: 1360px;
    padding: 78px 80px 84px;
  }
}

@media print, screen and (max-width: 979px) {
  .p-workshop__main {
    padding: 65px 20px 72px;
  }
}

.p-workshopBenefit {
  background-color: #fff;
  border-radius: 20px;
}

.p-workshopBenefit__inner {
  /* autoprefixer grid: no-autoplace */
  display: grid;
}

@media print, screen and (min-width: 980px) {
  .p-workshopBenefit__inner {
    grid-template-areas: "figure title" "figure body";
    grid-template-rows: repeat(2, auto);
    grid-template-columns: 1fr 2fr;
    row-gap: 14px;
  }
}

@media print, screen and (max-width: 979px) {
  .p-workshopBenefit__inner {
    grid-template-areas: "figure" "title" "body";
    grid-template-rows: repeat(3, auto);
    grid-template-columns: 1fr;
    padding-right: 30px;
    padding-left: 30px;
  }
}

.p-workshopBenefit__title {
  font-size: 18px;
  font-size: 1.125rem;
  grid-area: title;
}

@media print, screen and (min-width: 980px) {
  .p-workshopBenefit__title {
    margin-top: 57px;
    margin-right: 80px;
  }
}

@media print, screen and (max-width: 979px) {
  .p-workshopBenefit__title {
    margin-top: 34px;
  }
}

.p-workshopBenefit__body {
  grid-area: body;
}

@media print, screen and (min-width: 980px) {
  .p-workshopBenefit__body {
    padding-bottom: 50px;
    margin-right: 80px;
  }
}

@media print, screen and (max-width: 979px) {
  .p-workshopBenefit__body {
    padding-bottom: 35px;
  }
}

@media print, screen and (min-width: 980px) {
  .p-workshopBenefit__text {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 2.13333;
    letter-spacing: 0.05em;
  }
}

@media print, screen and (max-width: 979px) {
  .p-workshopBenefit__text {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.85714;
  }
}

@media print, screen and (min-width: 980px) {
  .p-workshopBenefit__text + .p-workshopBenefit__text {
    margin-top: 1.06667em;
  }
}

@media print, screen and (max-width: 979px) {
  .p-workshopBenefit__text + .p-workshopBenefit__text {
    margin-top: 0.92857em;
  }
}

.p-workshopBenefit__figure {
  display: flex;
  grid-area: figure;
  align-items: center;
  justify-content: center;
}

@media print, screen and (max-width: 979px) {
  .p-workshopBenefit__figure {
    padding-top: 48px;
  }
}

.p-workshopSchedule {
  padding-bottom: 70px;
}

.p-workshopSchedule__title {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  border: 0;
}

.p-workshopSchedule__notice {
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1.73333;
  text-align: center;
}

.p-workshopSchedule__list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
}

@media print, screen and (max-width: 599px) {
  .p-workshopSchedule__list {
    flex-direction: column;
  }
}

.p-workshopSchedule__item {
  background-color: #fff;
}

@media print, screen and (min-width: 1280px) {
  .p-workshopSchedule__item {
    width: calc((100% - 30px * 2) / 3 - 2px);
    max-width: 380px;
  }
}

@media print, screen and (min-width: 980px) {
  .p-workshopSchedule__item {
    border-radius: 20px;
  }
}

@media print, screen and (min-width: 600px) and (max-width: 1279px) {
  .p-workshopSchedule__item {
    width: calc((100% - 30px) / 2 - 2px);
  }
}

@media print, screen and (max-width: 979px) {
  .p-workshopSchedule__item {
    border-radius: 10px;
  }
}

@media print, screen and (min-width: 1280px) {
  .p-workshopSchedule__item:not(:nth-child(3n)) {
    margin-right: 30px;
  }
}

@media print, screen and (min-width: 1280px) {
  .p-workshopSchedule__item:nth-child(n + 4) {
    margin-top: 30px;
  }
}

@media print, screen and (min-width: 600px) and (max-width: 1279px) {
  .p-workshopSchedule__item:nth-child(n + 3) {
    margin-top: 30px;
  }
}

@media print, screen and (min-width: 600px) and (max-width: 1279px) {
  .p-workshopSchedule__item:nth-child(odd) {
    margin-right: 30px;
  }
}

@media print, screen and (max-width: 599px) {
  .p-workshopSchedule__item:not(:first-child) {
    margin-top: 30px;
  }
}

.p-workshopSummary {
  display: flex;
  flex-direction: column;
  height: 100%;
}

@media print, screen and (min-width: 980px) {
  .p-workshopSummary {
    padding: 35px 35px 30px;
  }
}

@media print, screen and (max-width: 979px) {
  .p-workshopSummary {
    padding-top: 28px;
    padding-right: 26px;
    padding-left: 26px;
  }
}

.p-workshopSummary__title {
  margin-top: 25px;
  font-weight: 500;
}

@media print, screen and (min-width: 980px) {
  .p-workshopSummary__title {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.625;
  }
}

@media print, screen and (max-width: 979px) {
  .p-workshopSummary__title {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 1.46667;
  }
}

.p-workshopSummary__text {
  font-size: 13px;
  font-size: 0.8125rem;
  padding-bottom: 26px;
  margin-top: 20px;
  line-height: 1.69231;
  color: #808080;
  transition: all 0.2s ease-out;
}

.p-workshopSummary__text[aria-hidden="true"] {
  max-height: 26px;
  padding: 0;
  margin: 0;
  opacity: 0;
}

.p-workshopSummary__figure {
  order: -1;
  text-align: center;
  overflow: hidden;
}

.p-workshopSummary__figure img {
  height: auto;
}

.p-workshopSummary__list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 35px;
  margin-top: auto;
  margin-right: -35px;
  margin-left: -35px;
  letter-spacing: 0.1em;
  border-top: 2px solid #f6f5f3;
  border-bottom: 2px solid #f6f5f3;
}

@media print, screen and (max-width: 979px) {
  .p-workshopSummary__list {
    padding-right: 26px;
    padding-left: 26px;
    margin-right: -26px;
    margin-left: -26px;
    letter-spacing: 0.05em;
  }
}

@media print, screen and (min-width: 980px) {
  .p-workshopSummary__timeIcon {
    width: 20px;
    height: 20px;
  }
}

@media print, screen and (min-width: 600px) and (max-width: 979px) {
  .p-workshopSummary__timeIcon {
    width: 19px;
    height: 19px;
  }
}

@media print, screen and (max-width: 599px) {
  .p-workshopSummary__timeIcon {
    width: 5.06667vw;
    height: 5.06667vw;
  }
}

.p-workshopSummary__term {
  flex-shrink: 0;
}

@media print, screen and (min-width: 980px) {
  .p-workshopSummary__term {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}

@media print, screen and (min-width: 600px) and (max-width: 979px) {
  .p-workshopSummary__term {
    font-size: 12px;
    font-size: 0.75rem;
  }
}

@media print, screen and (max-width: 599px) {
  .p-workshopSummary__term {
    font-size: 3.2vw;
  }
}

.p-workshopSummary__description {
  text-align: right;
}

@media print, screen and (min-width: 980px) {
  .p-workshopSummary__description {
    font-size: 15px;
    font-size: 0.9375rem;
  }
}

@media print, screen and (min-width: 600px) and (max-width: 979px) {
  .p-workshopSummary__description {
    font-size: 14px;
    font-size: 0.875rem;
  }
}

@media print, screen and (max-width: 599px) {
  .p-workshopSummary__description {
    font-size: 3.73333vw;
  }
}

.p-workshopSummary__description > time {
  font-family: zeitung, sans-serif;
  font-style: normal;
  font-weight: 600;
  padding-right: 0.2em;
  padding-left: 0.2em;
}

.p-workshopSummary__expanded {
  order: 2;
  padding-top: 12px;
  padding-bottom: 17px;
  margin-right: -26px;
  margin-left: -26px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

[data-whatintent="keyboard"] .p-workshopSummary__expanded:focus {
  outline: none;
  box-shadow: 0 0 0 3px #444;
}

.p-workshopSummary__chevron {
  width: 22px;
  height: 11px;
  color: #808080;
  fill: currentColor;
  transition: transform 0.2s ease-out;
}

.p-workshopSummary__expanded[aria-expanded="false"] .p-workshopSummary__chevron {
  transform: rotate(0.5turn);
}

.p-workshopSummary__footer {
  padding-top: 26px;
  text-align: center;
  transition: all 0.2s ease-out;
}

.p-workshopSummary__footer[aria-hidden="true"] {
  max-height: 0;
  padding: 0;
  margin: 0;
  overflow: hidden;
  opacity: 0;
}

.p-workshopSummary__link {
  font-size: 14px;
  font-size: 0.875rem;
  display: inline-block;
  font-weight: 500;
  color: #fff !important;
  background-color: #e18b9c;
}

@media print, screen and (min-width: 980px) {
  .p-workshopSummary__link {
    padding: 8px 40px;
    border-radius: 21px;
    min-width: 240px;
  }
}

@media print, screen and (max-width: 979px) {
  .p-workshopSummary__link {
    width: 100%;
    padding-top: 13px;
    padding-bottom: 12px;
    border-radius: 25px;
  }
}

[data-whatintent="keyboard"] .p-workshopSummary__link:focus {
  outline: none;
  box-shadow: 0 0 0 3px #444;
}

.p-workshopSummary__link.-soon {
  background-color: #808080;
}

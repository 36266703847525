@charset "utf-8";
//@include sp {};
//@include tb {};
//@include pc {};

.p-newsList {
  @include pc {
    width: 100%;
    height: auto;
  }
  &__inner {
    padding: 0;
    margin: 0 auto;
    @include pc {
      width: 100%;
      max-width: 1440px;
      height: auto;
    }
  }
  &__articleList {
    margin: 0 auto;
    margin-bottom: 52px;
    @include tb {
      width: 95%;
    }
    @include pc {
      max-width: 878px;
      height: auto;
      margin-bottom: 65px;
    }
  }
  &__article {
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 37px;
    background-color: #f6f5f3;
    border-radius: 20px;
    transition: all 0.3s ease 0s;
    @include tb {
      margin-top: 27px;
    }
    @include wide {
      min-height: 172px;
      &:hover {
        background-color: #f7d65b;
        transition: all 0.3s ease 0s;
      }
    }
    @include touch {
      border-radius: 10px;
    }
    &:hover {
      @include wide {
        transition: all 0.3s ease 0s;
        transform: translateX(1.5em);
      }
      .p-newsList__articleHeading {
        color: #808080;
        transition: transform 0.3s;
      }
      .p-newsList__arrowLine::before {
        @include wide {
          width: 130px;
          background-color: #808080;
          transition: all 0.3s ease 0s;
        }
      }
      .p-newsList__arrowLine::after {
        border-top: 2px solid #808080;
        transition: all 0.3s ease 0s;
      }
    }
  }
  &__articleLink {
    display: block;
    width: 100%;
    height: 100%;
    padding: 39px 20px;
    text-decoration: none;
    border-radius: inherit;
    @include pc {
      padding: 57px;
      cursor: pointer;
    }
    [data-whatintent="keyboard"] &:focus {
      outline: none;
      box-shadow: 0 0 0 3px currentColor;
    }
  }
  &__articleHeading {
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    line-height: calc(26 / 17);
    color: #444;
    letter-spacing: 0.12em;
    @include sourceHanSansJP_bold;
    @include pc {
      font-size: 18px;
      line-height: calc(26 / 18);
    }
  }
  &__articleTime {
    display: block;
    margin-top: 10px;
    font-size: 12px;
    line-height: calc(26 / 13);
    color: #808080;
    letter-spacing: 0.12em;
    @include sourceHanSansJP_regular;
    @include pc {
      font-size: 13px;
    }
  }
  &__arrow {
    position: relative;
    display: block;
    width: auto;
    height: auto;
  }
  &__arrowLine {
    position: relative;
    display: block;
    &::before {
      position: absolute;
      top: -6px;
      right: 5px;
      display: block;
      width: 38px;
      height: 1px;
      content: "";
      background: #444;
      transition: all 0.3s ease 0s;
    }
    &::after {
      position: absolute;
      top: -9px;
      right: 5px;
      display: block;
      width: 8px;
      height: 7px;
      content: "";
      border-top: 1px solid #444;
      transform: rotate(45deg);
    }
    @include pc {
      &::before {
        top: 21px;
        right: -18px;
        width: 80px;
        height: 2px;
      }
      &::after {
        top: 15px;
        right: -17px;
        width: 14px;
        height: 7px;
        border-top: 2px solid #444;
      }
    }
  }
}

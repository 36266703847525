@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include touch{}
*/

//.l-container {
//  position: relative;
//  &:before {
//    position: absolute;
//    width: 100%;
//    height: 10000px;
//    display: block;
//    content: '';
//    background-image: url(../img/dummy/20190729_pc_kasostore.jpg);
//    background-repeat: no-repeat;
//    background-position: center top;
//    background-size: 1440px auto;
//    opacity: 0.4;
//    z-index: -1;
//    top: 0;
//  }
//}

.p-store {
  &__tag {
    @include font-webfont_zeitung_bold;
    &.-new {
      color: #f74608;
    }
  }
}
.p-pageHeader.-store {
  .p-pageHeader__link {
    max-width: 1200px;
    margin: 32px auto 0;
    .c-button {
      position: relative;
      max-width: 320px;
    }
    @include pc {
      text-align: right;
    }
    @include touch {
      margin-top: 64px;
      text-align: center;
      .c-button {
        max-width: 264px;
        padding: 1.25em 1em;
      }
    }
  }
}

// png 透過できないためボタン塗りを手前に
.p-pageHeader.-store .p-pageHeader__link .c-button {
  padding: 0;
  background: none;
  .label {
    display: block;
    z-index: 1;
    position: relative;
    padding: 1em;
    border-radius: 3em;
    background: #e18b9c;
  }
}

// Woodstock
.onlinestore-woodstock {
  pointer-events: none;
  position: absolute;
  bottom: calc(100% + 24px);
  right: 24px;
  width: 60px;
  height: 60px;
  @include touch {
    bottom: calc(100% + 6px);
  }
  .os-woodstock {
    width: 100%;
    height: 100%;
    .ws {
      z-index: -1;
      position: absolute;
      bottom: -36px;
      left: 0;
      width: 100%;
      height: 100%;
      background: url(../img/store/ws_sprites.png) 0 0 / 300px 600px no-repeat;
    }
  }
  .os-orbit {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 157px;
    height: 80px;
  }
  .os-orbit.-line {
    bottom: -16px;
    left: 24px;
  }
  //#orbit {
  //  stroke: #f00;
  //}
}
.c-button.is-anim .os-woodstock .ws {
  top: -36px;
}
.c-button.is-anim .onlinestore-woodstock {
  opacity: 0;
  transition: opacity .4s 1.6s;
}
@for $i from 0 through 9 {
  .os-woodstock .ws[data-f="#{$i + 1}"] {
    background-position: 0 $i * 11.1111%;
  }
}
@for $i from 0 through 9 {
  .os-woodstock .ws[data-f="#{$i + 11}"] {
    background-position: 25% $i * 11.1111%;
  }
}
@for $i from 0 through 9 {
  .os-woodstock .ws[data-f="#{$i + 21}"] {
    background-position: 50% $i * 11.1111%;
  }
}
@for $i from 0 through 9 {
  .os-woodstock .ws[data-f="#{$i + 31}"] {
    background-position: 75% $i * 11.1111%;
  }
}
@for $i from 0 through 9 {
  .os-woodstock .ws[data-f="#{$i + 41}"] {
    background-position: 100% $i * 11.1111%;
  }
}

@charset "utf-8";

$sp: 600px; //固定
$tb: 768px;
$pc: 980px; //コンテンツ幅

//@include linkColor($color);
@mixin linkColor($color) {
  color: $color;
  &:hover,
  &:active,
  &:focus {
    color: lighten($color, 20%);
  }
}

//@include clearfix;
@mixin clearfix {
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}

/* ============================================ */

//.using{
//  @include lhCrop(80/40) ;
//  font-size: 40px;
//  line-height: (80/40);
//}

//見出し line-height詰め
//@include lhCrop(line-height/fontsize);
@mixin lhCrop($line-height) {
  &::before {
    display: block;
    width: 0;
    height: 0;
    margin-top: calc((1 - #{$line-height}) * 0.5em);
    content: "";
  }
}

/* ============================================ */

//.using{
//  @include widtnPercent(400, 1280);
//  background:#eee;
//}

//横幅 px → % 変換
// @include widtnPercent(子要素, 親要素);
@mixin widtnPercent($pxWidth, $parentPxWidth) {
  width: floor((($pxWidth / $parentPxWidth) * 10000%)/ 100);
}

/* ============================================ */

//.using {
//  @include fzPercentSP(px);
//  @include pc {
//    @include fzPercentSP(px);
//  }
//}

//font-size可変 SP
// @include fzPercentSP(px);
@mixin fzPercentSP($pxWidth) {
  font-size: (($pxWidth / 320 /*SPデザイン横幅*/) * 10000vw)/ 100;
}

//font-size可変 PC
// @include fzPercentPC(px);
@mixin fzPercentPC($pxWidth) {
  font-size: (($pxWidth / 1200 /*PCデザイン横幅*/) * 10000vw)/ 100;
}

/* ============================================ */

//object-fit
//HTMLにclass追加 img class="object-fit-img"
// @include object-fit;
@mixin object-fit {
  object-fit: cover;
  font-family: "object-fit: cover;";
}

/* ============================================ */

// letter-spacing
// use → @include tracking();
@mixin tracking($tracking) {
  letter-spacing: ($tracking / 1000) + em;
}

//@include sp{}
@mixin sp {
  @media (max-width: $sp - 1px) {
    @content;
  }
}

//@include pc{}
@mixin pc {
  @media (min-width: $pc) {
    @content;
  }
}

br.SPbr {
  display: inline;
  @include pc {
    display: none;
  }
}

br.PCbr {
  display: none;
  @include pc {
    display: inline;
  }
}

// visually-hidden:
// 視覚的にコンテンツを非表示にしたい場合や、スクリーンリーダーや
// 検索エンジンからアクセスできるようにしたい場合に使う
@mixin visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  border: 0;
}

@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include touch{}
*/

.p-note {
  max-width: 1040px;
  margin-right: auto;
  margin-left: auto;
  @include pc {
    padding: 0 80px 168px;
  }
  @include touch {
    padding: 0 20px 125px;
  }
  &.-workshop {
    @include pc {
      padding-top: 100px;
    }
    @include touch {
      padding-top: 80px;
    }
  }
  &__list {
    @include fz(15);
    padding-left: 1.2em;
    margin-top: 34px;
    line-height: (26 / 15);
    list-style-image: url(../img/common/icon_list.svg);
  }
  &__item {
    margin-top: 5px;
  }
}

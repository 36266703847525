@charset "utf-8";

/*
@include touch {} // max-width: 979px
@include sp {}    // max-width: 599px
@include tb {}    // min-width: 600px
@include pc {}    // min-width: 980px
@include wide {}  // min-width: 1280px
*/

/* slider */
.c-slider {
  padding-bottom: 40px;
}

.c-slider.flickity-enabled {
  position: relative;
  display: block;
}

.c-slider.flickity-enabled:focus {
  outline: 0;
}

.slider-item {
  position: relative;
  box-sizing: content-box;
}

.slider-item[data-num]::after {
  position: absolute;
  left: calc(50% - 1em);
  width: 2em;
  height: 2em;
  font-size: 40px;
  line-height: 2em;
  color: white;
  content: attr(data-num);
  background: $var-green;
  border-radius: 2em;
  @include font-webfont_zeitung_bold;
  @at-root .c-slider.-gallery & {
    display: none;
  }
  @at-root .c-slider.-about & {
    display: block !important;
  }
}

.slider-img img {
  width: 100%;
  border-radius: $var-border-radius;
}

.slider-img {
  padding-bottom: 24px;
}

.slider-item[data-num] .slider-img {
  @at-root .p-aboutMuseum & {
    padding-bottom: 80px;
  }
}

.flickity-page-dots {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.flickity-page-dots .dot {
  flex-basis: 8px;
  width: 8px;
  height: 8px;
  background: #a7dddf;
  border-radius: 8px;
}

.flickity-page-dots .dot:not(:first-child) {
  margin-left: 8px;
}

.flickity-page-dots .dot.is-selected {
  background: #4daabb;
}

@include touch {
  .c-slider {
    margin: 0 auto;
  }
  .slider-item {
    width: 280px;
  }
  .slider-item[data-num]::after {
    top: 240px;
    text-align: center;
  }
  .c-slider .slider-item:not(:first-child) {
    margin-left: 30px;
  }
}

@media (min-width: 1025px) {
  .c-slider .slider-item:not(:first-child) {
    margin-left: 40px;
  }
}

@include pc {
  .c-slider {
    text-align: center;
  }
  .slider-item {
    width: 480px;
  }
  .slider-item[data-num]::after {
    top: 420px;
  }
  .c-slider .slider-item:not(:first-child) {
    margin-left: 80px;
  }
}

/* not flickity */
.c-slider:not(.flickity-enabled) {
  display: flex;
  align-items: flex-start;
  overflow: auto;
}

@media (max-width: 1024px) {
  .c-slider:not(.flickity-enabled) {
    padding-left: 40px;
    margin: 88px -40px;
  }
  .c-slider:not(.flickity-enabled) .slider-item:last-child {
    padding-right: 40px;
  }
  .c-slider:not(.flickity-enabled) .slider-item:last-child[data-num]::after {
    left: calc(50% - 1em - 20px);
  }
}

@include touch {
  .c-slider:not(.flickity-enabled) {
    padding-left: 20px;
    margin: 0 -20px;
  }
  .c-slider:not(.flickity-enabled) .slider-item {
    min-width: 280px;
  }
  .c-slider:not(.flickity-enabled) .slider-item:last-child {
    padding-right: 20px;
  }
  .c-slider:not(.flickity-enabled) .slider-item:last-child[data-num]::after {
    left: calc(50% - 1em - 10px);
  }
}
@include pc {
  .c-slider:not(.flickity-enabled) {
    padding-left: 80px;
    margin: 88px -80px;
  }
  .c-slider:not(.flickity-enabled) .slider-item {
    min-width: 480px;
  }
  .c-slider:not(.flickity-enabled) .slider-item:last-child {
    padding-right: 80px;
  }
  .c-slider:not(.flickity-enabled) .slider-item:last-child[data-num]::after {
    left: calc(50% - 1em - 40px);
  }
}

.slider-text {
  margin-top: 94px;
}

@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

//HTML
//<div class="c-btnWrap -center">
//<div class="c-btn -white -w460"><a href="" class="">ボタン</a></div>
//<div class="c-btn -black -wFull"><button>ボタン</button></div>
//<div class="c-btn -red -wFull"><input type="submit" value="ボタン"></div>
//</div>
//形
a,
button,
input {
  .c-btn &,
  &.c-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 50px;
    font-weight: 500;
    line-height: (26/28);
    color: #fff;
    text-decoration: none;
    letter-spacing: 0.12em;
    cursor: pointer;
    background: #e18b9c;
    border-radius: 25px;
    transition: 0.3s ease-in-out;
    @include lhCrop(26/28);
    @include fz(14);
    @include pc {
      @include lhCrop(26/14);
      line-height: (26/14);
    }
    & + & {
      margin-left: 10px;
    }
  }
  .-left & {
    &::before {
      left: 8px;
    }
  }
  .-right & {
    &::before {
      right: 8px;
    }
  }
}

.c-btn {
  & + & {
    margin-left: 10px;
  }
  &.-pseudo {
    position: relative;
    a,
    button,
    input {
      position: relative;
      &::before {
        position: absolute;
        top: 50%;
        display: block;
        width: 10px;
        height: 10px;
        content: "";
        background: #000;
        transform: translateY(-50%);
      }
      &.-left {
        padding-left: 30px;
        &::before {
          left: 10px;
        }
      }
      &.-right {
        padding-right: 30px;
        &::before {
          right: 10px;
        }
      }
    }
  }
  &.-pseudo_inline {
    display: flex;
    justify-content: center;
    &.-left {
      a::before,
      input::before,
      button::before {
        display: block;
        width: 10px;
        height: 10px;
        margin-right: 6px;
        content: "";
        background: #000;
      }
    }
    &.-right {
      a::after,
      input::after,
      button::after {
        display: block;
        width: 10px;
        height: 10px;
        margin-left: 6px;
        content: "";
        background: #000;
      }
    }
  }
  &.-arrow {
    position: relative;
    &::before {
      position: absolute;
      top: 50%;
      display: block;
      width: 10px;
      height: 10px;
      content: "";
      border: 2px #000 solid;
      border-top: 0;
      border-left: 0;
    }
    &.-left::before {
      left: 15px;
      margin-top: -2px;
      transform: rotate(135deg) translateY(50%);
    }
    &.-right::before {
      right: 15px;
      margin-top: -10px;
      transform: rotate(-45deg) translateY(50%);
    }
  }
  &.-right,
  &.-left {
    position: relative;
  }
  &.-icon {
    position: relative;
    i {
      position: absolute;
      @include fz(20);
    }
    &.-left i {
      left: 10px;
      margin-right: 5px;
    }
    &.-right i {
      right: 10px;
      margin-left: 5px;
    }
  }
  &.-icon_inline {
    i {
      @include fz(20);
    }
    &.-left i {
      margin-right: 5px;
    }
    &.-right i {
      margin-left: 5px;
    }
  }
}

//色
.c-btn {
  &.-white {
    a,
    button,
    input {
      color: #000;
      background: #fff;
      border: 2px solid #000;
      &:hover {
        color: #fff;
        background: #000;
      }
    }
  }
  &.-black {
    a,
    button,
    input {
      color: #fff;
      background: #000;
      border: 2px solid #000;
      &:hover {
        color: #000;
        background: #fff;
      }
    }
  }
}

//横幅
.c-btn {
  &.-w235 {
    width: 235px;
    a,
    button,
    input {
      width: 100%;
    }
  }
  &.-w400 {
    width: 100%;
    @include pc {
      width: 400px;
    }
    a,
    button,
    input {
      width: 100%;
    }
  }
}

//縦幅
.c-btn {
  &.-h45 {
    a,
    button,
    input {
      height: 45px;
      border-radius: 45px;
      @include pc {
        letter-spacing: 0.18em;
      }
    }
  }
}

//囲み 右寄せ 中央寄せ 左寄せ
.c-btnWrap {
  display: flex;
  &.-left {
    justify-content: flex-start;
  }
  &.-center {
    justify-content: center;
  }
  &.-right {
    justify-content: flex-end;
  }
  &.-english {
    margin-bottom: -18px;
    @include tb {
      margin-bottom: 91px;
    }
    .-bottom {
      @include pc {
        margin-bottom: 190px;
      }
    }
  }
  &.-englishSP {
    display: block;
    @include pc {
      display: none !important;
    }
  }
}

//個別
.c-btn {
  &.-amazon {
    a,
    button,
    input {
      position: relative;
      background: #fff;
      border: 2px solid #f90;
      &:hover {
        color: #000;
        background: #ffe3b9;
      }
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 94px;
        height: auto;
        margin-top: 4px;
        transform: translate(-50%, -50%);
      }
    }
  }
  &.-ticket {
    width: 100%;
    max-width: 500px;
    padding-top: 68px;
    margin-top: 42px;
    background: none;
    @include pc {
      width: auto;
      max-width: none;
      padding-top: 98px;
      margin-top: 20px;
    }
    &:hover {
      box-shadow: none;
    }
    a,
    button,
    input {
      position: relative;
      width: 100%;
      padding-top: 20px;
      padding-bottom: 20px;
      line-height: (26/16);
      border-radius: 35px;
      @include lhCrop(26/16);
      @include fz(16);
      @include pc {
        width: 554px;
        padding-top: 30px;
        padding-bottom: 30px;
        margin: 0 auto;
        line-height: (26/18);
        border-radius: 40px;
        @include lhCrop(26/18);
        @include fz(18);
      }
      .-img {
        position: absolute;
        top: -68px;
        left: 50%;
        width: 43px;
        height: auto;
        transform: translateX(-50%);
        @include pc {
          top: -98px;
          width: 62px;
        }
        img {
          width: 43px;
          height: auto;
          @include pc {
            width: 62px;
          }
        }
        @at-root .c-btnWrap.-english & {
          position: absolute;
          top: -43px;
          left: 50%;
          width: 46px;
          height: auto;
          transform: translateX(-50%);
          @include pc {
            top: -57px;
            width: 52px;
          }
          img {
            width: 33px;
            height: auto;
            @include pc {
              width: 45px;
            }
          }
        }
      }
      [data-whatintent="keyboard"] & {
        outline: none;
        box-shadow: 0 0 0 3px $var-color;
      }
    }
    &.-englishBtn {
      padding-top: 0;
      margin-bottom: 0;
      @include pc {
        padding-top: 98px;
      }
    }
  }
  &.-dayTicket {
    padding-top: 0;
    margin-top: 42px;
    @include pc {
      padding-top: 0;
      margin-top: 54px;
    }
    a {
      background: #74b4d8;
    }
  }
  &.disabled {
    &:hover {
      transform: none;
    }
    a {
      pointer-events: none;
      background: #cecece;
    }
  }
}

.c-btnArea {
  width: 100%;
  max-width: 878px;
  height: auto;
  @include tb {
    margin: 48px auto 0;
  }
  &.-confirm {
    margin-top: 27px;
  }
}

.c-btn {
  display: block;
  width: 275px;
  height: 50px;
  margin: 0 auto;
  margin-bottom: 100px;
  background-color: #e18b9c;
  border-radius: 50px;
  border-radius: 28px !important;
  transition: all 0.3s ease 0s;
  @include pc {
    width: 240px;
    height: 56px;
  }
  &:hover {
    @include pc {
      transition: all 0.3s ease 0s;
      transform: translateY(-0.3em);
    }
  }
  &__link {
    display: block;
    width: 100%;
    height: 100%;
    padding-top: 20px;
    font-size: 14px;
    line-height: calc(26 / 28);
    color: #fff;
    text-align: center;
    text-decoration: none;
    letter-spacing: 0.04em;
    border-radius: 28px !important;
    @include sourceHanSansJP_regular;
    @include pc {
      padding-top: 19px;
      line-height: calc(22 / 14);
    }
    @at-root .p-inquires__inner & {
      padding-top: 0;
      letter-spacing: 0.1em;
    }
  }
  @at-root .p-inquires__inner & {
    padding: 0;
    @include tb {
      width: 400px;
    }
  }
}

.-back {
  margin-bottom: 20px;
  background-color: #808080 !important;
  @include tb {
    margin-bottom: 24px;
  }
}

// FAQ ▼

.c-faqNavBtn {
  display: block;
  border: 2.5px solid #519c60;
  border-radius: 40px;
  &:not(:last-child) {
    margin-right: 15px;
    @include tb {
      margin-right: 7px;
    }
  }
  @include tb {
    border: 3px solid #519c60;
  }
  @include pc {
    margin-right: 0;
  }
  &__link {
    display: block;
    padding: 6.5px 15px;
    font-size: 13px;
    font-weight: 500;
    line-height: calc(22 / 13);
    color: #519c60;
    text-decoration: none;
    letter-spacing: 0.12em;
    border-radius: inherit;
    @include sourceHanSansJP_regular;
    @include tb {
      padding: 6.5px 12px;
      font-size: 12px;
    }
    @include pc {
      padding: 5px 17px !important;
      font-size: 13px !important;
    }
    [data-whatintent="keyboard"] &:focus {
      outline: none;
      box-shadow: 0 0 0 3px $var-color;
    }
  }
}

@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include touch{}
*/

.p-pageHeader {
  padding: 30px 20px 80px;
  @include pc {
    padding: 48px 80px 80px;
  }
  &__inner {
    position: relative;
    max-width: 878px;
    height: auto;
    margin: 0 auto;
    @at-root .p-pageHeader.-english & {
      max-width: 1200px;
      @include tb {
      }
      @include pc {
      }
    }
    @at-root .p-pageHeader.-faq & {
      @include tb {
        padding-left: 20px;
      }
      @include pc {
        padding-left: 0;
      }
    }
    @at-root .p-pageHeader.-privacy & {
      @include tb {
        margin-left: 0;
      }
      @include pc {
        margin: 0 auto;
      }
    }
    @at-root .p-pageHeader.-terms & {
      max-width: 1200px;
      @include tb {
        margin-left: 0;
      }
      @include pc {
        max-width: 878px;
        margin: 0 auto;
      }
      @include wide {
        padding: 0;
      }
    }
  }
  &__mainTitle {
    padding-bottom: 30px;
    font-size: 32px;
    line-height: (42/32);
    text-transform: uppercase;
    letter-spacing: 0.02em;
    @include font-webfont_zeitung_bold;
    @include pc {
      font-size: 42px;
    }
    @at-root .p-pageHeader.-faq & {
      @include pc {
        padding-bottom: 27px !important;
      }
    }
    @at-root .p-pageHeader.-privacy & {
      @include sourceHanSansJP_bold;
      font-size: 24px;
      line-height: calc(60 / 48);
      letter-spacing: 0.12em;
      @include pc {
        font-size: 32px;
        line-height: calc(26 / 32);
      }
    }
    @at-root .p-pageHeader.-terms & {
      @include sourceHanSansJP_bold;
      font-size: 24px;
      line-height: calc(26 / 48);
      letter-spacing: 0.12em;
      @include pc {
        font-size: 32px;
        line-height: calc(26 / 32);
      }
    }
    @at-root .p-pageHeader.-news & {
      @include tb {
      }
    }
    @at-root .p-pageHeader.-english & {
      text-transform: none;
      .-sp {
        display: block;
        @include pc {
          display: none;
        }
      }
    }
    .normalcase {
      text-transform: none;
    }
  }
  &__subTitle {
    display: block;
    margin-top: -27px;
    font-size: 16px;
    line-height: calc(51.7 / 32);
    color: #808080;
    text-transform: uppercase;
    @include sourceHanSansJP_bold;
    @include tb {
      margin-top: -26px;
      line-height: calc(32 / 16);
    }
  }
  &__kv {
    position: relative;
  }
  &__logo {
    display: block;
    width: 210px;
    margin: 0 auto;
    margin-top: 34px;
    @include pc {
      width: 320px;
      margin-top: 32px;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  &__lead {
    padding-top: 90px;
    margin: 0 auto;
    @include pc {
      max-width: 840px;
      padding-top: 80px;
      padding-top: 110px;
    }
    &Title {
      @include fz(15);
      line-height: (30/26);
      text-align: center;
      letter-spacing: 0.12em;
      @include sourceHanSansJP_medium;
      @include pc {
        line-height: (26/16);
        text-align: left;
        @include fz(16);
      }
    }
    &Text {
      margin-top: 12px;
      @include fz(13);
      line-height: (46/26);
      letter-spacing: 0.12em;
      @include pc {
        line-height: (26/13);
      }
    }
  }
  &.-cafe {
    padding-bottom: 140px;
    background: #f6f5f3;
    @include sp {
      padding-bottom: 120px;
    }
    @include pc {
      padding: 48px 80px 80px;
    }
    .p-pageHeader__lead {
      padding-top: 32px;
      @include pc {
        max-width: 840px;
        padding-top: 22px;
      }
    }
  }
  &.-store {
    .p-pageHeader__lead {
      padding-top: 32px;
      @include pc {
        max-width: 840px;
        padding-top: 22px;
      }
    }
  }
  &.-inquires {
    padding-top: 31px;
    padding-right: 19px;
    padding-left: 19px;
    margin-bottom: -95px;
    @include pc {
      padding-right: 80px;
      padding-left: 80px;
    }
    @include wide {
      padding-top: 58px;
      margin-bottom: -80px;
    }
  }
  &.-news {
    margin-bottom: -79px;
    @include tb {
      padding-right: 44px;
      padding-left: 44px;
      margin-bottom: -78px;
    }
    .p-pageHeader__mainTitle {
      margin-bottom: -27px;
      @include tb {
        margin-top: -2px;
        margin-bottom: 0;
      }
      @include pc {
        margin-left: 0;
      }
      @include wide {
        margin-left: 37px;
      }
    }
  }
  &.-newsDetail {
    margin-bottom: -81px;
    @include tb {
      padding-right: 44px;
      padding-left: 44px;
    }
    .p-pageHeader__mainTitle {
      margin-bottom: -27px;
      @include tb {
        margin-top: -2px;
        margin-bottom: 0;
      }
      @include wide {
        margin-left: 37px;
      }
    }
  }
  &.-ticket {
    padding-right: 0;
    padding-bottom: 33px;
    padding-left: 0;
    @include tb {
      padding-right: 0;
      padding-bottom: 29px;
      padding-left: 0;
    }
  }
  &.-faq {
    padding-right: 20px;
    padding-right: 20px;
    padding-bottom: 9px;
    @include tb {
      padding-top: 44px;
      padding-right: 20px;
      padding-right: 20px;
      padding-bottom: 0;
    }
    @include pc {
      padding-top: 44px;
      padding-right: 80px;
      padding-left: 80px;
    }
    @include wide {
      padding-right: 80px;
      padding-left: 80px;
    }
  }
  &.-privacy {
    padding-top: 39px;
    padding-bottom: 2px;
    @include tb {
      padding-top: 77px;
      padding-bottom: 10px;
    }
    @include pc {
      padding-right: 80px;
      padding-left: 80px;
    }
    @include wide {
      padding-right: 0;
      padding-left: 0;
    }
  }
  &.-terms {
    max-width: 1200px;
    padding-top: 46px;
    padding-bottom: 8px;
    margin: 0 auto;
    @include tb {
      padding-top: 76px;
      padding-bottom: 10px;
    }
    @include pc {
      padding-right: 80px;
      padding-left: 80px;
    }
    @include wide {
      padding-right: 0;
      padding-left: 0;
    }
  }
  &.-news,
  &.-newsDetail {
    @include tb {
      max-width: 1440px;
      padding-bottom: 0;
      margin: 0 auto;
    }
    @include pc {
      padding-left: 100px;
    }
    @include wide {
      padding-left: 44px;
    }
  }
  &.-english {
    padding-bottom: 0;
    .p-pageHeader__img {
      img {
        @include tb {
          width: 100%;
          height: auto;
        }
      }
    }
  }
  &.-workshop {
    .p-pageHeader__lead {
      padding-top: 32px;
      @include pc {
        max-width: 840px;
        padding-top: 22px;
      }
    }
  }
}

.flickity-enabled:focus { outline: none; }
.flickity-viewport {
  position: relative;
  height: 100%;
}
.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}
.p-pageHeader__kv.flickity-enabled {
  position: relative;
  &:focus {
    outline: none;
  }
  .flickity-slider {
    transform: translateX(0) !important;
  }
  .flickity-page-dots {
    bottom: 16px;
  }
  .p-pageHeader__img {
    left: 0 !important;
    transition: opacity .4s;
  }
  .p-pageHeader__img.is-selected {
    z-index: 1;
  }
  .p-pageHeader__img:not(.is-selected) {
    opacity: 0;
    transition-delay: .2s;
  }
  .p-pageHeader__img,
  .p-pageHeader__img img {
    width: 100%;
  }
}

@charset "utf-8";

/*
@include touch {} // max-width: 979px
@include sp {}    // max-width: 599px
@include tb {}    // min-width: 600px
@include pc {}    // min-width: 980px
@include wide {}  // min-width: 1280px
*/

/* -------------------------------------------------- HOME */
body.isLoading,
body.isOpening {
  width: 100%;
}

#mainVisual ~ .l-contents {
  padding-top: 0;
}

/* -------------------------------------------------- visual */
.l-visual {
  position: relative;
  max-height: 100vh;
  overflow: hidden;
  background: $var-bg-beige;
}

.mainVisual-symbol,
.mainVisual-loader {
  z-index: 1;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-bottom: 80px;
}

@include touch {
  .mainVisual-symbol,
  .mainVisual-loader {
    padding-top: 55px;
  }
}
@include pc {
  .l-visual {
    min-height: 680px;
  }
  .mainVisual-symbol,
  .mainVisual-loader {
    padding-left: 80px;
  }
}

@media screen and (min-device-width: $pc) {
  .l-visual {
    height: 100vh !important;
  }
}

/* :::::: symbol :::::: */
.mainVisual-symbol {
  position: relative;
}

.mainVisual-symbol .logo {
  width: (88px * 1.8);
  height: (152px * 1.8);
}

.mainVisual-symbol:not(.active) {
  pointer-events: none;
  opacity: 0;
  transform: scale(1.08);
}

body.isOpening .mainVisual-symbol {
  transition: opacity 0.6s, transform 0.6s $var-easing;
}

body.isOpening .mainVisual-symbol.active {
  transition-delay: 0.3s;
}

@media screen and (min-width: 600px) {
  .mainVisual-symbol .logo {
    width: (88px * 2.2);
    height: (152px * 2.2);
  }
}
@media screen and (min-width: 1280px) {
  .mainVisual-symbol .logo {
    width: (88px * 2.5);
    height: (152px * 2.5);
  }
}
@media screen and (min-width: 1440px) and (min-height: 1024px) {
  .mainVisual-symbol .logo {
    width: (88px * 2.8);
    height: (152px * 2.8);
  }
}

/* :::::: loader :::::: */
.mainVisual-loader {
  position: absolute;
  top: 0;
  left: 0;
}

.mainVisual-loader svg {
  width: 48px;
  height: 48px;
  fill: none;
  stroke: $var-yellow;
  stroke-dasharray: 268;
  stroke-dashoffset: 268;
  stroke-width: 8;
  animation: loading 1.2s infinite linear;
}

.mainVisual-loader circle {
  animation: loader 1.6s infinite cubic-bezier(0.3, 0, 0.3, 1);
}

@keyframes loading {
  from {
    transform: none;
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    stroke-dashoffset: 268;
  }
  to {
    stroke-dashoffset: -268;
  }
}

body:not(.isLoading) .mainVisual-loader {
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.6s;
}

/* :::::: scroll :::::: */
.mainVisual-scroll {
  position: absolute;
  bottom: 64px;
  left: calc(50% - 4em + 40px);
  z-index: 1;
  width: 8em;
  font-size: 15px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.06em;
}

.mainVisual-scroll svg {
  display: block;
  width: 30px;
  height: 16px;
  margin: 0 auto 1em;
  fill: none;
  stroke: $var-black;
  stroke-width: 2;
}

.mainVisual-scroll:not(.active) {
  pointer-events: none;
  opacity: 0;
  transform: translateY(-2em);
}

.mainVisual-scroll:not(.active) svg {
  transform: translateY(-2em);
}

body.isOpening .mainVisual-scroll {
  transition: opacity 1s, transform 1s cubic-bezier(0.19, 1, 0.22, 1);
}

body.isOpening .mainVisual-scroll svg {
  transition: transform 1s $var-easing;
}

@include touch {
  .mainVisual-scroll {
    bottom: 48px;
    left: calc(50% - 4em);
    font-size: 12px;
  }
  .mainVisual-scroll svg {
    stroke-width: 1;
  }
}

/* -------------------------------------------------- opening */
.mainVisual-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

@include touch {
  .mainVisual-canvas {
    top: 55px;
    height: calc(100% - 55px);
  }
}
@include pc {
  .mainVisual-canvas {
    left: 80px;
    width: calc(100% - 80px);
  }
}

/* :::::: zoom/pan :::::: */
.mv-container,
.mv-orbit {
  position: absolute;
}

.mv-orbit {
  top: 0;
  left: 0;
}
$mv_ratio_def: 2.6;

.mv-container {
  top: calc(50% - #{300px / $mv_ratio_def * 1.8});
  left: calc(50% - #{1200px / ($mv_ratio_def * 2)});
}

.mv-container,
.mv-orbit {
  width: (1200px / $mv_ratio_def);
}

.mv-orbit {
  height: (300px / $mv_ratio_def);
}

.mv-illustration,
.mv-woodstock {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.mv-illustration {
  width: (1200px / $mv_ratio_def);
}

.mv-woodstock {
  z-index: 2;
  height: (300px / ($mv_ratio_def - 0.1));
}

@media screen and (min-width: 600px) {
  $mv_ratio_600: 1.8;
  .mv-container {
    top: calc(50% - #{300px / $mv_ratio_600 * 1.6});
    left: calc(50% - #{1200px / ($mv_ratio_600 * 2)});
  }
  .mv-container,
  .mv-orbit {
    width: (1200px / $mv_ratio_600);
  }
  .mv-orbit {
    height: (300px / $mv_ratio_600);
  }
  .mv-illustration {
    width: (1200px / $mv_ratio_600);
  }
  .mv-woodstock {
    height: (300px / ($mv_ratio_600 - 0.1));
  }
}
@media screen and (min-width: 1280px) {
  $mv_ratio_1280: 1.4;
  .mv-container {
    top: calc(50% - #{300px / $mv_ratio_1280 * 1.8});
    left: calc(50% - #{1200px / ($mv_ratio_1280 * 2)});
  }
  .mv-container,
  .mv-orbit {
    width: (1200px / $mv_ratio_1280);
  }
  .mv-orbit {
    height: (300px / $mv_ratio_1280);
  }
  .mv-illustration {
    width: (1200px / $mv_ratio_1280);
  }
  .mv-woodstock {
    height: (300px / ($mv_ratio_1280 - 0.1));
  }
}
@media screen and (min-width: 1440px) and (min-height: 1024px) {
  .mv-container {
    top: calc(50% - 540px);
    left: calc(50% - 600px);
  }
  .mv-container,
  .mv-orbit {
    width: 1200px;
  }
  .mv-orbit {
    height: 300px;
  }
  .mv-illustration {
    left: 60px;
    width: 1080px;
  }
  .mv-woodstock {
    height: 300px;
  }
}

.mv-woodstock,
.mv-orbit.-line {
  top: 0;
}
$ratio_def: 2;

.mv-woodstock .ws {
  position: absolute;
  top: 0;
  left: 0;
  width: (100px / $ratio_def);
  height: (100px / $ratio_def);
  margin: (-100px / ($ratio_def * 2)) 0 0 (-100px / ($ratio_def * 2));
  background: url(../img/home/ws_sprites.png) 0 0 no-repeat;
  background-size: (900px / $ratio_def) (4200px / $ratio_def);
  will-change: transform;
}

.mv-woodstock .ws:not([style]) {
  opacity: 0;
}

@media screen and (min-width: 600px) {
  .mv-woodstock,
  .mv-orbit.-line {
    top: 20px;
  }
  $ratio_600: 1.4;
  .mv-woodstock .ws {
    width: (100px / $ratio_600);
    height: (100px / $ratio_600);
    margin: (-100px / ($ratio_600 * 2)) 0 0 (-100px / ($ratio_600 * 2));
    background-size: (900px / $ratio_600) (4200px / $ratio_600);
  }
}
@media screen and (min-width: 1280px) {
  .mv-woodstock,
  .mv-orbit.-line {
    top: 80px;
  }
  $ratio_1280: 1.2;
  .mv-woodstock .ws {
    width: (100px / $ratio_1280);
    height: (100px / $ratio_1280);
    margin: (-100px / ($ratio_1280 * 2)) 0 0 (-100px / ($ratio_1280 * 2));
    background-size: (900px / $ratio_1280) (4200px / $ratio_1280);
  }
}
@media screen and (min-width: 1440px) and (min-height: 1024px) {
  .mv-woodstock,
  .mv-orbit.-line {
    top: 140px;
  }
  .mv-woodstock .ws {
    width: 100px;
    height: 100px;
    margin: -50px 0 0 -50px;
    background-size: 900px 4200px;
  }
}

.mv-kite {
  position: absolute;
  top: calc(5.6% / 0.24);
  left: calc(5.64% / 0.24);
  width: calc(1.58% / 0.24);
  height: calc(7.03% / 0.24);
  transform: rotate(1deg);
  transform-origin: 46% 0;
}

.mv-kite.swing {
  animation: swing 3.6s cubic-bezier(0.645, 0.045, 0.355, 1);
}

@keyframes swing {
  0% {
    transform: rotateZ(1deg);
  }
  34% {
    transform: rotateY(12deg) rotateZ(-2deg);
  }
  70% {
    transform: rotateY(-4deg) rotateZ(1deg);
  }
  90% {
    transform: rotateY(1deg) rotateZ(-0.2deg);
  }
  100% {
    transform: none;
  }
}

@for $i from 0 through 36 {
  .mv-woodstock[data-n="1"] .ws[data-f="#{$i + 1}"] {
    background-position: 0 $i * 2.5%;
  }
}
@for $i from 0 through 28 {
  .mv-woodstock[data-n="2"] .ws[data-f="#{$i + 1}"] {
    background-position: 12.5% $i * 2.5%;
  }
}
@for $i from 0 through 30 {
  .mv-woodstock[data-n="3"] .ws[data-f="#{$i + 1}"] {
    background-position: 25% $i * 2.5%;
  }
}
@for $i from 0 through 36 {
  .mv-woodstock[data-n="4"] .ws[data-f="#{$i + 1}"] {
    background-position: 37.5% $i * 2.5%;
  }
}
@for $i from 0 through 32 {
  .mv-woodstock[data-n="5"] .ws[data-f="#{$i + 1}"] {
    background-position: 50% $i * 2.5%;
  }
}
@for $i from 0 through 10 {
  .mv-woodstock[data-n="6"] .ws[data-f="#{$i + 1}"] {
    background-position: 62.5% $i * 2.5%;
  }
}
@for $i from 0 through 12 {
  .mv-woodstock[data-n="7"] .ws[data-f="#{$i + 1}"] {
    background-position: 75% $i * 2.5%;
  }
}
@for $i from 0 through 36 {
  .mv-woodstock[data-n="8"] .ws[data-f="#{$i + 1}"] {
    background-position: 87.5% $i * 2.5%;
  }
}

/* :::::: animation :::::: */
.mv-illustration,
.mv-woodstock {
  transform: scale(0.9);
}

.mv-illustration:not(.active),
.mv-woodstock:not(.active),
.mv-illustration.active.hide,
.mv-woodstock.active.hide,
.mv-orbit.-line.hide {
  opacity: 0;
}

.mv-container.scene1 .mv-illustration,
.mv-container.scene1 .mv-woodstock,
.mv-container.scene1 .mv-orbit.-line {
  transform: none;
}

.mv-container.scene2 .mv-illustration {
  transform: scale(2.4);
  transform-origin: 50% 24%;
}

.mv-container.scene2 .mv-woodstock,
.mv-container.scene2 .mv-orbit.-line {
  transform: scale(2.6);
  transform-origin: 51% 28%;
}

@media screen and (min-width: 600px) {
  .mv-container.scene2 .mv-illustration {
    transform: scale(2.2);
  }
  .mv-container.scene2 .mv-woodstock,
  .mv-container.scene2 .mv-orbit.-line {
    transform: scale(2.4);
  }
}
@media screen and (min-width: 1280px) {
  .mv-container.scene2 .mv-illustration {
    transform: scale(2);
    transform-origin: 50% 12%;
  }
  .mv-container.scene2 .mv-woodstock,
  .mv-container.scene2 .mv-orbit.-line {
    transform: scale(2.2);
    transform-origin: 50% 12%;
  }
}
@media screen and (min-width: 1440px) and (min-height: 1024px) {
  .mv-container.scene2 .mv-illustration {
    transform: scale(1.9);
  }
  .mv-container.scene2 .mv-woodstock,
  .mv-container.scene2 .mv-orbit.-line {
    transform: scale(2);
  }
}

body.isOpening .mv-illustration {
  transition: opacity 1s;
}

body.isOpening .scene1 .mv-illustration {
  transition: opacity 2s, transform 2s $var-easing;
}

body.isOpening .scene1 .mv-woodstock,
body.isOpening .scene1 .mv-orbit.-line {
  transition: opacity 0.4s, transform 2s $var-easing;
}

body.isOpening .scene2 .mv-illustration {
  transition: transform 3.6s cubic-bezier(0.645, 0.045, 0.355, 1);
}

body.isOpening .scene2 .mv-woodstock,
body.isOpening .scene2 .mv-orbit.-line {
  transition: transform 3.6s cubic-bezier(0.645, 0.045, 0.355, 1);
}

body.isOpening .mv-illustration.hide {
  transition: opacity 1s;
}

body.isOpening .mv-woodstock.hide,
body.isOpening .mv-orbit.-line.hide {
  transition: opacity 1s;
}

/* -------------------------------------------------- SKIP */
.mv-skip {
  position: absolute;
  z-index: 999;
  text-transform: uppercase;
  border-radius: 48px;
  @include font-webfont_zeitung_semibold;
}

.mv-skip .ico {
  position: relative;
  top: -2px;
  width: 1.5em;
  height: 1em;
  margin-left: 0.5em;
  vertical-align: middle;
  fill: none;
  stroke: $var-black;
  stroke-width: 2;
}

.mv-skip.hide,
.mainVisual-canvas.is-skip {
  pointer-events: none;
  opacity: 0;
  transition: opacity 1s;
}

.mv-skip.hide {
  transition-duration: 0.6s;
}

@include touch {
  .mv-skip {
    top: 32px;
    left: 24px;
    font-size: 12px;
  }
}
@include pc {
  .mv-skip {
    top: 40px;
    left: 56px;
    font-size: 16px;
  }
}

/* -------------------------------------------------- PICK UP */
.c-pickup {
  position: absolute;
  right: calc(50% - 80px);
  bottom: 40px;
  z-index: 10000;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  border: 3px solid $var-black;
}

.c-pickup:not(.open) {
  width: 160px;
  height: 70px;
  border-radius: 35px;
}

.c-pickup.open {
  bottom: 20px;
  width: calc(100% - 40px);
  border-radius: 20px;
}

@include touch {
  .c-pickup.open {
    right: 20px;
  }
}
@include pc {
  .c-pickup {
    right: 60px;
    bottom: 60px;
  }
  .c-pickup:not(.open) {
    width: 200px;
    height: 90px;
    border-radius: 45px;
  }
  .c-pickup.open {
    bottom: 50px;
    width: 400px;
  }
}

.c-pickup:not(.active) {
  pointer-events: none;
  opacity: 0;
  transform: scale(1.08);
}

body.isOpening .c-pickup {
  transition: opacity 1s, transform 1s $var-easing;
}

.c-pickup.anima {
  transition-delay: 0s, 0.3s, 0.3s, 0s, 0s;
  transition-timing-function: $var-easing;
  transition-duration: 0.6s, 0.6s, 0.6s, 0.4s, 0.3s;
  transition-property: bottom, right, width, height, border-radius;
}

.c-pickup.anima.open {
  transition-delay: 0.3s, 0s, 0s, 0.3s, 0.3s;
  transition-duration: 0.6s, 0.4s, 0.4s, 0.6s, 0.4s;
}

/* :::::: pickup-title :::::: */
.pickup-title {
  position: absolute;
  top: 14px;
  left: 0;
  width: 100%;
  margin: 0;
  font-size: 16px;
  font-size: 20px;
  line-height: 1.2;
  color: $var-black;
  text-align: center;
  text-transform: uppercase;
  @include font-webfont_zeitung_bold;
}

.pickup-subTitle {
  display: block;
  margin-top: 0.5em;
  font-size: 11px;
}

.c-pickup.open .pickup-title {
  top: 24px;
  font-size: 24px;
}

@include pc {
  .pickup-title {
    top: 20px;
    font-size: 20px;
  }
  .pickup-subTitle {
    font-size: 13px;
  }
  .c-pickup.open .pickup-title {
    top: 40px;
    font-size: 28px;
  }
}

.c-pickup.anima .pickup-title {
  transition-timing-function: $var-easing;
  transition-duration: 0.6s;
  transition-property: top, font-size;
}

.c-pickup.anima.open .pickup-title {
  transition-delay: 0.3s;
  transition-duration: 0.6s;
}

/* :::::: pickup-content :::::: */
.pickup-content {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  overflow: hidden;
  text-align: center;
}

.c-pickup:not(.open) .pickup-content {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.c-pickup.open .pickup-content {
  padding: 32px 20px;
  padding-top: 96px;
}

@include pc {
  .pickup-content {
    padding: 0 36px;
  }
  .c-pickup.open .pickup-content {
    padding: 36px;
    padding-top: 128px;
  }
}

.c-pickup.anima .pickup-content {
  transition-delay: 0.6s, 0.3s, 0s;
  transition-timing-function: $var-easing;
  transition-duration: 0s, 0.3s, 0.3s;
  transition-property: width, padding, opacity;
}

.c-pickup.anima.open .pickup-content {
  transition-delay: 0.3s;
  transition-duration: 0s, 0.6s, 0.6s;
}

/* :::::: pickup-list :::::: */
.c-pickupList {
  overflow: auto;
  font-size: 13px;
  text-align: left;
}

.c-pickup:not(.open) .c-pickupList {
  width: calc(100vw - 86px);
}

.c-pickup.open .c-pickupList {
  height: calc(100% - 73px);
}

@include touch {
  .c-pickupList {
    width: 100%;
  }
}
@include pc {
  .c-pickupList {
    font-size: 14px;
  }
  .c-pickup:not(.open) .c-pickupList {
    width: 322px;
  }
  .c-pickup.open .c-pickupList {
    height: calc(100% - 62px);
  }
}

.pickupList-item {
  position: relative;
  padding-left: 1.8em;
}

.pickupList-item:not(:last-child) {
  padding-bottom: 12px;
}

.pickupList-item::before {
  position: absolute;
  top: 0.5em;
  left: 0;
  display: block;
  width: 10px;
  height: 10px;
  content: "";
  background: $var-black;
  border-radius: 0.75em;
}

.pickupList-item a {
  padding-bottom: 0.2em;
  border-bottom: 1px solid currentcolor;
}

.pickupList-limit {
  display: block;
  margin-top: 0.2em;
  font-size: 0.8em;
  color: $var-gray;
}

.pickup-content .c-button {
  margin-top: 20px;
}

@include touch {
  .pickup-content .c-button {
    max-width: 190px;
  }
}

/* :::::: pickup-trigger :::::: */
.c-pickupButton {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  color: transparent;
  cursor: pointer;
  background: none;
  border: 0;
  -webkit-appearance: none;
  appearance: none;
}

.c-pickupButton::selection {
  color: transparent;
}

.c-pickupButton::before,
.c-pickupButton::after {
  position: absolute;
  top: calc(50% - 1px);
  left: calc(50% - 12px);
  width: 24px;
  height: 2px;
  content: "";
  background: $var-black;
  opacity: 0;
}

.c-pickup.open .c-pickupButton {
  top: 16px;
  right: 16px;
  width: 40px;
  height: 40px;
}

.c-pickup.open .c-pickupButton::before,
.c-pickup.open .c-pickupButton::after {
  opacity: 1;
}

.c-pickup.open .c-pickupButton::before {
  transform: rotate(45deg);
}

.c-pickup.open .c-pickupButton::after {
  transform: rotate(-45deg);
}

@include pc {
  .c-pickup.open .c-pickupButton {
    top: 24px;
    right: 24px;
  }
}

.c-pickup.anima .c-pickupButton::before,
.c-pickup.anima .c-pickupButton::after {
  transition: opacity 0.3s;
}

.c-pickup.anima.open .c-pickupButton::before,
.c-pickup.anima.open .c-pickupButton::after {
  transition-delay: 0.3s;
}

/* -------------------------------------------------- 重要なお知らせ */
.c-notice {
  position: relative;
  padding: 20px 0;
  font-size: 13px;
  color: white;
  background: $var-green;
}

.c-notice > * {
  position: relative;
}

.notice-title {
  margin: 0;
  font-size: inherit;
  font-weight: normal;
  color: #d4d4d4;
}

.notice-content p {
  margin: 0;
}

@include touch {
  .c-notice {
    padding: 24px;
    margin: 0 -20px;
    text-align: center;
  }
  .notice-title {
    margin-bottom: 1em;
  }
}
@include pc {
  .c-notice {
    display: flex;
    align-items: center;
  }
  .c-notice::before {
    position: absolute;
    top: 0;
    right: calc(50% - 50vw + 40px);
    left: calc(50% - 50vw + 40px);
    display: block;
    height: 100%;
    content: "";
    background: $var-green;
  }
  .notice-title {
    flex-basis: 13em;
  }
}

.c-notice_02 {
  position: relative;
  padding: 20px 0;
  font-size: 13px;
  color: white;
  background: $var-color;
}

.c-notice_02 > * {
  position: relative;
}

.notice_02-title {
  margin: 0;
  font-size: inherit;
  font-weight: normal;
  color: #d4d4d4;
}

.notice_02-content p {
  margin: 0;
}

@include touch {
  .c-notice_02 {
    padding: 24px;
    margin: 0 -20px;
    text-align: center;
  }
  .notice_02-title {
    margin-bottom: 1em;
  }
}
@include pc {
  .c-notice_02 {
    display: flex;
    align-items: center;
  }
  .c-notice_02::before {
    position: absolute;
    top: 0;
    right: calc(50% - 50vw + 40px);
    left: calc(50% - 50vw + 40px);
    display: block;
    height: 100%;
    content: "";
    background: $var-color;
  }
  .notice_02-title {
    flex-basis: 13em;
  }
}

/* -------------------------------------------------- news, campaign */
.p-homeNews {
  margin-top: 104px;
}
.p-homeCampaign {
  margin: 40px 0 104px;
}
@include touch {
  .p-homeNews,
  .p-homeCampaign {
    text-align: center;
    .c-title,
    .c-newsList {
      text-align: left;
    }
    .c-viewmore {
      margin-top: 32px;
    }
  }
  .newsList-content {
    padding-bottom: 16px;
    border-bottom: 1px solid #f6f5f3;
  }
  .p-homeCampaign .newsList-content:not(:first-child) {
    padding-top: 16px;
  }
  .newsList-date {
    font-size: 12px;
  }
  .newsList-content + .newsList-date {
    margin-top: 16px;
  }
}
@include pc {
  .c-newsList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .newsList-date,
  .newsList-content {
    padding: 18px 0;
    border-bottom: 1px solid #f6f5f3;
  }
  .newsList-date {
    padding: 20px 0;
  }
  .newsList-date:first-of-type,
  .newsList-content:first-of-type {
    border-top: 1px solid #f6f5f3;
  }
  .newsList-date {
    flex-basis: 8rem;
    font-size: 13px;
  }
  .newsList-content {
    flex-basis: calc(100% - 8rem);
  }
  .p-homeNews,
  .p-homeCampaign {
    position: relative;
    .c-viewmore {
      position: absolute;
      top: .5em;
      right: 0;
    }
  }
}

/* -------------------------------------------------- contents */
.p-homeContents {
  margin: 104px 0;
}

.c-contentsList {
  display: grid;
}

.contentsList-item {
  position: relative;
  overflow: hidden;
  border-radius: $var-border-radius;
}

.contentsList-item::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  content: "";
  background: linear-gradient(to top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
}

.contentsList-item.-gallery::before {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.05));
}

.contentsList-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

[data-whatintent="keyboard"] .contentsList-item {
  a {
    display: block;
    border-radius: inherit;
    &:focus {
      position: relative;
      outline: none;
      &::before {
        position: absolute;
        top: 3px;
        right: 3px;
        bottom: 3px;
        left: 3px;
        display: block;
        content: "";
        border-radius: inherit;
        box-shadow: 0 0 0 3px $var-color;
      }
    }
  }
}

@include touch {
  .c-contentsList {
    -ms-grid-rows: 1fr 20px 1fr 20px 1fr;
    -ms-grid-columns: 1fr 20px 1fr;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    picture {
      pointer-events: none;
    }
  }
  .contentsList-item > a {
    display: inline-block;
    width: 100%;
    height: 100%;
  }
  .contentsList-item.-gallery {
    // -ms-grid-column-span: 3;
    // grid-area: 1 / 1 / 3 / 3;
  }
  .contentsList-item.-store,
  .contentsList-item.-cafe {
    -ms-grid-row: 3;
  }
  .contentsList-item.-workshop,
  .contentsList-item.-app {
    -ms-grid-row: 5;
  }
  .contentsList-item.-cafe,
  .contentsList-item.-app {
    -ms-grid-column: 3;
  }
}
@include pc {
  .c-contentsList {
    -ms-grid-rows: 1fr 32px 1fr;
    -ms-grid-columns: 1fr 32px 1fr 32px 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 32px;
  }
  .contentsList-item.-gallery {
    // -ms-grid-column-span: 3;
    // grid-area: 1 / 1 / 2 / 3;
  }
  .contentsList-item.-cafe,
  .contentsList-item.-workshop,
  .contentsList-item.-app {
    -ms-grid-row: 3;
  }
  .contentsList-item.-workshop,
  .contentsList-item.-online {
    -ms-grid-column: 3;
  }
  .contentsList-item.-store,
  .contentsList-item.-app {
    -ms-grid-column: 5;
  }
}

.contentsList-item .c-menuRuby {
  position: absolute;
  bottom: 16px;
  left: 13px;
  width: calc(100% - 26px);
  font-size: 24px;
}
.contentsList-item.-camera .c-menuRuby {
  letter-spacing: -.01em;
}
.contentsList-item.-camera .c-menuRuby rt {
  letter-spacing: .12em;
}

.contentsList-item .c-menuRuby rt {
  font-size: 11px;
}

.contentsList-item .c-menuRuby .strong {
  display: inline-block;
  margin-bottom: 4px;
  color: #e60020;
  font-size: 22px;
  font-weight: bold;
}

@include sp {
  .contentsList-item .c-menuRuby {
    font-size: 18px;
  }
  .contentsList-item .c-menuRuby rt {
    font-size: 9px;
  }
  .contentsList-item .c-menuRuby .strong {
    margin-bottom: 4px;
    font-size: 16px;
  }
}
@include pc {
  .contentsList-item .c-menuRuby {
    bottom: 24px;
    left: 24px;
    width: calc(100% - 48px);
    font-size: 28px;
  }
  .contentsList-item .c-menuRuby .v-mb {
    display: none;
  }
  .contentsList-item .c-menuRuby .strong {
    font-size: 24px;
  }
}
@include wide {
  .contentsList-item .c-menuRuby {
    bottom: 32px;
    left: 32px;
    width: calc(100% - 64px);
    font-size: 32px;
  }
  .contentsList-item .c-menuRuby .strong {
    font-size: 28px;
  }
}

@media (min-width: $full) {
  .contentsList-item .c-menuRuby {
    font-size: 38px;
  }
  .contentsList-item .c-menuRuby .strong {
    font-size: 30px;
  }
}

.contentsList-info {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  padding: 40px;
  color: white;
  @include touch {
    opacity: 0;
  }
}

.contentsList-info::before {
  position: absolute;
  top: -20px;
  left: -20px;
  width: calc(100% + 40px);
  height: calc(100% + 420px);
  content: "";
  background: $var-green;
}

.contentsList-info > * {
  position: relative;
}

.contentsList-info .info-title {
  margin-bottom: 1em;
  font-size: 18px;
  font-weight: 600;
}

.contentsList-info .info-text {
  font-size: 13px;
  letter-spacing: 0.05em;
}

.contentsList-info .info-image {
  position: absolute;
  top: 40px;
  right: 40px;
}

.contentsList-info {
  transition: opacity 0.3s, transform 0.3s $var-easing;
}

.contentsList-item:not(:hover) .contentsList-info {
  pointer-events: none;
  opacity: 0;
  transform: scale(0.95);
}

@include pc {
  .contentsList-info {
    padding: 32px;
  }
  .contentsList-info .info-image {
    top: 32px;
    right: 32px;
  }
}
@include wide {
  .contentsList-info {
    padding: 40px;
  }
  .contentsList-info .info-image {
    top: 40px;
    right: 40px;
  }
}

/* -------------------------------------------------- ticket */
.p-homeTicket {
  position: relative;
  padding: 104px 0;
}

.p-homeTicket-inner {
  position: relative;
  overflow: hidden;
  background: white;
  border-radius: $var-border-radius;
}

.p-homeTicket-inner > * {
  position: relative;
  z-index: 1;
}

.p-homeTicket-inner::before,
.p-homeTicket-inner::after {
  position: absolute;
  content: "";
}

.p-homeTicket-inner::after {
  width: 140px;
  height: 140px;
  background: $var-bg-beige;
  border-radius: 140px;
}

.ticketInfo-title {
  margin-top: 24px;
  color: $var-gray;
}

@include touch {
  .p-homeTicket-inner {
    padding: 68px 6% 64px;
  }
  .p-homeTicket-inner::before {
    top: 80px;
    left: 10%;
    width: 80%;
    height: 30px;
    background-image: repeating-linear-gradient(to right, $var-bg-beige 0 24%, transparent 24% 38%, $var-bg-beige 38% 62%, transparent 62% 76%, $var-bg-beige 76% 100%);
  }
  .p-homeTicket-inner::after {
    top: -48px;
    left: calc(50% - 48px);
    width: 96px;
    height: 96px;
  }
  .p-homeTicket-inner .c-title {
    text-align: center;
  }
  .ticketInfo-title {
    margin-top: 16px;
  }
}
@include pc {
  .p-homeTicket-inner {
    padding: 56px 72px;
    padding-right: 120px;
  }
  .p-homeTicket-inner::before {
    top: 12%;
    right: 160px;
    width: 30px;
    height: 76%;
    background-image: repeating-linear-gradient(to bottom, $var-bg-beige 0 24%, transparent 24% 38%, $var-bg-beige 38% 62%, transparent 62% 76%, $var-bg-beige 76% 100%);
  }
  .p-homeTicket-inner::after {
    top: calc(50% - 70px);
    right: -70px;
  }
}

/* -------------------------------------------------- access */
.p-homeAccess {
  margin-bottom: 104px;
}

.l-accessMap {
  position: relative;
  margin-bottom: 60px;
}

.l-accessMap .c-button.-map {
  position: absolute;
  right: 0;
  bottom: -120px;
  @include pc {
    right: -73px;
  }
  @include wide {
    right: 0;
  }
}

.accessInfo-title {
  font-weight: 600;
}

@include touch {
  .accessMap-map {
    margin: 0 -20px;
  }
  .accessMap-map img {
    min-height: 300px;
    object-fit: cover;
  }
  .accessInfo-content {
    margin-bottom: 20px;
  }
  .p-homeAccess .c-button {
    margin-top: 52px;
  }
}
@include tb {
  .accessMap-map {
    width: 100vw;
  }
  .accessMap-map img {
    min-height: 300px;
    object-fit: cover;
  }
}
@include pc {
  .accessMap-map {
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
  .accessMap-map img {
    width: 100%;
  }
  .c-accessInfo {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .accessInfo-title,
  .accessInfo-content {
    margin-bottom: 20px;
  }
  .accessInfo-title {
    flex-basis: 7em;
  }
  .accessInfo-content {
    flex-basis: calc(100% - 7em);
  }
}
@include wide {
  .accessMap-map {
    width: 980px;
    height: 100%;
    margin: 0 auto;
  }
  .accessMap-map img {
    margin-left: -40px;
  }
}

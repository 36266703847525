@charset "utf-8";

.p-shopMap {
  margin: 80px 0 120px;
  @include pc {
    .c-sectionInner {
      margin-top: 48px;
    }
  }
  &__title {
    font-size: 22px;
    @include font-webfont_zeitung_bold;
    text-transform: uppercase;
    @include touch {
      font-size: 18px;
    }
  }
}
.p-shopMap__app {
  position: relative;
  @include touch {
    margin-top: 80px;
  }
  @include pc {
    max-width: 1200px;
    height: 860px;
  }
}
@media (min-width: 960px) and (max-width: 1440px) {
  .p-shopMap__app {
    height: calc(860vw / 14.4);
  }
}

/* マップ */
.p-shopMap__map {
  position: relative;
  width: 100%;
  .img {
    width: 100%;
    height: auto;
    fill: none;
    stroke: #7d7d7d;
    stroke-width: 12;
  }
  .nav {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .nav_item {
    cursor: default;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    overflow: hidden;
    height: 1.2em;
    font-size: 22px;
    line-height: 1.2;
    font-weight: 700;
    letter-spacing: 0;
    text-transform: uppercase;
    .ja {
      order: -1;
      color: #519c60;
    }
    .ja,
    .en {
      transition: transform .1s cubic-bezier(.3,1,.7,1);
    }
    .en {
      @include font-webfont_zeitung_bold;
    }
    &:not(.is-selected) .ja,
    &:not(.is-selected) .en {
      transform: translateY(-100%);
      transition-duration: .3s;
    }
    &.-i1 {
      top: 20%;
      left: 4%;
    }
    &.-i2 {
      top: 6%;
      left: 25%;
    }
    &.-i3 {
      top: 47%;
      left: 53.4%;
    }
    &.-i4 {
      top: 6%;
      right: 4%;
    }
    &.-i5 {
      top: 30%;
      right: 4%;
    }
    &.-i6 {
      bottom: 16%;
      right: 10%;
    }
    &.-i7 {
      bottom: 6%;
      left: 51%;
    }
    &.-i8 {
      bottom: 6%;
      left: 30%;
    }
    &.-i9 {
      bottom: 22%;
      left: 22%;
    }
    &.-i10 {
      top: 54%;
      left: 22%;
    }
  }
  @include touch {
    .nav_item {
      font-size: calc(11vw / 3.75);
      &.-i3 {
        left: 53%;
      }
    }
  }
  @include pc {
    position: absolute;
    top: calc(50% - 190px);
    left: calc(48% - 365px);
    width: 730px;
    height: 380px;
  }
}
@media (min-width: 960px) and (max-width: 1440px) {
  .p-shopMap__map {
    top: calc(50% - 190vw / 14.4);
    left: calc(48% - 365vw / 14.4);
    width: calc(730vw / 14.4);
    height: calc(380vw / 14.4);
  }
  .p-shopMap__map .nav_item {
    font-size: calc(22vw / 14.4);
  }
}

/* サムネイル */
.p-shopMap__thumb {
  .flickity-viewport {
    position: relative;
    height: 100%;
  }
  .flickity-slider {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .thumb_item {
    width: 200px;
    border-radius: 200px;
    &::after {
      content: "";
      position: absolute;
      inset: -4px;
      border: 8px solid #519c60;
      border-radius: inherit;
      transition: opacity .1s, transform .1s cubic-bezier(.3,1,.7,1);
    }
    &:not(.is-selected)::after {
      opacity: 0;
      transform: scale(1.05);
      transition-duration: .3s;
    }
    img {
      width: 100%;
      border-radius: inherit;
    }
  }
  .tree {
    position: absolute;
  }
  .charlie {
    position: absolute;
  }
  @include touch {
    margin-top: calc(32vw / 3.75);
    .thumb_item {
      width: 50vw;
      border-radius: 50vw;
      transform: scale(1.1);
      transition: transform .3s cubic-bezier(.3,1,.7,1);
      &::after {
        inset: calc(-4vw / 3.75);
        border-width: calc(8vw / 3.75);
      }
    }
    .thumb_item:not(.is-selected) {
      transform: scale(.8);
    }
    .tree {
      width: 104px;
      bottom: calc(100% + 11px);
      right: 56px;
    }
    .charlie {
      width: 56px;
      bottom: calc(100% + 12px);
      right: 0;
    }
  }
  @include pc {
    .thumb_item {
      position: absolute;
      &.-i1 {
        top: 0;
        left: 0;
      }
      &.-i2 {
        top: 0;
        left: 20%;
      }
      &.-i3 {
        top: 0;
        left: 40%;
      }
      &.-i4 {
        top: 0;
        left: 60%;
      }
      &.-i5 {
        top: 50%;
        right: 2%;
      }
      &.-i6 {
        bottom: 0;
        left: 60%;
      }
      &.-i7 {
        bottom: 0;
        left: 40%;
      }
      &.-i8 {
        bottom: 0;
        left: 20%;
      }
      &.-i9 {
        bottom: 0;
        left: 0;
      }
      &.-i10 {
        top: 50%;
        left: 0;
      }
    }
    .tree {
      top: 10%;
      right: 3%;
    }
    .charlie {
      top: 34%;
      right: -3%;
    }
  }
}
@media (min-width: 960px) and (max-width: 1440px) {
  .p-shopMap__thumb .thumb_item {
    width: calc(200vw / 14.4);
  }
  .p-shopMap__thumb .tree {
    width: calc(209vw / 14.4);
  }
  .p-shopMap__thumb .charlie {
    width: calc(114vw / 14.4);
  }
}

@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include touch{}
*/

.c-headingLine {
  position: relative;
  height: 32px;
  padding-right: 20px;
  padding-left: 20px;
  margin-bottom: 40px;
  @include tb {
    margin-right: auto;
    margin-left: auto;
  }
  @include pc {
    margin-bottom: 60px;
  }
  &__title {
    @include fz(20);
    position: absolute;
    top: 50%;
    left: 50%;
    display: inline-block;
    padding: 10px 20px;
    text-transform: uppercase;
    letter-spacing: 0.06em;
    background: #f6f5f3;
    transform: translate(-50%, -50%);
    @include font-webfont_zeitung_bold;
    @include pc {
      @include fz(22);
      min-width: 180px;
      padding: 10px 56px;
    }
  }
  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: calc(100% - 40px);
    height: 3px;
    content: "";
    background: #000;
    transform: translate(-50%, -50%);
    @include pc {
    }
  }
  &.-w740 {
    max-width: 1040px;
  }
  &.-w1040 {
    max-width: 700px;
    @include pc {
      max-width: 1040px;
    }
  }
}

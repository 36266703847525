@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include touch{}
*/

.p-cardPick {
  position: relative;
  padding-top: 90px;
  padding-bottom: 90px;
  margin-right: -20px;
  margin-left: -20px;
  background: #f6f5f3;
  @include pc {
    padding-top: 75px;
    margin-right: calc((((100vw - 85px) - 100%) / 2) * -1);
    margin-left: calc((((100vw - 85px) - 100%) / 2) * -1);
  }
  &.-column3 {
    margin: 0 -20px;
    @include pc {
      margin: 0 auto;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    //padding: 0 20px !important;
    margin-right: auto;
    margin-left: auto;
    @include sp {
      max-width: 315px;
    }
    @include pc {
      flex-direction: row;
      max-width: 1360px;
      padding: 0 80px;
    }
    .flickity-viewport {
      width: 100%;
    }
    .flickity-button {
      position: absolute;
      color: #333;
      background: hsla(0, 0%, 100%, 0.75);
      border: none;
      &:hover {
        cursor: pointer;
        background: white;
      }
      &:focus {
        outline: none;
        box-shadow: 0 0 0 5px #19f;
      }
      &:active {
        opacity: 0.6;
      }
      &:disabled {
        pointer-events: none;
        cursor: auto;
        opacity: 0.3;
      }
    }
    .flickity-button-icon {
      fill: currentColor;
    }
    .flickity-prev-next-button {
      top: 50%;
      width: 44px;
      height: 44px;
      border-radius: 50%;
      transform: translateY(-50%);
      &.previous {
        left: 10px;
      }
      &.next {
        right: 10px;
      }
    }
    .flickity-rtl .flickity-prev-next-button.previous {
      right: 10px;
      left: auto;
    }
    .flickity-rtl .flickity-prev-next-button.next {
      right: auto;
      left: 10px;
    }
    .flickity-prev-next-button .flickity-button-icon {
      position: absolute;
      top: 20%;
      left: 20%;
      width: 60%;
      height: 60%;
    }
    .flickity-page-dots {
      bottom: 64px;
    }
    @at-root .p-cardPick.-column3 & {
      max-width: inherit;
      @include sp {
        max-width: 275px;
      }
      @include tb {
        max-width: 86%;
      }
      @include pc {
        padding: 0 80px;
      }
    }
    &.-gallery {
      display: flex;
      flex-wrap: wrap;
      padding: 0 20px;
      margin-right: auto;
      margin-left: auto;
      @include sp {
        max-width: 315px;
      }
      @include pc {
        flex-direction: row;
        max-width: 1360px;
        padding: 0;
      }
      .flickity-page-dots {
        @include pc {
          bottom: -26px;
        }
      }
    }
    &.-store {
      @include tb {
        padding: 0 80px !important;
        margin-bottom: 18px;
      }
    }
  }
  &__item {
    width: 100%;
    margin-left: 10%;
    @include onlyTB {
      max-width: 30%;
      margin-left: 5%;
    }
    @include pc {
      width: 28%;
      max-width: 365px;
      margin-bottom: 0;
      margin-left: 8%;
    }
    @at-root .p-cardPick__list.-store .p-cardPick__item {
      @include pc {
        width: 31%;
        max-width: 365px;
        margin-bottom: 0;
        margin-left: 13.8%;
      }
      &:not(:first-of-type) {
        @include pc {
          margin-left: 4.4%;
        }
      }
      &:nth-child(1) {
        position: absolute;
        left: 1%;
      }
      &:nth-child(2) {
        position: absolute;
        left: 45%;
      }
      &:nth-child(3) {
        position: absolute;
        left: 80%;
      }
    }
    @at-root .p-cardPick__list.-cafe .p-cardPick__item {
      &:nth-child(3n + 1) {
        margin-bottom: 61px;
        margin-left: 0;
      }
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        margin-top: 0;
        margin-bottom: 61px;
      }
    }
    @at-root .p-cardPick.-column3 & {
      max-width: 1000px;
      @include touch {
        margin-bottom: 60px;
        margin-left: 0;
      }
      @include onlyTB {
        width: 46%;
        &:nth-child(2n + 1) {
          margin-left: 0;
        }
        &:nth-child(2n + 2) {
          margin-left: 6%;
        }
      }
    }
  }
}

.flickity-prev-next-button {
  display: none;
  @include wide {
    top: 46% !important;
    display: block;
    width: 80px !important;
    height: 80px !important;
    color: #fff !important;
    background-color: rgba(84, 177, 180, 0.9) !important;
    border-radius: 50%;
    transform: translateY(-50%);
    &.previous {
      left: 43px !important;
    }
    &.next {
      right: 43px !important;
    }
  }
  @at-root .p-cardPick__list.-gallery & {
    display: none;
    @include pc {
      top: 50% !important;
      display: block;
      width: 80px;
      height: 80px;
      color: #fff !important;
      background-color: rgba(84, 177, 180, 0.9) !important;
      border-radius: 50%;
      transform: translateY(-50%);
      &.previous {
        left: -22px !important;
      }
      &.next {
        right: -22px !important;
      }
    }
    .flickity-page-dots {
      bottom: 0 !important;
    }
  }
  @at-root .c-slider.-gallery & {
    display: none;
    @include wide {
      position: absolute;
      top: 46% !important;
      display: block;
      width: 80px;
      height: 80px;
      color: #fff !important;
      background-color: rgba(84, 177, 180, 0.9) !important;
      border-radius: 50%;
      fill: #fff;
      transform: translateY(-50%);
      &.previous {
        left: -38px !important;
      }
      &.next {
        right: -38px !important;
      }
    }
    .flickity-page-dots {
      bottom: 0 !important;
    }
    .flickity-button {
      color: #fff !important;
    }
    .flickity-button-icon {
      position: absolute;
      top: 30%;
      left: 29%;
      width: 42%;
      height: 42%;
    }
    .flickity-viewport {
      height: 252.75px !important;
      @include pc {
        height: 450px;
      }
    }
    .flickity-enabled {
      height: 288.75px !important;
      @include pc {
        height: 450px;
      }
    }
  }
  .flickity-button-icon {
    position: absolute;
    top: 30% !important;
    left: 29% !important;
    width: 42% !important;
    height: 42% !important;
  }
  @at-root .c-slider.-about & {
    @include wide {
      position: absolute;
      top: 37% !important;
      display: block;
    }
  }
}

.c-slider {
  height: 288.75px !important;
  margin-top: 50px;
  @include pc {
    height: 490px !important;
  }
  &.-about {
    height: 480px !important;
    @include tb {
      height: 480px !important;
    }
    @include pc {
      height: 633px !important;
    }
  }
}

.c-slider .slider-item:not(:first-child) {
  @include tb {
    margin-left: 40px !important;
  }
  @include pc {
    margin-left: 78px !important;
  }
}

.c-slider .slider-item:nth-child(3) {
  @include tb {
    position: absolute;
  }
  @include pc {
    position: absolute;
  }
}

.flickity-button:disabled {
  display: none !important;
}

@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include touch{}
*/

.l-container {
  display: flex;
  flex-direction: column;
  &.-displayBlock {
    display: block;
  }
}

@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include touch{}
*/

.p-cafe {
  padding-bottom: 100px;
  &__photo {
    max-width: 880px;
    margin-top: -66px;
    margin-right: auto;
    margin-bottom: 55px;
    margin-left: auto;
    @include pc {
      margin-bottom: 100px;
    }
    figure {
      max-width: 880px;
      overflow: hidden;
      border-radius: 20px;
    }
  }
  &__btn {
    margin-top: 40px;
    text-align: center;
    @include pc {
      margin-top: 50px;
      margin-bottom: 160px;
    }
    &Text {
      margin-top: 20px;
      @include fz(13);
      @include pc {
        @include fz(14);
        margin-top: 30px;
      }
    }
    a.c-button {
      position: relative;
      display: block;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 400px;
      height: 56px;
      margin-right: auto;
      margin-left: auto;
      transition: all 0.3s ease 0s;
      &::before {
        position: absolute;
        top: 50%;
        right: 20px;
        width: 30px;
        height: 20px;
        content: "";
        background-image: url(../img/common/ico_arrow.svg);
        background-size: 100% auto;
        transform: translateY(-50%);
      }
      &:hover {
        @include pc {
          transition: all 0.3s ease 0s;
          transform: translateY(-0.3em);
        }
      }
    }
    &.-englishBtn {
      margin-top: 71px;
      @include tb {
        margin-top: 0;
      }
      a.c-button {
        max-width: 280px;
        height: 48px;
        margin-bottom: 104px;
        @include tb {
          max-width: 400px;
          height: 56px;
          margin-bottom: 0;
        }
      }
    }
  }
}

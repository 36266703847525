.p-workshop {
  @include pc {
    max-width: none;
    margin-right: -80px;
    margin-left: -80px;
  }
  @include touch {
    margin-right: -20px;
    margin-left: -20px;
  }
  &__main {
    margin-right: auto;
    margin-left: auto;
    @include pc {
      max-width: 1360px;
      padding: 78px 80px 84px;
    }
    @include touch {
      padding: 65px 20px 72px;
    }
  }
}

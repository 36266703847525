@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include touch{}
*/

html {
}

body {
  @include fz(16);
  color: #333;
  @include font-JP;
}

html > body {
  @include pc {
  }
}

* {
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

*::before,
*::after {
  box-sizing: inherit;
}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
span,
em,
small,
strong,
sub,
sup,
mark,
del,
ins,
strike,
abbr,
dfn,
blockquote,
q,
cite,
code,
pre,
ol,
ul,
li,
dl,
dt,
dd,
div,
section,
article,
main,
aside,
nav,
header,
hgroup,
footer,
img,
figure,
figcaption,
address,
time,
audio,
video,
canvas,
iframe,
details,
summary,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  padding: 0;
  margin: 0;
  border: 0;
}

a {
  -webkit-touch-callout: none;
}

a:hover {
  text-decoration: none;
}

article,
aside,
footer,
header,
nav,
section,
main {
  display: block;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

ol,
ul {
  list-style: none;
}

img,
video {
  max-width: 100%;
}

img {
  vertical-align: top;
  border-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote::after,
blockquote::before,
q::after,
q::before {
  content: "";
  content: none;
}

[hidden] {
  display: none !important;
}

[disabled] {
  cursor: not-allowed;
}

[data-whatintent="mouse"] :focus,
[data-whatintent="touch"] :focus {
  outline: none;
}

[data-whatintent="keyboard"] :focus {
  outline: 3px solid currentColor;
}

/* form */

input[type="text"] input[type="number"],
input[type="date"],
input[type="time"],
input[type="password"],
input[type="email"],
textarea {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

textarea {
  display: block;
}

fieldset {
  margin: 0;
  border: none;
}

legend {
  display: none;
}

/* table */

table {
  border-spacing: 0;
  border-collapse: collapse;
  caption {
  }
  th,
  td {
  }
  thead {
    th {
    }
  }
  tbody {
    th,
    td {
    }
    th {
    }
    td {
    }
  }
}

.p-galleryContent {
  @include pc {
    padding-top: 25px;
  }
  @include touch {
    padding-top: 48px;
  }
  &__title {
    position: relative;
    line-height: (26 / 22);
    text-align: center;
    @include pc {
      @include fz(22);
      padding-bottom: 20px;
    }
    @include touch {
      @include fz(20);
      padding-bottom: 12px;
    }
    &::after {
      position: absolute;
      bottom: 0;
      left: 50%;
      display: block;
      width: 60px;
      content: "";
      border-bottom: 3px solid;
      transform: translateX(-50%);
      @include touch {
        transform: translateX(-50%) scale(0.5);
      }
    }
  }
  &__banner {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    margin-top: 38px;
    overflow: hidden;
    color: #fff;
    text-align: center;
    @include pc {
      border-radius: 20px;
    }
    @include touch {
      flex-direction: column-reverse;
      border-radius: 10px;
    }
    &.-green {
      background-color: $var-green;
    }
    &.-blue {
      background-color: $var-blue;
      margin-bottom: 75px;
    }
  }
  &__bannerText {
    flex: 1;
    @include touch {
      padding-top: 48px;
      padding-bottom: 43px;
    }
  }
  &__head {
    letter-spacing: 0.06em;
    @include tb {
      @include fz(18);
    }
    @include touch {
      @include fz(20);
      margin-top: -8px;
    }
    @include wide {
      @include fz(21);
      margin-top: -50px;
    }
    &::after {
      content: "";
      display: block;
      border-bottom: 2px solid #fff;
      width: 30px;
      height: auto;
      margin: -16px auto 44px;
      margin: 4px auto 26px;
      @include tb {
        margin: 15px auto 30px;
        width: 60px;
      }
      @include pc {
        margin: 6px auto 11px;
      }
      @include wide {
        margin: 15px auto 44px;
      }
    }
    &.-permanent {
      @include wide {
        margin-top: -10px;
      }
    }
  }
  &__name {
    font-weight: bold;
    letter-spacing: 0.06em;
    @include pc {
      @include fz(28);
      margin-top: 6px;
      padding: 0 20px;
    }
    @include touch {
      @include fz(24);
      margin-top: 13px;
      line-height: (70 / 48);
    }
  }
  &__text {
    text-align: left;
    margin: 0 auto;
    padding: 0 33px;
    @include tb {
      padding: 0 20px;
    }
    @include pc {
      max-width: 417px;
    }
    &.-permanentText {
      @include touch {
        padding: 0 33px;
      }
      @include wide {
        padding: 0 20px;
      }
    }
  }
  &__description {
    @include fz(13);
    line-height: calc(26 / 13);
    letter-spacing: 0.06em;
    @include tb {
      @include fz(12);
      line-height: calc(23 / 13);
    }
    @include wide {
      @include fz(13);
      line-height: calc(26 / 13);
    }
    &:not(:first-of-type) {
      @include tb {
        margin-top: 7px;
      }
      @include pc {
        margin-top: 10px;
      }
      @include wide {
        margin-top: 20px;
      }
    }
    &.-permanentDescription {
      @include touch {
        line-height: calc(46 / 26);
      }
      &:not(:first-of-type) {
        @include touch {
          margin-top: 18px;
        }
      }
    }
  }
  &__break {
    display: inline-block;
  }
  &__period {
    letter-spacing: 0.06em;
    @include tb {
      @include fz(16);
      margin-top: 0;
    }
    @include pc {
      @include fz(16);
    }
    @include touch {
      @include fz(13);
      margin-top: 23px;
      letter-spacing: 0.12em;
    }
    @include wide {
      @include fz(18);
      margin-top: 33px;
    }
  }
  &__image {
    flex: 1;
    @include touch {
      width: 100%;
    }
    > img {
      width: 100%;
    }
  }
}

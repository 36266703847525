@charset "utf-8";
//@include sp {};
//@include tb {};
//@include pc {};
//@include onlyTB {};
//@include onlyPC {};

.p-inquires {
  margin-top: -19px;
  @include tb {
    width: 100%;
    max-width: 1440px;
    height: auto;
    margin: 34px auto 144px;
  }
  &__inner {
    padding-top: 50px;
    @include tb {
      display: block;
      width: 100%;
      max-width: 878px;
      height: auto;
      margin: 0 auto;
    }
    @include pc {
      padding: 0;
    }
    @at-root .p-inquires.-complete & {
      margin-bottom: 81px;
      @include tb {
        margin-bottom: 62px;
      }
    }
  }
  &__lead {
  }
  &__text {
    margin-bottom: 15px;
    @include tb {
      margin-bottom: 8px;
    }
    @at-root .p-inquires.-complete & {
      margin-bottom: -4px;
      @include tb {
        margin-bottom: 18px;
      }
    }
  }
  &__description {
    @include sourceHanSansJP_regular;
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 1.9;
    letter-spacing: 0.06em;
    @include tb {
      margin-bottom: 0;
      font-size: 15px;
      line-height: 2.2;
      letter-spacing: 0.12em;
    }
    @at-root .p-inquires.-confirm & {
      line-height: 2;
      @include tb {
        margin-bottom: 10px;
      }
    }
    .-must {
      color: #d73d5b;
    }
    &.-English {
      letter-spacing: 0.07em;
    }
  }
  &__descriptionSP {
    display: block;
    margin-bottom: -12px;
    @include tb {
      display: block;
      margin-bottom: -33px;
    }
  }
  &__form {
    padding: 30px 0 0;
    margin-bottom: 36px;
    @include tb {
      padding: 56px 0 0;
      margin-bottom: 49px;
    }
  }
  &__formArea {
    @include tb {
      display: flex;
      justify-content: space-between;
    }
    &:not(:last-child) {
      @include tb {
        margin-bottom: 5px;
      }
    }
  }
  &__formOneLine {
    width: 100%;
    margin-bottom: 28px;
    @include tb {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    @at-root .p-inquires__formArea.-mb0 & {
      @include tb {
        margin-bottom: 0;
      }
    }
  }
  &__formOneLineInner {
    display: flex;
    margin-bottom: 11px;
    @include tb {
      display: flex;
      width: 50%;
    }
    @at-root .p-inquires__formArea.-br & {
      display: block;
    }
  }
  &__textName {
    @include sourceHanSansJP_bold;
    font-size: 14px;
    line-height: calc(22 / 14);
    letter-spacing: 0.06em;
    @include tb {
      line-height: calc(22 / 14);
      letter-spacing: 0.12em;
    }
    span {
      margin-left: 9px;
      font-size: 14px;
      color: #d73d5b;
      @include tb {
        margin-left: 5px;
      }
    }
  }
  &__fillInArea {
    position: relative;
    width: 100%;
    height: 47px;
    padding-left: 18px;
    font-size: 14px;
    line-height: calc(22 / 14);
    color: #727272;
    letter-spacing: 0.06em;
    background-color: #fff;
    border: 3px solid #f7d65b;
    border-radius: 0;
    appearance: none;
    @include sourceHanSansJP_regular;
    @include tb {
      width: 47.9%;
      height: 50px;
      letter-spacing: 0.12em;
    }
  }
  &__serect {
    position: relative;
    display: inline-block;
    width: 100%;
    &::after {
      position: absolute;
      top: 42%;
      right: 17.5px;
      width: 12px;
      height: 12px;
      margin-top: -4px;
      content: "";
      border: 0;
      border-right: solid 3px #519c60;
      border-bottom: solid 3px #519c60;
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      @include tb {
        right: 25.5px;
        width: 10px;
        height: 10px;
        border-right: solid 2px #519c60;
        border-bottom: solid 2px #519c60;
      }
    }
    @include tb {
      width: 47.9%;
      height: 50px;
    }
    & > .p-inquires__fillInArea {
      padding-left: 12px;
      @include tb {
        width: 100%;
        height: 50px;
        padding-left: 17px;
      }
    }
  }
  &__fillInTextArea {
    width: 100%;
    height: 273px;
    padding: 18px;
    border: 3px solid #f7d65b;
    @include tb {
      width: 47.9%;
      height: 361px;
    }
  }
  &__error {
  }
  &__errorMessage {
    @include sourceHanSansJP_bold;
    margin-left: 5px;
    font-size: 14px;
    color: #d73d5b;
    @at-root .p-inquires__formArea.-br & {
      margin-left: 0;
    }
  }
  textarea {
    resize: none;
  }
  &.-confirm {
    @include pc {
      margin-bottom: 125px;
    }
  }
  &.-complete {
    margin-top: -17px;
    margin-bottom: 123px;
    @include tb {
      margin-top: 30px;
    }
    @include pc {
      margin-top: 83px;
      margin-bottom: 131px;
    }
  }
}

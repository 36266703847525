@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include touch{}
*/

.p-ticket {
  position: relative;
  @include pc {
    position: static;
    max-width: 1200px;
    height: auto;
    margin: 0 auto;
  }
  .c-pageTitle {
    margin: 0 0 2.1em;
  }
  &__note {
    max-width: 880px;
    height: auto;
    margin: 0 auto;
    margin-top: 62px;
    line-height: (46/26);
    letter-spacing: 0.03em;
    @include fz(13);
    @include pc {
      margin-top: 32px;
      line-height: (26/13);
      letter-spacing: 0.12em;
    }
    @at-root .p-ticket__section.-info & {
      @include touch {
        padding: 0 20px;
      }
    }
    &Item {
      padding-left: 1.5em;
      text-indent: -1.5em;
    }
  }
  &__section.-fee {
    .c-h2 {
      margin-bottom: 28px;
      @include pc {
        margin-bottom: 44px;
      }
      &:lang(en) {
        margin-bottom: 28px;
        @include tb {
          margin-bottom: 38px;
          @include fz(22);
          line-height: calc(26 / 22);
          letter-spacing: 0.12em;
        }
      }
    }
    .p-ticket {
      &__lead {
        @include lhCrop(52/28);
        @include fz(14);
        margin-bottom: 56px;
        line-height: (52/28);
        letter-spacing: 0.06em;
        @include tb {
          @include lhCrop(26/15);
          @include fz(15);
          padding-right: 120px;
          margin-bottom: 46px;
          line-height: (26/15);
          letter-spacing: 0.12em;
        }
      }
      &Fee {
        &__table {
          width: 100%;
          border: 3px solid #519c60;
          &.-sp {
            @include pc {
              display: none;
            }
            caption {
              padding: 15px 8px 10px;
              line-height: (26/20);
              color: #fff;
              letter-spacing: 0.12em;
              background: #519c60;
              @include lhCrop(26/20);
              @include fz(20);
              h3 {
                font-weight: 700;
                &:lang(en) {
                  @include fz(20);
                  line-height: calc(26 / 40);
                  letter-spacing: 0.12em;
                  @include tb {
                    @include fz(22);
                    line-height: calc(26 / 22);
                  }
                }
              }
            }
            tbody {
              display: block;
              padding: 20px 20px 0;
              &:not(:first-of-type) {
                border-top: 3px solid #519c60;
              }
              tr:not(:nth-of-type(2)) td {
                border-top: 3px solid #519c60;
              }
            }
            tr,
            th,
            td {
              display: block;
              width: 100%;
            }
            th {
              padding: 8px;
              font-weight: 500;
              color: #fff;
              letter-spacing: 0.12em;
              background: #519c60;
              @include fz(14);
            }
            td {
              dl {
                display: flex;
                flex: 0 0 auto;
                align-items: center;
                justify-content: space-around;
                padding: 18px 0;
              }
              dt {
                flex-basis: 50%;
                width: 50%;
                font-weight: 400;
                text-align: center;
                letter-spacing: 0.06em;
                @include fz(14);
                &.-englishDT {
                  @include fz(12);
                  line-height: calc(32 / 24);
                  letter-spacing: 0.06em;
                  @include tb {
                    @include fz(13);
                    line-height: calc(19 / 13);
                    letter-spacing: 0.12em;
                  }
                }
              }
              dd {
                flex-basis: 50%;
                width: 50%;
                font-weight: 700;
                text-align: center;
                letter-spacing: 0.12em;
                @include fz(20);
              }
            }
          }
          &.-pc {
            display: none;
            @include pc {
              position: relative;
              display: table;
              width: 100%;
            }
            caption {
              //display: block;
              position: absolute;
              top: 22px;
              left: 34px;
              line-height: (26/22);
              color: #fff;
              letter-spacing: 0.12em;
              @include lhCrop(26/22);
              @include fz(22);
              h3 {
                font-weight: 500;
              }
            }
            thead {
              th {
                background: #519c60;
              }
            }
            tbody {
              &:not(:first-of-type) {
                border-top: 3px solid #519c60;
              }
              th {
                padding: 30px 8px 26px 27px;
              }
            }
            th,
            td {
              text-align: center;
            }
            th {
              @include fz(16);
              width: 25%;
              padding: 20px 5px 18px;
              font-weight: 500;
              color: #fff;
              span {
                display: inline-block;
                width: 100%;
                padding: 23px 0 20px;
                line-height: (26/16);
                background: #519c60;
                @include lhCrop(26/16);
                @include fz(16);
                &:lang(en) {
                  letter-spacing: 0.12em;
                }
              }
              &:lang(en) {
                @include fz(12);
                line-height: calc(32 / 12);
                letter-spacing: 0.06em;
                @include tb {
                  @include fz(13);
                  line-height: calc(19 / 13);
                  letter-spacing: 0.12em;
                }
              }
            }
            td {
              @include fz(22);
              position: relative;
              width: 25%;
              padding: 40px 0;
              font-weight: 700;
              letter-spacing: 0.12em;
              &:not(:first-of-type)::after {
                position: absolute;
                top: 50%;
                left: -1px;
                display: block;
                width: 3px;
                height: 60px;
                content: "";
                background: #519c60;
                transform: translateY(-50%);
              }
            }
          }
        }
      }
      &Schedule {
        margin-top: 40px;
        @include pc {
          margin-top: 25px;
        }
        & + .p-ticket__note {
          margin-top: 34px;
          @include pc {
            margin-top: 27px;
          }
        }
        & + .c-h2 {
          margin-top: 70px;
        }
        &__table {
          width: 100%;
          border: 3px solid #519c60;
          @include pc {
          }
          caption {
            width: 100%;
            padding: 15px 24px 12px;
            font-weight: 700;
            line-height: 1;
            color: #fff;
            text-align: left;
            letter-spacing: 0.12em;
            vertical-align: middle;
            background: #519c60;
            @include fz(20);
            @include clearfix;
            @include pc {
              @include fz(22);
              padding: 24px 30px 18px;
              font-weight: 500;
            }
            span {
              display: inline-block;
              float: right;
              margin-top: 0.2em;
              font-weight: 500;
              @include fz(14);
              @include pc {
                @include fz(16);
              }
            }
          }
          tr,
          th,
          td {
            display: block;
            width: 100%;
          }
          th {
            padding: 8px;
            font-weight: 500;
            color: #fff;
            letter-spacing: 0.12em;
            background: #519c60;
            @include fz(14);
          }
          td {
            dl {
              display: flex;
              flex: 0 0 auto;
              align-items: center;
              justify-content: space-around;
              padding: 18px 0;
            }
            dt {
              flex-basis: 34%;
              width: 34%;
              font-weight: 400;
              text-align: center;
              letter-spacing: 0.06em;
              @include fz(14);
              &.-englishDT {
                @include fz(12);
                line-height: calc(32 / 24);
                letter-spacing: 0.06em;
                @include tb {
                  @include fz(13);
                  line-height: calc(19 / 13);
                  letter-spacing: 0.12em;
                }
              }
            }
            dd {
              position: relative;
              display: flex;
              flex-basis: 66%;
              justify-content: space-around;
              width: 66%;
              font-weight: 700;
              text-align: center;
              letter-spacing: 0.12em;
              @include fz(20);
            }
            time {
              @include fz(20);
              @include sourceHanSansJP_bold;
              @include pc {
                @include fz(22);
              }
              &:first-of-type::after {
                position: absolute;
                top: 50%;
                left: 50%;
                display: inline-block;
                width: 10px;
                height: 2px;
                vertical-align: middle;
                content: "";
                background: #3a3a3a;
                transform: translate(-50%, -50%);
              }
            }
          }
        }
        &__table_02 {
          width: 100%;
          table-layout: fixed;
          border: 3px solid #519c60;
          caption {
            width: 100%;
            padding: 17px 24px 14px;
            font-weight: 700;
            line-height: 1;
            color: #fff;
            text-align: left;
            letter-spacing: 0.12em;
            vertical-align: middle;
            background: #519c60;
            @include fz(20);
            @include clearfix;
            @include pc {
              @include fz(22);
              padding: 24px 30px 18px;
              font-weight: 500;
            }
            span {
              display: inline-block;
              margin-top: 21px;
              font-weight: 500;
              line-height: 1.3;
              @include fz(12);
              @include pc {
                float: right;
                margin-top: 0.2em;
                @include fz(16);
              }
            }
          }
          tbody {
            display: block;
            padding: 20px 0;
            @include pc {
              padding: 34px 0 23px;
            }
            tr {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-around;
              padding: 0 10px;
              @include pc {
                padding: 0 30px;
              }
              td {
                display: flex;
                align-items: center;
                justify-content: center;
                width: calc((100% - 15px) / 2);
                min-height: 50px;
                padding: 4px;
                margin: 9px 0;
                font-weight: bold;
                text-align: center;
                border: 2.5px solid #f7d65b;
                border-radius: 30px;
                @include fz(20);
                @include pc {
                  width: calc((100% - 84px) / 4);
                  min-height: 60px;
                  padding: 7px;
                  margin: 14px 0;
                  border-width: 4px;
                  @include fz(22);
                }
                & + td {
                  margin-left: 15px;
                  @include pc {
                    margin-left: 28px;
                  }
                }
                &:nth-child(odd) {
                  @include under_pc {
                    margin-left: 0;
                  }
                }
                &:nth-child(4n + 1) {
                  @include pc {
                    margin-left: 0;
                  }
                }
                &._danger {
                  color: #d73d5b;
                  border-color: #d73d5b;
                }
                &._danger_text {
                  min-height: 0;
                  padding: 0;
                  margin-top: 0;
                  margin-left: auto !important;
                  color: #d73d5b;
                  border: none;
                  @include fz(10);
                  @include pc {
                    @include fz(14);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &__section.-info {
    padding-top: 70px;
    padding-bottom: 160px;
    margin-top: 55px;
    margin-right: calc(((100vw - 100%) / 2) * -1);
    margin-left: calc(((100vw - 100%) / 2) * -1);
    background: #f6f5f3;
    @include pc {
      padding-top: 90px;
      padding-bottom: 160px;
      margin-top: 70px;
    }
    .p-ticket__inner {
      padding: 0 20px;
      @include pc {
        padding: 0;
      }
    }
    .p-ticketInfo {
      max-width: 880px;
      margin: auto;
    }
    .p-ticketInfo__table {
      width: 100%;
      @include pc {
        width: 880px;
        margin: 0 auto;
        th.-englishTh {
          margin-bottom: 20px;
          background-color: #f6f5f3;
          @include tb {
            @include fz(16);
            line-height: calc(26 / 36);
            letter-spacing: 0.12em;
          }
        }
      }
      .p-ticketInfo__icon {
        display: inline-block;
        vertical-align: middle;
      }
      .p-ticketInfo__text {
        @include visually-hidden;
      }
      .-enabled {
        .p-ticketInfo__icon {
          width: 20px;
          height: 20px;
          border: 2px solid #d73d5b;
          border-radius: 50%;
          @include pc {
            width: 30px;
            height: 30px;
            border-width: 3px;
          }
        }
        .-smallText {
          @include fz(12);
          @include tb {
            margin-left: 0 !important;
          }
          @include pc {
            @include fz(13);
            margin-left: 25px !important;
          }
        }
      }
      .-disabled {
        .p-ticketInfo__icon {
          position: relative;
          width: 16px;
          height: 16px;
          @include pc {
            width: 23px;
            height: 23px;
          }
          &::before {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 20px;
            height: 2px;
            content: "";
            background: #3a3a3a;
            transform: translate(-50%, -50%) rotate(45deg);
            @include pc {
              width: 30px;
              height: 3px;
            }
          }
          &::after {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 20px;
            height: 2px;
            content: "";
            background: #3a3a3a;
            transform: translate(-50%, -50%) rotate(-45deg);
            @include pc {
              width: 30px;
              height: 3px;
            }
          }
        }
        .-smallText {
          @include fz(12);
          @include tb {
            margin-left: 0 !important;
          }
          @include pc {
            @include fz(13);
            margin-left: 25px !important;
          }
        }
      }
      .-ended {
        .p-ticketInfo__icon {
          width: 18px;
          height: 2px;
          background: #54b1b4;
          @include pc {
            width: 26px;
            height: 3px;
          }
        }
        .-smallText {
          @include fz(12);
          @include tb {
            margin-left: 0 !important;
          }
          @include pc {
            @include fz(13);
            margin-left: 25px !important;
          }
        }
      }
      caption {
        @include fz(20);
        padding: 0 20px;
        margin-bottom: 40px;
        text-align: left;
        letter-spacing: 0.12em;
        @include tb {
          box-sizing: border-box;
          float: left;
          width: 40%;
          padding: 0 0 0 20px;
          @include fz(22);
        }
        @include pc {
          padding: 0;
        }
      }
      thead {
        display: block;
        max-width: 450px;
        margin-bottom: 40px;
        @include tb {
          box-sizing: border-box;
          float: right;
          width: 60%;
          padding-right: 20px;
        }
        @include pc {
          padding-right: 0;
        }
        tr {
          display: block;
        }
        th {
          display: flex;
          justify-content: space-between;
          padding: 0 20px;
          text-align: center;
          @include tb {
            display: block;
            padding: 0;
            text-align: right;
          }
          span {
            @include fz(12);
            font-weight: 400;
            @include tb {
              display: inline-block;
              margin-right: 5px;
              font-weight: 500;
              @include fz(13);
              &:not(:first-of-type) {
                margin-left: 25px;
              }
            }
            .p-ticketInfo__icon {
              margin-right: 18px;
              @include tb {
                margin-right: 20px;
                transform: scale(0.83);
              }
              &.-englishMargin {
                @media print, screen and (max-width: 340px) {
                  margin-right: 5px;
                }
                @include sp {
                  margin-right: 10px;
                }
                @include tb {
                  margin-right: 8px;
                }
                @include pc {
                  margin-right: -12px;
                }
              }
            }
            .-enabled .p-ticketInfo__icon {
              border-width: 4px;
            }
            &.-englishMarginLeft {
              margin-left: 2px !important;
              @include tb {
              }
              @include pc {
                margin-left: 20px !important;
              }
            }
          }
        }
      }
      tbody {
        position: relative;
        padding-bottom: 20px;
        margin: 27px 20px 0;
        background: #fff;
        border-radius: 8px;
        @include sp {
          display: block;
        }
        @media (max-width: 980px - 1) {
          display: block;
          clear: both;
        }
        &.-englishLineColor {
          z-index: 4;
          background-color: #f6f5f3;
          border-bottom: 3px solid #fff;
          @include pc {
            z-index: 0;
            border-bottom: 5px solid #fff;
          }
          &::after {
            z-index: 3;
          }
        }
        &:first-of-type {
          margin-top: 0;
          @include pc {
            position: relative;
            z-index: 1;
            display: block;
            width: 66.6%;
            border-top-left-radius: 16px;
            border-bottom-left-radius: 16px;
            tr,
            th,
            td {
              width: 24%;
            }
            th {
              padding-left: 50%;
              &.-englishTh {
                border-top-left-radius: 8px;
                @include tb {
                  @include fz(16);
                  line-height: calc(26 / 36);
                  letter-spacing: 0.12em;
                  border-top-left-radius: 16px;
                }
              }
            }
            td {
              .p-ticketInfo__time,
              .p-ticketInfo__iconWrap {
                width: 48%;
                text-align: center;
              }
            }
            &::before,
            &::after {
              position: absolute;
              top: 0;
              z-index: 3;
              width: 3px;
              height: 100%;
              content: "";
              background: #f6f5f3;
              @include pc {
                width: 5px;
              }
            }
            &::before {
              left: 47%;
            }
            &::after {
              left: 100%;
            }
          }
        }
        &:last-of-type {
          @include pc {
            width: 33.3%;
            border-top-right-radius: 16px;
            border-bottom-right-radius: 16px;
            tr,
            th,
            td {
              width: 33.3%;
            }
            td {
              text-align: center;
            }
          }
        }
        @include pc {
          float: left;
          padding-bottom: 0;
          margin: 0;
          border-radius: 0;
          &:not(:first-of-type) {
            td time {
              display: none;
            }
          }
        }
        &::after {
          position: absolute;
          top: 0;
          left: 66%;
          z-index: 1;
          width: 3px;
          height: 100%;
          content: "";
          background: #f6f5f3;
          @include tb {
            left: calc(50% - 2px);
          }
          @include pc {
            content: none;
          }
          @at-root tbody.-englishLineColor & {
            background-color: #fff;
          }
        }
        tr,
        th {
          @include sp {
            display: block;
          }
          @media (max-width: 980px - 1) {
            display: block;
          }
        }
        tr:nth-of-type(2) {
          td {
            @include pc {
              height: 86px;
              padding-top: 20px;
            }
          }
        }
        tr:last-of-type {
          td {
            @include pc {
              height: 86px;
              padding-bottom: 20px;
            }
            &.-radiusLeftBottom {
              border-bottom-left-radius: 8px;
              @include pc {
                border-bottom-left-radius: 16px;
              }
            }
            &.-radiusRightBottom {
              border-bottom-left-radius: 8px;
              @include pc {
                border-bottom-right-radius: 16px;
              }
            }
          }
        }
        th {
          position: relative;
          z-index: 2;
          padding: 24px 8px 20px;
          margin-bottom: 20px !important;
          font-weight: 500;
          line-height: 1;
          text-align: center;
          background: #fff;
          border-bottom: 3px solid #f6f5f3;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          @include fz(18);
          @include pc {
            padding: 30px 8px 25px;
            margin-bottom: 0;
            background: none;
            border-bottom: 5px solid #f6f5f3;
            border-radius: 0;
          }
          &.-englishLineColor {
            z-index: 4;
            margin-bottom: 20px !important;
            background-color: #f6f5f3;
            border-bottom: 3px solid #fff;
            @include pc {
              z-index: 0;
              border-bottom: 5px solid #fff;
            }
            &::after {
              z-index: 3;
              @include pc {
                z-index: 0;
              }
            }
          }
          &.-radiusRightTop {
            border-top-right-radius: 8px;
            @include pc {
              border-bottom: 5px solid #fff;
              border-top-right-radius: 16px;
            }
          }
        }
        td {
          display: flex;
          flex: 0 0 auto;
          justify-content: space-between;
          padding: 8px 0;
          @include pc {
            display: table-cell;
            height: 60px;
            padding: 0;
          }
          .p-ticketInfo__time {
            box-sizing: border-box;
            flex-basis: 65.7%;
            padding-left: 20px;
            @include tb {
              flex-basis: 50%;
            }
            @include pc {
              display: inline-block;
              padding: 0;
            }
            time {
              @include fz(16);
              @include sourceHanSansJP_bold;
              letter-spacing: 0.12em;
              @include pc {
                @include fz(22);
                vertical-align: middle;
              }
              &:first-of-type::after {
                display: inline-block;
                width: 10px;
                height: 2px;
                margin: 0 20px;
                vertical-align: super;
                content: "";
                background: #3a3a3a;
                @include pc {
                  margin: 0 12px;
                }
              }
            }
          }
          .p-ticketInfo__iconWrap {
            flex-basis: 34%;
            text-align: center;
            @include tb {
              flex-basis: 50%;
            }
            @include pc {
              display: inline-block;
              width: auto;
            }
          }
          .p-ticketInfo__icon {
            vertical-align: sub;
            @include pc {
              vertical-align: middle;
            }
          }
        }
        &.-englishLineColor {
          &::before,
          &::after {
            content: "";
            background: #fff !important;
            @include pc {
              z-index: 2;
            }
          }
        }
        &.-englishLineColor {
          &::after {
            content: "";
            background: #fff !important;
            @include pc {
              z-index: -2;
            }
          }
        }
        .-englishBackColor {
          background-color: #f6f5f3 !important;
        }
        &.-spLine {
          &::after {
            position: absolute;
            top: 0;
            left: 66%;
            z-index: 1;
            width: 3px;
            height: 100%;
            content: "";
            background: #fff !important;
            @include tb {
              left: calc(50% - 2px);
            }
            @include pc {
              content: none;
            }
          }
        }
      }
    }
    .p-ticketInfo__title {
      @include fz(20);
      @include pc {
        @include fz(22);
      }
      & + .p-ticket__note {
        padding: 0;
        margin-top: 30px;
      }
    }
    .p-ticketInfo__table_02 {
      width: 100%;
      margin-top: 35px;
      background: #fff;
      border-radius: 10px;
      @include pc {
        margin-top: 47px;
      }
      tr {
        line-height: (20 / 13);
        @include fz(13);
        @include pc {
          line-height: (26 / 16);
          @include fz(16);
        }
        & + tr {
          border-top: 3px solid $var-bg-beige;
          @include pc {
            border-width: 4px;
          }
        }
        th,
        td {
          padding: {
            top: 19px;
            right: 0.4em;
            bottom: 18px;
            left: 1em;
          }
          @include pc {
            padding: {
              top: 24px;
              bottom: 21px;
            }
          }
        }
        th {
          width: calc(134.5 / 336 * 100%);
          font-weight: normal;
          border-right: 3px solid $var-bg-beige;
          @include pc {
            width: calc(274 / 879 * 100%);
            border-width: 4px;
          }
        }
        td {
          width: calc(198.5 / 336 * 100%);
          text-align: center;
          @include pc {
            width: calc(602 / 879 * 100%);
          }
          &._danger {
            color: #d73d5b;
          }
        }
      }
      &._bg_baige {
        background: $var-bg-beige;
        tr + tr {
          border-color: #fff;
        }
        th {
          border-color: #fff;
        }
      }
    }
  }
  &__section.-englishBottom {
    padding-bottom: 0 !important;
    background-color: #fff !important;
  }
  &__inner {
    @include pc {
      max-width: 880px;
      height: auto;
      margin: 0 auto;
    }
  }
  &__form {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 500px;
    margin: 42px auto 100px;
    @include pc {
      width: auto;
      max-width: none;
      margin: 54px auto 100px;
    }
    &.disabled {
      input[type="submit"] {
        pointer-events: none;
        background: #cecece;
      }
    }
    input[type="submit"] {
      width: 100%;
      padding: 20px 50px;
      font-weight: 500;
      line-height: 1.625;
      color: #fff;
      letter-spacing: 0.12em;
      background: #74b4d8;
      border-radius: 35px;
      transition: all 0.3s ease 0s;
      @include fz(16);
      @include pc {
        width: 554px;
        max-width: none;
        padding: 30px 0;
        line-height: 1.44444;
        border-radius: 40px;
        @include fz(18);
      }
      &:hover {
        @include pc {
          transform: translateY(-0.3em);
        }
      }
    }
  }
}

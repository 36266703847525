@charset "utf-8";
//@include sp {};
//@include tb {};
//@include pc {};

.p-user {
  @include pc {
    max-width: 878px;
    height: auto;
    padding: 0;
    margin: 0 auto;
  }
  h2 {
    @include sourceHanSansJP_bold;
    font-size: 18px;
    line-height: calc(32 / 16);
    letter-spacing: 0.12em;
    @include tb {
      font-size: 22px;
      line-height: calc(26 / 22);
      letter-spacing: 0.12em;
    }
    &::after {
      display: block;
      width: 30px;
      height: 1px;
      margin-top: 4px;
      content: "";
      background-color: #444;
      @include tb {
        width: 60px;
        height: 2.5px;
        margin-top: 18px;
      }
    }
  }
  p + h2 {
    margin-top: 57px;
    @include tb {
      margin-top: 50px;
    }
  }
  h3 {
    @include sourceHanSansJP_bold;
    margin-top: 24px;
    margin-bottom: 12px;
    font-size: 14px;
    line-height: calc(52 / 28);
    letter-spacing: 0.06em;
    @include tb {
      margin-top: 29px;
      margin-bottom: 10px;
      font-size: 16px;
      line-height: calc(32 / 16);
      letter-spacing: 0.12em;
    }
  }
  p {
    @include sourceHanSansJP_regular;
    margin-bottom: 19px;
    font-size: 14px;
    line-height: calc(52 / 28);
    letter-spacing: 0.06em;
    @include tb {
      margin-top: 9px;
      margin-bottom: 19px;
      font-size: 15px;
      line-height: calc(32 / 15);
      letter-spacing: 0.12em;
    }
  }
  &__linkarea {
    width: 100%;
    height: auto;
    margin-top: 43px;
    margin-bottom: 50px;
    @include tb {
      margin-top: 47px;
      margin-bottom: 54px;
    }
    strong {
      @include sourceHanSansJP_bold;
      margin-bottom: 8px;
      font-size: 14px;
      line-height: calc(52 / 28);
      letter-spacing: 0.06em;
      @include tb {
        margin-bottom: 0;
        font-size: 16px;
        line-height: calc(32 / 15);
        letter-spacing: 0.12em;
      }
    }
    a {
      display: inline;
      padding-bottom: 5px;
      margin-top: 13px;
      font-size: 14px;
      line-height: calc(52 / 28);
      letter-spacing: 0.06em;
      word-break: break-all;
      border-bottom: 1px solid #444;
      @include sourceHanSansJP_regular;
      @include tb {
        display: inline-block;
        padding-bottom: 7px;
        margin-top: 16px;
        font-size: 15px;
        line-height: 1;
        letter-spacing: 0.12em;
      }
      &:hover {
        @include tb {
          padding-bottom: 8px;
          border-bottom: none;
        }
      }
      &:not(:last-of-type) {
        margin-bottom: 51px;
        @include tb {
          margin-bottom: 53px;
        }
      }
    }
    & > p {
      margin-bottom: 9px;
      @include tb {
        margin-bottom: -2px !important;
      }
      &:not(:first-of-type) {
        margin-top: 51px;
        @include tb {
          margin-top: 0;
        }
      }
    }
  }
}

@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include touch{}
*/

.c-pullmenu {
  position: relative;
  display: inline-block;
  &::before {
    position: absolute;
    top: 50%;
    right: 25px;
    z-index: 1;
    display: block;
    width: 9px;
    height: 9px;
    margin-top: -2px;
    pointer-events: none;
    content: "";
    border-right: 2px solid #519c60;
    border-bottom: 2px solid #519c60;
    transform: translateY(-50%) rotate(45deg);
  }
  select {
    height: 42px;
    padding: 0 60px;
    font-size: inherit;
    color: inherit;
    color: #519c60;
    text-indent: 0.01px;
    text-overflow: "";
    letter-spacing: 0.12em;
    vertical-align: middle;
    background: none transparent;
    border: 3px solid #519c60;
    border-radius: 20px;
    outline: none;
    appearance: button;
    appearance: none;
    @include fz(14);
  }
}

//select option{
//  background-color: #fff;
//  color: #333;
//}
//select::-ms-expand {
//  display: none;
//}
//select:-moz-focusring {
//  color: transparent;
//  text-shadow: 0 0 0 #828c9a;
//}
//.select-wrap.select-primary:before{
//  color:#fff;
//}
//.select-wrap.select-primary > select{
//  background:#0084B4;
//  color:#fff;
//  border-color:#0084B4;
//}
//.select-wrap.select-primary > select:-moz-focusring {
//  color: transparent;
//  text-shadow: 0 0 0 #fff;
//}
//.select-wrap.select-inverse:before{
//  color:#fff;
//}
//.select-wrap.select-inverse > select{
//  color:#fff;
//  border-color: #fff;
//}
//
//.select-wrap.select-inverse > select:-moz-focusring {
//  color: transparent;
//  text-shadow: 0 0 0 #fff;
//}

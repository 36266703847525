@charset "utf-8";

/*
@include touch {} // max-width: 979px
@include sp {}    // max-width: 599px
@include tb {}    // min-width: 600px
@include pc {}    // min-width: 980px
@include wide {}  // min-width: 1280px
*/

/* -------------------------------------------------- main */
.l-main {
  box-sizing: content-box;
  max-width: 1200px;
  padding: 0 20px;
  margin: 0 auto;
}

.l-inner {
  max-width: 880px;
  margin: 88px auto;
}

@include pc {
  .l-main {
    padding: 0 80px;
  }
}

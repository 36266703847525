@charset "utf-8";
//@include sp {};
//@include tb {};
//@include pc {};

.p-privacy {
  margin: 0 auto;
  margin-bottom: 115px;
  @include pc {
    width: 100%;
    max-width: 1440px;
    height: auto;
    padding: 0;
    margin-bottom: 206px;
  }
}

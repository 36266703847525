.p-workshopBenefit {
  background-color: #fff;
  border-radius: 20px;
  &__inner {
    /* autoprefixer grid: no-autoplace */
    display: grid;
    @include pc {
      grid-template-areas: "figure title" "figure body";
      grid-template-rows: repeat(2, auto);
      grid-template-columns: 1fr 2fr;
      row-gap: 14px;
    }
    @include touch {
      grid-template-areas: "figure" "title" "body";
      grid-template-rows: repeat(3, auto);
      grid-template-columns: 1fr;
      padding-right: 30px;
      padding-left: 30px;
    }
  }
  &__title {
    @include fz(18);
    grid-area: title;
    @include pc {
      margin-top: 57px;
      margin-right: 80px;
    }
    @include touch {
      margin-top: 34px;
    }
  }
  &__body {
    grid-area: body;
    @include pc {
      padding-bottom: 50px;
      margin-right: 80px;
    }
    @include touch {
      padding-bottom: 35px;
    }
  }
  &__text {
    @include pc {
      @include fz(15);
      line-height: (32 / 15);
      letter-spacing: 0.05em;
    }
    @include touch {
      @include fz(14);
      line-height: (52 / 28);
    }
    & + & {
      @include pc {
        margin-top: 32 / 15 * 0.5em;
      }
      @include touch {
        margin-top: 52 / 28 * 0.5em;
      }
    }
  }
  &__figure {
    display: flex;
    grid-area: figure;
    align-items: center;
    justify-content: center;
    @include touch {
      padding-top: 48px;
    }
  }
}

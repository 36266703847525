@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include touch{}
*/

.p-cardAll {
  padding-top: 80px;
  overflow: hidden;
  &__title {
    @include font-webfont_zeitung_bold;
    @include fz(22);
    text-transform: uppercase;
    letter-spacing: 0.12em;
  }
  &__meta {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
  }
  &__select {
    display: flex;
    justify-content: flex-end;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
    @include sp {
      max-width: 275px;
    }
    @include onlyTB {
      justify-content: center;
    }
    @include pc {
      justify-content: flex-start;
    }
  }
  &__item {
    margin-bottom: 60px;
    @include tb {
      width: 46%;
      margin-bottom: 80px;
      margin-left: 7%;
      &:nth-child(2n + 1) {
        margin-left: 0;
      }
    }
    @include pc {
      width: 23% !important;
      margin-bottom: 80px;
      margin-left: 2% !important;
      &:nth-child(2n + 1) {
        margin-left: 2%;
      }
    }
    @include pc {
      max-width: (100%/4);
      margin-bottom: 80px;
      margin-left: 26px;
      &:nth-child(4n + 1) {
        margin-left: 0;
      }
    }
  }
  .c-sectionInner {
    margin-bottom: 80px;
  }
}

.p-accessRailway {
  @include pc {
    padding-top: 100px;
    padding-bottom: 130px;
  }
  @include touch {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  &__body {
    /* autoprefixer grid: no-autoplace */
    @include pc {
      display: grid;
      grid-template-areas: "map map" "summary detail";
      grid-template-rows: repeat(2, auto);
      grid-template-columns: 43% 1fr;
      gap: 60px;
      margin-top: 30px;
    }
    @include touch {
      display: grid;
      grid-template-areas: "map" "summary" "detail";
      grid-template-rows: repeat(3, auto);
      grid-template-columns: 100%;
      row-gap: 30px;
      margin-top: 40px;
    }
  }
  &__summary {
    grid-area: summary;
  }
  &__text {
    letter-spacing: 0.06em;
    @include pc {
      @include fz(15);
      line-height: (32 / 15);
    }
    @include touch {
      @include fz(14);
      margin-top: 5px;
      line-height: (52 / 28);
    }
  }
  &__detail {
    @include fz(15);
    grid-area: detail;
    @include touch {
      @include fz(14);
    }
  }
  &__list {
    > dt {
      font-weight: 500;
    }
  }
  &__map {
    grid-area: map;
    @include touch {
      text-align: center;
    }
  }
}

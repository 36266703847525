@charset "utf-8";

/*
@include touch {} // max-width: 979px
@include sp {}    // max-width: 599px
@include tb {}    // min-width: 600px
@include pc {}    // min-width: 980px
@include wide {}  // min-width: 1280px
*/

/* -------------------------------------------------- header */
.l-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  color: #383737;
  text-align: center;
  background: $var-yellow;
}

.l-header svg {
  fill: currentcolor;
}

.l-header .snoopy {
  position: relative;
  margin: 0;
}

.l-header .snoopy a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 123px;
}

.l-header .c-menuButton,
.l-header .c-ticketButton,
.l-header .c-englishLink {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  text-transform: uppercase;
  @include font-webfont_zeitung_bold;
}

.l-header .c-menuButton,
.l-header .c-ticketButton {
  flex-direction: column;
  line-height: 1.6;
  letter-spacing: 0.08rem;
}

.l-header .c-menuButton {
  position: relative;
  padding: 0;
  background: none;
  border: 0;
  -webkit-appearance: none;
  appearance: none;
}

.l-header .c-menuButton svg {
  fill: none;
  stroke: currentcolor;
  stroke-width: 3;
}

.l-header .c-menuButton .p1 {
  transform: translateY(-14px);
}

.l-header .c-menuButton .p3 {
  transform: translateY(14px);
}

.l-header .c-menuButton::after {
  position: absolute;
  bottom: 1em;
  left: 0;
  width: 100%;
  content: "Close";
}

.l-header .c-englishLink span {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
}

.snsLinks-item svg {
  fill: currentcolor;
}

[data-whatintent="keyboard"] .l-header *:focus {
  outline: none;
  box-shadow: inset 0 0 0 3px currentColor;
}

@include touch {
  .l-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 55px;
  }
  .l-header .snoopy a {
    width: 55px;
    height: 55px;
  }
  .l-header .snoopy img {
    width: 32px;
    height: 32px;
  }
  .l-header .c-menuButton,
  .l-header .c-ticketButton {
    width: 55px;
    height: 55px;
    font-size: 9px;
  }
  .l-header .c-menuButton {
    order: 2;
    margin-left: 8px;
  }
  .l-header .c-menuButton svg {
    width: 26px;
    height: 26px;
  }
  .l-header .c-ticketButton {
    order: 1;
    margin-left: auto;
  }
  .l-header .c-ticketButton svg {
    width: 24px;
    height: 24px;
    margin: 2px 0;
  }
  .l-header .c-snsLinks {
    display: none;
  }
  .l-header .c-englishLink {
    width: 55px;
    height: 55px;
    font-size: 12px;
  }
  .l-header .c-englishLink {
    position: relative;
  }
  .l-header .c-englishLink::before,
  .l-header .c-englishLink::after {
    position: absolute;
    top: 1px;
    width: 1px;
    height: calc(100% - 2px);
    content: "";
    background-image: repeating-linear-gradient(to bottom, currentcolor 0 1px, transparent 1px 5px);
  }
  .l-header .c-englishLink::before {
    left: 0;
  }
  .l-header .c-englishLink::after {
    right: 0;
  }
}

@media print, screen and (max-width: $pc - 1px) and (-ms-high-contrast: none) {
  .l-header .c-englishLink::before,
  .l-header .c-englishLink::after {
    background-image: linear-gradient(0deg, $var-black 1px, transparent 1px);
    background-repeat: repeat-y;
    background-size: 1px 5px;
  }
}

@include pc {
  .l-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 80px;
    height: 100%;
    overflow-x: hidden;
  }
  .l-header .snoopy {
    flex-basis: 123px;
    width: 80px;
    height: 123px;
    margin-bottom: auto;
  }
  .l-header .snoopy::after {
    position: absolute;
    bottom: 0;
    left: 3px;
    width: calc(100% - 6px);
    height: 1px;
    content: "";
    background-image: repeating-linear-gradient(to right, currentcolor 0 2px, transparent 2px 8px);
  }
  .l-header .c-menuButton,
  .l-header .c-ticketButton {
    box-sizing: content-box;
    flex-basis: 80px;
    width: 80px;
    min-height: 80px;
    margin: 4vh 0;
  }
  .l-header .c-menuButton {
    margin-top: 40px;
  }
  .l-header .c-menuButton svg {
    width: 32px;
    height: 32px;
    margin-bottom: 6px;
  }
  .l-header .c-ticketButton {
    margin-bottom: 48px;
  }
  .l-header .c-ticketButton svg {
    width: 30px;
    height: 30px;
    margin-bottom: 8px;
  }
  .l-header .c-snsLinks {
    position: relative;
    padding: 27px 19px;
    margin-top: auto;
  }
  .l-header .c-snsLinks::before,
  .l-header .c-snsLinks::after {
    position: absolute;
    left: 3px;
    width: calc(100% - 6px);
    height: 1px;
    content: "";
    background-image: repeating-linear-gradient(to right, currentcolor 0 2px, transparent 2px 8px);
  }
  .l-header .c-snsLinks::before {
    top: 0;
  }
  .l-header .c-snsLinks::after {
    bottom: 0;
  }
  .l-header .snsLinks-item a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
  }
  .l-header .snsLinks-item svg {
    width: 15px;
    height: 15px;
  }
  .l-header .c-englishLink {
    flex-basis: 70px;
    width: 80px;
    min-height: 70px;
  }
}

@media print, screen and (min-width: $pc) and (-ms-high-contrast: none) {
  .l-header {
    min-height: 680px;
  }
  .l-header .snoopy::after {
    background-image: linear-gradient(90deg, $var-black 2px, transparent 2px);
  }
  .l-header .c-snsLinks::before,
  .l-header .c-snsLinks::after {
    background-image: linear-gradient(90deg, $var-black 2px, transparent 2px);
  }
  .l-header .snoopy::after,
  .l-header .c-snsLinks::before,
  .l-header .c-snsLinks::after {
    background-repeat: repeat-x;
    background-size: 8px 1px;
  }
}

/* animation */
.l-header .c-menuButton path {
  transition: transform 0.3s $var-easing;
  transform-origin: 50% 50%;
}

.l-header .c-menuButton .label,
.l-header .c-menuButton::after {
  transition: opacity 0.2s, transform 0.2s $var-easing;
}

.l-header .c-menuButton[aria-expanded="true"] .label,
.l-header .c-menuButton[aria-expanded="false"]::after {
  opacity: 0;
  transform: translateY(0.8em);
}

.l-header .c-menuButton[aria-expanded="true"] .p1 {
  transform: rotate(-45deg);
}

.l-header .c-menuButton[aria-expanded="true"] .p2 {
  transform: scaleX(0);
}

.l-header .c-menuButton[aria-expanded="true"] .p3 {
  transform: rotate(45deg);
}

.p-accessAbout {
  position: relative;
  padding-top: 139px;
  padding-bottom: 70px;
  margin-top: -80px;
  //z-index: -1;
  @include touch {
    padding-top: 147px;
    margin-top: -30px;
  }
  &__list {
    @include pc {
      @include fz(15);
      display: flex;
      flex-wrap: wrap;
      margin-top: 30px;
      line-height: (26 / 15);
    }
    @include touch {
      @include fz(14);
      margin-top: 25px;
      line-height: (46 / 28);
    }
  }
  &__term {
    font-weight: 500;
    @include pc {
      width: 120px;
      min-height: 2.2em;
    }
    &:not(:first-of-type) {
      @include touch {
        margin-top: 20px;
      }
    }
  }
  &__detail {
    @include pc {
      width: calc(100% - 120px);
    }
    @include touch {
      margin-top: 8px;
    }
  }
  &__notice {
    margin-top: 20px;
    @include pc {
      @include fz(15);
      line-height: (26 / 15);
    }
    @include touch {
      @include fz(14);
      line-height: (46 / 28);
    }
    > small {
      font-size: inherit;
    }
  }
  &__contact {
    @include fz(15);
    margin-top: 20px;
  }
  &__illust {
    position: absolute;
    @include pc {
      right: 0;
      bottom: 0;
      width: 94px;
      height: 106px;
    }
    @include touch {
      top: 42px;
      left: 0;
      width: 62px;
    }
    > img {
      width: 100%;
      height: 100%;
    }
  }
}

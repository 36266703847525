@charset "utf-8";

/*
@include touch {} // max-width: 979px
@include sp {}    // max-width: 599px
@include tb {}    // min-width: 600px
@include pc {}    // min-width: 980px
@include wide {}  // min-width: 1280px
*/

/* -------------------------------------------------- APP */
.l-main.p-app {
  padding-top: 42px;
}

.p-app .keyVisual-copy {
  font-size: 24px;
  text-transform: uppercase;
}

.p-app .keyVisual-text {
  max-width: 380px;
  font-size: 13px;
}

.c-keyVisual .c-appLinks {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.c-keyVisual .appLinks-item:nth-child(n + 2) {
  margin-left: 24px;
}

.c-keyVisual .c-appLinks img {
  width: auto;
  height: 50px;
}

.c-keyVisual .keyVisual-smartphone {
  position: absolute;
}

.p-app .c-title {
  font-size: 22px;
}

.c-title.-centerbar {
  text-align: center;
}

.c-title.-centerbar::after {
  display: block;
  width: 40px;
  margin: 12px auto 0;
  content: "";
  border-top: 2px solid currentcolor;
}

@include touch {
  .p-app .c-title {
    font-size: 20px;
  }
  .p-app .c-keyVisual {
    padding-top: calc(88vw / 3.75);
  }
  .p-app .keyVisual-content {
    padding-top: calc(56vw / 3.75);
  }
  .c-keyVisual .keyVisual-smartphone {
    top: 0;
    left: calc(50% - 92vw / 3.75);
    width: calc(210vw / 3.75);
    height: calc(389vw / 3.75);
  }
}
@include pc {
  .p-app .c-keyVisual {
    padding-bottom: 160px;
  }
  .c-keyVisual .keyVisual-smartphone {
    top: 50px;
    left: calc(25% - 140px);
    width: 320px;
    height: 593px;
  }
}

/* 遊び方 */
.p-appHowToPlay .c-title.-centerbar {
  margin-bottom: 80px;
}

.c-howToPlay {
  position: relative;
}

.c-howToPlay + .c-howToPlay {
  margin-top: 112px;
}

.c-howToPlay::after {
  position: absolute;
  top: -70px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 140px;
  font-size: 70px;
  color: white;
  content: attr(data-num);
  background: $var-green;
  border-radius: 140px;
  @include font-webfont_zeitung_bold;
}

.c-howToPlay:not(.-reverse)::after {
  right: -70px;
}

.c-howToPlay.-reverse::after {
  left: -70px;
}

.c-howToPlay > * {
  box-sizing: border-box;
  flex-basis: 50%;
}

.c-howToPlay .c-title {
  margin-bottom: 40px;
}

.howToPlay-img img {
  width: 100%;
}

.howToPlay-text {
  font-size: 15px;
}

@include touch {
  .p-appHowToPlay .c-title.-centerbar {
    margin-bottom: 64px;
  }
  .c-howToPlay + .c-howToPlay {
    margin-top: 64px;
  }
  .c-howToPlay::after {
    top: -35px;
    width: 70px;
    height: 70px;
    font-size: 35px;
    border-radius: 70px;
  }
  .c-howToPlay:not(.-reverse)::after {
    right: 0;
  }
  .c-howToPlay.-reverse::after {
    left: 0;
  }
  .howToPlay-img {
    padding: 0 35px;
    margin-bottom: 40px;
  }
  .howToPlay-text {
    font-size: 14px;
  }
  .c-howToPlay .c-title {
    margin-bottom: 32px;
  }
}
@include pc {
  .c-howToPlay {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .c-howToPlay:not(.-reverse) .howToPlay-text {
    order: -1;
    padding-right: 80px;
  }
  .c-howToPlay.-reverse .howToPlay-text {
    padding-left: 80px;
  }
}

/* 公園をめぐろう！ */
.p-appGoPark {
  margin-top: 80px;
  margin-bottom: 100px;
}

.p-appGoPark .c-title {
  margin: 40px 0 20px;
  font-size: 15px;
}

.goPark-img img {
  width: 100%;
  border-radius: $var-border-radius;
}

.goPark-text {
  font-size: 13px;
}

@include pc {
  .p-appGoPark {
    margin-top: 160px;
    margin-bottom: 200px;
  }
  .p-appGoPark .c-title {
    font-size: 16px;
  }
}

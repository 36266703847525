.p-galleryComic {
  /* autoprefixer grid: no-autoplace */
  display: grid;
  grid-template-areas: "figure" "title" "body";
  grid-template-rows: repeat(3, auto);
  grid-template-columns: 1fr;
  max-width: 880px;
  margin-right: auto;
  margin-left: auto;
  &.-left {
    @include pc {
      display: grid;
      grid-template-areas: "figure title" "figure body";
      grid-template-rows: repeat(2, auto);
      grid-template-columns: 50% 1fr;
      column-gap: 80px;
    }
  }
  &.-right {
    @include pc {
      display: grid;
      grid-template-areas: "title figure" "body figure";
      grid-template-rows: repeat(2, auto);
      grid-template-columns: 1fr 50%;
      column-gap: 80px;
    }
  }
  & + & {
    @include pc {
      margin-top: 108px;
    }
    @include touch {
      margin-top: 80px;
    }
  }
  &.-left + &.-right,
  &.-right + &.-left {
    margin-top: 54px;
  }
  &__title {
    grid-area: title;
    line-height: (32 / 22);
    @include pc {
      @include fz(22);
      margin-top: 42px;
      letter-spacing: 0.06em;
    }
    @include touch {
      @include fz(20);
      margin-top: 37px;
    }
    .-left &,
    .-right & {
      @include pc {
        display: flex;
        align-items: flex-end;
      }
      @include touch {
        margin-top: 28px;
      }
    }
  }
  &__body {
    grid-area: body;
    margin-top: 28px;
  }
  &__text {
    letter-spacing: 0.06em;
    @include pc {
      @include fz(15);
      line-height: (32 / 15);
    }
    @include touch {
      @include fz(14);
      line-height: ceil-decimal((52 / 28), 1);
    }
    &.-center {
      margin-top: 0;
      @include pc {
        margin-top: 93px;
      }
      @include wide {
        margin-top: 146px;
        margin-bottom: 172px;
      }
    }
  }
  &__figure {
    grid-area: figure;
    @include touch {
      text-align: center;
    }
  }
  &.-specialBottom {
    @include pc {
      margin-bottom: -354px !important;
    }
    @include touch {
      margin-bottom: -170px;
    }
    @include tb {
      margin-bottom: -10px !important;
    }
  }
}

.-specialBottom {
  // @include pc {
  //   margin-bottom: -354px!important;
  // }
  // @include touch {
  //   margin-bottom: -170px;
  // }
  @include tb {
    margin-bottom: 0 !important;
  }
}

@charset "utf-8";

/*
@include touch {} // max-width: 979px
@include sp {}    // max-width: 599px
@include tb {}    // min-width: 600px
@include pc {}    // min-width: 980px
@include wide {}  // min-width: 1280px
*/

/* -------------------------------------------------- スタッフ募集中 */
a.p-footerPr {
  position: relative;
  display: block;
  padding: 0.8em 1em 1em;
  font-size: 13px;
  line-height: 1.4;
  color: white;
  text-align: center;
  letter-spacing: 0.1rem;
  background: #d73d5b;
}

@include pc {
  a.p-footerPr {
    font-size: 15px;
  }
}

.p-footerPr .-en {
  display: inline-block;
  margin-bottom: 4px;
  font-size: 12px;
  @include font-webfont_zeitung_bold;
  text-transform: uppercase;
}

@include pc {
  .p-footerPr .-en {
    font-size: 13px;
  }
}

.p-footerPr::after {
  position: absolute;
  top: calc(50% - 15px);
  right: 40px;
  width: 42px;
  height: 30px;
  content: "";
  background: url(../img/common/ico_arrow.svg) center / cover no-repeat;
  transition: transform 0.3s $var-easing;
}

@include touch {
  .p-footerPr::after {
    position: absolute;
    top: calc(50% - 15px);
    right: 40px;
    width: 28px;
    height: 20px;
    content: "";
    background: url(../img/common/ico_arrow.svg) center / cover no-repeat;
    transition: transform 0.3s $var-easing;
  }
}

.p-footerPr:hover::after {
  transform: translateX(8px);
}

@include sp {
  .p-footerPr::after {
    top: 22px;
    right: 20%;
  }
}
@include touch {
  .p-footerPr::after {
    top: 22px;
    right: 35%;
  }
}
@include pc {
  .p-footerPr::after {
    right: 37.8%;
  }
}

/* -------------------------------------------------- back to top */
#top,
#mainContents {
  display: block;
}

.c-pagetop {
  position: absolute;
  z-index: 1000;
  overflow: hidden;
  line-height: 1.6;
  color: transparent;
  text-transform: uppercase;
  letter-spacing: 0;
}

.c-pagetop img {
  position: absolute;
  transform-origin: 100% 0;
}

.c-pagetop .c {
  position: absolute;
  top: 78%;
  right: 18%;
}

.c-pagetop .c:nth-child(1) {
  transform: rotate(-65deg);
}

.c-pagetop .c:nth-child(2) {
  transform: rotate(-60deg);
}

.c-pagetop .c:nth-child(3) {
  transform: rotate(-55deg);
}

.c-pagetop .c:nth-child(4) {
  transform: rotate(-50deg);
}

.c-pagetop .c:nth-child(5) {
  transform: rotate(-42deg);
}

.c-pagetop .c:nth-child(6) {
  transform: rotate(-37deg);
}

.c-pagetop .c:nth-child(7) {
  transform: rotate(-29deg);
}

.c-pagetop .c:nth-child(8) {
  transform: rotate(-24deg);
}

.c-pagetop .c:nth-child(9) {
  transform: rotate(-19deg);
}

.c-pagetop .c::after {
  position: absolute;
  display: block;
  width: 1em;
  height: 1em;
  color: $var-black;
  @include font-webfont_zeitung_bold;
}

.c-pagetop .c:nth-child(1)::after {
  content: "B";
}

.c-pagetop .c:nth-child(2)::after {
  content: "a";
}

.c-pagetop .c:nth-child(3)::after {
  content: "c";
}

.c-pagetop .c:nth-child(4)::after {
  content: "k";
}

.c-pagetop .c:nth-child(5)::after {
  content: "t";
}

.c-pagetop .c:nth-child(6)::after {
  content: "o";
}

.c-pagetop .c:nth-child(7)::after {
  content: "T";
}

.c-pagetop .c:nth-child(8)::after {
  content: "o";
}

.c-pagetop .c:nth-child(9)::after {
  content: "p";
}

@include touch {
  .c-pagetop {
    top: -98px;
    right: 0;
    width: calc(165px * 0.8);
    height: calc(165px * 0.8);
    font-size: 9px;
  }
  .p-footerPr + .l-footer .c-pagetop {
    top: -170px;
  }
  .c-pagetop img {
    right: calc(-16px * 0.8);
    width: calc(109px * 0.8);
    height: calc(140px * 0.8);
  }
}
@include pc {
  .c-pagetop {
    top: -122px;
    right: calc(50% - 50vw + 40px);
    width: 140px;
    height: 160px;
    font-size: 12px;
  }
  .p-footerPr + .l-footer .c-pagetop {
    top: -210px;
  }
  .c-pagetop img {
    right: -16px;
  }
}

/* animation */
.c-pagetop img {
  transition: transform 0.7s;
}

.c-pagetop .c::after {
  transition: opacity 0.56s, transform 0.56s;
}

.c-pagetop.active img {
  transition-timing-function: cubic-bezier(0.39, 1.56, 0.55, 0.99);
}

.c-pagetop.active .c::after {
  transition-delay: 0.1s;
  transition-timing-function: cubic-bezier(0.39, 1.5, 0.55, 0.99);
  transform: translateY(-120px);
}

.c-pagetop:not(.active) img {
  transition-timing-function: $var-easing;
  transition-duration: 0.3s;
  transform: rotate(-45deg);
}

.c-pagetop:not(.active) .c::after {
  opacity: 0;
  transition-timing-function: $var-easing;
  transition-duration: 0.2s;
  transform: none;
}

[data-whatintent="keyboard"] .c-pagetop:focus {
  outline: 3px solid $var-color;
}

@include touch {
  .c-pagetop.active .c::after {
    transform: translateY(-96px);
  }
}

/* -------------------------------------------------- footer */
.l-footer {
  position: relative;
  box-sizing: content-box;
  padding: 0 40px;
  margin: 0 auto;
  background-color: $var-bg-beige;
}

@include touch {
  .l-footer {
    padding: 40px 32px;
  }
}
@include pc {
  .l-footer {
    padding: 64px 80px;
  }
}

@media (min-width: $full) {
  .l-footer::before {
    position: absolute;
    top: 0;
    right: calc(50% - 50vw + 40px);
    left: calc(50% - 50vw + 40px);
    z-index: -1;
    height: 100%;
    content: "";
    background: $var-bg-beige;
  }
}

.c-footerLinks {
  font-size: 12px;
}

.footerLinks-item a {
  position: relative;
  display: inline-block;
  padding-bottom: 2px;
}

.footerLinks-item a::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  content: "";
  border-bottom: 1px solid currentcolor;
  transition: transform 0.3s $var-easing;
  transform-origin: 0 0;
}

.footerLinks-item a:hover::after {
  transform: scaleX(0);
  transform-origin: 100% 0;
}

[data-whatintent="keyboard"] .footerLinks-item a:focus {
  position: relative;
  outline: none;
  &::before {
    position: absolute;
    top: -1em;
    right: -1em;
    bottom: -1em;
    left: -1em;
    content: "";
    border: 3px solid;
  }
}

@include touch {
  .c-footerLinks {
    font-size: 11px;
  }
  .footerLinks-item a {
    padding: 0.8em 0;
  }
  .footerLinks-item a::after {
    bottom: calc(0.8em - 2px);
  }
}
@include pc {
  .c-footerLinks {
    display: flex;
    flex-wrap: wrap;
  }
  .footerLinks-item {
    margin: 0 32px 12px 0;
  }
}

.copyright {
  margin: 8px 0 0;
  font-size: 12px;
  color: #888;
}

@include touch {
  .copyright {
    margin-top: 2em;
    font-size: 10px;
  }
}

// marginAdjustment

.-marginAdjustment {
  @include pc {
    margin-left: 80px;
  }
}

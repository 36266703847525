@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include touch{}
*/

//★あとでさしかえ
.l-contents {
  padding-top: 55px;
  overflow-x: hidden;
  @include pc {
    padding-top: 0;
    padding-left: 80px;
  }
}

@charset "utf-8";

.p-fixedButton {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 100;
  width: 180px;
  transition: opacity 0.5s ease-in-out;
  &.opacity {
    opacity: 0;
  }
  @include under_pc {
    width: 150px;
  }
  @include sp {
    bottom: 20px;
    right: 20px;
    width: 130px;
  }
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 5px;
    left: 5px;
    width: 180px;
    height: 180px;
    border-radius: 50%;
    background-color: #3a3a3a;
    transition: transform 0.3s ease-in-out;
    @include under_pc {
      width: 150px;
      height: 150px;
    }
    @include sp {
      top: 3px;
      left: 3px;
      width: 130px;
      height: 130px;
    }
  }
  @include pc {
    &:hover .p-fixedButton-link img {
      transform: translate(-4px, -4px);
    }
    &:hover::before {
      transform: translate(4px, 4px);
    }
  }
  .p-fixedButton-link {
    position: relative;
    img {
      transition: transform 0.3s ease-in-out;
    }
  }
  .p-fixedButton-text {
    position: absolute;
    top: -18px;
    right: -16px;
    color: #e60020;
    font-family: zeitung, sans-serif;
    @include fz(24);
    font-weight: bold;
    transform: rotate(35deg);
    @include sp {
      @include fz(16);
      top: -9px;
      right: -8px;
    }
  }
}

@charset "utf-8";

/*
@include touch {} // max-width: 979px
@include sp {}    // max-width: 599px
@include tb {}    // min-width: 600px
@include pc {}    // min-width: 980px
@include wide {}  // min-width: 1280px
*/

/* -------------------------------------------------- menu */
.l-menu {
  position: fixed;
  z-index: 999;
  box-sizing: border-box;
  padding: 55px 30px 0;
  color: white;
}

[data-whatintent="keyboard"] .l-menu *:focus {
  position: relative;
  outline: none;
  &::after {
    position: absolute;
    top: -1em;
    right: -1em;
    bottom: -1em;
    left: -1em;
    content: "";
    border: 3px solid #fff;
  }
}

.l-menu,
.menu-overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@include pc {
  .l-menu {
    padding: 0 40px 0 120px;
  }
}
@include wide {
  .l-menu {
    padding: 0 80px 0 160px;
  }
}

.menu-overlay {
  position: fixed;
  background: $var-color;
}

/* menu list */
.l-menu .c-siteLinks {
  position: relative;
  display: grid;
  grid-template-columns: 3fr 7fr;
  grid-gap: 32px 24px;
  width: 100%;
  max-width: 1200px;
  -ms-grid-rows: (auto 16px) [5] auto;
  -ms-grid-columns: 3fr 24px 7fr;
}

.l-menu .siteLinks-item.-news {
  grid-area: 2 / 1;
}

.l-menu .siteLinks-item.-gallery {
  grid-area: 3 / 1;
}

.l-menu .siteLinks-item.-ticket {
  grid-area: 4 / 1;
}

.l-menu .siteLinks-item.-store {
  grid-area: 5 / 1;
}

.l-menu .siteLinks-item.-cafe {
  grid-area: 6 / 1;
}

.l-menu .siteLinks-item.-news,
.l-menu .siteLinks-item.-app {
  -ms-grid-row: 3;
}

.l-menu .siteLinks-item.-gallery,
.l-menu .siteLinks-item.-about {
  -ms-grid-row: 5;
}

.l-menu .siteLinks-item.-ticket,
.l-menu .siteLinks-item.-access {
  -ms-grid-row: 7;
}

.l-menu .siteLinks-item.-store,
.l-menu .siteLinks-item.-faq {
  -ms-grid-row: 9;
}

.l-menu .siteLinks-item.-cafe {
  -ms-grid-row: 11;
}

.l-menu .siteLinks-item.-workshop,
.l-menu .siteLinks-item.-app,
.l-menu .siteLinks-item.-about,
.l-menu .siteLinks-item.-access,
.l-menu .siteLinks-item.-faq {
  -ms-grid-column: 3;
}

.l-menu .c-siteLinks a {
  display: inline-block;
}

.l-menu .c-localLinks,
.l-menu .c-snsLinks {
  display: flex;
  font-size: 13px;
}

.l-menu .c-localLinks {
  flex-wrap: wrap;
}

.l-menu .localLinks-item:not(:first-child) {
  position: relative;
  padding-left: 20px;
  margin-left: 20px;
}

.l-menu .localLinks-item:not(:first-child)::before {
  position: absolute;
  left: -0.25em;
  color: $var-gray;
  content: "/";
}

.l-menu .c-snsLinks {
  justify-content: space-between;
  width: 100px;
  margin-left: auto;
}

.l-menu .snsLinks-item svg {
  width: 18px;
  height: 18px;
}

.l-menu .c-englishLink {
  font-size: 13px;
  text-transform: uppercase;
}

.l-menu .c-englishLink a::before {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 2.5em;
  margin-right: 1.5em;
  vertical-align: middle;
  content: "";
  border-bottom: 1px solid currentcolor;
}

@include touch {
  .l-menu.active {
    overflow: auto;
  }
  .l-menu .c-siteLinks {
    -ms-grid-rows: (auto 16px) [7] auto;
    -ms-grid-columns: auto 24px auto;
    grid-template: auto / auto auto;
    grid-gap: 24px;
    padding: 32px 0;
  }
  .l-menu .c-localLinks,
  .l-menu .c-snsLinks,
  .l-menu .c-englishLink {
    font-size: 10px;
  }
  .l-menu .c-localLinks {
    padding: 20px 0;
    border-top: 1px solid $var-black;
    border-bottom: 1px solid $var-black;
  }
  .l-menu .localLinks-item:not(:first-child) {
    padding-left: 1em;
    margin-left: 1em;
  }
  .l-menu .siteLinks-item.-local {
    grid-area: 7 / 1 / 8 / 3;
    -ms-grid-row: 13;
    -ms-grid-column-span: 3;
  }
  .l-menu .siteLinks-item.-sns {
    grid-area: 8 / 2 / 9 / 3;
    -ms-grid-row: 15;
    margin-top: -10px;
  }
  .l-menu .c-englishLink {
    grid-area: 8 / 1 / 9 / 2;
    -ms-grid-row: 15;
    align-self: center;
    margin-top: -10px;
  }
  .l-menu .c-snsLinks {
    margin-right: -10px;
  }
  .l-menu .snsLinks-item {
    padding: 10px;
  }
  .l-menu .snsLinks-item svg {
    width: 14px;
    height: 14px;
  }
}
@include sp {
  .l-menu .c-siteLinks {
    grid-gap: 16px;
    padding: 24px 0;
  }
}
@include pc {
  .l-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .l-menu .siteLinks-item {
    display: inline-block;
  }
  .l-menu .siteLinks-item.-local {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: auto 0 6px;
  }
  .l-menu .siteLinks-item.-local {
    -ms-grid-row: 11;
    -ms-grid-column: 3;
    -ms-grid-row-align: end;
  }
  .l-menu .siteLinks-item.-sns {
    position: absolute;
    right: 0;
    bottom: 6px;
  }
  .l-menu .c-englishLink {
    position: absolute;
    top: 0;
    right: 0;
  }
}
@include wide {
  .l-menu .c-siteLinks {
    -ms-grid-columns: 1fr 24px 2fr;
    grid-template-columns: 1fr 2fr;
  }
}

/* menu animation */
.l-menu.anima .c-siteLinks {
  transition: opacity 0.3s, transform 0.3s $var-easing;
}

.l-menu.anima .menu-overlay {
  transition: opacity 0.3s;
}

.l-menu:not(.active) {
  pointer-events: none;
}

.l-menu:not(.active) .c-siteLinks {
  opacity: 0;
}

.l-menu:not(.active) .menu-overlay {
  opacity: 0;
}

@include tb {
  .l-menu:not(.active) .c-siteLinks {
    transform: translateX(-10vw);
  }
}
@include touch {
  .l-menu:not(.active) .c-siteLinks {
    transform: translateY(-10vh);
  }
}

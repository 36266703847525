.p-galleryFeature {
  /* autoprefixer grid: no-autoplace */
  display: grid;
  grid-template-areas: "figure" "title" "body";
  grid-template-rows: repeat(3, auto);
  grid-template-columns: 1fr;
  padding-bottom: 77px;
  margin-top: 100px;
  @include wide {
    grid-template-areas: "figure title" "figure body";
    grid-template-rows: repeat(2, auto);
    grid-template-columns: 50% 1fr;
    column-gap: 80px;
    padding-right: 80px;
    padding-bottom: 115px;
    padding-left: 80px;
    margin-top: 110px;
  }
  &__title {
    @include fz(20);
    grid-area: title;
    margin-top: 60px;
    line-height: (32 / 22);
    letter-spacing: 0.06em;
    @include wide {
      @include fz(22);
    }
  }
  &__body {
    grid-area: body;
    margin-top: 27px;
    @include wide {
      margin-top: 0;
    }
    > p {
      letter-spacing: 0.06em;
      @include pc {
        @include fz(15);
        line-height: (32 / 15);
      }
      @include touch {
        @include fz(14);
        line-height: ceil-decimal((52 / 28), 1);
      }
      &:nth-child(n + 2) {
        @include pc {
          margin-top: 32 / 15 * 1em;
        }
        @include touch {
          margin-top: 52 / 28 * 1em;
        }
      }
    }
    .-posterSide {
      @include wide {
        margin-top: 244px;
        margin-bottom: 268px;
      }
    }
  }
  &__figure {
    grid-area: figure;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    @include pc {
      max-width: none;
    }
  }
}

.p-accessCar {
  @include pc {
    padding-top: 112px;
    padding-bottom: 130px;
  }
  @include touch {
    padding-top: 80px;
    padding-bottom: 112px;
  }
  &__body {
    display: flex;
    @include pc {
      margin-top: 45px;
    }
    @include touch {
      flex-direction: column-reverse;
      margin-top: 40px;
    }
  }
  &__textGroup {
    @include pc {
      flex-grow: 1;
      width: calc(100% - 68px - 440px);
      margin-right: 68px;
    }
    @include touch {
      margin-top: 40px;
    }
  }
  &__subTitle {
    font-weight: 500;
    letter-spacing: 0.06em;
    @include pc {
      @include lhCrop(29 / 16);
    }
    @include touch {
      @include fz(14);
    }
  }
  &__list {
    margin-top: 14px;
    letter-spacing: 0.06em;
    @include pc {
      @include fz(15);
      display: flex;
      flex-wrap: wrap;
      line-height: (32 / 15);
    }
    @include touch {
      @include fz(14);
      line-height: (52 / 28);
    }
  }
  &__term {
    @include pc {
      width: 118px;
    }
    @include touch {
      font-weight: 500;
    }
    &:not(:first-of-type) {
      @include touch {
        margin-top: 20px;
      }
    }
  }
  &__description {
    @include pc {
      width: calc(100% - 118px);
    }
    @include touch {
      margin-top: 6px;
    }
  }
  &__summary {
    letter-spacing: 0.06em;
    @include pc {
      @include fz(15);
      margin-top: 30px;
      line-height: (32 / 15);
    }
    @include touch {
      @include fz(14);
      margin-top: 14px;
      line-height: (52 / 28);
    }
  }
  &__map {
    flex-shrink: 0;
    @include pc {
      width: 440px;
    }
    @include touch {
      text-align: center;
    }
  }
}

@charset "utf-8";

.p-pageHeader.-camera {
  padding-bottom: 64px;
  @include sp {
    padding-bottom: 32px;
  }
  .p-pageHeader__img img {
    width: 100%;
  }
  .p-pageHeader__lead {
    padding-top: 48px;
    @include sp {
      padding-top: 24px;
    }
    p {
      margin-top: 1em;
    }
  }
}

.p-camera {
  max-width: 700px;
  margin: 0 auto;
}
.l-main.p-camera {
  padding-bottom: 136px;
}

/* こんな写真が撮れます */
.p-camera__img {
  img {
    border-radius: 20px;
  }
  .caption {
    margin-top: .5em;
    font-size: 14px;
    text-align: right;
    @include sp {
      font-size: 12px;
    }
  }
}
.p-camera__text {
  margin-top: .5em;
  font-size: 16px;
  @include pc {
    line-height: 1.2em;
  }
  @include sp {
    margin-top: 1em;
    line-height: 1.6;
    text-align: center;
  }
  .price {
    position: relative;
    top: -.15em;
    font-size: 180%;
    vertical-align: middle;
  }
  .note {
    font-size: 80%;
    @include sp {
      display: block;
      line-height: 1;
    }
  }
}

/* SNOOPY ROOM CAMERA 撮影方法 */
.p-camera__flow {
  margin-top: 48px;
  padding: 24px;
  border-radius: 20px;
  text-align: center;
  background: #f6f5f3;
  @include sp {
    padding: 24px calc(24vw / 3.75);
  }
  &Title {
    font-size: 23px;
    @include pc {
      .main,
      .free {
        position: relative;
        top: -.15em;
        vertical-align: middle;
      }
      .main {
        font-size: 120%;
      }
    }
    @include sp {
      font-size: 18px;
      line-height: 1.2;
    }
    .free {
      font-size: 70%;
    }
    &::before {
      content: "";
      display: inline-block;
      position: relative;
      top: -.3em;
      width: 3em;
      height: 2.5em;
      margin-right: .5em;
      vertical-align: middle;
      background: url(../img/camera/camera_ico.png) center / contain no-repeat;
      @include sp {
        display: block;
        width: 3.23em;
        height: 2.65em;
        margin: 0 auto;
      }
    }
  }
  &List {
    display: flex;
    gap: 2em .5em;
    justify-content: center;
    margin-top: 24px;
    text-align: left;
    @include sp {
      column-gap: 0;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
  &Item {
    z-index: 0;
    position: relative;
    padding-left: 1.4em;
    counter-increment: step;
    font-size: 18px;
    line-height: 1.4;
    font-weight: 700;
    &::before {
      content: counter(step);
      z-index: -1;
      position: absolute;
      top: calc(50% - .55em);
      left: 0;
      color: #f7d65b;
      font-size: 444%;
      line-height: 1;
      font-family: "zeitung", sans-serif;
    }
    @include sp {
      font-size: 16px;
      &:nth-child(2n+1) {
        flex-basis: 40%;
      }
      &:nth-child(2n) {
        flex-basis: 60%;
      }
    }
  }
  &Note {
    margin-top: 24px;
    font-size: 14px;
    @include sp {
      font-size: 10px;
      letter-spacing: .1em;
    }
    b {
      font-size: 120%;
      vertical-align: middle;
    }
    .num {
      position: relative;
      top: -.15em;
      font-size: 160%;
      vertical-align: middle;
    }
  }
}







.p-workshopSummary {
  $block: ".p-workshopSummary";
  display: flex;
  flex-direction: column;
  height: 100%;
  @include pc {
    padding: 35px 35px 30px;
  }
  @include touch {
    padding-top: 28px;
    padding-right: 26px;
    padding-left: 26px;
  }
  &__title {
    margin-top: 25px;
    font-weight: 500;
    @include pc {
      @include fz(16);
      line-height: (26 / 16);
    }
    @include touch {
      @include fz(15);
      line-height: (44 / 30);
    }
  }
  &__text {
    @include fz(13);
    padding-bottom: 26px;
    margin-top: 20px;
    line-height: (22 / 13);
    color: $var-gray;
    transition: all 0.2s ease-out;
    &[aria-hidden="true"] {
      max-height: 26px;
      padding: 0;
      margin: 0;
      opacity: 0;
    }
  }
  &__figure {
    order: -1;
    text-align: center;
    overflow: hidden;
    img {
      height: auto;
    }
  }
  &__list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 35px;
    margin-top: auto;
    margin-right: -35px;
    margin-left: -35px;
    letter-spacing: 0.1em;
    border-top: 2px solid $var-bg-beige;
    border-bottom: 2px solid $var-bg-beige;
    @include touch {
      padding-right: 26px;
      padding-left: 26px;
      margin-right: -26px;
      margin-left: -26px;
      letter-spacing: 0.05em;
    }
  }
  &__timeIcon {
    @include pc {
      width: 20px;
      height: 20px;
    }
    @include onlyTB {
      width: 19px;
      height: 19px;
    }
    @include sp {
      width: 19 / 375 * 100vw;
      height: 19 / 375 * 100vw;
    }
  }
  &__term {
    flex-shrink: 0;
    @include pc {
      @include fz(13);
    }
    @include onlyTB {
      @include fz(12);
    }
    @include sp {
      font-size: 12 / 375 * 100vw;
    }
  }
  &__description {
    text-align: right;
    @include pc {
      @include fz(15);
    }
    @include onlyTB {
      @include fz(14);
    }
    @include sp {
      font-size: 14 / 375 * 100vw;
    }
    > time {
      @include font-webfont_zeitung_semibold;
      padding-right: 0.2em;
      padding-left: 0.2em;
    }
  }
  &__expanded {
    order: 2;
    padding-top: 12px;
    padding-bottom: 17px;
    margin-right: -26px;
    margin-left: -26px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    [data-whatintent="keyboard"] &:focus {
      outline: none;
      box-shadow: 0 0 0 3px $var-color;
    }
  }
  &__chevron {
    width: 22px;
    height: 11px;
    color: $var-gray;
    fill: currentColor;
    transition: transform 0.2s ease-out;
    #{$block}__expanded[aria-expanded="false"] & {
      transform: rotate(0.5turn);
    }
  }
  &__footer {
    padding-top: 26px;
    text-align: center;
    transition: all 0.2s ease-out;
    &[aria-hidden="true"] {
      max-height: 0;
      padding: 0;
      margin: 0;
      overflow: hidden;
      opacity: 0;
    }
  }
  &__link {
    @include fz(14);
    display: inline-block;
    font-weight: 500;
    color: #fff !important;
    background-color: $var-pink;
    @include pc {
      padding: 8px 40px;
      border-radius: 21px;
      min-width: 240px;
    }
    @include touch {
      width: 100%;
      padding-top: 13px;
      padding-bottom: 12px;
      border-radius: 25px;
    }
    [data-whatintent="keyboard"] &:focus {
      outline: none;
      box-shadow: 0 0 0 3px $var-color;
    }
    &.-soon {
      background-color: $var-gray;
    }
  }
}

.p-galleryPhoto {
  max-width: 1040px;
  padding-bottom: 80px;
  margin-right: auto;
  margin-left: auto;
  @include pc {
    padding-top: 72px;
    margin-bottom: 130px;
  }
  @include touch {
    padding-top: 66px;
    padding-right: 28px;
    padding-left: 28px;
    margin-bottom: 70px;
  }
  &__title {
    margin-bottom: 27px;
    text-align: center;
    letter-spacing: 0.06em;
    @include pc {
      @include fz(22);
      //margin-bottom: 0;
    }
    @include touch {
      @include fz(20);
    }
  }
  &__carousel {
    display: flex;
    @include pc {
      margin-top: 53px;
    }
    @include touch {
      flex-direction: column;
      margin-top: 28px;
    }
  }
  &__text {
    margin-top: 44px;
    letter-spacing: 0.06em;
    @include pc {
      @include fz(15);
      margin-top: 52px;
      line-height: (32 / 15);
    }
    @include touch {
      @include fz(14);
      margin-top: 44px;
      margin-right: -28px;
      margin-left: -28px;
      line-height: ceil-decimal((52 / 28), 1);
    }
  }
}

@charset "utf-8";
//@include sp {};
//@include tb {};
//@include pc {};

.p-terms {
  margin: 0 auto;
  margin-bottom: 129px;
  @include pc {
    width: 100%;
    max-width: 1440px;
    height: auto;
    padding: 0;
    margin-bottom: 110px;
  }
}

@charset "utf-8";

/*
@include touch {} // max-width: 979px
@include sp {}    // max-width: 599px
@include tb {}    // min-width: 600px
@include pc {}    // min-width: 980px
@include wide {}  // min-width: 1280px
*/

body {
  margin: 0;
  line-height: 1.8;
  letter-spacing: 0.12rem;
}

ul,
ol {
  padding: 0;
  margin: 0;
  list-style: none;
}

a {
  color: inherit;
  text-decoration: inherit;
}

dl,
dd,
figure {
  margin: 0;
}

img,
svg,
iframe {
  vertical-align: middle;
}

img {
  max-width: 100%;
  &[height] {
    height: auto;
  }
}

@include tb {
  .v-mb {
    display: none;
  }
}
@include touch {
  .v-dt {
    display: none;
  }
}

/* -------------------------------------------------- variables */
$var-color: #444;
$var-black: #3a3a3a;
$var-gray: #808080;
$var-pink: #e18b9c;
$var-yellow: #f7d65b;
$var-green: #519c60;
$var-blue: #54b1b4;
$var-fs-title: 42px;
$var-fs-text: 15px;
$var-bg-beige: #f6f5f3;
$var-border-radius: 20px;
$var-easing: cubic-bezier(0.215, 0.61, 0.355, 1);
$var-easing-reverse: cubic-bezier(0.645, 0, 0.785, 0.39);

@include touch {
  $var-fs-title: 32px;
  $var-fs-text: 14px;
  $var-border-radius: 10px;
}

/* -------------------------------------------------- compornent */
.c-pageTitle {
  margin: 0 0 0.8em;
  font-size: $var-fs-title;
  line-height: 1.2;
  color: $var-black;
  text-transform: uppercase;
  letter-spacing: 0;
  @include font-webfont_zeitung_bold;
}

.c-title {
  margin: 0 0 32px;
  font-size: $var-fs-title;
  line-height: 1.2;
  color: $var-black;
  text-transform: uppercase;
  letter-spacing: 0;
  @include font-webfont_zeitung_bold;
}

/* ページビジュアル */
.c-keyVisual img {
  width: 100%;
}

.c-keyVisual.-hasText {
  position: relative;
}

.c-keyVisual .keyVisual-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  font-size: 18px;
}

.p-app .keyVisual-content {
  color: $var-black;
  background: $var-yellow;
}

.p-gallery .keyVisual-content {
  color: white;
  background: $var-green;
}

.c-keyVisual .keyVisual-lead {
  margin: 0;
}

.c-keyVisual .keyVisual-copy {
  margin: 12px auto 0;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.2;
}

.c-keyVisual .keyVisual-text {
  margin: 32px auto 0;
}

@include touch {
  .c-keyVisual .keyVisual-image img {
    border-radius: 10px 10px 0 0;
  }
  .c-keyVisual .keyVisual-content {
    font-size: 16px;
    border-radius: 0 0 10px 10px;
  }
  .c-keyVisual .keyVisual-lead,
  .c-keyVisual .keyVisual-copy {
    text-align: center;
  }
}
@include pc {
  .c-keyVisual.-hasText {
    display: flex;
  }
  .c-keyVisual .keyVisual-image,
  .c-keyVisual .keyVisual-content {
    box-sizing: border-box;
    flex-basis: 50%;
  }
  .c-keyVisual .keyVisual-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px 0 0 20px;
  }
  .c-keyVisual .keyVisual-content {
    border-radius: 0 20px 20px 0;
  }
}

/* 下線のあるタイトル */
.c-title.-centerbar {
  text-align: center;
}

.c-title.-centerbar::after {
  display: block;
  width: 40px;
  margin: 12px auto 0;
  content: "";
  border-top: 2px solid currentcolor;
}

/* 両サイドに引き線のあるタイトル */
.c-title.-sidebar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  font-size: 20px;
  text-align: center;
  white-space: nowrap;
}

.c-title.-sidebar::before,
.c-title.-sidebar::after {
  flex-basis: 40%;
  content: "";
  border-top: 3px solid currentcolor;
}

.c-title.-sidebar::before {
  margin-right: 1em;
}

.c-title.-sidebar::after {
  margin-left: 1em;
}

@include touch {
  .c-title.-sidebar {
    font-size: 19px;
  }
}

.c-text {
  font-size: $var-fs-text;
  line-height: 1.8;
  color: $var-color;
  @include sourceHanSansJP_regular;
}

.c-text p {
  margin: 0;
}

.c-note {
  font-size: 13px;
}

/* 下線リンク */
.c-link {
  position: relative;
  display: inline-block;
  padding-bottom: 2px;
  color: $var-pink;
}

.c-link::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  content: "";
  border-bottom: 1px solid currentcolor;
  transition: transform 0.3s $var-easing;
  transform-origin: 0 0;
}

.c-link:hover::after {
  transform: scaleX(0);
  transform-origin: 100% 0;
}

[data-whatintent="keyboard"] .c-link:focus {
  position: relative;
  outline: none;
  &::before {
    position: absolute;
    top: -0.5em;
    right: -1em;
    bottom: -0.5em;
    left: -1em;
    content: "";
    box-shadow: 0 0 0 3px $var-color;
  }
}

/* ボタン */
a.c-button {
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  max-width: 240px;
  padding: 1em;
  font-size: 14px;
  line-height: 1;
  color: white;
  text-align: center;
  letter-spacing: 0.12em;
  background: $var-pink;
  border-radius: 3em;
  transition: all 0.3s ease-in-out;
  &:hover {
    @include pc {
      transition: all 0.3s ease 0s;
      transform: translateY(-0.3em);
    }
  }
}

[data-whatintent="keyboard"] .c-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px $var-color;
}

.c-button.-map {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 170px;
  height: 170px;
  border-radius: 50%;
  &.-large {
    @include pc {
      width: 180px;
      height: 180px;
    }
  }
}

.c-button.-map::after {
  position: absolute;
  top: 70%;
  left: calc(50% - 16px);
  width: 42px;
  height: 30px;
  content: "";
  background: url(../img/common/ico_arrow.svg) center / cover no-repeat;
}

.c-button.-arrow {
  font-weight: 500;
  background-image: url(../img/common/ico_arrow.svg);
  background-repeat: no-repeat;
  @include pc {
    @include fz(14);
    width: auto;
    max-width: none;
    padding: 1.367em 6em;
    background-position: right 30px center;
  }
  @include touch {
    padding-top: 1.29em;
    padding-bottom: 1.29em;
    background-position: right 28px center;
    background-size: 25px 16px;
  }
  @include wide {
    @include fz(15);
    padding: 1.367em 7.68em;
  }
}

@include touch {
  .c-button {
    padding: 1.4em 0.7em;
  }
  .c-button.-map {
    width: 130px;
    height: 130px;
  }
  .c-button.-map::after {
    left: calc(50% - 10px);
    width: 25.2px;
    height: 18px;
  }
}
@include tb {
  .c-button.-map {
    font-size: 16px;
  }
}

/* ルビ */
.c-menuRuby {
  display: table;
  font-size: 19px;
  line-height: 1.2;
  color: #fff;
  text-align: left;
  text-transform: uppercase;
  ruby-align: left;
  ruby-align: start;
  ruby-position: under;
  @include font-webfont_zeitung_bold;
}

.c-menuRuby rt {
  padding-top: 4px;
  font-size: 10px;
  text-align: inherit;
}

@-moz-document url-prefix() {
  .c-menuRuby {
    position: relative;
    padding-bottom: 0.7em;
  }
  .c-menuRuby rt {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

@media all and (-ms-high-contrast: none) {
  .c-menuRuby rt {
    display: table-footer-group;
    line-height: 2;
  }
}

@supports (-ms-ime-align: auto) {
  .c-menuRuby rt {
    display: table-footer-group;
  }
}

@include pc {
  .c-menuRuby {
    font-size: 32px;
  }
  .c-menuRuby rt {
    font-size: 13px;
  }
}
@include wide {
  @-moz-document url-prefix() {
    .c-menuRuby {
      padding-bottom: 0.5em;
    }
  }
}

/* 背景色 */
.c-bg,
.c-bg > * {
  position: relative;
}

.c-bg::before {
  position: absolute;
  top: 0;
  right: calc(50% - 50vw);
  left: calc(50% - 50vw);
  height: 100%;
  content: "";
}

.c-bg.-beige::before {
  background: $var-bg-beige;
}

.c-bg.-home::before {
  height: 900px;
  @include pc {
    height: 700px;
  }
  @include wide {
    height: 600px;
  }
}

@include touch {
  .c-bg::before {
    right: -20px;
    left: -20px;
  }
}

/* -------------------------------------------------- layout */
@include touch {
  .l-column .c-button {
    display: block;
    max-width: 200px;
    margin: 40px auto 0;
  }
}
@include pc {
  .l-column {
    display: grid;
    grid-template: auto auto / 1fr 1fr 1fr;
    grid-gap: 0 32px;
  }
  .l-column .c-text {
    grid-area: 1 / 2 / 3 / 4;
    -ms-grid-column: 3;
    -ms-grid-column-span: 3;
  }
  .l-column .c-button {
    grid-area: 2 / 1 / 3 / 2;
    margin-top: auto;
  }
}

@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

// nav ▼

.p-faqNav {
  padding-right: 20px;
  padding-left: 20px;
  margin-bottom: 52px;
  overflow: auto;
  white-space: nowrap;
  @include tb {
    width: 100%;
    max-width: 878px;
    height: auto;
    margin: 40px auto 34px;
  }
  @include pc {
    max-width: 1440px;
  }
  &__inner {
    width: 836px;
    padding-right: 20px;
    @include tb {
      display: block;
      width: 100%;
      max-width: 878px;
      height: auto;
      padding-right: 0;
      margin: 0 auto;
      overflow: auto;
      white-space: nowrap;
    }
    @include pc {
      max-width: 878px;
      padding: 0;
    }
  }
  &__btns {
    display: flex;
    justify-content: space-between;
  }
}

// contents ▼

.p-faq {
  @include tb {
    width: 100%;
    max-width: 1440px;
    height: auto;
    padding: 63px 0 93px;
    margin: 0 auto;
  }
  &__main {
  }
  &.-bgColor {
    width: 100vw !important;
    max-width: 100vw !important;
    padding-right: 20px;
    padding-left: 20px;
    margin-right: calc(((100vw - 100%) / 2) * -1);
    margin-left: calc(((100vw - 100%) / 2) * -1);
    background-color: #f6f5f3;
    @include pc {
      padding-right: 120px;
      padding-left: 120px;
    }
  }
  &__inner {
    padding: 72px 0;
    @include tb {
      display: block;
      width: 100%;
      max-width: 878px;
      height: auto;
      padding: 5px 20px 0;
      margin: 0 auto;
    }
    @include pc {
      padding: 5px 0 0;
    }
  }
  &__heading {
    @include sourceHanSansJP_bold;
    font-size: 18px;
    line-height: calc(26 / 36);
    color: #444;
    letter-spacing: 0.12em;
    &::after {
      display: block;
      width: 30px;
      height: 1px;
      margin-top: 14px;
      content: "";
      background-color: #444;
      @include tb {
        width: 40px;
        height: 2px;
        margin-top: 16px;
      }
    }
  }
  &__listWrap {
    width: auto;
    height: auto;
  }
  &__list {
    margin-top: 26px;
    @include tb {
      margin-top: 37px;
    }
  }
  &__listInner {
    display: flex;
  }
  &__listTitle {
    display: block;
    width: 100%;
    height: auto;
    margin-top: 4px;
    margin-bottom: 15px;
    font-size: 15px;
    line-height: calc(44 / 30);
    color: #444;
    letter-spacing: 0.12em;
    @include sourceHanSansJP_medium;
    @include tb {
      margin-bottom: 11px;
      font-size: 16px;
      line-height: calc(26 / 16);
    }
  }
  &__listDescription {
    display: block;
    width: 100%;
    height: auto;
    margin-top: 1px;
    font-size: 14px;
    line-height: calc(46 / 28);
    color: #444;
    letter-spacing: 0.12em;
    @include sourceHanSansJP_regular;
    @include tb {
      margin-top: 5px;
      line-height: calc(26 / 16);
    }
  }
  &__listHead {
    display: block;
    width: 30px;
    height: 30px;
    padding-top: 4px;
    padding-left: 1px;
    margin-right: 21px;
    font-size: 13px;
    line-height: calc(26 / 15);
    color: #fff;
    text-align: center;
    letter-spacing: 0.12em;
    border-radius: 50%;
    @include font-webfont_zeitung_semibold;
    @include tb {
      width: 34px;
      height: 32px;
      padding-top: 3px;
      margin-right: 18px;
      font-size: 15px;
    }
  }
  .-green {
    background-color: #54b1b4;
  }
  .-orange {
    background-color: #f74608;
  }
}

@charset "utf-8";
//@include sp {};
//@include tb {};
//@include pc {};

.c-pageNation {
  margin-bottom: 145px;
  text-align: center;
  @include tb {
    margin-bottom: 213px;
  }
  &.-about {
    margin-top: 80px;
    margin-bottom: 0;
  }
  &__inner {
    @include pc {
      max-width: 878px;
      height: auto;
      margin: 0 auto;
    }
  }
  &__numbers {
    position: relative;
    display: inline-block;
    width: auto;
    max-width: 203px;
    height: auto;
    margin: 0 auto;
    &::before {
      position: absolute;
      right: 0;
      left: 0;
      display: block;
      max-width: 71px;
      height: 3px;
      margin: 0 auto;
      content: "";
      background: #000;
    }
    @include pc {
      max-width: 248px;
      &::before {
        max-width: 80px;
      }
    }
  }
  &__numberpoints {
    display: flex;
    justify-content: center;
    column-gap: 24px;
    @include pc {
    }
  }
  &__numberPoint {
    position: relative;
    display: block;
    margin-top: 19px;
    text-align: center;
    @include pc {
      display: block;
      width: 20px;
      height: 20px;
      margin-top: 20px;
    }
    &:hover {
      @include pc {
        width: 10px;
      }
    }
  }
  &__number {
    @include font-webfont_zeitung_semibold;
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    font-size: 13px;
    line-height: calc(28.8 / 13);
    color: #444;
    text-decoration: none;
    letter-spacing: 0.12em;
    transition: all 0.2s ease 0s;
    backface-visibility: hidden;
    @include pc {
      font-size: 15px;
    }
    // &:hover {
    //   @include pc {
    //     font-size: 15px;
    //     line-height: calc(32 / 15);
    //     color: #f7d65b;
    //     transition: all 0.2s ease 0s;
    //   }
    // }
  }
  &__numberPoint {
    @include font-webfont_zeitung_semibold;
    position: relative;
    display: block;
    width: 10px;
    height: 100%;
    font-size: 13px;
    line-height: calc(28.8 / 13);
    color: #444;
    text-decoration: none;
    letter-spacing: 0.12em;
    transition: all 0.2s ease 0s;
    backface-visibility: hidden;
    @include pc {
      font-size: 15px;
      // width: 100%;
      width: 10px;
    }
    // &:hover {
    //   @include pc {
    //     font-size: 15px;
    //     line-height: calc(32 / 15);
    //     color: #f7d65b;
    //     transition: all 0.2s ease 0s;
    //   }
    // }
    .active {
      &::before {
        position: absolute;
        bottom: -15px;
        left: -1px;
        display: inline-block;
        width: 10px;
        height: 10px;
        content: "";
        background: #519c60;
        border-radius: 100%;
        @include pc {
          top: 35px;
          bottom: -22px;
          left: -2px;
          width: 11px;
          height: 11px;
        }
      }
    }
    &.-pcView {
      display: none;
      @include tb {
        display: block;
      }
    }
  }
  &__arrow {
    &.-prev {
      position: absolute;
      top: 1px;
      left: -13vw;
      @include tb {
        left: -44px;
      }
    }
    &.-next {
      position: absolute;
      top: 1px;
      right: -13vw;
      @include tb {
        right: -44px;
      }
    }
  }
  &__arrow,
  &__underDot {
    display: block;
    width: 20px;
    height: 20px;
    margin-top: 22px;
    text-align: center;
    font-size: 13px;
    @include pc {
      font-size: 15px;
    }
  }
  &__underDot {
    margin-top: 20px;
    font-size: 18px;
    @include pc {
      margin-top: 26px;
    }
  }
  &__arrow {
    a {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}

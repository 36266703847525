@charset "utf-8";
//@include sp {};
//@include tb {};
//@include pc {};
//@include onlyTB {};
//@include onlyPC {};

.l-subHeader {
  position: relative;
  width: 100%;
  height: 77px;
  padding: 0 20px;
  margin: 0 auto;
  @include tb {
    width: 100%;
    max-width: 1440px;
    height: 127px;
    margin: 0 auto;
  }
  &__inner {
    position: relative;
    display: block;
    width: 100%;
    max-width: 878px;
    height: 77px;
    margin: 0 auto;
    @include tb {
      width: 100%;
      max-width: 878px;
      height: 127px;
      margin: 0 auto;
    }
  }
  &__heading {
    position: absolute;
    top: 26px;
    z-index: 0;
    display: block;
    font-size: 32px;
    line-height: calc(51.7 / 32);
    text-transform: uppercase;
    @include pc {
      top: 43px;
      left: 79px;
      font-size: 42px;
    }
    @include onlyTB {
      top: 43px;
      left: 10%;
      font-size: 42px;
      letter-spacing: 0.02em;
    }
    @mixin onlyPC {
      top: 60px;
      left: 134px;
    }
    @at-root .l-subHeader.-faq & {
      @include pc {
        left: 0;
      }
      @include onlyTB {
        left: 0;
      }
      @mixin onlyPC {
        left: 0;
      }
    }
    @at-root .l-subHeader.-inquires & {
      top: 21px;
      @include tb {
        top: 5px;
        left: 0;
      }
    }
  }
  &__subHeading {
    position: absolute;
    top: 70px;
    z-index: 0;
    display: block;
    font-size: 16px;
    line-height: calc(51.7 / 32);
    color: #808080;
    text-transform: uppercase;
    @include sourceHanSansJP_bold;
    @include tb {
      position: absolute;
      top: 67px;
      z-index: 0;
      display: block;
      font-size: 16px;
      line-height: calc(32 / 16);
      text-transform: uppercase;
    }
  }
  &__character {
    position: absolute;
    top: 43px;
    right: 14px;
    z-index: 10;
    display: block;
    width: 67px;
    height: auto;
    @include tb {
      top: 56px;
      right: 44px;
      width: 100px;
      height: auto;
    }
    @include pc {
      top: 56px;
      right: 30px !important;
      width: 100px;
      height: auto;
    }
    @at-root .l-subHeader.-snoopy & {
      position: absolute;
      top: 42px;
      right: 24px;
      display: block;
      width: 51px;
      height: auto;
      @include tb {
        top: 62px;
        right: 63px !important;
        width: 74px;
        height: auto;
      }
      @include pc {
        right: 23px;
        width: 74px;
        height: auto;
      }
    }
    @at-root .l-subHeader.-faq & {
      position: absolute;
      top: 42px;
      right: 24px;
      display: block;
      width: 51px;
      height: auto;
      @include tb {
        top: 68px;
        right: 0 !important;
        width: 89px;
        height: auto;
      }
      @include pc {
        right: 23px;
        width: 74px;
        height: auto;
      }
    }
  }
  &__characterImg {
    display: block;
    width: 100%;
    height: auto;
  }
}

@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include touch{}
*/

.c-pageHeading {
  margin-bottom: 40px;
  &__title {
    display: flex;
    align-items: baseline;
  }
  &__jp {
    @include fz(20);
    line-height: (36/20);
    @include lhCrop(line-height/fontsize);
  }
  &__en {
    @include fz(12);
    margin-left: 15px;
    text-transform: uppercase;
  }
  &__lead {
    @include fz(16);
    margin-top: 1em;
  }
}

@charset "utf-8";
//@include sp {};
//@include tb {};
//@include pc {};
//@include onlyTB {};
//@include onlyPC {};

.p-confirm {
  margin-top: 44px;
  margin-bottom: 78px;
  @include tb {
    margin-top: 62px;
    margin-bottom: 46px;
  }
  &__inner {
    @include tb {
      display: flex;
      justify-content: space-between;
    }
    &:not(:last-child) {
      @include tb {
        margin-bottom: 5px;
      }
    }
    & > .p-inquires__formOneLine {
      margin-bottom: 20px;
      @include tb {
        margin-bottom: 9px;
      }
      .p-inquires__formOneLineInner {
        margin-bottom: 9px;
      }
    }
  }
  &__answerArea {
    width: 100%;
    @include tb {
      width: 47.8%;
    }
  }
  &__answer {
    @include sourceHanSansJP_regular;
    margin-bottom: 11px;
    font-size: 14px;
    line-height: calc(22 / 14);
    letter-spacing: 0.12em;
  }
}

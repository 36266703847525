@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include touch{}
*/

html:not(.wf-active) {
	opacity: 0;
}
html.is-anim {
	transition: opacity .3s;
}

//本文（和文）：Source Han Sans Japanese / regular 400, Medium 500, Bold 700
//見出し等の欧文：Zeitung / semi bold 600, Bold 700
//VIEW MORE：Bitter / regular 400

//@include font-webfont_bitter;
@mixin font-webfont_bitter {
  font-family: bitter, serif;
  font-style: normal;
  font-weight: 400;
}

//@include font-webfont_zeitung_bold;
@mixin font-webfont_zeitung_bold {
  font-family: zeitung, sans-serif;
  font-style: normal;
  font-weight: 700;
}

//@include font-webfont_zeitung_semibold;
@mixin font-webfont_zeitung_semibold {
  font-family: zeitung, sans-serif;
  font-style: normal;
  font-weight: 600;
}

//@include sourceHanSansJP_regular;
@mixin sourceHanSansJP_regular {
  font-family: source-han-sans-japanese, sans-serif;
  font-style: normal;
  font-weight: 400;
}

//@include sourceHanSansJP_medium;
@mixin sourceHanSansJP_medium {
  font-family: source-han-sans-japanese, sans-serif;
  font-style: normal;
  font-weight: 500;
}

//@include sourceHanSansJP_bold;
@mixin sourceHanSansJP_bold {
  font-family: source-han-sans-japanese, sans-serif;
  font-style: normal;
  font-weight: 700;
}

//@include font-JP;
@mixin font-JP {
  //font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, sans-serif;
  font-family: source-han-sans-japanese, sans-serif;
  font-style: normal;
  font-weight: 400;
}

//@include fz();
@mixin fz($size, $base: 16) {
  font-size: $size + px;
  font-size: ($size / $base) + rem;
}

// Source Han Sans JP ▼

// @include sourceHanSansJP_regular;
@mixin sourceHanSansJP_regular {
  font-family: source-han-sans-japanese, sans-serif;
  font-style: normal;
  font-weight: 400;
}

// @include sourceHanSansJP_bold;
@mixin sourceHanSansJP_bold {
  font-family: source-han-sans-japanese, sans-serif;
  font-style: normal;
  font-weight: 700;
}

//@include sourceHanSansJP_medium;
@mixin sourceHanSansJP_medium {
  font-family: source-han-sans-japanese, sans-serif;
  font-style: normal;
  font-weight: 500;
}

@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include touch{}
*/

.c-card {
  display: block;
  @at-root .p-cardAll & {
    //    max-width: 280px;
  }
  &__link {
    display: block;
    text-decoration: none;
  }
  &__figure {
    overflow: hidden;
    border-radius: 20px;
    img {
      width: 100%;
      height: auto;
    }
  }
  &__meta {
    display: flex;
  }
  &__cat {
    a {
      text-decoration: none;
    }
  }
  &__tag {
    margin-left: 8px;
    text-transform: uppercase;
    letter-spacing: 0.06em;
    white-space: nowrap;
    @include fz(13);
    @include font-webfont_zeitung_bold;
    &.-new {
      color: #f74608;
    }
    &.-comingsoon {
      color: #54b1b4;
    }
    &.-soldout {
      color: #999;
    }
  }
  &__body {
    padding-top: 30px;
  }
  &__date {
    margin-left: 20px;
  }
  &__title {
    @include fz(16);
    line-height: (26/16);
    letter-spacing: 0.06em;
    @include lhCrop(26/16);
    @include sourceHanSansJP_medium;
  }
  &__text {
    @include fz(13);
    margin-top: 20px;
    line-height: (22/13);
    color: #808080;
    letter-spacing: 0.12em;
    @include lhCrop(22/13);
  }
  &__price {
    @include fz(13);
    margin-top: 18px;
    @include sourceHanSansJP_medium;
    letter-spacing: 0.06em;
  }
  &__pricelist {
    padding: 0 20px 20px;
  }
}

.p-workshopSchedule {
  padding-bottom: 70px;
  &__title {
    @include visually-hidden;
  }
  &__notice {
    @include fz(15);
    line-height: (26 / 15);
    text-align: center;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
    @include sp {
      flex-direction: column;
    }
  }
  &__item {
    background-color: #fff;
    @include wide {
      width: calc((100% - 30px * 2) / 3 - 2px);
      max-width: 380px;
    }
    @include pc {
      border-radius: 20px;
    }
    @include normal {
      width: calc((100% - 30px) / 2 - 2px);
    }
    @include touch {
      border-radius: 10px;
    }
    &:not(:nth-child(3n)) {
      @include wide {
        margin-right: 30px;
      }
    }
    &:nth-child(n + 4) {
      @include wide {
        margin-top: 30px;
      }
    }
    &:nth-child(n + 3) {
      @include normal {
        margin-top: 30px;
      }
    }
    &:nth-child(odd) {
      @include normal {
        margin-right: 30px;
      }
    }
    &:not(:first-child) {
      @include sp {
        margin-top: 30px;
      }
    }
  }
}

.p-accessBus {
  /* autoprefixer grid: no-autoplace */
  @include pc {
    display: block;
    grid-template-areas: "title image" "description image" "button image";
    grid-template-rows: 96px 104px 80px;
    grid-template-columns: 1fr 50%;
    column-gap: 80px;
    padding-top: 60px;
    padding-bottom: 145px;
  }
  @include touch {
    padding-top: 50px;
    padding-bottom: 70px;
  }
  &__title {
    padding-top: 20px;
    @include pc {
      grid-area: title;
    }
  }
  &__image {
    @include pc {
      grid-area: image;
    }
    @include touch {
      margin-top: 35px;
      text-align: center;
    }
  }
  &__description {
    letter-spacing: 0.06em;
    @include pc {
      @include fz(15);
      grid-area: description;
      margin-top: 26px;
      line-height: (32 / 15);
    }
    @include touch {
      @include fz(14);
      margin-top: 30px;
      line-height: (52 / 28);
    }
  }
  &__btn {
    @include pc {
      grid-area: button;
    }
    @include touch {
      max-width: 240px;
      margin-top: 30px;
      margin-right: auto;
      margin-left: auto;
    }
  }
}

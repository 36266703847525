.p-gallery {
  @include pc {
    margin-right: -80px;
    margin-left: -80px;
  }
  @include touch {
    margin-right: -20px;
    margin-left: -20px;
  }
  &__main {
    @include pc {
      padding-right: 80px;
      padding-left: 80px;
      margin-top: -37px;
      margin-bottom: 175px;
    }
    @include touch {
      padding-right: 20px;
      padding-left: 20px;
      margin-top: -77px;
    }
  }
}

.c-tab {
  &__list {
    display: flex;
    justify-content: center;
  }
  &__button {
    color: #fff;
    letter-spacing: inherit;
    background-color: $var-green;
    @include pc {
      @include fz(14);
      padding: 6px 40px;
      border-radius: 19px;
    }
    @include touch {
      @include fz(13);
      padding: 9px 36px;
      border-radius: 22px;
    }
    &:nth-child(n + 2) {
      @include pc {
        margin-left: 16px;
      }
      @include touch {
        margin-left: 23px;
      }
    }
    &[aria-selected="false"] {
      opacity: 0.5;
    }
    [data-whatintent="keyboard"] &:focus {
      outline: none;
      box-shadow: 0 0 0 3px $var-color;
    }
  }
  &__contents {
    display: grid;
    grid-template-areas: "panel";
    grid-template-rows: auto;
    grid-template-columns: 1fr;
  }
  &__panel {
    /* autoprefixer grid: no-autoplace */
    grid-area: panel;
    transition: all 0.4s $var-easing;
    &[aria-hidden="true"] {
      display: none;
      pointer-events: none;
      visibility: hidden;
      opacity: 0;
    }
    &:not([aria-hidden="true"]) {
      transition-delay: 0.2s;
      transition-timing-function: $var-easing-reverse;
    }
  }
}

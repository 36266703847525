@charset "utf-8";

.c-viewmore {
  display: inline-block;
  color: #e18b9c;
  font-size: 13px;
  @include font-webfont_bitter
  background: linear-gradient(currentcolor, currentcolor) left bottom / 100% 1px no-repeat;
}
@media (hover: hover) {
  .c-viewmore {
    transition: background-size .4s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .c-viewmore:hover {
    background-position: right bottom;
    background-size: 0 1px;
  }
}
@include touch {
  .c-viewmore {
    font-size: 12px;
  }
}
@charset "utf-8";

/*
@include touch {} // max-width: 979px
@include sp {}    // max-width: 599px
@include tb {}    // min-width: 600px
@include pc {}    // min-width: 980px
@include wide {}  // min-width: 1280px
*/

.c-character {
  pointer-events: none;
  .c-character__img {
    width: inherit;
    height: inherit;
  }
  &:not(.active) {
    opacity: 0;
  }
  &.active {
    transition: opacity 1s;
  }
}

@keyframes sprites {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 0;
  }
}

/* -------------------------------------------------- NEWS (charlie brown / snoopy) */
.c-character.-charlie {
  position: absolute;
  top: 98px;
  right: 34px;
  z-index: 1;
  img {
    opacity: 0;
  }
  .c-character__sprites {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
  }
  .c-character__sprites::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: url(../img/news/charlie_brown_sprites.png) 0 0 no-repeat;
    background-size: calc(105% * 9) 100%;
  }
  @include sp {
    width: 71px;
    height: 71px;
  }
  @include tb {
    top: 77px;
    right: 64px;
    width: 105px;
    height: 105px;
  }
  @include pc {
    top: 53px;
    right: 132px;
  }
  @media print, screen and (min-width: 1166px) {
    right: calc(50% - 450px);
  }
  &:not(.active) {
    transform: translateX(32px);
  }
  &.active {
    transition: opacity 1s, transform 1s $var-easing;
    .c-character__sprites {
      animation: charlieWalk 0.5s both cubic-bezier(0.55, 0.085, 0.68, 0.53);
    }
    .c-character__sprites::after {
      animation: sprites 0.6s 0.1s steps(8) both;
    }
  }
  @keyframes charlieWalk {
    0% {
      transform: translateY(-16%);
    }
    40% {
      transform: translateY(0);
    }
    70% {
      transform: translateY(-6%);
    }
    100% {
      transform: translateY(0);
    }
  }
}

.c-character.-snoopy {
  position: absolute;
  top: 98px;
  right: 64px;
  &::before,
  &::after {
    position: absolute;
    bottom: 0;
    width: 8px;
    height: 8px;
    content: "";
  }
  &::before {
    left: 11px;
    background: url(../img/news/obj_1.svg) center / cover no-repeat;
  }
  &::after {
    left: 40px;
    background: url(../img/news/obj_2.svg) center / cover no-repeat;
  }
  @include sp {
    width: 52px;
    height: 70px;
  }
  @include tb {
    top: 86px;
    right: 108px;
    width: 75px;
    height: 100px;
    &::before,
    &::after {
      width: 10px;
      height: 10px;
    }
    &::before {
      left: 16px;
    }
    &::after {
      left: 59px;
    }
  }
  @include pc {
    top: 62px;
    right: 210px;
  }
  @media print, screen and (min-width: 1280px) {
    right: calc(50% - 415px);
  }
  &:not(.active) {
    transform: translateX(64px);
    &::before,
    &::after {
      transform: rotate(360deg);
    }
  }
  &.active {
    transition: opacity 1s, transform 1s $var-easing;
    &::before,
    &::after {
      transition: transform 1s $var-easing;
    }
  }
}

/* -------------------------------------------------- TICKET (schroeder / sally brown) */
.c-character.-schroeder {
  @include sp {
    position: absolute;
    top: 40px;
    right: 30px;
    width: 37px;
    height: 70px;
  }
  @include tb {
    position: relative;
    top: 2px;
    width: 52px;
    height: 100px;
    margin: -100px 59px 0 auto;
    margin-right: 6.6%;
  }
  .c-character__obj {
    position: absolute;
    &::after {
      display: block;
      width: 18px;
      height: 18px;
      content: "";
    }
    &:first-of-type {
      top: 28px;
      left: -17px;
      &::after {
        background: url(../img/ticket/obj_1.svg) center / cover no-repeat;
      }
    }
    &:last-of-type {
      top: 4px;
      right: -20px;
      &::after {
        background: url(../img/ticket/obj_2.svg) center / cover no-repeat;
      }
    }
  }
  &:not(.active) {
    transform: translateX(-12px);
    .c-character__obj:first-of-type {
      transform: translate(50%, 150%);
    }
    .c-character__obj:last-of-type {
      transform: translate(-20%, 150%);
    }
  }
  &.active {
    transition: opacity 1s, transform 1s $var-easing;
    .c-character__obj {
      opacity: 0;
      transition: opacity 1s, transform 1s $var-easing;
    }
    .c-character__obj:first-of-type {
      transform: rotate(-20deg);
      &::after {
        animation: noteDance 1s both $var-easing;
      }
    }
    .c-character__obj:last-of-type {
      transform: rotate(10deg);
      &::after {
        animation: noteDance 1s both $var-easing;
      }
    }
  }
  @keyframes noteDance {
    0% {
      transform: translateX(0);
    }
    20% {
      transform: translateX(-4%);
    }
    40% {
      transform: translateX(10%);
    }
    60% {
      transform: translateX(-8%);
    }
    80% {
      transform: translateX(6%);
    }
    100% {
      transform: translateX(0);
    }
  }
}

.c-character.-sally {
  @include sp {
    width: 41px;
    height: 70px;
  }
  @include tb {
    width: 58px;
    height: 101px;
  }
  &:not(.active) .c-character__img {
    transform: translateY(-12px);
  }
  &.active .c-character__img {
    transition: transform 1s $var-easing;
  }
}

/* -------------------------------------------------- WORKSHOP (lucy) */
.c-character.-lucy {
  position: relative;
  img {
    opacity: 0;
  }
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: url(../img/workshop/lucy_sprites.png) 0 0 no-repeat;
    background-size: 900% 100%;
  }
  @include sp {
    width: 43px;
    height: 71px;
  }
  @include tb {
    width: 75px;
    height: 123px;
  }
  &.active::after {
    animation: sprites 0.4s 0.1s steps(8) both;
  }
}

/* -------------------------------------------------- ACCESS (peppermint patty) */
.c-character.-peppermint_patty {
  /* MEMO: 62x70 <- touch | pc -> 94x106 */
  img {
    opacity: 0;
  }
  background: url(../img/access/peppermint_patty_sprites.png) 0 0 no-repeat;
  background-size: calc(100% * 9) 100%;
  &.active {
    animation: sprites 0.6s steps(8) 3 both;
  }
}

/* -------------------------------------------------- FAQ (linus) */
.c-character.-linus {
  position: relative;
  img {
    opacity: 0;
  }
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: url(../img/faq/linus_sprites.png) 0 0 no-repeat;
    background-size: 900% 100%;
  }
  @include sp {
    top: -90px;
    width: 63px;
    height: 70px;
    margin: -70px 10px 0 auto;
    &::after {
    }
  }
  @include tb {
    top: -70px;
    width: 90px;
    height: 101px;
    margin: -101px 60px 0 auto;
  }
  @include pc {
    margin-right: calc(50% - 442px);
  }
  &.active::after {
    animation: sprites 0.4s 0.1s steps(8) both;
  }
}

.c-carousel {
  $block: &;
  max-width: 1340px;
  @include pc {
    width: 50%;
  }
  &:nth-child(n + 2) {
    @include pc {
      margin-left: 80px;
    }
    @include touch {
      margin-top: 44px;
    }
  }
  &__container {
    position: relative;
  }
  &__stage {
    #{$block} & {
      overflow: hidden;
      @include pc {
        border-radius: 20px;
      }
      @include touch {
        border-radius: 10px;
      }
    }
  }
  &__content {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    transition-timing-function: ease;
    transition-duration: 0.2s;
    transition-property: transform;
    transform: translateX(0);
  }
  &__item {
    flex-basis: 100%;
    flex-grow: 0;
    flex-shrink: 0;
  }
  &__image {
    display: block;
    width: 100%;
    height: auto;
  }
  &__button {
    position: absolute;
    top: 0;
    width: 44px;
    height: 100%;
    padding: 0;
    overflow: hidden;
    color: transparent;
    background-color: transparent;
    background-image: none;
    border-width: 0;
    transition-timing-function: linear;
    transition-duration: 0.2s;
    transition-property: color;
    @include pc {
      top: 50%;
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    #{$block}__stage:hover + &:not(:disabled),
    #{$block}__stage:hover + & + &:not(:disabled) {
      @include pc {
        color: #fff;
        background-color: rgba($var-blue, 0.9);
      }
    }
    &:disabled {
      color: transparent;
      cursor: default;
      @include pc {
        background-color: transparent;
      }
    }
    &:not(:disabled):hover {
      color: #fff;
      background-color: rgba($var-blue, 0.9);
    }
    &::before {
      display: block;
      width: 100%;
      height: 100%;
      content: "";
    }
    &::after {
      position: absolute;
      top: 50%;
      width: 14px;
      height: 14px;
      content: "";
    }
    &.-prev {
      left: 0;
      @include pc {
        transform: translate(-50%, -50%);
      }
      @include touch {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      &::after {
        left: 50%;
        box-shadow: inset 4px 4px 0 currentColor;
        transform: rotate(-45deg) translate(0, -50%);
      }
    }
    &.-next {
      right: 0;
      @include pc {
        transform: translate(50%, -50%);
      }
      @include touch {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
      &::after {
        right: 50%;
        box-shadow: inset -4px 4px 0 currentColor;
        transform: rotate(45deg) translate(-2px, -8px);
      }
    }
    [data-whatintent="keyboard"] &:focus {
      color: #fff;
      background-color: rgba($var-blue, 0.9);
      outline: none;
      box-shadow: 0 0 0 3px $var-color;
    }
  }
  &__footer {
    padding: 0 36px;
    @include pc {
      margin-top: 18px;
    }
    @include touch {
      margin-top: 25px;
    }
  }
  &__indicator {
    display: flex;
    justify-content: center;
  }
  &__indicatorItem {
    width: 8px;
    height: 8px;
    margin: 0 4px;
  }
  &__indicatorButton {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 0;
    overflow: hidden;
    background-color: rgba($var-blue, 0.6);
    background-image: none;
    border-radius: 50%;
    transition-timing-function: linear;
    transition-duration: 0.2s;
    transition-property: background-color;
    &:disabled {
      cursor: default;
      background-color: $var-blue;
    }
    &::before {
      display: block;
      width: 100%;
      height: 0;
      padding-top: 100%;
      content: "";
    }
    [data-whatintent="keyboard"] &:focus {
      outline: none;
      box-shadow: 0 0 0 3px $var-color;
    }
  }
}

@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.p-english {
  @include pc {
    max-width: 880px;
    height: auto;
    margin: 0 auto;
  }
  .c-h2 {
    margin-top: 70px;
  }
  .c-btnWrap + .c-h2 {
    margin-top: 100px;
    @include pc {
      margin-top: 70px;
    }
  }
  .c-btn {
    &.-ticket {
      @include pc {
        padding-top: 50px;
      }
    }
    &.-dayTicket {
      margin-top: 32px;
      @include pc {
        padding-top: 0;
        margin-top: 52px;
      }
    }
  }
  &__text {
    @include pc {
      margin-bottom: 46px;
    }
    p,
    li {
      @include sourceHanSansJP_regular;
      @include fz(13);
      line-height: calc(26 / 13);
      letter-spacing: 0.12em;
      @include pc {
        @include fz(15);
        margin-bottom: 20px;
        line-height: calc(26 / 15);
      }
    }
    p,
    ul {
      @include pc {
        margin-bottom: 70px;
      }
    }
    &.-access {
      margin-top: 70px;
      margin-bottom: 80px;
      @include pc {
        margin-bottom: 80px;
      }
      p {
        margin-bottom: 20px;
      }
      p:last-of-type {
        margin-bottom: 58px;
      }
    }
  }
  &__tableComment {
    margin-top: 16px;
    @include pc {
      margin-top: 16px;
    }
    p {
      @include sourceHanSansJP_regular;
      @include fz(13);
      margin-bottom: 20px;
      line-height: (26/13);
      letter-spacing: 0.12em;
      @include pc {
        @include fz(15);
        margin-bottom: 20px;
        line-height: (26/15);
      }
    }
    .c-btnWrap + & {
      margin-top: 80px;
    }
    & + .p-ticket__section {
      padding-top: 0 !important;
      margin-top: 0 !important;
    }
  }
  .-noFlex {
    display: block;
  }
  .-pc {
    display: none;
    @include pc {
      display: block;
    }
  }
  .-tableUnder {
    display: block;
    padding: 0 20px;
    margin-top: 16px !important;
    @include pc {
      width: 880px !important;
      padding: 0;
      margin: 0 auto;
    }
  }
  .p-ticket__lead {
    font-size: 13px !important;
    @include tb {
      font-size: 15px !important;
    }
  }
  &__textSmall {
    margin-bottom: 20px;
    line-height: calc(26 / 26);
    color: #808080;
    letter-spacing: 0.12em;
    @include fz(13);
    @include tb {
      margin-bottom: 26px;
      line-height: calc(26 / 13);
    }
  }
}

.p-galleryLook {
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  @include pc {
    padding-right: 80px;
    padding-bottom: 170px;
    padding-left: 80px;
    margin-top: 90px;
  }
  @include touch {
    padding-right: 20px;
    padding-bottom: 100px;
    padding-left: 20px;
    margin-top: 77px;
  }
  &__title {
    @include font-webfont_zeitung_bold;
    position: relative;
    text-align: center;
    @include pc {
      @include fz(22);
      letter-spacing: 0.06em;
    }
    @include touch {
      @include fz(20);
      letter-spacing: 0.02em;
    }
    &::before {
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      height: 3px;
      content: "";
      transform: translateY(-50%);
      @include pc {
        width: calc((100% - 240px) / 2);
        background-color: currentColor;
      }
      @include touch {
        width: calc((100% - 185px) / 2);
        background-image: linear-gradient(to bottom, transparent 0 25%, currentColor 25% 75%, transparent 75% 100%);
      }
    }
    &::after {
      position: absolute;
      top: 50%;
      right: 0;
      display: block;
      height: 3px;
      content: "";
      transform: translateY(-50%);
      @include pc {
        width: calc((100% - 240px) / 2);
        background-color: currentColor;
      }
      @include touch {
        width: calc((100% - 185px) / 2);
        background-image: linear-gradient(to bottom, transparent 0 25%, currentColor 25% 75%, transparent 75% 100%);
      }
    }
  }
  &__text {
    margin-top: 40px;
    text-align: center;
    @include pc {
      @include fz(15);
      line-height: (32 / 15);
    }
    @include touch {
      @include fz(14);
      line-height: (52 / 28);
    }
  }
  &__link {
    position: relative;
    text-transform: uppercase;
    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      content: "";
      border-bottom: 1px solid;
      transition: transform 0.3s $var-easing;
      transform-origin: 0 0;
    }
    &:hover {
      &::after {
        transform: scaleX(0);
        transform-origin: 100% 0;
      }
    }
  }
}
